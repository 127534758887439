<button [matMenuTriggerFor]="menu" class="ds-btn ds-btn--secondary">Projet</button>
<mat-menu #menu="matMenu" (closed)="onMenuClosed()">
  <ng-template matMenuContent>
    <div
      class="date-filters-container"
      (click)="$event.stopPropagation()"
      (keydown.tab)="$event.stopPropagation()"
      (keydown.shift.tab)="$event.stopPropagation()"
      cdkTrapFocus
      [cdkTrapFocusAutoCapture]="true"
    >
      <div class="single-filter-container">
        <span class="ds-input-group__label"> Nombre de partenaires </span>
        <div class="range-filters-container">
          <div
            id="input-min-nb-partenaire"
            class="form-step__total ds-input-group range-filter"
            [class.ds-input-group--error]="minNbPartenaire < 0 || minNbPartenaire > maxNbPartenaire"
          >
            <span class="ds-input-group__input">
              <input
                id="minNbPartenaire"
                name="minNbPartenaire"
                type="number"
                placeholder="Minimum"
                min="0"
                (keypress)="preventValues($event)"
                class="ds-input-group__input"
                [(ngModel)]="minNbPartenaire"
                #minNbPartenaireInput
              />
            </span>
          </div>
          <div
            id="input-max-nb-partenaire"
            class="form-step__total ds-input-group range-filter"
            [class.ds-input-group--error]="maxNbPartenaire < 0 || minNbPartenaire > maxNbPartenaire"
          >
            <span class="ds-input-group__input">
              <input
                id="maxNbPartenaire"
                name="maxNbPartenaire"
                type="number"
                (keypress)="preventValues($event)"
                min="0"
                placeholder="Maximum"
                class="ds-input-group__input"
                [(ngModel)]="maxNbPartenaire"
                #maxNbPartenaireInput
              />
            </span>
          </div>
        </div>
        <!-- message d'erreur -->
        <ng-container id="error-budget" *ngIf="minNbPartenaire > maxNbPartenaire">
          <div class="ds-input-group__feedback">Le minimum doit être inférieur ou égal au maximum</div>
        </ng-container>
      </div>
      <div class="single-filter-container">
        <span class="ds-input-group__label">Thématique </span>
        <lib-custom-multiselect
          #thematiqueFilterComponent
          (autocompleteEvent)="autocompleteEvent($event, THEMATIQUE)"
          (selectedListChange)="selectedListChange($event, THEMATIQUE)"
          [(dataList)]="thematiqueDataList"
          [selectedList]="thematiqueSelectedList"
          placeholder="Thématique"
          name="Thematique"
          class="filter"
          [isLoading]="loadingMap.get(THEMATIQUE)"
          [autocompleteStart]="1"
        >
        </lib-custom-multiselect>
      </div>
      <div class="single-filter-container">
        <span class="ds-input-group__label">Pôle de compétitivité </span>
        <lib-custom-multiselect
          #poleFilterComponent
          (autocompleteEvent)="autocompleteEvent($event, POLE_DE_COMPETITIVITE)"
          (selectedListChange)="selectedListChange($event, POLE_DE_COMPETITIVITE)"
          [(dataList)]="poleDataList"
          [selectedList]="poleSelectedList"
          placeholder="Pôle de compétitivité"
          name="PoleDeCompetitivite"
          class="filter"
          [isLoading]="loadingMap.get(POLE_DE_COMPETITIVITE)"
          [autocompleteStart]="1"
        >
        </lib-custom-multiselect>
      </div>
      <div class="single-filter-container">
        <span class="ds-input-group__label filter-label">Objet du projet </span>
        <span class="subtitle">Description courte </span>
        <div class="filter-container">
          <input
            #objetProjetFilterComponent
            class="filter-input"
            type="text"
            name="ObjetDuProjet"
            id="ObjetDuProjet"
            placeholder="Description courte"
            [(ngModel)]="objetProjetInputValue"
          />
        </div>
      </div>
      <div class="filters-actions">
        <button id="cancel-btn" class="ds-btn ds-btn--tertiary" (click)="resetFilters()">Réinitialiser</button>

        <button id="save-btn" class="ds-btn ds-btn--primary" (click)="applyFilters()">Appliquer</button>
      </div>
    </div>
  </ng-template>
</mat-menu>
