<button [matMenuTriggerFor]="menu" class="ds-btn ds-btn--secondary">Date</button>
<mat-menu #menu="matMenu" (closed)="onMenuClosed()">
  <div
    class="date-filters-container"
    (click)="$event.stopPropagation()"
    (keydown.tab)="$event.stopPropagation()"
    (keydown.shift.tab)="$event.stopPropagation()"
  >
    <div class="single-filter-container">
      <label class="ds-input-group__label filter-label"> Date de relève </label>
      <lib-date-range-picker #dateReleveComponent></lib-date-range-picker>
    </div>
    <div class="single-filter-container">
      <label class="ds-input-group__label filter-label">Copil: date de passage </label>
      <lib-date-range-picker #copilDateDePassageComponent></lib-date-range-picker>
    </div>
    <div class="filters-actions">
      <button id="cancel-btn" class="ds-btn ds-btn--tertiary" (click)="resetFilters()">Réinitialiser</button>

      <button id="save-btn" class="ds-btn ds-btn--primary" (click)="applyFilters()">Appliquer</button>
    </div>
  </div>
</mat-menu>
