<h2 id="modal-title" class="modal__title" *ngIf="modalData.title">{{ modalData.title }}</h2>

<form id="input-typeDoc" class="modal__content" [formGroup]="inputForm">
  <!-- Liste déroulante des années -->
  <div
    class="ds-input-group"
    [class.ds-input-group--error]="!inputForm.controls['currentYear'].valid && inputForm.controls['currentYear'].touched"
  >
    <label for="annee-select" class="ds-input-group__label">Exercice en cours<span class="ds-input-group__label-asterisk"> *</span></label>
    <select id="annee-select" name="annee-select" class="ds-input-group__input" formControlName="currentYear">
      <option [ngValue]="null" selected disabled>Sélectionnez une valeur</option>
      @for (year of modalData.years; track year){
      <option [value]="year">{{ year }}</option>
      }
    </select>
    <!-- Message d'erreur -->
    @if(!inputForm.controls['currentYear']?.valid && inputForm.controls['currentYear']?.touched){
    <ng-container>
      <div class="ds-input-group__feedback">Merci de bien vouloir sélectionner l'exercice en cours</div>
    </ng-container>
    }
  </div>

  <!-- Champ de saisie pour le numéro -->
  <div
    *ngIf="!modalData.onlyYears"
    class="ds-input-group"
    [class.ds-input-group--error]="
      !inputForm.controls['soldeTresorerieDernierBilan'].valid && inputForm.controls['soldeTresorerieDernierBilan'].touched
    "
  >
    <label for="input-field-number" class="ds-input-group__label">
      Solde de trésorerie du dernier bilan<span class="ds-input-group__label-asterisk"> *</span></label
    >

    <input
      id="input-field-number"
      name="input-field-number"
      type="number"
      [placeholder]="'Montant en milliers d’euros TTC'"
      class="ds-input-group__input"
      formControlName="soldeTresorerieDernierBilan"
      #textInputNumber
    />

    <!-- Icône et message d'erreur -->
    @if (!inputForm.controls['soldeTresorerieDernierBilan']?.valid && inputForm.controls['soldeTresorerieDernierBilan']?.touched){
    <ng-container id="error-input-field-number">
      <div class="ds-input-group__feedback">Merci de bien vouloir renseigner le solde de trésorerie du dernier bilan</div>
    </ng-container>
    }
  </div>
</form>

<div id="modal-btn" class="modal__actions">
  <button *ngIf="modalData.textReturnButton" class="ds-btn ds-btn--secondary" (click)="cancelModal()" mat-dialog-close>
    {{ modalData.textReturnButton }}
  </button>
  <button class="ds-btn ds-btn--primary" (click)="saveModal()" cdkFocusInitial>
    {{ modalData.textGoButton }}
  </button>
</div>
