export class FileUtils {
  public static readonly KYC_EXTENSIONS = ['pdf', 'gif', 'png', 'jpg', 'jpeg', 'bmp', 'tif', 'tiff', 'msg'];
  public static readonly FINNO_EXTENSIONS = ['pdf', 'xls', 'xlsx', 'doc', 'docx', 'ppt', 'pptx'];
  public static readonly VIDEO_EXTENSIONS = ['mp4', 'wmv', 'mov', 'flv', 'avi', '3gp'];

  public static getFileExtension(fileName: string): string {
    return (fileName.split('.').pop() || '').toLowerCase();
  }

  public static getFileTypeAndSizeValidity(
    file: File,
    supportedExtensions: string[],
    maxFileSize: number
  ): {
    isFileTypeValid: boolean;
    isFileSizeValid: boolean;
    nameFileNotSupported: string[];
  } {
    const nameFileNotSupported: string[] = [];
    // verifier si le type du fichier est valide par extension
    const isFileTypeValid = supportedExtensions.includes(this.getFileExtension(file.name));
    // verifier si la taille du fichier est valide
    const isFileSizeValid = !(file.size && file.size > maxFileSize * 1048576);
    
    if (!supportedExtensions.includes(file.type) || !isFileSizeValid) {
      nameFileNotSupported.push(file.name);
    }

    return { isFileTypeValid, isFileSizeValid, nameFileNotSupported };
  }
}
