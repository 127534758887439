import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class SidebarStateService {
  clickBehavior = new Subject<boolean>();

  changeState(state: boolean): void {
    this.clickBehavior.next(state);
  }
}
