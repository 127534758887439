import { DatePipe, formatDate } from '@angular/common';

export class DateTimeUtils {
  static datePipe = new DatePipe('en-US');

  /**
   * Format a date to a string in format french dd/mm/yyyy.
   */
  public static toDate(date: Date | undefined): Date | null {
    if (!date) {
      return null;
    } else {
      return new Date(date);
    }
  }

  public static toUtcDate(date: Date | null): Date | null {
    if (!date) {
      return null;
    }

    if (typeof date === 'string') {
      date = new Date(date);
    }

    return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0));
  }

  /**
   * Format a date to a simple date string (without time).
   */
  public static formatToDate(date: Date): string | null {
    if (!date) {
      return null;
    }
    return this.datePipe.transform(new Date(date), 'yyyy-MM-dd');
  }

  /**
   * Convert a string into date
   */
  public static stringToDate(value: string): Date | undefined {
    const date: Date = new Date(value);
    if (isNaN(date.getTime())) {
      return undefined;
    }
    return date;
  }

  /**
   * Calculate number of years between two dates
   */
  public static calculateNbOfYearsBetweenToDate(fromDate: Date, toDate: Date): number {
    return (toDate.getTime() - fromDate.getTime()) / 1000 / 60 / 60 / 24 / 365;
  }

  /**
   * Format a date for front .
   */
  public static formatDateForFront(value: string): string | null {
    if (!value) {
      return null;
    }
    return value
      .replace(/^(\d\d)(\d)$/g, '$1/$2')
      .replace(/^(\d\d\/\d\d)(\d+)$/g, '$1/$2')
      .replace(/[^\d/]/g, '');
  }

  public static generateTimes(startHourIncluded: number, endHourExcluded: number, stepInMinutes: number): string[] {
    const times: string[] = [];
    for (let hour = startHourIncluded; hour < endHourExcluded; hour++) {
      for (let minute = 0; minute < 60; minute += stepInMinutes) {
        const time = `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;
        times.push(time);
      }
    }
    return times;
  }

  /**
   * Formats a given date using the specified format.
   * @param date - The date to be formatted (can be a Date object, string, or number).
   * @param format - The format string (e.g., 'dd/MM/yyyy', 'fullDate').
   * @param locale - The locale string (default is 'fr-FR').
   * @returns The formatted date as a string.
   */
  public static format(date: Date | string | number, format: string = 'dd/MM/yyyy', locale: string = 'fr-FR'): string {
    return formatDate(date, format, locale);
  }

  public static compareDates(dateA: Date | null | undefined, dateB: Date | null | undefined): number {
    // Check if both dates are falsy
    if (!dateA && !dateB) {
      return 0;
    }

    // Check if dateA is falsy
    if (!dateA) {
      return 1; // Push dateA to the back
    }

    // Check if dateB is falsy
    if (!dateB) {
      return -1; // Push dateB to the back
    }

    // If both dates are valid, sort by date
    return new Date(dateA).getTime() - new Date(dateB).getTime();
  }

  public static toLocalDate(date: Date): any {
    if (date) return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())).toISOString().split('T')[0];
  }
}
