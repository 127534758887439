import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@environments-candidat/environment';
import { PxlOidcService } from '@services-candidat/pxl-oidc.service';
import { ShowToastrService, EnumAuthConfigurationType } from '@shared-ui';

@Component({
  selector: 'pxl-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  userData: any;
  isAuthenticated: boolean;
  isMcb: boolean;

  constructor(private router: Router, public oidcSecurityService: PxlOidcService, private showToastrService: ShowToastrService) {}

  ngOnInit(): void {        
    this.isMcb = localStorage.getItem('idp') === EnumAuthConfigurationType.MCB;
    this.oidcSecurityService.isAuthenticated$.subscribe({
      next: ({ isAuthenticated }) => {
        this.isAuthenticated = isAuthenticated;
      },
      error: (err: HttpErrorResponse) => {
        this.showToastrService.checkCodeError(err?.error);
      },
    });

    this.oidcSecurityService.getUserData().subscribe({
      next: userData => {
        if (userData) {
          this.userData = userData;
        }
      },
      error: (err: HttpErrorResponse) => {
        this.showToastrService.checkCodeError(err?.error);
      },
    });
  }

  onGoToAccueil(): void {
    if (this.isAuthenticated) {
      this.router.navigate(['/espace']);
    } else {
      this.router.navigate(['/accueil']);
    }
  }

  deconnexion(): void {
    const configId = localStorage.getItem('idp');
    if (configId === EnumAuthConfigurationType.ISAM) {
      const x = window.open(environment.isam.stsServer + '/pkmslogout', '_blank');
      this.oidcSecurityService.logoffAndRevokeTokens().subscribe(() => {
        x.blur();
        x.close();
        this.router.navigate(['/']).then(() => {
          window.location.reload();
        });
      });
    } else {
      this.oidcSecurityService.logoffAndRevokeTokens().subscribe(() => {});
    }
  }

  monProfilBpi(): void {
    window.open(environment.mcb.profil, '_blank');
  }
}
