import { Component, Input, Output, EventEmitter, ViewChild, ElementRef, OnInit } from '@angular/core';
import { Aap, FilterDataInterface, FilterRsSiretInterface } from '@shared-ui';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'lib-custom-multiselect',
  templateUrl: './multiselect.component.html',
  styleUrls: ['./multiselect.component.scss'],
})
export class MultiselectComponent implements OnInit {
  readonly TIMEOUT_MS = 300;

  @ViewChild('search') searchElement: ElementRef;

  @Input() dataList: FilterDataInterface[];
  @Output() dataListChange = new EventEmitter<FilterDataInterface[]>();
  @Input() selectedList: FilterDataInterface[];
  @Output() selectedListChange = new EventEmitter<FilterDataInterface[]>();
  originalDataList: FilterDataInterface[];

  @Input() name: string;
  @Input() placeholder: string;
  @Input() isLoading = false;
  @Input() isDisabled = false;
  @Input() autocompleteStart = 2;
  @Input() showSelectIcon = false;
  @Input() timeout = this.TIMEOUT_MS;
  @Input() isFrontAutocomplete = false;

  @Output() autocompleteEvent = new EventEmitter<string>();

  isClosed = true;
  focused = false;
  autocompleteInputValue = '';
  autocompleteTimeout: NodeJS.Timeout;

  ngOnInit(): void {
    if (this.isFrontAutocomplete) {
      this.autocompleteStart = -1;
      this.timeout = 0;
      this.originalDataList = cloneDeep(this.dataList);
    }
  }

  emitSelectedList(): void {
    this.selectedListChange.emit(this.selectedList);
  }

  onFocus(): void {
    this.focused = true;
    this.isClosed = false;
    this.searchElement.nativeElement.focus();
  }

  onFocusOut(): void {
    this.focused = false;
    this.isClosed = true;
  }

  /**
   * Vérifie si la liste d'options doit être affichée ou non
   * @returns boolean indiquant si la liste doit être affichée ou non
   */
  showList(): boolean {
    if (this.showSelectIcon && !this.isClosed) {
      return true;
    }
    return this.focused && (this.selectedList?.length > 0 || this.autocompleteInputValue?.trim().length > this.autocompleteStart);
  }

  /**
   * Fonction appelée quand la valeur de l'input change
   */
  onSearchInputChange(): void {
    clearTimeout(this.autocompleteTimeout);
    if (this.autocompleteInputValue.length > this.autocompleteStart) {
      this.autocompleteTimeout = setTimeout(() => {
        this.executeAutocomplete();
      }, this.timeout);
      this.isClosed = false;
    } else {
      this.dataList = [];
    }
  }

  executeAutocomplete(): void {
    if (this.isFrontAutocomplete) {
      this.dataList = this.originalDataList.filter(data => {
        const normalizeString = (str: string) =>
          str
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .toLowerCase();
        const normalizedValue = normalizeString(data?.value || '');
        const normalizedEvent = normalizeString(this.autocompleteInputValue?.trim());
        return normalizedValue.includes(normalizedEvent) || data?.isSelected;
      });
    } else {
      this.autocompleteEvent.emit(this.autocompleteInputValue?.trim());
    }
  }

  /**
   * Clic sélection checkbox
   * @param option checkbox à selectionner
   */
  checkboxSelected(e: Event, option: FilterDataInterface): void {
    e.stopPropagation();
    this.selectedList.push(option);
    this.emitSelectedList();
  }

  /**
   * Clic déselection checkbox
   * @param option checkbox à déselectionner
   */
  checkboxUnselected(e: Event, option: FilterDataInterface): void {
    e.stopPropagation();
    this.selectedList.splice(this.selectedList.indexOf(option), 1);
    this.emitSelectedList();
  }

  /**
   * Determine si on doit afficher une checkbox, car si l'option est déjà
   * sélectionnée, on ne doit pas l'afficher sur la liste de choix
   * @param option checkbox à afficher ou non
   * @returns true si on affiche la checkbox et false sinon
   */
  showFilterOption(option: FilterDataInterface): boolean {
    const find = this.selectedList.filter(data => {
      if (typeof data.id === 'string' || (data.id as Aap).id) {
        return data.id === option.id;
      } else {
        const optionId = option.id as FilterRsSiretInterface;
        return (
          (data.id as FilterRsSiretInterface).raisonSocial === optionId.raisonSocial &&
          (data.id as FilterRsSiretInterface).siret === optionId.siret
        );
      }
    });

    return find.length === 0;
  }

  /**
   * Clic sur la checkbox Sélectionner tout
   */
  checkboxAllClicked(e: Event): void {
    e.stopPropagation();
    e.preventDefault();
    if (this.isCheckboxAllChecked()) {
      this.selectedList = [];
    } else {
      this.dataList.forEach(data => {
        if (this.selectedList.filter(selected => data.id === selected.id).length === 0) {
          this.selectedList.push(data);
        }
      });
    }
    this.emitSelectedList();
  }

  /**
   * Determine si la checkbox Sélectionner tout est checkée ou non
   * @returns true si la checkbox est sélectionnée, false sinon
   */
  isCheckboxAllChecked(): boolean {
    return this.selectedList.length > 0;
  }

  /**
   * Retourne la valeur qui sera affichée sur le placeholder
   */
  getPlaceHolderValue(): string {
    const addCharS = this.selectedList?.length > 1 ? 's' : '';
    return this.selectedList?.length > 0 ? `${this.selectedList?.length} "${this.placeholder}" sélectionné` + addCharS : this.placeholder;
  }

  /**
   * Fonction qui réinitialise les valeurs du champs aux valeurs par défaut
   */
  reset(): void {
    this.autocompleteInputValue = '';
    this.selectedList = [];
    this.emitSelectedList();
  }

  /**
   * Fonction qui toggle l'affichage de l'icon de sélection
   */
  toggleSelectIcon(): void {
    this.isClosed = !this.isClosed;
    this.focused = false;
  }
}
