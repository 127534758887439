import { enumKeys } from '../../utils/enum-utils';

export enum IntervenantRoles {
  EXPERT_MINISTERIEL = 'EXPERT_MINISTERIEL',
  EXPERT_INDEPENDANT = 'EXPERT_INDEPENDANT',
  EXPERT_EXTERNE = 'EXPERT_EXTERNE',
  INSTRUCTEUR_INTERNE = 'INSTRUCTEUR_INTERNE',
  EVALUATEUR_ETAT_EN_LECTURE_SEULE = 'EVALUATEUR_ETAT_EN_LECTURE_SEULE',
  EVALUATEUR_NON_ETAT_EN_LECTURE_SEULE = 'EVALUATEUR_NON_ETAT_EN_LECTURE_SEULE',
  EXPERT_JURY_NATIONAL = 'EXPERT_JURY_NATIONAL',
  VICE_PRESIDENT_JURY_NATIONAL = 'VICE_PRESIDENT_JURY_NATIONAL',
  COORDINATEUR_CPMO = 'COORDINATEUR_CPMO',
  SGPI_DIRECTION_EVALUATION = 'SGPI_DIRECTION_EVALUATION',
  SGPI_DIRECTION_FINANCIERE = 'SGPI_DIRECTION_FINANCIERE',
}

export namespace IntervenantRoles {
  export function toString(role: IntervenantRoles): string {
    switch (role) {
      case IntervenantRoles.EXPERT_MINISTERIEL:
        return 'Expert ministériel';
      case IntervenantRoles.EXPERT_INDEPENDANT:
        return 'Expert indépendant';
      case IntervenantRoles.EXPERT_EXTERNE:
        return 'Expert externe';
      case IntervenantRoles.INSTRUCTEUR_INTERNE:
        return 'Instructeur';
      case IntervenantRoles.EVALUATEUR_ETAT_EN_LECTURE_SEULE:
        return 'Etat';
      case IntervenantRoles.EVALUATEUR_NON_ETAT_EN_LECTURE_SEULE:
        return 'Autre Evaluateur';
      default:
        return '';
    }
  }

  export function all(): IntervenantRoles[] {
    return enumKeys(IntervenantRoles) as unknown as IntervenantRoles[];
  }
}
