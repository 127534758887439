import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { CCU_FILE_NAME, DocumentHelper, DocumentProjet, Environment, SignedUrlResponseModel, SousTypesResponseInterface } from '@shared-ui';
import { Observable, throwError } from 'rxjs';
import { map } from 'rxjs/operators';

// TODO: this service should be renamed to something S3 related
@Injectable()
export class UploadDocumentHttpService {
  private readonly _apiAdminUrl: string;
  private readonly _apiUrl: string;
  private readonly _apiUrlLambda: string;

  constructor(@Inject('environment') private environment: Environment, public httpClient: HttpClient) {
    this._apiAdminUrl = this.environment.apiAdminUrl;
    this._apiUrl = this.environment.apiUrl;
    this._apiUrlLambda = this.environment.apiUrlLambda;
  }

  createS3SignedUrlForDocument(
    documentProjet: DocumentProjet,
    folderPath: string,
    typeUpload: string,
    multiDocument?: boolean
  ): Observable<string> {
    if (!documentProjet.relatedResourceId && !documentProjet.id) {
      return throwError(() => new Error('relatedResourceId or id is missing'));
    }
    const documentId: string = multiDocument ? documentProjet.relatedResourceId! : documentProjet.id;
    return this.getValueForDocUpload(
      documentProjet.nom,
      folderPath,
      typeUpload,
      documentId,
      documentProjet.projetId,
      documentProjet.typeDoc,
      multiDocument
    ).pipe(
      map(response => {
        if (!response.body || !response.body.url) {
          throw new Error("Erreur lors de la récupération de l'url signée");
        }
        return response.body.url;
      })
    );
  }

  getValueForDocDownloadFromPath(path: string, typeBucket: string): Observable<HttpResponse<SignedUrlResponseModel>> {
    return this.httpClient.post<SignedUrlResponseModel>(
      this._apiUrlLambda + 'uploads/signed-url',
      {
        typeAction: 'GET',
        typeBucket: typeBucket,
        nom: path,
      },
      { observe: 'response' }
    );
  }

  getValueForDocDownload(document: DocumentProjet): Observable<HttpResponse<{ url: string }>> {
    const typeUpload = DocumentHelper.getUploadDocumentType(document);
    const typeBucket = DocumentHelper.getDocumentBucketType(typeUpload);
    const filePath = DocumentHelper.getDocumentS3FilePath(document);
    return this.getValueForDocDownloadFromPath(filePath, typeBucket);
  }

  deleteDocumentFromS3(document: DocumentProjet): Observable<void> {
    const typeUpload = DocumentHelper.getUploadDocumentType(document);
    const typeBucket = DocumentHelper.getDocumentBucketType(typeUpload);
    const filePath = DocumentHelper.getDocumentS3FilePath(document);
    return this.httpClient.post<void>(this._apiUrlLambda + 'uploads/signed-url', {
      typeAction: 'DELETE',
      typeBucket: typeBucket,
      nom: filePath,
      metadata: {
        type: typeUpload,
        idProjet: document.projetId,
        idDocument: document.id,
      },
    });
  }

  deleteDocFromS3(
    path: string,
    typeUpload: string,
    documentId: string,
    projetId: string
  ): Observable<HttpResponse<SignedUrlResponseModel>> {
    const typeBucket = DocumentHelper.getDocumentBucketType(typeUpload);
    return this.httpClient.post<SignedUrlResponseModel>(
      this._apiUrlLambda + 'uploads/signed-url',
      {
        typeAction: 'DELETE',
        typeBucket: typeBucket,
        nom: path,
        metadata: {
          type: typeUpload,
          idProjet: projetId,
          idDocument: documentId,
        },
      },
      { observe: 'response' }
    );
  }

  getLogoDocument(idProjet: string): Observable<HttpResponse<DocumentProjet[]>> {
    return this.httpClient.get<DocumentProjet[]>(this._apiUrl + 'documents?projetIds=' + idProjet + '&typesDocuments=MEDIA_IMAGE', {
      observe: 'response',
    });
  }

  getValueForLogoUploadFicheDeCom(path: string, fileName: string, documentId: string): Observable<HttpResponse<SignedUrlResponseModel>> {
    return this.httpClient.post<SignedUrlResponseModel>(
      this._apiUrlLambda + 'uploads/signed-url',
      {
        typeAction: 'PUT',
        typeBucket: 'LOGOS_PUBLIC',
        nom: 'logos/' + path + '/' + fileName,
        metadata: {
          idDocument: documentId,
        },
      },
      { observe: 'response' }
    );
  }

  uploadLogo(url: string, file: File): Observable<any> {
    return this.httpClient.put<any>(url, file);
  }

  getValueForDocLogoDownload(path: string): Observable<HttpResponse<SignedUrlResponseModel>> {
    return this.httpClient.post<SignedUrlResponseModel>(
      this._apiUrlLambda + 'uploads/signed-url',
      {
        typeAction: 'GET',
        typeBucket: 'LOGOS_PUBLIC',
        nom: 'logos/' + path,
      },
      { observe: 'response' }
    );
  }

  deleteDocLogo(path: string): Observable<HttpResponse<SignedUrlResponseModel>> {
    return this.httpClient.post<SignedUrlResponseModel>(
      this._apiUrlLambda + 'uploads/signed-url',
      {
        typeAction: 'DELETE',
        typeBucket: 'LOGOS_PUBLIC',
        nom: 'logos/' + path,
      },
      { observe: 'response' }
    );
  }

  // TODO: Move this method to a dedicated service
  getDocFromGed(documentId: string, isSiteAdmin?: boolean): Observable<HttpResponse<Blob>> {
    return this.httpClient.get<Blob>(`${isSiteAdmin ? this._apiAdminUrl : this._apiUrl}documents/${documentId}/binary`, {
      observe: 'response',
      headers: { Accept: 'application/octet-stream' },
      responseType: 'blob' as 'json',
    });
  }

  getDocumentCCUsUrl() {
    return `${this.environment.docPublicBaseUrl}${CCU_FILE_NAME}`;
  }

  saveDocument(isSiteAdmin: boolean, document: DocumentProjet) {
    return this.httpClient.post<DocumentProjet>(`${isSiteAdmin ? this._apiAdminUrl : this._apiUrl}documents`, JSON.stringify(document), {
      observe: 'response',
    });
  }

  getValueForDocUpload(
    fileName: string,
    path: string,
    typeUpload: string,
    documentId: string,
    projetId: string,
    typeDocument?: string,
    multiDocument?: boolean
  ): Observable<HttpResponse<SignedUrlResponseModel>> {
    const typeBucket = DocumentHelper.getDocumentBucketType(typeUpload);
    const metadata: any = {
      type: typeUpload,
      idDocument: documentId,
      typeDocument: typeDocument ? typeDocument : '',
    };
    if (multiDocument) {
      metadata.isMultiDocument = 'true';
    } else if (projetId) {
      metadata.idProjet = projetId;
    }
    return this.httpClient.post<SignedUrlResponseModel>(
      this._apiUrlLambda + 'uploads/signed-url',
      {
        typeAction: 'PUT',
        typeBucket: typeBucket,
        nom: path + '/' + fileName,
        metadata: metadata,
      },
      { observe: 'response' }
    );
  }

  uploadDoc(
    url: string,
    file: File,
    projetId: string,
    documentId: string,
    typeUpload: string,
    typeDocument?: string,
    multiDocument?: boolean
  ): Observable<HttpResponse<any>> {
    let httpHeader = new HttpHeaders({
      'x-amz-meta-type': typeUpload,
      'x-amz-meta-idDocument': documentId,
      'x-amz-meta-typeDocument': typeDocument ? typeDocument : '',
    });

    if (multiDocument) {
      httpHeader = httpHeader.append('x-amz-meta-isMultiDocument', 'true');
    } else {
      if (projetId) {
        httpHeader = httpHeader.append('x-amz-meta-idProjet', projetId);
      }
    }

    return this.httpClient.put<any>(url, file, { observe: 'response', headers: httpHeader });
  }

  // TODO: Move this method to a dedicated service
  deleteDocument(isSiteAdmin: boolean, idProjet: string, idDocument: string): Observable<HttpResponse<any>> {
    let params = new HttpParams();
    params = params.append('projetId', idProjet);

    return this.httpClient.delete(`${isSiteAdmin ? this._apiAdminUrl : this._apiUrl}documents/${idDocument}`, {
      params: params,
      observe: 'response',
    });
  }

  getSousTypesDocument(): Observable<SousTypesResponseInterface[]> {
    return this.httpClient.get<SousTypesResponseInterface[]>(this._apiAdminUrl + 'documents/sous-type-document');
  }
}
