<div class="ds-select ds-select-group" tabindex="1" (focus)="onFocus()" (libClickOutside)="onFocusOut()">
  <div class="filter-container" [ngClass]="{ 'filter-container-disabled': isDisabled }">
    <input
      #search
      class="filter-input"
      type="text"
      [name]="name"
      [id]="name"
      [placeholder]="getPlaceHolderValue()"
      (focus)="onFocus()"
      (input)="onSearchInputChange()"
      [(ngModel)]="autocompleteInputValue"
      [disabled]="isDisabled"
    />
    <div class="icon-container" *ngIf="!showSelectIcon">
      <mat-spinner diameter="20" *ngIf="isLoading; else finishedLoading"></mat-spinner>
      <ng-template #finishedLoading>
        <lib-pxl-icon class="icon" icon="icon-other-search"></lib-pxl-icon>
      </ng-template>
    </div>
    <ng-container>
      <button class="icon-select-container" *ngIf="showSelectIcon" (click)="toggleSelectIcon()">
        <span class="ds-icon" *ngIf="isClosed; else isOpened" aria-hidden="true">expand_more</span>
        <ng-template #isOpened>
          <span class="ds-icon" aria-hidden="true">expand_less</span>
        </ng-template>
      </button>
    </ng-container>
  </div>
  <ul class="ds-select__options-list" *ngIf="dataList !== null || selectedList?.length > 0" [ngClass]="{ 'show-element': showList() }">
    <li
      class="ds-select__options-list-boxitem ds-select__options-list-boxitem--all"
      *ngIf="selectedList?.length > 0 || dataList?.length > 0; else noResult"
      (click)="checkboxAllClicked($event)"
    >
      <input type="checkbox" name="check-all-list" id="check-all-list" [checked]="isCheckboxAllChecked()" />
      <label for="check-all-list">Sélectionner tout</label>
    </li>
    <ng-template #noResult>
      <li class="ds-select__message-list--empty">Pas d’options trouvées</li>
    </ng-template>
    <ng-container *ngFor="let option of selectedList">
      <li class="ds-select__options-list-boxitem" (click)="checkboxUnselected($event, option)">
        <input type="checkbox" [name]="option.id" [id]="option.id" [checked]="true" />
        <label [for]="option.id">{{ option.value }}</label>
      </li>
    </ng-container>
    <ng-container *ngFor="let option of dataList">
      <li class="ds-select__options-list-boxitem" *ngIf="showFilterOption(option)" (click)="checkboxSelected($event, option)">
        <input type="checkbox" [name]="option.id" [id]="option.id" [checked]="false" />
        <label [for]="option.id">{{ option.value }}</label>
      </li>
    </ng-container>
  </ul>
</div>
