import { Component, OnInit, Renderer2 } from '@angular/core';
import { SidebarStateService } from '../../../services/sidebar-state.service';

@Component({
  selector: 'pxl-sidebar-button',
  templateUrl: './sidebar-button.component.html',
  styleUrls: ['./sidebar-button.component.scss'],
})
export class SidebarButtonComponent implements OnInit {
  private sideBarIsOpen: boolean;

  constructor(private renderer: Renderer2, private sidebarStateService: SidebarStateService) {}

  ngOnInit(): void {
    this.sidebarStateService.clickBehavior.subscribe(state => {
      this.sideBarIsOpen = state;
    });
  }

  toggleSidebar(): void {
    this.sideBarIsOpen = !this.sideBarIsOpen;
    this.sidebarStateService.changeState(this.sideBarIsOpen);

    if (this.sideBarIsOpen) {
      this.renderer.removeClass(document.body, 'sidebar-closed');
    } else {
      this.renderer.addClass(document.body, 'sidebar-closed');
    }
  }
}
