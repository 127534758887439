export enum SectionFinanciere_PlanTresorerie {
  EDEX = "Total encaissements d'exploitation",
  EHEX = 'Total encaissements hors exploitation',
  TE = 'Total des encaissements',
  DDEX = "Total décaissements d'exploitation",
  DHEX = 'Total décaissements hors exploitation',
  TD = 'Total des décaissements',
  STFP = 'Solde de trésorerie en fin de période',
  SCTFP = 'Solde cumulé de trésorerie en fin de période',
}

export namespace SectionFinanciere_NameSpace_PlanTresorerie {
  export function toEnum(sfString: string): SectionFinanciere_PlanTresorerie | undefined {
    return Object.values(SectionFinanciere_PlanTresorerie).find((value: SectionFinanciere_PlanTresorerie) => value === sfString);
  }
}

export enum SaisieLabel_PlanTresorerie {
  EV = 'Encaissements sur ventes',
  APE = "Autres produits d'exploitation",
  LF = 'Levées de fonds',
  ACC = 'Apports en compte courant',
  PB = 'Prêts bancaires',
  FBPIPCB = 'Financements Bpifrance - Dont prêts et crédit-bail',
  FBPIAR = 'Financements Bpifrance - Dont aides remboursables',
  FBPISUB = 'Financements Bpifrance - Dont subventions',
  AEHE = 'Autres encaissements hors exploitation',
  DRD = 'Dépenses de R&D',
  IFBPIAN = 'Intérêts sur financements Bpifrance anciens et nouveaux',
  IFHBPIAN = 'Intérêts sur financements hors Bpifrance anciens et nouveaux',
  LCBAN = 'Loyers sur crédit-bail anciens et nouveaux',
  ADE = "Autres décaissements d'exploitation",
  ICI = 'Investissements corporels et incorporels',
  RFBPIAN = 'Remboursements de financements Bpifrance anciens et nouveaux (en capital)',
  RFHBPIAN = 'Remboursements de financements hors Bpifrance anciens et nouveaux (en capital)',
  RCC = 'Remboursements de compte courant',
  ADHE = 'Autres décaissements hors exploitation',
}

export const ISOLATED_EDITABLE_ROW_PLAN_TRESORERIE = 'Trésorerie en début de période';

export const IS_ISOLATED_EDITABLE_ROW_PLAN_TRESORERIE: (sectionLabel: string) => boolean = (sectionLabel: string) =>
  ISOLATED_EDITABLE_ROW_PLAN_TRESORERIE === sectionLabel;
