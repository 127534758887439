<lib-demande-modification-container [demande]="demande" [suiviProjet]="suiviProjet">
  <ng-container auteur> Créée le {{ demande.dateCreation | date : "dd/MM/yyyy à HH'h'mm" }} </ng-container>
  <ng-container action-section>
    <div class="card__correction card card-correction" *ngIf="canWrite || demande.scope === EnumScope.STRUCTURE">
      <p class="card__title">Finaliser mes corrections</p>
      <p class="card__auteur">
        Si vous souhaitez porter à l'attention de Bpifrance un détail sur vos corrections, merci de renseigner la zone de saisie ci dessous.
      </p>
      <div class="card__description">
        <form id="input-typeDoc" class="modal__content" [formGroup]="correctionForm">
          <div
            class="ds-input-group"
            [class.ds-input-group--error]="!correctionForm.controls['description'].valid && correctionForm.controls['description'].touched"
          >
            <label for="description" class="ds-input-group__label">Détail de la correction :</label>
            <textarea
              id="demande_description"
              name="description"
              rows="5"
              maxlength="500"
              class="ds-input-group__textarea"
              formControlName="description"
              type="text"
              #descriptionInput
            ></textarea>
            <div class="form-input__length-hint" [class.form-input__length-hint--full]="descriptionInput.value.length === 500">
              <span>{{ descriptionInput.value.length || 0 }}/500</span>
            </div>
            <!-- Icône et message d'erreur -->
            <ng-container
              id="error-description"
              *ngIf="!correctionForm.controls['description'].valid && correctionForm.controls['description'].touched"
            >
              <div class="ds-input-group__feedback">Merci de bien vouloir renseigner un message valide</div>
            </ng-container>
          </div>
        </form>
      </div>
    </div>

    <div class="card__actions" *ngIf="canWrite || demande.scope === EnumScope.STRUCTURE">
      <button id="projet_btn_validate" type="button" class="ds-btn ds-btn--primary" (click)="openConfirmModal()">
        Envoyer mes corrections
      </button>
    </div>
  </ng-container>
</lib-demande-modification-container>
