import { HttpErrorResponse } from '@angular/common/http';
import { Component, DestroyRef, Inject, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  IntervenantRoles,
  PermissionUtils,
  PreferencesUtilisateurService,
  PreferenceUtilisateur,
  ShowToastrService,
  TableCellType,
  TableColumnConfiguration,
  TableUtils,
  Utilisateur,
  UtilisateurAbstractService,
} from '@shared-ui';
import { ActivatedRoute, Router } from '@angular/router';

declare const dsLib: any;

@Component({
  selector: 'lib-recherche-transverse',
  templateUrl: './recherche-transverse.component.html',
  styleUrls: ['./recherche-transverse.component.scss'],
})
export class RechercheTransverseComponent implements OnInit {
  private readonly DASHBOARD_PREFERENCE_KEY = 'search_columns';
  private readonly TABLE_CONF = [
    { id: 'produitInnovant', label: 'Produit Innovant', nom: 'produitInnovant', ordre: 0, visible: true, disabled: true },
    { id: 'produit', label: 'Produit', nom: 'produit', ordre: 1, visible: true, disabled: true },
    { id: 'dispositif', label: 'Dispositif', nom: 'dispositif', ordre: 2, visible: true, disabled: true },
    { id: 'aap', label: 'Code AAP', ordre: 3, nom: 'aap', visible: true, disabled: true },
    { id: 'acronyme', label: 'Acronyme projet', nom: 'acronyme', ordre: 4, visible: true, disabled: true },
    { id: 'denominationSociale', label: 'Dénomination Sociale', nom: 'denominationSociale', ordre: 5, visible: true, disabled: true },
    { id: 'statutAvancement', label: 'Statut', nom: 'statutAvancement', ordre: 6, visible: true, disabled: true },
    {
      id: 'montantTotalDeLAideRetenueHt',
      label: 'Montant engagé (€)',
      nom: 'montantTotalDeLAideRetenueHt',
      ordre: 7,
      visible: true,
      disabled: true,
      type: TableCellType.NUMBER,
      restrictedRoles: [IntervenantRoles.COORDINATEUR_CPMO, IntervenantRoles.SGPI_DIRECTION_EVALUATION],
    },
    {
      id: 'dateDeSignatureClient',
      label: 'Date de signature client',
      nom: 'dateDeSignatureClient',
      ordre: 8,
      visible: true,
      disabled: true,
      isDate: true,
      type: TableCellType.DATE,
    },
    {
      id: 'dateDeReleve',
      label: 'Date de relève',
      nom: 'dateDeReleve',
      ordre: 9,
      visible: false,
      disabled: false,
      isDate: true,
      type: TableCellType.DATE,
    },
    { id: 'nomChefDeFile', label: 'Nom du Chef de file', nom: 'nomChefDeFile', ordre: 10, visible: false, disabled: false },
    {
      id: 'assietePresentee',
      label: 'Assiette présentée',
      nom: 'assietePresentee',
      ordre: 11,
      visible: false,
      disabled: false,
      type: TableCellType.NUMBER,
    },
    {
      id: 'nbDePartenaires',
      label: 'Nb de partenaires',
      nom: 'nbDePartenaires',
      ordre: 12,
      visible: false,
      disabled: false,
      type: TableCellType.NUMBER,
    },
    { id: 'thematique', label: 'Thématique', nom: 'thematique', ordre: 13, visible: false, disabled: false },
    { id: 'typeEntreprise', label: "Type d'entreprise", nom: 'typeEntreprise', ordre: 14, visible: false, disabled: false },
    {
      id: 'codePostalDeRealisationPartenaire',
      label: 'Code postal de réalisation / partenaire',
      nom: 'codePostalDeRealisationPartenaire',
      ordre: 15,
      visible: false,
      disabled: false,
    },
    {
      id: 'poleDeCompetitiviteLabellisateur',
      label: 'Pôle de compétitivité labellisateur',
      nom: 'poleDeCompetitiviteLabellisateur',
      ordre: 16,
      visible: false,
      disabled: false,
    },
    { id: 'description', label: 'Objet du projet (description courte)', nom: 'description', ordre: 17, visible: false, disabled: false },
    {
      id: 'directionRegionaleBpifrance',
      label: 'Direction Régionale Bpifrance',
      nom: 'directionRegionaleBpifrance',
      ordre: 18,
      visible: false,
      disabled: false,
    },
    { id: 'siret', label: 'SIRET', nom: 'siret', ordre: 19, visible: false, disabled: false, type: TableCellType.SIRET },
    { id: 'siren', label: 'SIREN', nom: 'siren', ordre: 20, visible: false, disabled: false, type: TableCellType.SIREN },
    {
      id: 'nomResponsableProjet',
      label: 'Partenaire Nom du responsable projet',
      nom: 'nomResponsableProjet',
      ordre: 21,
      visible: false,
      disabled: false,
      restrictedRoles: [],
    },
    {
      id: 'prenomResponsableProjet',
      label: 'Partenaire Prénom du responsable projet',
      nom: 'prenomResponsableProjet',
      ordre: 22,
      visible: false,
      disabled: false,
      restrictedRoles: [],
    },
    {
      id: 'personneAffecteeEligibiliteLcbftOad',
      label: 'Personne affectée Eligibilité-LCBFT-OAD',
      nom: 'personneAffecteeEligibiliteLcbftOad',
      ordre: 23,
      visible: false,
      disabled: false,
      restrictedRoles: [],
    },
    {
      id: 'personneAffecteeALInstruction',
      label: "Personne affectée à l'instruction",
      nom: 'personneAffecteeALInstruction',
      ordre: 24,
      visible: false,
      disabled: false,
      restrictedRoles: [],
    },
    {
      id: 'propositionDecisionOperateur',
      label: 'Proposition / Décision Opérateur',
      nom: 'propositionDecisionOperateur',
      ordre: 25,
      visible: false,
      disabled: false,
      restrictedRoles: [],
    },
    {
      id: 'coexChargeDeDeveloppement',
      label: 'COEX : Chargé de développement',
      nom: 'coexChargeDeDeveloppement',
      ordre: 26,
      visible: false,
      disabled: false,
      restrictedRoles: [],
    },
    {
      id: 'coexDecisionComite',
      label: 'COEX : Décision Comité',
      nom: 'coexDecisionComite',
      ordre: 27,
      visible: false,
      disabled: false,
      restrictedRoles: [IntervenantRoles.COORDINATEUR_CPMO, IntervenantRoles.SGPI_DIRECTION_EVALUATION],
    },
    {
      id: 'reorientationYcAutreOperateurFlechage',
      label: 'Réorientation (yc autre opérateur) / Fléchage',
      nom: 'reorientationYcAutreOperateurFlechage',
      ordre: 28,
      visible: false,
      disabled: false,
      restrictedRoles: [IntervenantRoles.COORDINATEUR_CPMO, IntervenantRoles.SGPI_DIRECTION_EVALUATION],
    },
    {
      id: 'auditionDecision',
      label: 'Audition : Décision',
      nom: 'auditionDecision',
      ordre: 29,
      visible: false,
      disabled: false,
      restrictedRoles: [IntervenantRoles.COORDINATEUR_CPMO, IntervenantRoles.SGPI_DIRECTION_EVALUATION],
    },
    {
      id: 'expertiseJuridiqueEtOuPiInterne',
      label: 'Expertise juridique et/ou PI interne',
      nom: 'expertiseJuridiqueEtOuPiInterne',
      ordre: 30,
      visible: false,
      disabled: false,
      restrictedRoles: [],
    },
    {
      id: 'assietteTotaleRetenue',
      label: 'Assiette totale retenue',
      nom: 'assietteTotaleRetenue',
      ordre: 31,
      visible: false,
      disabled: false,
      type: TableCellType.NUMBER,
      restrictedRoles: [IntervenantRoles.COORDINATEUR_CPMO, IntervenantRoles.SGPI_DIRECTION_EVALUATION],
    },
    {
      id: 'resultatDeLInstruction',
      label: "Résultat de l'instruction",
      nom: 'resultatDeLInstruction',
      ordre: 32,
      visible: false,
      disabled: false,
      restrictedRoles: [],
    },
    {
      id: 'copilDecisionComite',
      label: 'COPIL : Décision Comité',
      nom: 'copilDecisionComite',
      ordre: 33,
      visible: false,
      disabled: false,
      restrictedRoles: [IntervenantRoles.COORDINATEUR_CPMO, IntervenantRoles.SGPI_DIRECTION_EVALUATION],
    },
    {
      id: 'copilDateDeSignatureDpm',
      label: 'COPIL : Date de signature DPM',
      nom: 'copilDateDeSignatureDpm',
      ordre: 34,
      visible: false,
      disabled: false,
      isDate: true,
      type: TableCellType.DATE,
    },
    {
      id: 'referentContractuel',
      label: 'Référent contractuel',
      nom: 'referentContractuel',
      ordre: 35,
      visible: false,
      disabled: false,
      restrictedRoles: [],
    },
    {
      id: 'responsableDeSuivi',
      label: 'Responsable de suivi',
      nom: 'responsableDeSuivi',
      ordre: 36,
      visible: false,
      disabled: false,
      restrictedRoles: [],
    },
    {
      id: 'secteurActiviteBad',
      label: "Secteur d'activité BAD",
      nom: 'secteurActiviteBad',
      ordre: 37,
      visible: false,
      disabled: false,
      restrictedRoles: [IntervenantRoles.SGPI_DIRECTION_FINANCIERE],
    },
    {
      id: 'sousSecteurActiviteBad',
      label: "Sous secteur d'activité BAD",
      nom: 'sousSecteurActiviteBad',
      ordre: 38,
      visible: false,
      disabled: false,
      restrictedRoles: [IntervenantRoles.SGPI_DIRECTION_FINANCIERE],
    },
    {
      id: 'sourceDonnee',
      label: 'Source de la donnée',
      nom: 'sourceDonnee',
      ordre: 39,
      visible: false,
      disabled: false,
      restrictedRoles: [],
    },
  ] as TableColumnConfiguration[];

  destroyRef = inject(DestroyRef);

  tabs = new dsLib.Tabs('dstab');

  displayedColumns: string[] = [];
  tableConf: TableColumnConfiguration[];
  private readonly isRestrictedTableHeader: boolean;
  private utilisateur: Utilisateur;
  isDoubleProfile = false;
  isSiteEval = false;

  constructor(
    @Inject('environment') private environment: any,
    public showToastrService: ShowToastrService,
    public preferenceUtilisateurService: PreferencesUtilisateurService,
    private route: ActivatedRoute,
    private utilisateurService: UtilisateurAbstractService,
    private router: Router
  ) {
    this.isRestrictedTableHeader = this.route.snapshot.data?.['restrictedTableHeader'];
    this.initTableConf();
    this.isDoubleProfile = localStorage.getItem('DOUBLE_PROFILE') === 'true';
    this.isSiteEval = this.isRestrictedTableHeader;
  }

  ngOnInit(): void {
    this.getPreference();
  }

  private initTableConf() {
    if (!this.isRestrictedTableHeader) {
      this.tableConf = this.TABLE_CONF;
      return;
    }
    this.utilisateurService.getUtilisateurObservable().subscribe({
      next: utilisateur => {
        this.utilisateur = utilisateur;
        this.initRestrictedTableConf();
      },
      error: (err: HttpErrorResponse) => {
        this.showToastrService.checkCodeError(err?.error);
      },
    });
  }

  private initRestrictedTableConf() {
    const userRole = PermissionUtils.resolveIntervenantRoles(this.utilisateur);
    this.tableConf = userRole ? this.TABLE_CONF.filter(tcc => !tcc.restrictedRoles || tcc.restrictedRoles.includes(userRole)) : [];
  }

  fillDisplayedColumns() {
    this.displayedColumns = ['select'];
    this.tableConf.forEach(column => {
      if (column.visible) {
        this.displayedColumns.push(column.id);
      }
    });
  }

  getPreference() {
    this.preferenceUtilisateurService.getPreferenceForCurrentUser().subscribe({
      next: (response: PreferenceUtilisateur) => {
        this.handlePreferenceResult(response);
      },
      error: (err: HttpErrorResponse) => {
        this.fillDisplayedColumns();
        if (err?.status !== 404) {
          this.showToastrService.checkCodeError(err?.error);
        }
      },
    });
  }

  private handlePreferenceResult(response: PreferenceUtilisateur) {
    const displayedColumns = response?.preferences?.[this.DASHBOARD_PREFERENCE_KEY];
    this.tableConf = TableUtils.handlePreferenceResponse(displayedColumns, this.tableConf);
    this.fillDisplayedColumns();
  }

  updateSuiviPreferences(columns: string[]) {
    this.preferenceUtilisateurService
      .updatePreference(
        this.DASHBOARD_PREFERENCE_KEY,
        columns.filter(col => col !== 'select')
      )
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (response: PreferenceUtilisateur) => {
          this.handlePreferenceResult(response);
        },
        error: (err: HttpErrorResponse) => {
          this.showToastrService.checkCodeError(err?.error);
        },
      });
  }

  onClickGoToChoice(): void {
    localStorage.removeItem('CHOSEN_PROFILE');
    this.router.navigate(['evaluateur-choice']);
  }

  redirectToCandidats(): void {
    window.location.href = this.environment.espaceCandidat + '/projets';
  }
}
