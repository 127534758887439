import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { IbanPipe } from '../pipes/iban.pipe';

@Directive({
  selector: '[libIban]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: IbanDirective,
      multi: true,
    },
  ],
})
export class IbanDirective implements ControlValueAccessor {
  private onChange: (value: any) => void = () => {};
  private onTouched: () => void = () => {};

  constructor(private el: ElementRef, private renderer: Renderer2, private ibanPipe: IbanPipe) {}

  @HostListener('blur', ['$event.target.value'])
  onBlur(value: string): void {
    const iban = this.getIban(value);

    const formattedValue = this.formatIban(iban);
    this.renderer.setProperty(this.el.nativeElement, 'value', formattedValue);
    this.onChange(this.getIban(formattedValue));

    this.onTouched();
  }

  writeValue(iban: any): void {
    if (iban) {
      const formattedValue = this.formatIban(iban);
      this.renderer.setProperty(this.el.nativeElement, 'value', formattedValue);
      this.onChange(this.getIban(formattedValue));
    } else {
      this.renderer.setProperty(this.el.nativeElement, 'value', '');
    }
  }

  public formatIban(value: string): string {
    return this.ibanPipe.transform(value);
  }

  getIban(formattedValue: string): string {
    return formattedValue.replace(/\s/g, '');
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.renderer.setProperty(this.el.nativeElement, 'disabled', isDisabled);
  }
}
