<div id="titre_page" class="page-header">
  <div class="d-flex flex-wrap">
    <a *ngIf="goBackUrl" class="page-header__back" (click)="goBack()">
      <lib-pxl-icon class="icon" icon="icon-arrow-arrow-back-ios" aria-hidden="true"></lib-pxl-icon>
      {{ goBackLabel }}
    </a>
    <h2 class="page-header__title w-100">{{ title }}</h2>
    <h3 class="sub-header" *ngIf="subTitle">{{ subTitle }}</h3>
  </div>

  <ng-content select="[centered-elements]"></ng-content>

  <ng-content select="[right-elements]"></ng-content>
</div>
