export class Invitation {
  id: string;
  idProjet: string;
  nomProjet: string;
  nomAap: string;
  lienProjet: string;
  idStructure: string;
  expired?: boolean;
  active = true;
  emailUtilisateur: string;
  dateExpiration: Date;
  dateCreation: Date;
  dateModification: Date;
}
