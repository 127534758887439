<h2 id="modal-title" class="modal__title">Erreur du chargement</h2>

<div id="modal-body" class="modal__content">
  <p class="error">
    Une ou plusieurs erreurs se sont produites lors de l'import du CSV des dépenses du projet. Veuillez vérifier votre fichier CSV.
  </p>
  <ul>
    <li *ngFor="let errorParent of uploadErrors">
      <span class="error-message">{{ errorParent.key?.columnLabel }}</span>
      <ul>
        <li *ngFor="let error of errorParent.errors">Ligne {{ error.rowNumber }}: {{ error.message }}</li>
      </ul>
    </li>
  </ul>
</div>

<div id="modal-btn" class="modal__actions">
  <button class="ds-btn ds-btn--primary" mat-dialog-close>OK</button>
</div>
