import { computed, Injectable, Signal, signal, WritableSignal } from '@angular/core';
import { BilanFinancier, PeriodeFinanciere, SaisieFinanciere, SectionFinanciere, SituationFinanciere, Structure } from '@shared-ui';

@Injectable({
  providedIn: 'root',
})
export class SignalService {
  private structuresSignal: WritableSignal<Structure[]> = signal([]);
  private structureSignal: WritableSignal<Structure> = signal({} as Structure);
  private comptesResultatsSignal: WritableSignal<PeriodeFinanciere[]> = signal([]);
  private plansFinancementSignal: WritableSignal<PeriodeFinanciere[]> = signal([]);
  private plansTresorerieSignal: WritableSignal<PeriodeFinanciere[]> = signal([]);
  private situationFinanciereSignal: WritableSignal<SituationFinanciere> = signal({ commentaire: '', bilans: [] });
  private exerciceEnCoursSignal: WritableSignal<number> = signal(0);
  private soldeTresorerieDernierBilanSignal: WritableSignal<number> = signal(0);

  // Structure

  public setStructures(structures: Structure[]): void {
    this.structuresSignal.set(structures);
  }

  public setStructure(structure: Structure): void {
    this.structureSignal.set(structure);
  }

  public getStructure(): Structure {
    return this.structureSignal();
  }

  public getStructureById(id: string): Structure | undefined {
    const structureSignal: Signal<Structure | undefined> = computed(() =>
      this.structuresSignal().find((structure: Structure) => structure.id === id)
    );
    return structureSignal();
  }

  // DonneesFinancieres

  private updatePeriodesFinancieres(
    periodesFinancieres: PeriodeFinanciere[],
    newValue: number,
    periode: string,
    sectionLabel: string,
    saisieLabel: string
  ): PeriodeFinanciere[] {
    const pf: PeriodeFinanciere | undefined = periodesFinancieres.find(pf => pf.periode === periode);
    const sf: SectionFinanciere | undefined = pf?.sections.find(sf => sf.label === sectionLabel);
    const saisie: SaisieFinanciere | undefined = sf?.saisies.find(saisie => saisie.label === saisieLabel);

    if (saisie) {
      saisie.saisie = newValue;
    }

    return periodesFinancieres;
  }

  //  ComptesResultats

  public setComptesResultats(periodesFinancieres: PeriodeFinanciere[]): void {
    this.comptesResultatsSignal.set(periodesFinancieres);
  }

  public updateComptesResultats(newValue: number, periode: string, sectionLabel: string, saisieLabel: string): void {
    this.comptesResultatsSignal.update((periodesFinancieres: PeriodeFinanciere[]) =>
      this.updatePeriodesFinancieres(periodesFinancieres, newValue, periode, sectionLabel, saisieLabel)
    );
  }

  public getComptesResultats(): PeriodeFinanciere[] {
    return this.comptesResultatsSignal();
  }

  // Plan de financement

  public setPlanFinancement(periodesFinancieres: PeriodeFinanciere[]): void {
    this.plansFinancementSignal.set(periodesFinancieres);
  }

  public updatePlanFinancement(periode: string, sectionLabel: string, saisieLabel: string, newValue: number): void {
    this.plansFinancementSignal.update((periodesFinancieres: PeriodeFinanciere[]) =>
      this.updatePeriodesFinancieres(periodesFinancieres, newValue, periode, sectionLabel, saisieLabel)
    );
  }

  public getPlanFinancement(): PeriodeFinanciere[] {
    return this.plansFinancementSignal();
  }

  // Plan de trésorerie

  public setPlansTresorerie(periodesFinancieres: PeriodeFinanciere[]): void {
    this.plansTresorerieSignal.set(periodesFinancieres);
  }

  public updatePlansTresorerie(periode: string, sectionLabel: string, saisieLabel: string, newValue: number): void {
    this.plansTresorerieSignal.update((periodesFinancieres: PeriodeFinanciere[]) =>
      this.updatePeriodesFinancieres(periodesFinancieres, newValue, periode, sectionLabel, saisieLabel)
    );
  }

  public getPlansTresorerie(): PeriodeFinanciere[] {
    return this.plansTresorerieSignal();
  }

  // Situation financière

  public setSituationFinanciere(situationFinanciere: SituationFinanciere): void {
    this.situationFinanciereSignal.set(situationFinanciere);
  }

  public updateSituationFinanciereComment(comment: string): void {
    this.situationFinanciereSignal.update((situationFinanciere: SituationFinanciere) => ({
      ...situationFinanciere,
      commentaire: comment,
    }));
  }

  public updateSituationFinanciereBilans(rowDef: string, newValue: number): void {
    this.situationFinanciereSignal.update((situationFinanciere: SituationFinanciere) => {
      const bilans: BilanFinancier[] = situationFinanciere.bilans;
      const bilan: BilanFinancier | undefined = bilans.find((bilan: BilanFinancier) => bilan.ligneBilan === rowDef);
      if (bilan) {
        bilan.montant = newValue;
      }
      return situationFinanciere;
    });
  }

  public getSituationFinanciere(): SituationFinanciere {
    return this.situationFinanciereSignal();
  }

  public updateExerciceEnCours(exerciceEnCours: number): void {
    this.exerciceEnCoursSignal.set(exerciceEnCours);
  }

  public getExerciceEnCours(): number {
    return this.exerciceEnCoursSignal.asReadonly()();
  }

  public updateSoldeTresorerieDernierBilan(soldeTresorerieDernierBilan: number): void {
    this.soldeTresorerieDernierBilanSignal.set(soldeTresorerieDernierBilan);
  }

  public getSoldeTresorerieDernierBilan(): number {
    return this.soldeTresorerieDernierBilanSignal();
  }
}
