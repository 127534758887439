import { DemandeRectification } from '@shared-ui';
import { AdresseInterface } from '../interfaces/adresse.interface';

export class Adresse implements AdresseInterface {
  pays: string;
  codePays: string;
  cp: string | null;
  ville: string;
  mentionDistribution: string;
  hameau: string;
  numero: string;
  complement: string;
  typeVoie: string;
  voie: string;
  complementInfo: string;
  departement: string;
  divisionAdministrative: string;
  regionAdministrative: string;
  demandeRectification?: DemandeRectification;
}
