import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Projet, S3Object } from '../../../shared/models/_public_models';

export class FicheComHelperService {
  public static isFicheComComplete(projet: Projet): boolean {
    const form = this.getForm(projet);
    if (projet?.partenaireType !== 'MULTI') {
      return form.valid;
    }
    return form.valid && this.isLogoEntreprisesComplete(projet);
  }

  public static getForm(projet: Projet): FormGroup {
    const requiredPropertyMulti = projet?.partenaireType === 'MULTI' ? Validators.required : null;
    const form = new FormGroup({
      informationProjet: new FormGroup({
        description: new FormControl<string>('', Validators.required),
        fonctionnementConsortium: new FormControl<string>('', requiredPropertyMulti),
        objectifs: new FormControl<string>('', Validators.required),
        pathS3Image: new FormControl<S3Object | undefined>(undefined, Validators.required),
        legendeCredit: new FormControl<string>('', Validators.required),
      }),
      informationChefDeFile: new FormGroup({
        pathS3Image: new FormControl<S3Object | undefined>(undefined, Validators.required),
        activite: new FormControl<string>('', Validators.required),
      }),
      contactPresse: new FormGroup({
        nomPrenom: new FormControl<string>('', Validators.required),
        telephone: new FormControl<string>('', Validators.required),
        email: new FormControl<string>('', [Validators.required, Validators.email]),
      }),
    });
    form.patchValue(projet?.ficheCom);
    return form;
  }

  public static isLogoEntreprisesComplete(projet: Projet): boolean {
    const structures = projet?.structures.filter(
      structure => !structure.closed && structure.role !== 'MANDATAIRE' && structure.role !== 'CHEF_DE_FILE'
    );
    return structures?.length === projet?.ficheCom?.logoEntreprises?.length;
  }

  public static getDocumentPath(projetId: string, structureId?: string, fileName?: string): string {
    const projectPath = projetId + '/fiche-de-com';
    const structurePath = structureId ? '/' + structureId : '';
    const fileNamePath = fileName ? '/' + fileName : '';
    return projectPath + structurePath + fileNamePath;
  }
}
