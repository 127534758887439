import { Inject, Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { EnumInstructionStatus } from '../enums/enum.instruction-status';
import { StatutGrilleImpacts } from '../enums/enum.statut-grille-impact';
import { Aap } from '../models/aap.model';
import { Environment, SITE_ADMIN, SITE_EVAL } from '../models/environment.model';
import { GrilleImpactStatutBaseModel } from '../models/grille-impact-statut-base.model';
import { GrilleImpactStatutModel } from '../models/grille-impact-statut.model';
import { GrilleImpactsVersion } from '../models/grille-impact-version.model';
import { GrilleImpacts } from '../models/grille-impacts.model';
import { Projet } from '../models/projet.model';
import { StructureRoleType } from '../types/structureRole.type';
import { StructureHelperService } from './structure.helper.service';
import { EnumProjetEtape } from '../enums/enumProjetEtape';
import { Structure } from '../models/structure.model';

@Injectable({
  providedIn: 'root',
})
export class GrilleImpactHelperService {
  constructor(@Inject('environment') private environment: Environment) {}
  public grillesImpacts = new Subject<GrilleImpacts>();

  static isGrilleImpactsValid(statutGI: StatutGrilleImpacts): boolean {
    return [StatutGrilleImpacts.COMPLETED, StatutGrilleImpacts.FINALISED].includes(statutGI);
  }

  static isAllGIStructuresValids(grilleImpactsStatuts: GrilleImpactStatutModel, structureIds: string[]): boolean {
    return (
      !!structureIds &&
      structureIds.every(structureId => {
        const statut = GrilleImpactHelperService.getGIStructureStatut(grilleImpactsStatuts, structureId);
        return statut && GrilleImpactHelperService.isGrilleImpactsValid(statut);
      })
    );
  }

  static isStructuresGrillesImpactValid(projet: Projet, aap: Aap, statuts: GrilleImpactStatutModel) {
    const structuresMandatoryGIs = GrilleImpactHelperService.getStructuresIdsWithMandatoryGI(projet, aap);
    return structuresMandatoryGIs.length === 0 || GrilleImpactHelperService.isAllGIStructuresValids(statuts, structuresMandatoryGIs);
  }

  static getStructuresIdsWithMandatoryGI(projet: Projet, aap: Aap): string[] {
    const TOUT: StructureRoleType = 'TOUT';
    const MANDATAIRE: StructureRoleType = 'MANDATAIRE';
    if (!aap?.kpis || aap.kpis.length === 0 || projet?.structures?.length === 0) return [];
    if (aap.kpis.find(kpi => kpi.typeStructure === TOUT)) {
      return projet.structures
        .filter(
          structure =>
            !structure.closed &&
            !StructureHelperService.structureHasRole(structure, [MANDATAIRE]) &&
            this.checkFinancement(aap, projet, structure)
        )
        .map(structure => structure.id);
    }
    const requiredTypeStructure = aap.kpis
      .map(kpi => kpi.typeStructure)
      .filter(typeStructure => typeStructure !== undefined) as StructureRoleType[];

    return projet.structures
      ?.filter(
        structure =>
          !structure.closed &&
          requiredTypeStructure.includes(structure.typeStructure as unknown as StructureRoleType) &&
          this.checkFinancement(aap, projet, structure)
      )
      .map(structure => structure.id);
  }

  static getGIStructureStatut(
    grilleImpactsStatuts: GrilleImpactStatutModel | undefined,
    structureId: string
  ): StatutGrilleImpacts | undefined {
    if (!grilleImpactsStatuts || !grilleImpactsStatuts.statutStructures || grilleImpactsStatuts.statutStructures.length == 0)
      return undefined;
    let statuts: GrilleImpactStatutBaseModel[] = grilleImpactsStatuts.statutStructures.filter(
      gIStatut => gIStatut.idRessource === structureId
    );
    if (!statuts || statuts.length === 0) return undefined;
    statuts = statuts.filter(statut => statut.statut !== StatutGrilleImpacts.FINALISED);
    if (statuts.length === 0) return StatutGrilleImpacts.FINALISED;
    // 1er statut car une grille d'impact non finalisée par structure :
    return statuts[0].statut;
  }

  static getGIProjetStatut(grilleImpactsStatuts: GrilleImpactStatutModel): StatutGrilleImpacts | undefined {
    if (!grilleImpactsStatuts) return undefined;
    return grilleImpactsStatuts.statut;
  }

  // TODO adapt when grilleImpacts versions will be managed
  static getCurrentGrilleImpacts(grillesImpacts: GrilleImpacts[]): GrilleImpacts | undefined {
    const sortedGrillesImpacts = grillesImpacts?.sort((b, a) => {
      return new Date(b.dateModification).valueOf() - new Date(a.dateModification).valueOf();
    });
    return sortedGrillesImpacts ? sortedGrillesImpacts[sortedGrillesImpacts.length - 1] : undefined;
  }

  static sortGrilleImpact(grillesImpacts: GrilleImpactsVersion[]): GrilleImpactsVersion[] {
    grillesImpacts.sort((a, b) => (new Date(b.dateValidation).valueOf() > new Date(a.dateValidation).valueOf() ? 1 : -1));
    return grillesImpacts;
  }

  isGIEditableByIntern(projet: Projet): boolean {
    if (this.environment.site !== SITE_ADMIN && this.environment.site !== SITE_EVAL) {
      return true;
    }

    return projet?.instruction?.statut !== (EnumInstructionStatus as any)[EnumInstructionStatus.FAITE];
  }

  setGrillesImpactsData(data: any) {
    this.grillesImpacts.next(data);
  }

  getGrillesImpactsData() {
    return this.grillesImpacts.asObservable();
  }

  static checkFinancement(aap: Aap, projet: Projet, structure: Structure): boolean | undefined {
    if (this.getProjectEtapeName(projet) === EnumProjetEtape.PRE_DEPOT && !aap.budgetEstime && structure.budgetPreDepot) {
      return structure.budgetPreDepot.besoin;
    } else if (this.getProjectEtapeName(projet) !== EnumProjetEtape.PRE_DEPOT && structure.budgetDepot) {
      return structure.budgetDepot.besoin;
    }
    return true;
  }

  static getProjectEtapeName(projet: Projet): EnumProjetEtape | null {
    if (projet?.etapes == null) {
      return null;
    }
    return projet?.etapes[projet?.etapes.length - 1].nom;
  }
}
