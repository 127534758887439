import { enumKeys } from "../../utils/enum-utils";

export enum EnumGenre {
  MONSIEUR = 'MONSIEUR',
  MADAME = 'MADAME',
}

export namespace EnumGenre {
  export function toString(genre: EnumGenre): string {
    switch (genre) {
      case EnumGenre.MONSIEUR:
        return 'Monsieur';
      case EnumGenre.MADAME:
        return 'Madame';
    }
  }

  export function all(): EnumGenre[] {
    return enumKeys(EnumGenre) as unknown as EnumGenre[];
  }
}
