import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments-candidat/environment';
import { Aap, ShowToastrService } from '@shared-ui';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable()
export class AapService {
  aapSubject: BehaviorSubject<Aap> = new BehaviorSubject(null);
  aapByIdSubject: BehaviorSubject<HttpResponse<Aap>> = new BehaviorSubject(null);
  aapsListSubject: Subject<HttpResponse<Aap[]>> = new Subject();

  constructor(private httpClient: HttpClient, private showToastrService: ShowToastrService) {}

  getAap(): Observable<HttpResponse<Aap[]>> {
    this.httpClient.get<Aap[]>(environment.apiUrl + 'aaps/filtres', { observe: 'response' }).subscribe({
      next: aaps => {
        this.aapsListSubject.next(aaps);
      },
      error: (err: HttpErrorResponse) => {
        this.showToastrService.checkCodeError(err?.error);
      },
    });

    return this.loadAap();
  }

  getAapById(id: string): Observable<HttpResponse<Aap>> {
    this.httpClient.get<Aap>(environment.apiUrl + `aaps/${id}`, { observe: 'response' }).subscribe({
      next: aap => {
        this.aapByIdSubject.next(aap);
      },
      error: (err: HttpErrorResponse) => {
        this.showToastrService.checkCodeError(err?.error);
      },
    });

    return this.loadAapSubject();
  }

  loadAap(): Observable<HttpResponse<Aap[]>> {
    return this.aapsListSubject.asObservable();
  }

  public loadAapSubject(): Observable<HttpResponse<Aap>> {
    return this.aapByIdSubject.asObservable();
  }
}
