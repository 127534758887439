import { AfterViewInit, Component, DestroyRef, EventEmitter, Output, ViewChild, ViewEncapsulation, inject } from '@angular/core';
import { MAT_MENU_DEFAULT_OPTIONS, MatMenuTrigger } from '@angular/material/menu';
import { FilterDataInterface, MultiselectComponent, MultiSelectCriteria, RechercheTransverseService, ShowToastrService } from '@shared-ui';

@Component({
  selector: 'lib-interne-bpifrance-filters',
  templateUrl: './interne-bpifrance-filters.component.html',
  styleUrls: ['./interne-bpifrance-filters.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: MAT_MENU_DEFAULT_OPTIONS,
      useValue: {
        overlayPanelClass: 'interne-bpifrance-filters-menu',
      },
    },
  ],
})
export class InterneBpifranceFiltersComponent implements AfterViewInit {
  destroyRef = inject(DestroyRef);

  readonly DIRECTION_REGIONALE_BPIFRANCE = 'direction_regionale_bpifrance';
  readonly PERSONNE_AFFECTEE_ELIGIBILITE_LCBFT_OAD = 'personne_affectee_eligibilite_lcbft_oad';
  readonly PERSONNE_AFFECTEE_INSTRUCTION = 'personne_affectee_a_l_instruction';
  readonly COEX_CHARGE_DEVELOPPEMENT = 'coex_charge_de_developpement';
  readonly EXPERTISE_JURIDIQUE_PI_INTERNE = 'expertise_juridique_et_ou_pi_interne';
  readonly REFERENT_CONTRACTUEL = 'referent_contractuel';
  readonly RESPONSABLE_SUIVI = 'responsable_de_suivi';

  @ViewChild(MatMenuTrigger) trigger!: MatMenuTrigger;
  @ViewChild('directionRegionaleBpifranceFilterComponent') directionRegionale!: MultiselectComponent;
  @ViewChild('personneAffecteeEligibiliteLcbftOadFilterComponent') affecteeEligibiliteLcbftOad!: MultiselectComponent;
  @ViewChild('personneAffecteeInstructionFilterComponent') affecteeInstruction!: MultiselectComponent;
  @ViewChild('coexChargeDeveloppementFilterComponent') coexChargeDev!: MultiselectComponent;
  @ViewChild('expertiseJuridiquePiInterneFilterComponent') expertiseJPI!: MultiselectComponent;
  @ViewChild('referentContractuelFilterComponent') referentContractuel!: MultiselectComponent;
  @ViewChild('responsableSuiviFilterComponent') responsableSuivi!: MultiselectComponent;

  isOpened = false;

  @Output() filtersChanged: EventEmitter<MultiSelectCriteria> = new EventEmitter<MultiSelectCriteria>();

  directionRegionaleSelectedList: FilterDataInterface[] = [];
  directionRegionaleDataList: FilterDataInterface[] = [];

  affecteeEligibiliteLcbftOadSelectedList: FilterDataInterface[] = [];
  affecteeEligibiliteLcbftOadDataList: FilterDataInterface[] = [];

  affecteeInstructionSelectedList: FilterDataInterface[] = [];
  affecteeInstructionDataList: FilterDataInterface[] = [];

  coexChargeDevSelectedList: FilterDataInterface[] = [];
  coexChargeDevDataList: FilterDataInterface[] = [];

  expertiseJPISelectedList: FilterDataInterface[] = [];
  expertiseJPIDataList: FilterDataInterface[] = [];

  referentContractuelSelectedList: FilterDataInterface[] = [];
  referentContractuelDataList: FilterDataInterface[] = [];

  responsableSuiviSelectedList: FilterDataInterface[] = [];
  responsableSuiviDataList: FilterDataInterface[] = [];

  loadingMap = new Map();

  constructor(private rechercheTransverseService: RechercheTransverseService, private toastrService: ShowToastrService) {
    this.loadingMap.set(this.DIRECTION_REGIONALE_BPIFRANCE, false);
    this.loadingMap.set(this.PERSONNE_AFFECTEE_ELIGIBILITE_LCBFT_OAD, false);
    this.loadingMap.set(this.PERSONNE_AFFECTEE_INSTRUCTION, false);
    this.loadingMap.set(this.COEX_CHARGE_DEVELOPPEMENT, false);
    this.loadingMap.set(this.EXPERTISE_JURIDIQUE_PI_INTERNE, false);
    this.loadingMap.set(this.REFERENT_CONTRACTUEL, false);
    this.loadingMap.set(this.RESPONSABLE_SUIVI, false);
  }

  ngAfterViewInit(): void {
    this.trigger.menuOpened.subscribe(() => {
      this.isOpened = true;
    });
  }

  applyFilters(): void {
    const fieldCriteriaMap: MultiSelectCriteria = {};
    fieldCriteriaMap[this.DIRECTION_REGIONALE_BPIFRANCE] = this.getFieldCriteria(this.directionRegionaleSelectedList);
    fieldCriteriaMap[this.PERSONNE_AFFECTEE_ELIGIBILITE_LCBFT_OAD] = this.getFieldCriteria(this.affecteeEligibiliteLcbftOadSelectedList);
    fieldCriteriaMap[this.PERSONNE_AFFECTEE_INSTRUCTION] = this.getFieldCriteria(this.affecteeInstructionSelectedList);
    fieldCriteriaMap[this.COEX_CHARGE_DEVELOPPEMENT] = this.getFieldCriteria(this.coexChargeDevSelectedList);
    fieldCriteriaMap[this.EXPERTISE_JURIDIQUE_PI_INTERNE] = this.getFieldCriteria(this.expertiseJPISelectedList);
    fieldCriteriaMap[this.REFERENT_CONTRACTUEL] = this.getFieldCriteria(this.referentContractuelSelectedList);
    fieldCriteriaMap[this.RESPONSABLE_SUIVI] = this.getFieldCriteria(this.responsableSuiviSelectedList);

    this.filtersChanged.emit(fieldCriteriaMap);
    if (this.isOpened) {
      this.trigger.closeMenu();
    }
  }

  private getFieldCriteria(selectedList: FilterDataInterface[]): string[] {
    return selectedList.length > 0 ? selectedList.map(item => item.value) : [];
  }

  resetFilters(): void {
    this.directionRegionale?.reset();
    this.directionRegionaleSelectedList = [];
    this.affecteeEligibiliteLcbftOad?.reset();
    this.affecteeEligibiliteLcbftOadSelectedList = [];
    this.affecteeInstruction?.reset();
    this.affecteeInstructionSelectedList = [];
    this.coexChargeDev?.reset();
    this.coexChargeDevSelectedList = [];
    this.expertiseJPI?.reset();
    this.expertiseJPISelectedList = [];
    this.referentContractuel?.reset();
    this.referentContractuelSelectedList = [];
    this.responsableSuivi?.reset();
    this.responsableSuiviSelectedList = [];
  }

  onMenuClosed(): void {
    if (this.isOpened) {
      this.isOpened = false;
      this.applyFilters();
    }
  }

  preventValues(event: KeyboardEvent): void {
    const forbiddenKeys = ['-', ',', '.', 'e', 'E', '+'];
    if (forbiddenKeys.includes(event.key)) {
      event.preventDefault();
    }
  }

  selectedListChange(event: any[], source: string): void {
    switch (source) {
      case this.DIRECTION_REGIONALE_BPIFRANCE:
        this.directionRegionaleSelectedList = event;
        break;
      case this.PERSONNE_AFFECTEE_ELIGIBILITE_LCBFT_OAD:
        this.affecteeEligibiliteLcbftOadSelectedList = event;
        break;
      case this.PERSONNE_AFFECTEE_INSTRUCTION:
        this.affecteeInstructionSelectedList = event;
        break;
      case this.COEX_CHARGE_DEVELOPPEMENT:
        this.coexChargeDevSelectedList = event;
        break;
      case this.EXPERTISE_JURIDIQUE_PI_INTERNE:
        this.expertiseJPISelectedList = event;
        break;
      case this.REFERENT_CONTRACTUEL:
        this.referentContractuelSelectedList = event;
        break;
      case this.RESPONSABLE_SUIVI:
        this.responsableSuiviSelectedList = event;
        break;
    }
  }

  autocompleteEvent(event: any, source: string): void {
    this.loadingMap.set(source, true);
    const searchObject = { query: this.removeElasticsearchSpecialCharacters(event), fieldName: source, isPrefix: false };

    this.rechercheTransverseService.getAutocomplete(searchObject).subscribe({
      next: (response: any) => {
        this.handleAutoCompleteResponse(response, source);
      },
      error: (err: any) => {
        this.toastrService.checkCodeError(err?.error);
        this.loadingMap.set(source, false);
      },
    });
  }

  private handleAutoCompleteResponse(response: any, source: string): void {
    switch (source) {
      case this.DIRECTION_REGIONALE_BPIFRANCE:
        this.directionRegionaleDataList = (response.body as string[]).map(direction => ({ id: direction, value: direction }));
        break;
      case this.PERSONNE_AFFECTEE_ELIGIBILITE_LCBFT_OAD:
        this.affecteeEligibiliteLcbftOadDataList = (response.body as string[]).map(affectee => ({ id: affectee, value: affectee }));
        break;
      case this.PERSONNE_AFFECTEE_INSTRUCTION:
        this.affecteeInstructionDataList = (response.body as string[]).map(affectee => ({ id: affectee, value: affectee }));
        break;
      case this.COEX_CHARGE_DEVELOPPEMENT:
        this.coexChargeDevDataList = (response.body as string[]).map(coex => ({ id: coex, value: coex }));
        break;
      case this.EXPERTISE_JURIDIQUE_PI_INTERNE:
        this.expertiseJPIDataList = (response.body as string[]).map(expertise => ({ id: expertise, value: expertise }));
        break;
      case this.REFERENT_CONTRACTUEL:
        this.referentContractuelDataList = (response.body as string[]).map(referent => ({ id: referent, value: referent }));
        break;
      case this.RESPONSABLE_SUIVI:
        this.responsableSuiviDataList = (response.body as string[]).map(responsable => ({ id: responsable, value: responsable }));
        break;
    }
    this.loadingMap.set(source, false);
  }

  private removeElasticsearchSpecialCharacters(str: string): string {
    const specialCharacters = [
      '+',
      '-',
      '=',
      '&&',
      '.',
      '||',
      '>',
      '<',
      '!',
      '(',
      ')',
      '{',
      '}',
      '[',
      ']',
      '^',
      '"',
      '~',
      '*',
      '?',
      ':',
      '\\',
      '/',
    ];
    return str.replace(new RegExp(`[${specialCharacters.join('\\')}]`, 'g'), ' ');
  }
}
