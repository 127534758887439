export class NumberUtils {
  /**
   * Determine whether the given `input` is a number.
   *
   * @param {String} input
   *
   * @returns {Boolean}
   */
  static isNumeric(input: any): boolean {
    return typeof input === 'string' && !Number.isNaN(input);
  }
}
