import { Inject, Injectable } from '@angular/core';
import { Environment } from '../models/environment.model';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { DemandeCorrection } from '../models/demande-correction.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DemandeModificationHttpService {
  constructor(@Inject('environment') private environment: Environment, public httpClient: HttpClient) {}

  updateCorrection(demandeCorrection: DemandeCorrection): Observable<HttpResponse<DemandeCorrection>> {
    return this.httpClient.put<DemandeCorrection>(this.environment.apiUrl + 'demandes-correction', JSON.stringify(demandeCorrection), {
      observe: 'response',
    });
  }

  updateDocumentComplementaire(demandeId: string, demandeDocComplement: any): Observable<HttpResponse<DemandeCorrection>> {
    return this.httpClient.patch<DemandeCorrection>(
      this.environment.apiUrl + 'demande-complements/' + demandeId,
      JSON.stringify(demandeDocComplement),
      {
        observe: 'response',
      }
    );
  }
}
