import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { TableCellType } from '../interfaces/table-column-configuration.interface';

@Pipe({
  name: 'formatText',
})
export class FormatTextPipe implements PipeTransform {
  private readonly CELL_MAX_LENGTH = 35;

  constructor(private datePipe: DatePipe) {}

  transform(text: any, type: TableCellType | undefined): string | null {
    if (text == null || text.length === 0) {
      return '-';
    }
    switch (type) {
      case TableCellType.DATE:
        return this.datePipe.transform(text, 'dd/MM/yyyy');
      case TableCellType.SIRET:
        return this.formatSiret(text);
      case TableCellType.SIREN:
        return this.formatSiren(text);
      case TableCellType.NUMBER:
        return this.formatNumber(text);
      default:
        return text?.length > this.CELL_MAX_LENGTH ? text.substring(0, this.CELL_MAX_LENGTH) + '...' : text;
    }
  }

  private formatSiret(text: string): string {
    return text.replace(/.{13}(?=.)/, (m: string) => m.replace(/.{10}(?=.)/, n => n.replace(/.{3}(?=.)/g, '$& ')));
  }

  private formatSiren(text: string): string {
    return text.replace(/.{3}(?=.)/g, '$& ');
  }

  private formatNumber(text: number): string {
    return text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  }
}
