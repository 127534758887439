import { InstructionStructureInterface } from '../interfaces/instruction-structure.interface';
import { SyntheseControleSecondNiveau } from './synthese-controle-second-niveau.model';
import { SyntheseCompletudeSecondNiveau } from './synthese-completude-second-niveau.model';
import { CompletudeSecondNiveau } from './completude-second-niveau.model';

export class InstructionStructure implements InstructionStructureInterface {
  syntheseControleSecondNiveau: SyntheseControleSecondNiveau = new SyntheseControleSecondNiveau();
  syntheseCompletudeSecondNiveau: SyntheseCompletudeSecondNiveau;
  completudeSecondNiveau: CompletudeSecondNiveau;
}
