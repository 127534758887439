import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { URL_CGU, URL_PUBLIC_DOC } from '@shared-candidat/utils/constants';
import { Aap } from '@shared-ui';

export interface ModalData {
  title: string;
  aap: Aap;
  textGoButton: string;
  textReturnButton: string;
}
@Component({
  selector: 'pxl-modal',
  templateUrl: './invitation-contact-modal.component.html',
  styleUrls: ['./invitation-contact-modal.component.scss'],
})
export class InvitationContactModalComponent implements OnInit {
  @Output() responseModal: EventEmitter<any> = new EventEmitter();

  anchorSrc = URL_CGU + '#rgpd';
  emailForm: UntypedFormGroup;
  alreadyExist = false;

  constructor(
    public dialogRef: MatDialogRef<InvitationContactModalComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: any,
    private formBuilder: UntypedFormBuilder
  ) {}

  ngOnInit(): void {
    this.emailForm = this.formBuilder.group({
      email: [null, Validators.required],
    });
  }

  sendInvitation(): void {
    if (this.emailForm.valid) {
      const { email } = this.emailForm.value;
      this.dialogRef.close(email);
    } else {
      this.emailForm.markAllAsTouched();
    }
  }

  downloadRGPD(): void {
    window.open(URL_PUBLIC_DOC + this.modalData.aap.programme.fichierRgpd);
  }
}
