<h2 class="modal__title" *ngIf="modalData.title">{{ modalData.title }}</h2>

<div id="input-changeDoc" class="modal__content">
  <form id="structure-form" class="form" [formGroup]="changeDocForm">
    <lib-pxl-toggle
      [labels]="toggleLabels"
      [defaultLabel]="projectToggleState ? ToggleValues.STRUCTURES : ToggleValues.PROJET"
      (changeToggleEvent)="onChangeToggle($event)"
    ></lib-pxl-toggle>

    <div
      id="input-type"
      class="form-step__type ds-input-group mt-3"
      [class.ds-input-group--error]="!projetControl.valid && projetControl.touched"
      *ngIf="!projectToggleState"
    >
      <label for="projet" class="ds-input-group__label">Type de fichier <span class="ds-input-group__label-asterisk"> *</span></label>
      <div class="ds-input-group__select ds-input-group__select--with-icon">
        <select id="projet" name="projet" formControlName="projet">
          <option [ngValue]="null" selected disabled hidden>Sélectionner</option>
          <option *ngFor="let item of documentsForPrj" [ngValue]="item">{{ item.nomDocument }}</option>
        </select>
      </div>
      <label *ngIf="!isUploadableToProject" class="ds-input-group__label" [class.error-message]="isWarningRed"
        >Le fichier ne peut pas être rattaché au scope projet.</label
      >
    </div>

    <div
      id="input-type"
      class="form-step__type ds-input-group mt-3"
      [class.ds-input-group--error]="!structureCibleControl.valid && structureCibleControl.touched"
      *ngIf="projectToggleState"
    >
      <label for="structure" class="ds-input-group__label">Structure <span class="ds-input-group__label-asterisk"> *</span></label>
      <div class="ds-input-group__select ds-input-group__select--with-icon">
        <select id="structure" name="structure" (change)="refreshdocumentsForSelectedStructure()" formControlName="structureCible">
          <option [ngValue]="null" selected disabled hidden>Sélectionner</option>
          <option *ngFor="let item of modalData.moveDocumentConfig.structures" [ngValue]="item">{{ item.raisonSociale }}</option>
        </select>
      </div>
    </div>

    <div
      id="input-type"
      class="form-step__type ds-input-group mt-3"
      [class.ds-input-group--error]="!documentCibleControl.valid && documentCibleControl.touched"
      *ngIf="projectToggleState"
    >
      <label for="projet" class="ds-input-group__label">Type de fichier <span class="ds-input-group__label-asterisk"> *</span></label>
      <div class="ds-input-group__select ds-input-group__select--with-icon">
        <select id="documentCible" name="documentCible" formControlName="documentCible">
          <option [ngValue]="null" selected disabled hidden>Sélectionner</option>
          <optgroup label="Type de fichier">
            <option *ngFor="let item of documentsForStr" [ngValue]="item">{{ item.nomDocument }}</option>
          </optgroup>
          <optgroup label="Fichier actionnarial" *ngIf="documentsForStrActio.length > 0">
            <option *ngFor="let item of documentsForStrActio" [ngValue]="item">{{ item.nomDocument }}</option>
          </optgroup>
        </select>
      </div>
    </div>
  </form>
  <div class="modal__actions">
    <button *ngIf="modalData.textReturnButton" id="cancel" class="ds-btn ds-btn--secondary" mat-dialog-close>
      {{ modalData.textReturnButton }}
    </button>
    <button id="go-action" class="ds-btn ds-btn--primary" cdkFocusInitial (click)="submitForm(changeDocForm)">
      {{ modalData.textGoButton }}
    </button>
  </div>
</div>
