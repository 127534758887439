import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserService } from '@services-candidat/user.service';
import { URL_CGU } from '@shared-candidat/utils/constants';
import { NpsCreation, ShowToastrService, Utilisateur } from '@shared-ui';

@Component({
  selector: 'pxl-nps-modal',
  templateUrl: './nps-modal.component.html',
  styleUrls: ['./nps-modal.component.scss'],
})
export class NpsModalComponent implements OnInit {
  @Output() responseModal: EventEmitter<any> = new EventEmitter();
  enabledBtnCGU = false;
  enabledBtnDataCollect = false;
  npsForm: UntypedFormGroup;

  npsCreation: NpsCreation = new NpsCreation();

  src = URL_CGU;
  anchorSrc = URL_CGU + '#rgpd';
  user: Utilisateur;

  constructor(
    public dialogRef: MatDialogRef<NpsModalComponent>,
    private userService: UserService,
    private showToastrService: ShowToastrService,
    private formBuilder: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public modalData: any
  ) {}

  ngOnInit(): void {
    this.createForm();
  }

  createForm(): void {
    this.npsForm = this.formBuilder.group({
      note: [null, Validators.required],
      commentaire: [null],
    });
  }

  sendAvis(formNps: any): void {
    Object.assign(this.npsCreation, formNps);
    this.npsCreation.etape = this.modalData.etape;
    this.npsCreation.nombreProjets = this.modalData.nombreProjets;
    this.npsCreation.role = this.modalData.role;
    this.userService.createNps(this.npsCreation).subscribe({
      next: response => {
        this.user = response.body;

        this.userService.setUserObservable(this.user);
        this.userService.setUserObservableForCGU(this.user);

        this.dialogRef.close(true);
      },
      error: (err: HttpErrorResponse) => {
        this.showToastrService.checkCodeError(err?.error);
      },
    });
  }
}
