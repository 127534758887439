import { IsolatedEditableRow_ComptesResultats } from '../../dynamic-table/dynamic-table.model';

export enum SectionFinanciere_ComptesResultats {
  TOTAL_PEX = "A. TOTAL DES PRODUITS D'EXPLOITATION",
  TOTAL_CPT = 'B. TOTAL CONSO. EN PROVENANCE DE TIERS',
  VA = 'C. VALEUR AJOUTEE (A-B)',
  EBE = "D. EXCEDENT BRUT D'EXPLOITATION",
  RESULTAT_EXPLOITATION = "E. RESULTAT D'EXPLOITATION",
  RESULTAT_CAI = 'F. RESULTAT COURANT AVANT IMPOTS',
  RESULTAT_EXERCICE = "G. RESULTAT DE L'EXERCICE",
  CAPACITE_AUTOFINANCEMENT = "CAPACITE D'AUTOFINANCEMENT",
}

export namespace SectionFinanciere_NameSpace_ComptesResultats {
  export function toEnum(sfString: string): SectionFinanciere_ComptesResultats | undefined {
    return Object.values(SectionFinanciere_ComptesResultats).find((value: SectionFinanciere_ComptesResultats) => value === sfString);
  }
}

export enum SaisieLabel_ComptesResultats {
  CA = "Chiffre d'affaires (HT) total de l'entreprise",
  PI = '+ Production immobilisée',
  PST = '+ Production stockée',
  AMPM = 'Achat de matières premières et marchandises',
  VSMM = '+/- Variation de stock de matières et marchandises',
  AACE = '+ Autres achats et charges externes',
  SE = "+ Subventions d'exploitation (hors aide envisagée)",
  CARP = "Dont chiffre d'affaires généré par les résultats du projet",
  DVE = "Dont ventes à l'exportation",
  DST = 'Dont sous-traitance',
  DCBR = 'Dont crédit-bail - redevances',
  IT = '- Impôts et taxes',
  CP = '- Charges de personnel',
  DA = '- Dotation aux amortissements',
  ACE = "- Autres charges d'exploitation",
  APE = "+ Autres produits d'exploitation",
  PF = '+ Produits financiers',
  CF = '- Charges financières',
  PE = '+ Produits exceptionnels',
  CE = '- Charges exceptionnelles',
  PSA = '- Participation des salariés',
  IB = '- Impôts sur les bénéfices',
  EF = 'Effectifs',
}

export const TOOLTIP_BY_ROW_COMPTES_RESULTATS: Record<string, string> = {
  [SectionFinanciere_ComptesResultats.CAPACITE_AUTOFINANCEMENT]: `G + amortissements + ou - résultats exceptionnels`,
  [IsolatedEditableRow_ComptesResultats.MP]: `Les dépenses du projet peuvent être immobilisées et amorties (ligne "Immobilisation des dépenses du projet proposé" de l'onglet Plan de financement), ou bien passées pour tout ou partie en charges d’exploitation.\n
  Surlignement automatique en rouge en cas de différence entre "Montant du projet" et ["Montant du projet passé en charges d'exploitation" + "Immobilisation des dépenses du projet proposé"] : correction nécessaire`,
};

export const IS_ISOLATED_EDITABLE_ROW_COMPTES_RESULTATS: (sectionLabel: string) => boolean = (sectionLabel: string) =>
  Object.values<string>(IsolatedEditableRow_ComptesResultats).includes(sectionLabel);
