import { EnqueteInterface } from '../interfaces/enquete.interface';

export class Enquete implements EnqueteInterface {
  sourceInfo: string;
  sourceInfositeWeb: string;
  hasExploitRecherchePublic: boolean;
  exploitRecherchePublicOrganisme: string;
  exploitRecherchePublicOrganismeCoordonnees: string;
  hasBrevetDepotPublic: string;
  lieRecherchePublic: boolean;
  lieRecherchePublicDetail: string;
  incubeIncubateurPublic: boolean;
  incubateurPublicDetail: string;
  incubeIncubateurPrive: boolean;
  incubateurPriveDetail: string;
  matureSATT: boolean;
  sattdetail: string;
  objetFinancement: boolean;
  objetFinancementType: string;
  developpementDurable: boolean;
  developpementDurableEnergiesRenouv: string[] = [];
  developpementDurableGestionPollution: string[] = [];
  developpementDurableOptimConsommation: string[] = [];
}
