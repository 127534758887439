import { Component, EventEmitter, Inject, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Environment, PxlValidators, URL_PME } from '@shared-ui';
import { distinctUntilChanged } from 'rxjs';
import { siretValidator } from '../../shared/directives/siret.validator';
import { EnumMotifNonRattachement } from '../../shared/enums/enum.motifNonRattachement';
import { EnumTypeStructure } from '../../shared/enums/enum.typeStructure';
import { DemandeRectification } from '../../shared/models/demande-rectification.model';
import { SubscriptionDestroyerComponent } from '../../shared/subscription-destroyer/subscription-destroyer.abstract';
import { DemandeRectificationModalComponent } from '../structure-adresse/demande-rectification-modal/demande-rectification-modal.component';

@Component({
  selector: 'lib-information-siege',
  templateUrl: './information-siege.component.html',
  styleUrls: ['./information-siege.component.scss'],
})
export class InformationSiegeComponent extends SubscriptionDestroyerComponent implements OnInit, OnChanges {
  @Input() structureForm: any;
  @Input() readOnly = false;
  @Input() readOnlyExceptions: string[] = [];
  @Input() canRequestRectification = false;
  @Output() rectificationRequested = new EventEmitter<DemandeRectification>();
  @Output() rectificationCanceled = new EventEmitter<boolean>();

  isPaysFrance = false;
  isSiretMandatory = true;
  isActiviteMandatory = true;
  anchorValue: any;
  pme = false;

  get effectifSiege(): FormControl {
    return this.structureForm.get('effectifSiege') as FormControl;
  }
  get effectifGroupe(): FormControl {
    return this.structureForm.get('effectifGroupe') as FormControl;
  }

  get isPme(): FormControl {
    return this.structureForm.get('pme') as FormControl;
  }

  constructor(private dialog: MatDialog, @Inject('environment') private environment: Environment) {
    super();
  }

  ngOnInit() {
    this.updateState();
    this.updateValidationOnMotifNonRattachementChange();
    this.updateActiviteOnPaysChange();
    this.cancelRectificationOnInfoChange();

    this.structureForm.get('typeStructure').valueChanges.subscribe((value: EnumTypeStructure) => {
      if (
        value === EnumTypeStructure.PETITE_ENTREPRISE_NON_COTEE ||
        value === EnumTypeStructure.PETITE_ENTREPRISE_COTEE ||
        value === EnumTypeStructure.MOYENNE_ENTREPRISE_NON_COTEE ||
        value === EnumTypeStructure.MOYENNE_ENTREPRISE_COTEE
      ) {
        this.pme = true;
        this.structureForm.addControl('pme', new FormControl(null, [Validators.required, PxlValidators.mustBeChecked()]));
      } else {
        this.pme = false;
        this.structureForm.get('pme')?.setValue(false);
        this.structureForm.get('pme')?.clearValidators();
        this.structureForm.get('pme')?.updateValueAndValidity();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['readOnly']) {
      this.updateState();
    }
  }

  updateState() {
    const typeStructure = EnumTypeStructure[this.structureForm.get('typeStructure').value as EnumTypeStructure];
    if (!EnumTypeStructure.forPersonneMorale().includes(typeStructure)) {
      this.structureForm.get('typeStructure')?.reset();
    }
    this.structureForm.get('raisonSocial')?.addValidators([Validators.required]);

    if (this.isPme && this.isPme.value === true) {
      this.pme = true;
    }

    const relatedControlNames = [
      'typeStructure',
      'effectifSiege',
      'effectifGroupe',
      'siret',
      'creationDate',
      'raisonSocial',
      'activite',
      'adresse',
    ];
    relatedControlNames.forEach(controlName => {
      if (this.readOnly && !this.readOnlyExceptions.includes(controlName)) {
        this.structureForm.get(controlName)?.disable();
      } else {
        this.structureForm.get(controlName)?.enable();
      }
    });
  }

  updateActiviteOnPaysChange() {
    this.structureForm
      .get('adresse.codePays')
      ?.valueChanges.pipe(distinctUntilChanged(), this.takeUntilDestroyed())
      .subscribe((codePays: string) => {
        this.isPaysFrance = codePays === 'FR';
        if (this.isPaysFrance && !this.readOnly) {
          this.structureForm.get('activite')?.enable();
        } else {
          if (!this.readOnly) {
            this.structureForm.get('activite')?.reset();
          }
          this.structureForm.get('activite')?.disable();
        }
      });
    this.structureForm.get('adresse.codePays').updateValueAndValidity();
  }

  private updateValidationOnMotifNonRattachementChange() {
    this.structureForm
      .get('raisonSiret')
      ?.valueChanges.pipe(distinctUntilChanged(), this.takeUntilDestroyed())
      .subscribe((motif: EnumMotifNonRattachement) => {
        // having a reason (motifNonRattachement) means the user probably does not have a siret or activite
        if (motif) {
          this.structureForm.get('siret')?.clearValidators();
          this.structureForm.get('activite')?.clearValidators();
          this.isSiretMandatory = false;
          this.isActiviteMandatory = false;
        } else {
          this.structureForm.get('siret')?.setValidators([Validators.required, siretValidator()]);
          this.structureForm.get('activite')?.setValidators([Validators.required]);
          this.isSiretMandatory = true;
          this.isActiviteMandatory = true;
        }
        this.structureForm.get('siret')?.updateValueAndValidity();
        this.structureForm.get('activite')?.updateValueAndValidity();
      });
    this.structureForm.get('raisonSiret')?.updateValueAndValidity();
  }

  handleRectificationRequest(): void {
    const dialogRef = this.dialog.open(DemandeRectificationModalComponent, {
      data: {
        demandeRectification: this.structureForm?.get('adresse.demandeRectification'),
      },
    });

    dialogRef.afterClosed().subscribe(demandeRectification => {
      if (demandeRectification !== undefined) {
        this.structureForm?.get('adresse.demandeRectification')?.patchValue(demandeRectification);
        this.rectificationRequested.emit(demandeRectification);
        this.anchorValue = this.extractInfo();
      }
    });
  }

  cancelRectificationOnInfoChange() {
    this.anchorValue = this.extractInfo();
    this.structureForm?.valueChanges.pipe(this.takeUntilDestroyed()).subscribe(() => {
      const currentValue = this.extractInfo();
      const hasChanged =
        Object.entries(this.anchorValue).toString().toLowerCase() !== Object.entries(currentValue).toString().toLowerCase();
      if (hasChanged) {
        this.rectificationCanceled.emit(hasChanged);
      }
    });
  }

  private extractInfo() {
    const relatedControlNames = [
      'siret',
      'creationDate',
      'raisonSocial',
      'activite',
      'adresse.codePays',
      'adresse.pays',
      'adresse.cp',
      'adresse.ville',
      'adresse.mentionDistribution',
      'adresse.hameau',
      'adresse.numero',
      'adresse.complement',
      'adresse.typeVoie',
      'adresse.voie',
      'adresse.complementInfo',
      'adresse.divisionAdministrative',
    ];
    const info = {} as any;
    relatedControlNames.forEach(key => {
      info[key] = this.structureForm.get(key)?.value;
    });
    return info;
  }

  protected readonly EnumTypeStructure = EnumTypeStructure;

  onkeypress($event: any): boolean {
    return !($event.key && $event.key.length === 1 && /[^\d]/.test($event.key));
  }

  downloadDefPME(): void {
    window.open(this.environment.docPublicBaseUrl + URL_PME);
  }
}
