import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'lib-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss'],
})
export class AccordionComponent {
  @Input() titre = '';
  @Input() tag = '';
  @Input() tagClass = '';
  @Input() link = '';
  @Input() accordionOpen = false;
  @Output() linkClick = new EventEmitter<string>();
  @Output() accordionToggle = new EventEmitter<boolean>();

  toggleAccordion(): void {
    this.accordionOpen = !this.accordionOpen;
    this.accordionToggle.emit(this.accordionOpen);
  }
}
