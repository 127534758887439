import { Component, OnInit, inject } from '@angular/core';
import { PxlOidcService } from '@services-candidat/pxl-oidc.service';
import { URL_CGU, URL_MENTION_LEGALE } from '@shared-candidat/utils/constants';
import { EnumAuthConfigurationType } from '@shared-ui';

@Component({
  selector: 'pxl-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  year: number;
  private oidcSecurityService = inject(PxlOidcService);

  ngOnInit(): void {
    this.year = new Date().getFullYear();
  }

  getMentionLegale(): void {
    window.open(URL_MENTION_LEGALE);
  }

  getCgu(): void {
    window.open(URL_CGU);
  }

  connexion(): void {
    localStorage.setItem('redirect', '/espace');
    localStorage.setItem('idp', EnumAuthConfigurationType.ISAM);
    this.oidcSecurityService.authorize(EnumAuthConfigurationType.ISAM);
  }
}
