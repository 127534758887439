import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[libPositiveIntegerOnly]',
})
export class PositiveIntegerOnlyDirective {
  @HostListener('keypress', ['$event'])
  onKeyPress(event: KeyboardEvent): void {
    if (event.key && /[^\d ]/.test(event.key)) {
      event.preventDefault();
    }
  }
}
