<section class="page-section">
  <form class="form" [formGroup]="signatureManuelleForm">
    <h2 class="page-section__title mt-3">Correspondant signature manuelle de la fiche de demande d’aide</h2>
    <div class="card">
      <section>
        <div
          class="ds-input-group"
          [class.ds-input-group--error]="
            signatureManuelleForm.controls['signeeParRepresentantLegal'].invalid &&
            signatureManuelleForm.controls['signeeParRepresentantLegal'].touched
          "
        >
          <div class="questions-container">
            <div>
              <label class="fw-bold question-signature-manuelle">
                Est ce que la fiche de demande d’aide est signée par le représentant légal que vous avez déclaré dans la section
                “Représentant.e légal.e” ?
                <lib-asterisk></lib-asterisk>
              </label>
            </div>
            <div class="radio-btn">
              <label class="ds-radio-classic" for="signeeParRepresentantLegal-true">
                <input
                  type="radio"
                  class="ds-radio__input"
                  id="signeeParRepresentantLegal-true"
                  name="signeeParRepresentantLegal"
                  formControlName="signeeParRepresentantLegal"
                  [value]="true"
                />
                <span class="ds-radio__label"><span>Oui</span></span>
              </label>
              <label class="ds-radio-classic" for="signeeParRepresentantLegal-false">
                <input
                  type="radio"
                  class="ds-radio__input"
                  id="signeeParRepresentantLegal-false"
                  name="signeeParRepresentantLegal"
                  formControlName="signeeParRepresentantLegal"
                  [value]="false"
                />
                <span class="ds-radio__label"><span>Non</span></span>
              </label>
            </div>
          </div>
          <ng-container
            id="error-signeeParRepresentantLegal"
            *ngIf="
              !signatureManuelleForm.controls['signeeParRepresentantLegal'].valid &&
              signatureManuelleForm.controls['signeeParRepresentantLegal'].touched
            "
          >
            <div class="ds-input-group__feedback">Merci de bien vouloir renseigner une valeur.</div>
          </ng-container>
        </div>
      </section>

      <section *ngIf="!isSignedByRepresentLegal">
        <lib-fiche-demande-aide-signature-identite-form
          [identiteForm]="identityForm"
          [etapeProjet]="etapeProjet"
          [title]="TITLE_FORM"
          componentId="signature-manuelle"
        >
        </lib-fiche-demande-aide-signature-identite-form>
      </section>
    </div>
  </form>
</section>
