import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Environment } from '../models/environment.model';
import { InformationSiege } from '../models/information-siege.model';

@Injectable({
  providedIn: 'root',
})
export class SiretService {
  constructor(@Inject('environment') private environment: Environment, public httpClient: HttpClient) {}

  searchSiret(siret: string): Observable<InformationSiege[]> {
    return this.httpClient.get<InformationSiege[]>(`${this.environment.apiUrl}structures/informations-sieges?siret=${siret}`);
  }
}
