export enum EnumStatutDemandeCorrection {
  OUVERT = 'Ouvert' as any,
  CORRIGE = 'Corrigée' as any,
  VALIDE = 'Validée' as any,
  REJETE = 'Rejetée' as any,
}

export enum EnumStatutDemandeCorrectionTech {
  OUVERT = 'OUVERT',
  CORRIGE = 'CORRIGE',
  VALIDE = 'VALIDE',
  REJETE = 'REJETE',
}
