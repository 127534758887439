<h2 id="modal-title" class="modal__title" *ngIf="modalData.title">{{ modalData.title }}</h2>

<form id="input-typeDoc" class="modal__content" [formGroup]="npsForm">
  <div class="ds-input-group" [class.ds-input-group--error]="!npsForm.controls['note'].valid && npsForm.controls['note'].touched">
    <label for="note" class="ds-input-group__label">
      Sur une échelle de 1 à 10, à quel point recommanderiez-vous à un de vos pairs le parcours de dépôt de projet ?
    </label>
    <div class="select-container">
      <div id="id_input-note-select_1234" class="input-note-select note-select">
        <input formControlName="note" type="radio" name="note" id="1" value="1" hidden />
        <label for="1">1</label>
        <input formControlName="note" type="radio" name="note" id="2" value="2" hidden />
        <label for="2">2</label>
        <input formControlName="note" type="radio" name="note" id="3" value="3" hidden />
        <label for="3">3</label>
        <input formControlName="note" type="radio" name="note" id="4" value="4" hidden />
        <label for="4">4</label>
        <input formControlName="note" type="radio" name="note" id="5" value="5" hidden />
        <label for="5">5</label>
        <input formControlName="note" type="radio" name="note" id="6" value="6" hidden />
        <label for="6">6</label>
        <input formControlName="note" type="radio" name="note" id="7" value="7" hidden />
        <label for="7">7</label>
        <input formControlName="note" type="radio" name="note" id="8" value="8" hidden />
        <label for="8">8</label>
        <input formControlName="note" type="radio" name="note" id="9" value="9" hidden />
        <label for="9">9</label>
        <input formControlName="note" type="radio" name="note" id="10" value="10" hidden />
        <label for="10">10</label>
      </div>
    </div>
  </div>
  <div
    class="ds-input-group"
    [class.ds-input-group--error]="!npsForm.controls['commentaire'].valid && npsForm.controls['commentaire'].touched"
  >
    <label for="commentaire" class="ds-input-group__label">Commentaire</label>
    <span class="ds-input-group__input">
      <textarea
        id="nps_commentaire"
        name="commentaire"
        type="text"
        rows="5"
        col="0"
        maxlength="1000"
        formControlName="commentaire"
        class="ds-input-group__textarea"
      ></textarea>
    </span>
  </div>
</form>

<div id="modal-btn" class="modal__actions">
  <button *ngIf="modalData.textReturnButton" class="ds-btn ds-btn--secondary" mat-dialog-close>
    {{ modalData.textReturnButton }}
  </button>
  <button class="ds-btn ds-btn--primary" [disabled]="!npsForm.valid" (click)="sendAvis(npsForm.value)" cdkFocusInitial>
    {{ modalData.textGoButton }}
  </button>
</div>
