<h3 [ngClass]="{ disabled: disabled }" class="page-section__title">
  {{ title }}
  <span>{{ titleComplement }}</span>
</h3>

<p class="info-size-message" *ngIf="showMaxSizeInfo">Poids maximum par fichier {{ FILE_SIZE_LIMIT }}Mo</p>

<p *ngIf="showAddDocSubtitle" class="info-size-message">Merci d’ajouter ici tous vos documents en rapport avec le projet.</p>

<p class="info-size-message" *ngIf="showAddProjectDocSubtitle">
  Merci d’ajouter ici tous vos documents en rapport avec le projet. Pour plus d’informations, vous pouvez consulter
  <a [href]="dossier">le dossier de candidature</a>
</p>
