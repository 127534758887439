import { Injectable } from '@angular/core';
import { DocumentHelper, DocumentProjet, EnumScanDocument, ShowToastrService, UploadDocumentHttpService } from '@shared-ui';
import { catchError, map, switchMap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { EMPTY, Observable, of, throwError } from 'rxjs';
import { DownloadUtils } from '../../utils/download-utils';

@Injectable()
export class DocumentFileManagerService {
  constructor(
    public uploadDocumentService: UploadDocumentHttpService,
    private showToastrService: ShowToastrService,
    private downloadUtils: DownloadUtils
  ) {}

  public uploadDocumentToS3(documentProjet: DocumentProjet, file: File, multiDocument?: boolean): Observable<void> {
    if (!documentProjet.relatedResourceId && !documentProjet.id) {
      return throwError(() => new Error('relatedResourceId or id is missing'));
    }
    const folderPath = DocumentHelper.getDocumentS3FolderPath(documentProjet, multiDocument);
    const typeUpload = DocumentHelper.getUploadDocumentType(documentProjet);
    const documentId: string = multiDocument ? documentProjet.relatedResourceId!: documentProjet.id;
    return this.uploadDocumentService.createS3SignedUrlForDocument(documentProjet, folderPath, typeUpload, multiDocument).pipe(
      switchMap((signedUrl: string) =>
        this.uploadDocumentService.uploadDoc(
          signedUrl,
          file,
          documentProjet.projetId,
          documentId,
          typeUpload,
          documentProjet.typeDoc,
          multiDocument
        )
      ),
      map(resp => resp.body)
    );
  }

  /**
   * Download a document from the Ged or S3 depending on the document type and the stockerGed property.
   *
   * @returns Observable<void> Be aware of the fact that the observable returned can emit complete event only
   */
  downloadDocument(document: DocumentProjet, isSiteAdmin?: boolean): Observable<void> {
    if (document.type === 'CCUs') {
      return this.downloadDocumentCCUs();
    } else if (document.stockerGed) {
      return this.downloadDocumentFromGed(document, isSiteAdmin);
    } else {
      return this.downloadFromS3(document);
    }
  }

  deleteDocument(document: DocumentProjet): Observable<void> {
    // Delete from Ged is already handled by the backend. And if the document is unsafe, it will be deleted from the S3
    if (document.stockerGed || document.scan === EnumScanDocument.UNSAFE) {
      return of(undefined); // undefined is returned to emit a next event in the observable. Else the observable will emit a complete event only.
    }
    return this.uploadDocumentService.deleteDocumentFromS3(document);
  }

  private downloadFromS3(document: DocumentProjet): Observable<void> {
    return this.uploadDocumentService.getValueForDocDownload(document).pipe(
      switchMap(response => {
        if (!response.body || !response.body.url) {
          return throwError(() => new Error("Erreur lors de la récupération de l'url signée"));
        }
        window.open(response.body.url);
        return EMPTY;
      }),
      catchError((err: HttpErrorResponse) => {
        this.showToastrService.checkCodeError(err?.error);
        return throwError(() => err);
      })
    );
  }

  private downloadDocumentFromGed(document: DocumentProjet, isSiteAdmin?: boolean): Observable<void> {
    return this.uploadDocumentService.getDocFromGed(document.id, isSiteAdmin).pipe(
      switchMap(response => {
        if (!response.body) {
          return throwError(() => new Error('Erreur lors de la récupération du document depuis la Ged'));
        }
        this.downloadUtils.download(response.body, document.nom);
        return EMPTY;
      }),
      catchError((err: HttpErrorResponse) => {
        this.showToastrService.checkCodeError(err?.error);
        return throwError(() => err);
      })
    );
  }

  private downloadDocumentCCUs(): Observable<void> {
    window.open(this.uploadDocumentService.getDocumentCCUsUrl());
    return EMPTY;
  }
}
