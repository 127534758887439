import { enumKeys } from '@shared-ui';

export enum EnumTypeSignataire {
  SIGNATAIRE_INTERNE = 'SIGNATAIRE_INTERNE',
  SIGNATAIRE_EXTERNE = 'SIGNATAIRE_EXTERNE',
  SUIVEUR_INTERNE = 'SUIVEUR_INTERNE',
  SUIVEUR_EXTERNE = 'SUIVEUR_EXTERNE',
  SOLIDAIRE_EXTERNE = 'SOLIDAIRE_EXTERNE',
}

export namespace EnumTypeSignataire {
  export function toString(typeSignataire: EnumTypeSignataire): string {
    switch (typeSignataire) {
      case EnumTypeSignataire.SIGNATAIRE_INTERNE:
        return 'Signataire interne';
      case EnumTypeSignataire.SIGNATAIRE_EXTERNE:
        return 'Signataire externe';
      case EnumTypeSignataire.SUIVEUR_INTERNE:
        return 'Suiveur interne';
      case EnumTypeSignataire.SUIVEUR_EXTERNE:
        return 'Suiveur externe';
      case EnumTypeSignataire.SOLIDAIRE_EXTERNE:
        return 'Solidaire externe';
    }
  }

  export function all(): EnumTypeSignataire[] {
    return enumKeys(EnumTypeSignataire) as unknown as EnumTypeSignataire[];
  }
}
