export type MultiSelectCriteria = { [key: string]: string[] }; // fieldName, values

export type RangeCriteria = { [key: string]: { start?: number; end?: number } | null };

export type ProjetFilters = { rangeFilters: RangeCriteria; selectFilters: MultiSelectCriteria; objetProjet: string | undefined };

export type PartenaireFilters = { rangeFilters: RangeCriteria; selectFilters: MultiSelectCriteria };

export type RangeDatesCriteria = { [key: string]: { start: string; end: string } | null };

export type BasicFilters = {
  aaps: string[];
  acronymes: string[];
  denominationsSociales: string[];
  dispositifs: string[];
  processFr30List: string[];
};

export class SearchCriteria {
  rangeDatesCriterias?: RangeDatesCriteria = {};
  freeSearchQuery?: string;
  multiSelectCriterias?: MultiSelectCriteria = {};
  rangeCriterias?: RangeCriteria = {};
  objetProjet?: string; // description
}

export class RechercheTransverse {
  id: string;
  produitInnovant: string;
  produit: string;
  dispositif: string;
  aap: string;
  acronyme: string;
  denominationSociale: string;
  statutAvancement: string;
  montantTotalDeLAideRetenueHt: number;
  dateDeSignatureClient: string;
  dateDeReleve: Date;
  // TODO: Add missing properties
}

export class RechercheTransverseExportCriteria {
  ids: string[];
}
