<header id="header-structure" class="page-header">
  <a class="page-header__back" (click)="onGoToConsortiumInfo()">
    <lib-pxl-icon class="icon" icon="icon-arrow-arrow-back-ios"></lib-pxl-icon>
    Ma Structure
  </a>
  <h2 class="page-header__title">Eléments financiers</h2>
</header>
<div class="page-content">
  <section class="page-section">
    <div id="dstab" class="ds-tabs ds-tabs--underline">
      <ul class="ds-tabs-list d-flex justify-content-between onglets" role="tablist" aria-label="nom du système d'onglet">
        <div class="d-flex">
          <li>
            <button
              id="tab_a"
              class="ds-tabs-list__tab ds-tabs-list__tab--active"
              role="tab"
              [tabindex]="0"
              aria-controls="panela"
              aria-selected="true"
            >
              Comptes de résultats
            </button>
          </li>
          <li>
            <button id="tab_b" class="ds-tabs-list__tab" role="tab" [tabindex]="1" aria-controls="panelb" aria-selected="false">
              Plan de financement
            </button>
          </li>
          <li>
            <button id="tab_c" class="ds-tabs-list__tab" role="tab" [tabindex]="2" aria-controls="panelc" aria-selected="false">
              Plan de trésorerie
            </button>
          </li>
          <li>
            <button id="tab_d" class="ds-tabs-list__tab" role="tab" [tabindex]="3" aria-controls="paneld" aria-selected="false">
              Vérif. situation financière
            </button>
          </li>
        </div>
      </ul>

      <div [tabindex]="0" role="tabpanel" id="panela" class="ds-tabs-panel" aria-labelledby="tab_a">
        <lib-card-info *ngIf="isEvaluateur" [message]="readonlyMessage"></lib-card-info>
        <lib-card-info *ngIf="partenaire === EnumTypePartenaire.INDIV" [message]="MESSAGE_PORTEUR"></lib-card-info>
        <lib-card-info *ngIf="partenaire === EnumTypePartenaire.MULTI" [message]="MESSAGE_COLLABORATIF"></lib-card-info>
        <lib-card-info [message]="EUROS_AMOUNTS_MESSAGE"></lib-card-info>
        <lib-projet-consortium-df-comptes-resultats
          [isEvaluateur]="isEvaluateur"
          [minNbOfColumns]="minNbOfColumns"
          [displayYearsButtons]="!isEvaluateur && status !== Status.VALIDE"
          [isComptesResultats]="true"
          [pf]="comptesResultats"
          [status]="status"
          [upTitleRow]="false"
          [isDisabled]="status === Status.VALIDE"
          (updateCurrentYearEmitter)="onUpdateCurrentYear()"
        >
        </lib-projet-consortium-df-comptes-resultats>
      </div>

      <div [tabindex]="1" role="tabpanel" id="panelb" class="ds-tabs-panel" aria-labelledby="tab_b" hidden="">
        <lib-card-info *ngIf="isEvaluateur" [message]="readonlyMessage"></lib-card-info>
        <lib-card-info *ngIf="partenaire === EnumTypePartenaire.INDIV" [message]="MESSAGE_PORTEUR"></lib-card-info>
        <lib-card-info *ngIf="partenaire === EnumTypePartenaire.MULTI" [message]="MESSAGE_COLLABORATIF"></lib-card-info>
        <lib-card-info [message]="EUROS_AMOUNTS_MESSAGE"></lib-card-info>
        <lib-projet-consortium-df-plan-financement
          [isEvaluateur]="isEvaluateur"
          [minNbOfColumns]="minNbOfColumns"
          [displayYearsButtons]="!isEvaluateur && status !== Status.VALIDE"
          [pf]="plansFinancement"
          [status]="status"
          [upTitleRow]="false"
          [isDisabled]="status === Status.VALIDE"
          (updateCurrentYearEmitter)="onUpdateCurrentYear()"
        >
        </lib-projet-consortium-df-plan-financement>
        <section class="page-section">
          <div>
            <span class="bold text-uppercase">Solde trésorerie du dernier bilan : &nbsp;&nbsp; </span>
            <span class="bold"> {{ soldeTresorerie | number }} K&euro;</span>
          </div>
        </section>
      </div>

      <div [tabindex]="2" role="tabpanel" id="panelc" class="ds-tabs-panel" aria-labelledby="tab_c" hidden="">
        <lib-card-info *ngIf="isEvaluateur" [message]="readonlyMessage"></lib-card-info>
        <lib-card-info [message]="EUROS_AMOUNTS_MESSAGE"></lib-card-info>
        <lib-card-info *ngIf="!isQuestionEnabled && isOlderThanHeightYears" [message]="OLDER_THAN_HEIGHT_MESSAGE"></lib-card-info>
        <section class="label-slide-toggle">
          <label>Etes-vous une entreprise créée il y a plus de 8 ans ?</label>
          <mat-slide-toggle [disabled]="!isQuestionEnabled" [color]="'primary'" [(ngModel)]="isOlderThanHeightYears">
            {{ isOlderThanHeightYears ? 'Oui' : 'Non' }}
          </mat-slide-toggle>
        </section>
        <lib-projet-consortium-df-plan-tresorerie
          [isEvaluateur]="isEvaluateur"
          [pf]="plansTresorerie"
          [status]="status"
          [upTitleRow]="false"
          [isDisabled]="isOlderThanHeightYears || status === Status.VALIDE"
        >
        </lib-projet-consortium-df-plan-tresorerie>
      </div>

      <div [tabindex]="3" role="tabpanel" id="paneld" class="ds-tabs-panel" aria-labelledby="tab_d" hidden="">
        <lib-card-info *ngIf="isEvaluateur" [message]="readonlyMessage"></lib-card-info>
        <lib-projet-consortium-df-situation-financiere
          [isEvaluateur]="isEvaluateur"
          [situationFinanciere]="situationFinanciere"
          [isDisabled]="status === Status.VALIDE"
        >
        </lib-projet-consortium-df-situation-financiere>
      </div>
    </div>
  </section>
  <lib-cancel-save-validate-modify-buttons
    [status]="status"
    [hideAllButtons]="isEvaluateur"
    [hideModifyButtons]="isProjetDepot"
    [displayCancelButton]="false"
    (saveEventEmitter)="onSave()"
    (validateEventEmitter)="onValidate()"
    (updateEventEmitter)="onUpdate()"
  >
  </lib-cancel-save-validate-modify-buttons>
</div>
