import { Injectable } from '@angular/core';
import { Observable, map, of } from 'rxjs';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { environment } from '@environments-candidat/environment';
import { AuthOptions } from 'angular-auth-oidc-client/lib/auth-options';
import { EnumAuthConfigurationType } from '@shared-ui';

@Injectable()
export class PxlOidcService {
  constructor(private oidcSecurityService: OidcSecurityService) {}

  getUserData(): Observable<any> {
    const userDataObservable = environment.isLocal
      ? of(this.getJwtPayload(environment.mockToken))
      : this.oidcSecurityService.getUserData(localStorage.getItem('idp'));
    return userDataObservable.pipe(
      map(userData => {
        if (userData && userData.email && !userData.emailAddress) {
          userData.firstName = userData.given_name;
          userData.lastName = userData.family_name;
          userData.emailAddress = userData.email;
        }
        return userData;
      })
    );
  }

  get isAuthenticated$(): Observable<any> {
    return this.isAuthenticated().pipe(map(isAut => ({ isAuthenticated: isAut })));
  }

  isAuthenticated(): Observable<boolean> {
    return environment.isLocal ? of(true) : this.oidcSecurityService.isAuthenticated(localStorage.getItem('idp'));
  }

  checkAuth(url?: string): Observable<any> {
    return environment.isLocal ? of({ isAuthenticated: true }) : this.oidcSecurityService.checkAuth(url, localStorage.getItem('idp'));
  }

  authorize(configId?: string, authOptions?: AuthOptions): void {
    if (!environment.isLocal) {
      this.oidcSecurityService.authorize(configId, authOptions);
    }
  }

  getAccessToken(): Observable<string> {
    if (environment.isLocal) {
      return of(environment.mockToken);
    }

    const configId = localStorage.getItem('idp');
    if (configId === EnumAuthConfigurationType.ISAM) {
      return this.oidcSecurityService.getAccessToken(configId);
    } else {
      return this.oidcSecurityService.getIdToken(configId);
    }
  }

  logoff(urlHandler?: (url: string) => any): void {
    const authOptions = { urlHandler };
    this.oidcSecurityService.logoff(null, authOptions);
  }

  logoffAndRevokeTokens(urlHandler?: (url: string) => any): Observable<any> {
    const configId = localStorage.getItem('idp');
    let logout;
    this.oidcSecurityService.getIdToken(configId).subscribe(result => {
      const authOptions: any = { urlHandler };
      authOptions.customParams = { id_token_hint: result };
      logout = this.oidcSecurityService.logoffAndRevokeTokens(configId, authOptions);
    });
    return logout;
  }

  private getJwtPayload(token: string) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split('')
        .map(c => {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );
    return JSON.parse(jsonPayload);
  }
}
