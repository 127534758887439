<div *ngIf="actions">
  <div class="menu_actions">
    <button
      id="actions-btn"
      class="ds-btn ds-btn--primary btn-correction position-relative"
      placement="bottom-right"
      [ngbPopover]="popCorrection"
    >
      ...
    </button>
    <ng-template #popCorrection>
      <div class="popover_container">
        <div *ngFor="let action of actions | keyvalue">
          <div *ngIf="action.value.visible" class="popover_link">
            <lib-pxl-icon class="icon" [icon]="action.value.icon"></lib-pxl-icon>
            <a (click)="actionClick.emit(action.key)">{{ action.value.label }}</a>
          </div>
        </div>
      </div>
    </ng-template>
  </div>
</div>
