import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[libAutoFocus]',
})
export class AutoFocusDirective {
  constructor(el: ElementRef) {
    setTimeout(() => {
      el.nativeElement.focus();
    });
  }
}
