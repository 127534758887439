import { FormGroup } from '@angular/forms';
import {
  Aap,
  DocumentAapModel,
  DocumentConfig,
  DocumentProjet,
  EnumProjetEtape,
  EnumScope,
  MoveDocumentConfig,
  Projet,
  Structure,
} from '@shared-ui';

export class SharedFunction {
  getProjectEtapeName(projet: Projet): EnumProjetEtape | null {
    if (projet?.etapes == null) {
      return null;
    }
    return projet?.etapes[projet?.etapes.length - 1].nom;
  }

  getDocumentsAapForStructure(aap: Aap, structure: Structure, projet: Projet, scope: EnumScope): DocumentAapModel[] {
    const projetEtapeName = this.getProjectEtapeName(projet);
    return aap.documentAaps?.filter((docAap: any) => {
      return (
        docAap.scope === scope &&
        docAap.etapes.includes(projetEtapeName) &&
        docAap.typePartenaires?.includes(projet.partenaireType) &&
        docAap.roleStructures?.includes(structure.role) &&
        docAap.typeStructures.includes(structure.typeStructure)
      );
    });
  }

  isDisabledFormValid(form: FormGroup): boolean {
    const wasDisabled = form?.disabled;
    form?.enable();
    const formValid = form?.valid;
    if (wasDisabled) {
      form?.disable();
    }
    return formValid;
  }

  getDocumentName(document: DocumentProjet): string {
    if (!document.nom) {
      return 'Masqué';
    } else {
      return document.nom;
    }
  }

  getMoveDocumentConfig(aap: Aap, projet: Projet): MoveDocumentConfig {
    return {
      structures: projet.structures.map(structure => ({
        id: structure.id,
        raisonSociale: structure.raisonSocial,
      })),
      documents: this.getDocumentConfigs(aap, projet),
    };
  }

  private getDocumentConfigs(aap: Aap, projet: Projet): DocumentConfig[] {
    const etapeProjet = this.getProjectEtapeName(projet) as EnumProjetEtape;
    const documentsProjet: DocumentConfig[] = aap.documentAaps
      ?.filter(
        document =>
          document.scope === EnumScope.PROJET &&
          document.etapes.includes(etapeProjet) &&
          document.typePartenaires?.includes(projet.partenaireType)
      )
      .map(document => ({
        nomDocument: document.nomDocument,
        scope: EnumScope.PROJET,
        mediaTypes: document.mediaTypes,
        connaissanceClient: document.connaissanceClient,
      }))
      .sort((a, b) => (a.nomDocument > b.nomDocument ? 1 : -1));

    return documentsProjet.concat(
      projet.structures.flatMap(structure =>
        aap.documentAaps
          ?.filter(
            (docAap: any) =>
              [EnumScope.STRUCTURE, EnumScope.STRUCTURE_ACTIONARIAL].includes(docAap.scope) &&
              docAap.etapes.includes(etapeProjet) &&
              docAap.typePartenaires?.includes(projet.partenaireType) &&
              docAap.roleStructures?.includes(structure.role) &&
              docAap.typeStructures.includes(structure.typeStructure)
          )
          .map(document => ({
            nomDocument: document.nomDocument,
            scope: document.scope,
            mediaTypes: document.mediaTypes,
            connaissanceClient: document.connaissanceClient,
            structureId: structure.id,
          }))
      )
    );
  }
}
