<div
  id="modal_supp_folder"
  class="ds-modal ds-modal-mask ds-exemple"
  role="dialog"
  aria-label="Modale de suppression des dossiers"
  aria-describedby="modalTitle"
  tabindex="-1"
>
  <div class="ds-modal-centered">
    <!-- Modal Content -->
    <div class="ds-modal-content ds-card--modal" role="document" tabindex="0">
      <!-- Header -->
      <div class="ds-modal__header">
        <h4 class="ds-modal__header-title" id="modalTitle" tabindex="0" data-ds-focus>
          {{ modalData.title }}
        </h4>
        <button data-ds-dismiss data-ds-focus class="ds-modal__header-closeIcon" (click)="cancel()" aria-label="Fermeture de la modale">
          <span class="ds-icon" aria-hidden="true">close</span>
        </button>
      </div>
      <!-- Body -->
      <div class="ds-modal__body">
        <div class="mb-3">
          <lib-accordion titre="Colonnes non éditables">
            <div cdkDropList class="example-list" (cdkDropListDropped)="drop($event)">
              <div class="column-box ds-checkbox-group">
                <div class="example-box" *ngFor="let element of disabledColumns; index as i" cdkDrag cdkDragDisabled>
                  <div class="column-data-box">
                    {{ element.label }}
                  </div>
                  <lib-pxl-icon icon="icon-locked-column" class="icon"></lib-pxl-icon>
                </div>
              </div>
            </div>
          </lib-accordion>
        </div>

        <lib-accordion titre="Colonnes éditables" [accordionOpen]="true">
          <div cdkDropList class="example-list" (cdkDropListDropped)="drop($event)">
            <div class="column-box" class="ds-checkbox-group">
              <div class="example-box" *ngFor="let element of activeColumns; index as i" cdkDrag>
                <div class="column-data-box">
                  <mat-checkbox [(ngModel)]="element.visible" [disabled]="element.disabled" (ngModelChange)="changeColumnVisibility()">
                    {{ element.label }}
                  </mat-checkbox>
                </div>
                <lib-pxl-icon icon="icon-order-column" class="icon"></lib-pxl-icon>
              </div>
            </div>
          </div>
        </lib-accordion>
      </div>

      <div id="modal-btn" class="modal__actions">
        <button class="ds-btn ds-btn--secondary" mat-dialog-close (click)="cancel()">Annuler</button>
        <button class="ds-btn ds-btn--primary" (click)="submit()">Modifier</button>
      </div>
    </div>
  </div>
</div>
