import { Injectable } from '@angular/core';
import { ShowToastrService } from '@shared-ui';
import { Observable } from 'rxjs';

@Injectable()
export class resizeImage {
  constructor(private showToastrService: ShowToastrService) {}

  static getRatioWidth: number;
  static getRatioHeight: number;

  public processImage(file: File, maxWidth: number, maxHeight: number): Observable<File | null> {
    return new Observable(observer => {
      const img = new Image();
      const reader = new FileReader();

      reader.onload = (e: ProgressEvent) => {
        img.src = (e.target as FileReader).result as string;
        img.onload = () => {
          let width = img.width;
          let height = img.height;

          const widthRatio = maxWidth / width;
          const heightRatio = maxHeight / height;
          const ratio = Math.min(widthRatio, heightRatio);

          resizeImage.getRatioWidth = width = width * ratio;
          resizeImage.getRatioHeight = height = height * ratio;

          // Check if the image resolution is too low
          if (img.width < maxWidth && img.height < maxHeight) {
            this.showToastrService.error("La résolution de l'image est trop basse. Veuillez télécharger une image de meilleure qualité.");
            observer.complete();
            return;
          }

          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d')!;
          canvas.width = width;
          canvas.height = height;
          ctx.drawImage(img, 0, 0, width, height);

          canvas.toBlob(blob => {
            if (blob) {
              observer.next(new File([blob], file.name, { type: file.type }));
              observer.complete();
            } else {
              observer.next(null);
              observer.complete();
            }
          }, file.type);
        };
      };

      reader.readAsDataURL(file);
    });
  }

  static getWidth(): number {
    return resizeImage.getRatioWidth;
  }

  static getHeight(): number {
    return resizeImage.getRatioHeight;
  }
}
