import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'toNumber',
})
export class ToNumberPipe implements PipeTransform {
  transform(value: string): string | null {
    if (value) {
      value = value.toString().replace(/\s/g, '');
    }
    const retNumber = Number(parseInt(value, 10));
    return isNaN(retNumber) ? null : retNumber.toLocaleString('fr-FR');
  }
}
