import { enumKeys } from "../../utils/enum-utils";

export enum EnumAapStatut {
  BROUILLON = 'BROUILLON',
  CREE = 'CREE',
  OUVERT = 'OUVERT',
  FERME = 'FERME',
  ARCHIVE = 'ARCHIVE',
  SUPPRIME = 'SUPPRIME'
}

export namespace EnumAapStatut {
  export function toString(statut: EnumAapStatut): string {
    switch (statut)  {
      case EnumAapStatut.BROUILLON: return 'Brouillon';
      case EnumAapStatut.CREE: return 'Créé';
      case EnumAapStatut.OUVERT: return 'Ouvert';
      case EnumAapStatut.FERME: return 'Fermé';
      case EnumAapStatut.ARCHIVE: return 'Archivé';
      case EnumAapStatut.SUPPRIME: return 'Supprimé';
    }
  }

  export function all(): EnumAapStatut[] {
    return enumKeys(EnumAapStatut) as unknown as EnumAapStatut[];
  }
}
