<h2 id="modal-title" class="modal__title" *ngIf="modalData.title">{{ modalData.title }}</h2>

<form id="input-typeDocInvalide" class="modal__content" [formGroup]="documentInvalideForm">
  <div
    id="input-invalide"
    class="form-step__invalide ds-input-group"
    [class.ds-input-group--error]="
      !documentInvalideForm.controls['invalideMotifs'].valid && documentInvalideForm.controls['invalideMotifs'].touched
    "
  >
    <label *ngIf="modalData.description2" for="input-field" class="ds-input-group__label">{{ modalData.description2 }}</label>
    <div class="select-container">
      <div class="input-role-select role-select">
        <ng-container *ngFor="let item of EnumDocumentInvalide.all()">
          <input formControlName="invalideMotifs" type="radio" name="invalideMotifs" [id]="item" [value]="item" hidden />
          <label [for]="item">{{ EnumDocumentInvalide.toString(item) }}</label>
        </ng-container>
      </div>
    </div>
  </div>

  <div
    class="ds-input-group"
    [class.ds-input-group--error]="
      !documentInvalideForm.controls['inputField'].valid && documentInvalideForm.controls['inputField'].touched
    "
  >
    <label *ngIf="modalData.description" for="input-field" class="ds-input-group__label">{{ modalData.description }}</label>
    <textarea
      id="input-field"
      name="input-field"
      rows="5"
      col="0"
      [attr.maxlength]="maxLength"
      class="ds-input-group__textarea"
      formControlName="inputField"
      type="text"
      placeholder="Saisir votre commentaire"
      #textInput
    ></textarea>
    <div class="form-input__length-hint" [class.form-input__length-hint--full]="textInput.value?.length === maxLength">
      <span>{{ textInput.value?.length || 0 }}/{{ maxLength }}</span>
    </div>
    <!-- Icône et message d'erreur -->
    <ng-container
      id="error-input-field"
      *ngIf="!documentInvalideForm.controls['inputField'].valid && documentInvalideForm.controls['inputField'].touched"
    >
      <div class="ds-input-group__feedback">Merci de bien vouloir renseigner le champ</div>
    </ng-container>
  </div>
</form>

<div id="modal-btn" class="modal__actions">
  <button *ngIf="modalData.textReturnButton" class="ds-btn ds-btn--secondary" (click)="cancelModal()" mat-dialog-close>
    {{ modalData.textReturnButton }}
  </button>
  <button class="ds-btn ds-btn--primary" (click)="returnValue()" cdkFocusInitial>
    {{ modalData.textGoButton }}
  </button>
</div>
