import { Component, Input, OnInit } from '@angular/core';
import {
  BilanFinancier,
  Cell,
  DisplayColumn,
  Row,
  ROW_HEADER_COLUMN_DEF,
  RowType,
  SignalService,
  SituationFinanciere,
  Status,
} from '@shared-ui';
import { Observable, ReplaySubject } from 'rxjs';
import { BilanReference, PmeNotPme } from './situation-financiere.model';
import { DataSource } from '@angular/cdk/collections';

@Component({
  selector: 'lib-projet-consortium-df-situation-financiere',
  templateUrl: './projet-consortium-df-situation-financiere.component.html',
  styleUrls: ['./projet-consortium-df-situation-financiere.component.scss'],
})
export class ProjetConsortiumDfSituationFinanciereComponent implements OnInit {
  @Input() isEvaluateur = false;
  @Input() situationFinanciere: SituationFinanciere | undefined;
  @Input() status: Status = Status.NON_VALIDE;
  @Input() isDisabled = false;

  protected dataSource: DynamicTableDataSource = new DynamicTableDataSource([]);
  protected displayedColumns: string[] = [];
  protected columns: DisplayColumn[] = [];
  protected rows: SfRow[] = [];
  protected isPme = true;
  protected isInDifficulty = false;
  protected toggleLabels: PmeNotPme[] = ['PME', 'Non PME'];
  protected comment = '';

  private readonly LIGNE_BILAN_COLUMN_LABEL = 'Ligne du bilan';
  private readonly MONTANT_EUROS_COLUMN_LABEL = 'Montant en euros (dernier bilan)';
  private readonly TOTAL_ROW_LABEL = 'TOTAL';
  private readonly allNumbersRegex = new RegExp(/^[-0-9.,]+$/g);
  private readonly specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'Enter'];

  public valueByRow: Record<BilanReference, number> = {
    DA: 0,
    DB: 0,
    DD: 0,
    DE: 0,
    DF: 0,
    DG: 0,
    DH: 0,
    DI: 0,
  };
  private total: number = 0;

  constructor(private signalService: SignalService) {}

  ngOnInit(): void {
    if (this.situationFinanciere) {
      this.signalService.setSituationFinanciere(this.situationFinanciere);
      this.comment = this.situationFinanciere.commentaire;
    }
    this.buildColumns();
    this.rows = this.buildRows();
    this.dataSource.setData(this.rows);
  }

  private buildColumns(): void {
    this.displayedColumns = [ROW_HEADER_COLUMN_DEF, this.LIGNE_BILAN_COLUMN_LABEL, this.MONTANT_EUROS_COLUMN_LABEL];
    this.columns = [
      { def: ROW_HEADER_COLUMN_DEF, label: '', editable: false, sticky: true, width: '14rem' },
      { def: this.LIGNE_BILAN_COLUMN_LABEL, label: this.LIGNE_BILAN_COLUMN_LABEL, sticky: false, editable: false, width: '7rem' },
      { def: this.MONTANT_EUROS_COLUMN_LABEL, label: this.MONTANT_EUROS_COLUMN_LABEL, sticky: false, editable: true, width: '14rem' },
    ];
  }

  private buildRows(): SfRow[] {
    const bilanRows: SfRow[] = this.buildBilansRows();
    const totalRow: SfRow = this.buildTotalRow();
    return [...bilanRows, totalRow];
  }

  private buildBilansRows(): SfRow[] {
    const bilans: BilanFinancier[] = this.situationFinanciere?.bilans || [];
    return bilans.map((bilan: BilanFinancier) => {
      const { label, ligneBilan, montant } = bilan;
      this.valueByRow[ligneBilan as BilanReference] = montant ?? 0;
      return {
        rowType: RowType.MIXED,
        rowDef: ligneBilan,
        [ROW_HEADER_COLUMN_DEF]: {
          value: label,
          isEditable: false,
          rowDef: label,
          colDef: ROW_HEADER_COLUMN_DEF,
          rowType: RowType.MIXED,
          sectionLabel: '',
          isRowHeader: true,
        },
        [this.LIGNE_BILAN_COLUMN_LABEL]: {
          value: ligneBilan,
          isEditable: false,
          rowDef: ligneBilan,
          colDef: this.LIGNE_BILAN_COLUMN_LABEL,
          rowType: RowType.MIXED,
          sectionLabel: '',
          isRowHeader: false,
        },
        [this.MONTANT_EUROS_COLUMN_LABEL]: {
          value: montant,
          isEditable: true,
          rowDef: ligneBilan,
          colDef: this.MONTANT_EUROS_COLUMN_LABEL,
          rowType: RowType.MIXED,
          sectionLabel: '',
          isRowHeader: false,
        },
      };
    });
  }

  private buildTotalRow(): SfRow {
    this.updateTotal();
    return {
      rowType: RowType.RESULT,
      style: 'font-weight: bold',
      [ROW_HEADER_COLUMN_DEF]: {
        value: this.TOTAL_ROW_LABEL,
        isEditable: false,
        rowDef: this.TOTAL_ROW_LABEL,
        colDef: ROW_HEADER_COLUMN_DEF,
        rowType: RowType.RESULT,
        sectionLabel: '',
        isRowHeader: true,
      },
      [this.LIGNE_BILAN_COLUMN_LABEL]: {
        value: '',
        isEditable: false,
        rowDef: this.TOTAL_ROW_LABEL,
        colDef: this.LIGNE_BILAN_COLUMN_LABEL,
        rowType: RowType.RESULT,
        sectionLabel: '',
        isRowHeader: false,
      },
      [this.MONTANT_EUROS_COLUMN_LABEL]: {
        value: this.total,
        isEditable: false,
        rowDef: this.TOTAL_ROW_LABEL,
        colDef: this.MONTANT_EUROS_COLUMN_LABEL,
        rowType: RowType.RESULT,
        sectionLabel: '',
        isRowHeader: false,
      },
    };
  }

  public onDataChange(event: Event, cell: Cell) {
    const rowDef: BilanReference | undefined = cell.rowDef as BilanReference;
    if (rowDef) {
      const value: number = Number((event.target as HTMLInputElement)?.value ?? '');
      this.valueByRow[rowDef] = value;
      this.updateTotalRow();
      this.signalService.updateSituationFinanciereBilans(rowDef, value);
    }
  }

  private updateTotalRow(): void {
    this.updateTotal();
    const rowToUpdate: SfRow | undefined = this.rows.find((row: SfRow) => row.rowType === RowType.RESULT);
    if (rowToUpdate) {
      (rowToUpdate[this.MONTANT_EUROS_COLUMN_LABEL] as Cell).value = this.total;
      this.dataSource.setData(this.rows);
    }
  }

  public updateTotal(): void {
    // (DA + DB)/2 + ( DD + DE + DF + DG + DH + DI)
    this.total = Object.entries(this.valueByRow).reduce((ac, [key, value]) => (ac += ['DA', 'DB'].includes(key) ? value / 2 : value), 0);

    this.isInDifficulty = this.total < 0;
  }

  protected getColSpan(cell: Cell) {
    const { rowType, isRowHeader } = cell;
    if (rowType === RowType.RESULT && isRowHeader) {
      return 2;
    }
    return 1;
  }

  protected isHidden(cell: Cell) {
    const { rowType, colDef } = cell;
    return rowType === RowType.RESULT && colDef === this.LIGNE_BILAN_COLUMN_LABEL;
  }

  protected onKeyPress($event: KeyboardEvent): boolean {
    const key: string = $event.key;
    return this.specialKeys.includes(key) || !!key.match(this.allNumbersRegex);
  }

  protected ontoggleChange($event: string) {
    this.isPme = ($event as PmeNotPme) === 'PME';
  }

  protected onComment(comment: string) {
    this.signalService.updateSituationFinanciereComment(comment);
  }
}

export type SfRow = Partial<Row>;

export class DynamicTableDataSource extends DataSource<SfRow> {
  private _dataStream = new ReplaySubject<SfRow[]>();

  constructor(initialData: SfRow[]) {
    super();
    this.setData(initialData);
  }

  connect(): Observable<SfRow[]> {
    return this._dataStream;
  }

  disconnect() {}

  setData(rows: SfRow[]) {
    this._dataStream.next(rows);
  }
}
