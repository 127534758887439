export enum EnumQualifRue {
  '001' = 'A',
  '002' = 'B',
  '003' = 'BIS',
  '004' = 'C',
  '005' = 'D',
  '006' = 'E',
  '007' = 'F',
  '008' = 'G',
  '009' = 'H',
  '010' = 'I',
  '011' = 'J',
  '012' = 'K',
  '013' = 'L',
  '014' = 'M',
  '015' = 'N',
  '016' = 'O',
  '017' = 'Q',
  '018' = 'QUATER',
  '019' = 'QUINQUIES',
  '020' = 'T',
  '021' = 'TER',
  '022' = 'U',
}
