import { Component, Input, OnInit } from '@angular/core';
import { noop } from 'rxjs';
import { DynamicTableComponent, DynamicTableDataSource } from '../../dynamic-table/dynamic-table.component';
import { Cell, CellNumberValue, IsolatedEditableRow_ComptesResultats } from '../../dynamic-table/dynamic-table.model';
import { TableDataRecord, WarningAmountsByPeriod } from '../../../shared/models/donnees-financieres.model';
import { DonneesFinancieresService, YearButtonAction } from '../../../shared/services/donnees-financieres/donnees-financieres.service';
import { SignalService } from '../../../shared/services/signal.service';
import {
  IS_ISOLATED_EDITABLE_ROW_COMPTES_RESULTATS,
  SaisieLabel_ComptesResultats as SLCR,
  SectionFinanciere_ComptesResultats as SFCR,
  SectionFinanciere_NameSpace_ComptesResultats,
  TOOLTIP_BY_ROW_COMPTES_RESULTATS,
} from './comptes-resultats.model';
import { DONNEES_FINANCIERES_COMMON_SAISIE_LABELS as COMMON_SAISIE_LABELS } from '../donnees-financieres-common-labels.model';

@Component({
  selector: 'lib-projet-consortium-df-comptes-resultats',
  templateUrl: '../../dynamic-table/dynamic-table.component.html',
  styleUrls: ['../../dynamic-table/dynamic-table.component.scss'],
})
export class ProjetConsortiumDfComptesResultatsComponent extends DynamicTableComponent implements OnInit {
  @Input() public upTitleRow = false;

  public valueBySaisieBySectionByPeriod: TableDataRecord = {};

  private sumSaisies = (colDef: string, sfcr: SFCR, saisiesToSum: SLCR[]): number => {
    return saisiesToSum.map(saisie => this.valueBySaisieBySectionByPeriod[colDef][sfcr][saisie]).reduce((sum, current) => sum + current, 0);
  };

  public resultsCalculatorBySectionFinanciere: Record<SFCR, (colDef: string) => void> = {
    [SFCR.TOTAL_PEX]: (colDef: string) => {
      // Total des produits d'exploitation
      const totalPex: number = this.sumSaisies(colDef, SFCR.TOTAL_PEX, [SLCR.CA, SLCR.PI, SLCR.PST]);
      this.valueBySaisieBySectionByPeriod[colDef][SFCR.TOTAL_PEX][SFCR.TOTAL_PEX] = totalPex;
      this.updateDataSource(colDef, SFCR.TOTAL_PEX, totalPex);
      this.resultsCalculatorBySectionFinanciere[SFCR.TOTAL_CPT](colDef);
    },

    [SFCR.TOTAL_CPT]: (colDef: string) => {
      // Total conso. en provenance de tiers
      const totalCPT: number = this.sumSaisies(colDef, SFCR.TOTAL_CPT, [SLCR.AMPM, SLCR.VSMM, SLCR.AACE]);
      this.valueBySaisieBySectionByPeriod[colDef][SFCR.TOTAL_CPT][SFCR.TOTAL_CPT] = totalCPT;
      this.updateDataSource(colDef, SFCR.TOTAL_CPT, totalCPT);

      // Valeur ajoutée (A-B)
      const totalPex = this.valueBySaisieBySectionByPeriod[colDef][SFCR.TOTAL_PEX][SFCR.TOTAL_PEX] || 0;
      const totalVa: number = totalPex - totalCPT;
      this.valueBySaisieBySectionByPeriod[colDef][SFCR.VA] = {
        [SFCR.VA]: totalVa,
      };
      this.updateDataSource(colDef, SFCR.VA, totalVa);

      this.resultsCalculatorBySectionFinanciere[SFCR.EBE](colDef);
    },

    [SFCR.VA]: () => noop(),

    [SFCR.EBE]: (colDef: string) => {
      // Excédent brut d'exploitation
      const vaByPeriode: Record<string, number> | undefined = this.valueBySaisieBySectionByPeriod[colDef][SFCR.VA];
      const va: number = (vaByPeriode && vaByPeriode[SFCR.VA]) || 0;
      const se: number = this.valueBySaisieBySectionByPeriod[colDef][SFCR.EBE][SLCR.SE];
      const it: number = this.valueBySaisieBySectionByPeriod[colDef][SFCR.EBE][SLCR.IT];
      const cp: number = this.valueBySaisieBySectionByPeriod[colDef][SFCR.EBE][SLCR.CP];
      const totalEBE: number = va + se - it - cp;
      this.valueBySaisieBySectionByPeriod[colDef][SFCR.EBE][SFCR.EBE] = totalEBE;
      this.updateDataSource(colDef, SFCR.EBE, totalEBE);

      this.resultsCalculatorBySectionFinanciere[SFCR.RESULTAT_EXPLOITATION](colDef);
    },

    [SFCR.RESULTAT_EXPLOITATION]: (colDef: string) => {
      // Résultat d'exploitation
      const ebeByPeriode: Record<string, number> | undefined = this.valueBySaisieBySectionByPeriod[colDef][SFCR.EBE];
      const ebe: number = (ebeByPeriode && ebeByPeriode[SFCR.EBE]) || 0;
      const da: number = this.valueBySaisieBySectionByPeriod[colDef][SFCR.RESULTAT_EXPLOITATION][SLCR.DA];
      const ace: number = this.valueBySaisieBySectionByPeriod[colDef][SFCR.RESULTAT_EXPLOITATION][SLCR.ACE];
      const ape: number = this.valueBySaisieBySectionByPeriod[colDef][SFCR.RESULTAT_EXPLOITATION][SLCR.APE];
      const totalRE: number = ebe - da - ace + ape;
      this.valueBySaisieBySectionByPeriod[colDef][SFCR.RESULTAT_EXPLOITATION][SFCR.RESULTAT_EXPLOITATION] = totalRE;
      this.updateDataSource(colDef, SFCR.RESULTAT_EXPLOITATION, totalRE);

      this.resultsCalculatorBySectionFinanciere[SFCR.RESULTAT_CAI](colDef);
    },

    [SFCR.RESULTAT_CAI]: (colDef: string) => {
      // Résultat courant avant impôt
      const rexByPeriode: Record<string, number> | undefined = this.valueBySaisieBySectionByPeriod[colDef][SFCR.RESULTAT_EXPLOITATION];
      const rex: number = (rexByPeriode && rexByPeriode[SFCR.RESULTAT_EXPLOITATION]) || 0;
      const pf: number = this.valueBySaisieBySectionByPeriod[colDef][SFCR.RESULTAT_CAI][SLCR.PF];
      const cf: number = this.valueBySaisieBySectionByPeriod[colDef][SFCR.RESULTAT_CAI][SLCR.CF];
      const totalCAI: number = rex + pf - cf;
      this.valueBySaisieBySectionByPeriod[colDef][SFCR.RESULTAT_CAI][SFCR.RESULTAT_CAI] = totalCAI;
      this.updateDataSource(colDef, SFCR.RESULTAT_CAI, totalCAI);

      this.resultsCalculatorBySectionFinanciere[SFCR.RESULTAT_EXERCICE](colDef);
    },

    [SFCR.RESULTAT_EXERCICE]: (colDef: string) => {
      // Résultat de l'exercice
      const caiByPeriode: Record<string, number> | undefined = this.valueBySaisieBySectionByPeriod[colDef][SFCR.RESULTAT_CAI];
      const cai: number = (caiByPeriode && caiByPeriode[SFCR.RESULTAT_CAI]) || 0;
      const pe: number = this.valueBySaisieBySectionByPeriod[colDef][SFCR.RESULTAT_EXERCICE][SLCR.PE];
      const ce: number = this.valueBySaisieBySectionByPeriod[colDef][SFCR.RESULTAT_EXERCICE][SLCR.CE];
      const psa: number = this.valueBySaisieBySectionByPeriod[colDef][SFCR.RESULTAT_EXERCICE][SLCR.PSA];
      const ib: number = this.valueBySaisieBySectionByPeriod[colDef][SFCR.RESULTAT_EXERCICE][SLCR.IB];
      const totalREEX: number = cai + pe - ce - psa - ib;
      this.valueBySaisieBySectionByPeriod[colDef][SFCR.RESULTAT_EXERCICE][SFCR.RESULTAT_EXERCICE] = totalREEX;
      this.updateDataSource(colDef, SFCR.RESULTAT_EXERCICE, totalREEX);

      this.resultsCalculatorBySectionFinanciere[SFCR.CAPACITE_AUTOFINANCEMENT](colDef);
    },

    [SFCR.CAPACITE_AUTOFINANCEMENT]: (colDef: string) => {
      // Capacité d'autofinancement
      const reexByPeriode: Record<string, number> | undefined = this.valueBySaisieBySectionByPeriod[colDef][SFCR.RESULTAT_EXERCICE];
      const reex: number = (reexByPeriode && reexByPeriode[SFCR.RESULTAT_EXERCICE]) || 0;
      const da: number = this.valueBySaisieBySectionByPeriod[colDef][SFCR.RESULTAT_EXPLOITATION][SLCR.DA];
      const ce: number = this.valueBySaisieBySectionByPeriod[colDef][SFCR.RESULTAT_EXERCICE][SLCR.CE];
      const pe: number = this.valueBySaisieBySectionByPeriod[colDef][SFCR.RESULTAT_EXERCICE][SLCR.PE];
      const totalAutoF: number = reex + da + ce - pe;
      this.valueBySaisieBySectionByPeriod[colDef][SFCR.CAPACITE_AUTOFINANCEMENT] = {
        [SFCR.CAPACITE_AUTOFINANCEMENT]: totalAutoF,
      };
      this.updateDataSource(colDef, SFCR.CAPACITE_AUTOFINANCEMENT, totalAutoF);
      this.service.updateSharedValueByPeriodBySaisie(COMMON_SAISIE_LABELS.CA, colDef, totalAutoF);
    },
  };

  constructor(protected override service: DonneesFinancieresService, private signalService: SignalService) {
    super(service);
  }

  ngOnInit(): void {
    this.service
      .getYearActionFromPlanFinancement()
      .pipe(this.takeUntilDestroyed())
      .subscribe((yearAction: YearButtonAction) => {
        if (yearAction === YearButtonAction.ADD) {
          this.addYear();
        }
        if (yearAction === YearButtonAction.REMOVE) {
          this.removeYear();
        }
      });
  }

  public override initDonneesFinancieres = (): void => {
    this.signalService.setComptesResultats(this.periodesFinancieres);
    this.isRemoveYearButtonDisabled = this.periodesFinancieres.length <= this.minNbOfColumns;
    this.buildColumns();
    this.rows = this.buildRows(this.upTitleRow, IS_ISOLATED_EDITABLE_ROW_COMPTES_RESULTATS, TOOLTIP_BY_ROW_COMPTES_RESULTATS);
    this.dataSource = new DynamicTableDataSource(this.rows);
    this.valueBySaisieBySectionByPeriod = this.initDataRecord();
    this.initTableCalculation(this.periodes);
    this.service.completeWarningAmountsByPeriod_ComptesResultats(this.valueBySaisieBySectionByPeriod);
  };

  private initTableCalculation(periodes: string[]) {
    periodes.forEach((periode: string) => {
      this.resultsCalculatorBySectionFinanciere[SFCR.TOTAL_PEX](periode);
    });
  }

  public override onAddYear = (): void => {
    this.service.setYearActionFromComptesResultat(YearButtonAction.ADD);
    this.service.setYearActionFromComptesResultat(YearButtonAction.NONE);
    this.addYear();
  };

  private addYear(): void {
    const period: string = this.service.generateAddedYear(this.periodesFinancieres.length);
    this.service.addWarningAmountsByPeriod(period);
    this.periodesFinancieres = this.service.addComptesResultatsPeriodeFinanciere(this.periodesFinancieres, period);
    this.initDonneesFinancieres();
  }

  public override onRemoveYear = (): void => {
    this.service.setYearActionFromComptesResultat(YearButtonAction.REMOVE);
    this.service.setYearActionFromComptesResultat(YearButtonAction.NONE);
    this.removeYear();
  };

  private removeYear(): void {
    if (this.periodesFinancieres.length > this.minNbOfColumns) {
      const lastPeriod: string | undefined = this.periodesFinancieres.at(-1)?.periode;
      if (lastPeriod) {
        this.service.clearMpMpceSaisiesWarningAmountsByPeriod(lastPeriod);
      }
      this.periodesFinancieres.pop();
    }
    this.initDonneesFinancieres();
  }

  public override onDataChange = (event: Event, cell: Cell): void => {
    const newValue: string | number | undefined = (event?.target as HTMLInputElement)?.value ?? '';
    const { colDef, sectionLabel, rowDef } = cell;
    const cellNumberValue = new CellNumberValue(newValue).value;
    this.valueBySaisieBySectionByPeriod[colDef][sectionLabel][rowDef] = cellNumberValue;
    this.signalService.updateComptesResultats(cellNumberValue, colDef, sectionLabel, rowDef);

    const sectionFinanciereLabel: SFCR | undefined = SectionFinanciere_NameSpace_ComptesResultats.toEnum(sectionLabel);
    if (sectionFinanciereLabel) {
      this.resultsCalculatorBySectionFinanciere[sectionFinanciereLabel](colDef);
    }
    this.emitProjectAmountValue(colDef);
  };

  private emitProjectAmountValue(colDef: string): void {
    const mp: number =
      this.valueBySaisieBySectionByPeriod[colDef][IsolatedEditableRow_ComptesResultats.MP][IsolatedEditableRow_ComptesResultats.MP];
    const mpce: number =
      this.valueBySaisieBySectionByPeriod[colDef][IsolatedEditableRow_ComptesResultats.MPCE][IsolatedEditableRow_ComptesResultats.MPCE];
    const warningAmountsByPeriod: WarningAmountsByPeriod = this.service.getWarningAmountsByPeriod();
    warningAmountsByPeriod[colDef].mpSaisie$.next(mp);
    warningAmountsByPeriod[colDef].mpceSaisie$.next(mpce);
    this.service.setWarningAmountsByPeriod(warningAmountsByPeriod);
  }
}
