<div class="message-card" [ngClass]="isFirmInDifficulty ? 'in-difficulty' : 'not-in-difficulty'">
  {{ isInDifficultyMessage }}
</div>

<div *ngIf="!isFirmInDifficulty && !isPme" class="not-pme-info">
  <p class="card__title">Dans le cas d'une non PME</p>
  <div [innerHTML]="NOT_PME_NOT_IN_DIFFICULTY_CHECKING_MESSAGE"></div>
</div>

<div *ngIf="isFirmInDifficulty || !isPme" class="comment-container">
  <span class="comment-title">Commentaire</span>
  <textarea
    class="ds-input-group__textarea"
    type="text"
    [disabled]="isEvaluateur"
    [attr.maxlength]="maxLength"
    [value]="comment"
    (change)="onComment($event)"
  ></textarea>
</div>
