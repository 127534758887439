import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, tap } from 'rxjs';

import { environment } from '@environments-candidat/environment';
import {
  DemandeComplement,
  DemandeCorrection,
  DocumentProjet,
  Domaine,
  Enquete,
  EnumProjetStatut,
  EnumScope,
  EnumTypeDocument,
  Invitation,
  Projet,
  ProjetInfo,
  ProjetUpdateThematiqueModel,
  Secteur,
  SyntheseContractualisationProjet,
} from '@shared-ui';

@Injectable()
export class ProjetService {
  projetUrl = environment.apiUrl + 'projets';
  projetCandidatUrl = environment.apiUrl + 'candidat/projets';
  documentUrl = environment.apiUrl + 'documents';
  notifUrl = environment.apiNotif;
  private projetsSubject = new BehaviorSubject(null);

  public setProjetObservable(projet: Projet): void {
    this.projetsSubject.next(projet);
  }

  public getProjetObservable(): Observable<Projet> {
    return this.projetsSubject.asObservable();
  }

  constructor(private httpClient: HttpClient) {}

  getProjets(): Observable<HttpResponse<Projet[]>> {
    return this.httpClient.get<Projet[]>(this.projetUrl, { observe: 'response' });
  }

  getProjetById(id: string): Observable<HttpResponse<Projet>> {
    return this.httpClient
      .get<Projet>(this.projetCandidatUrl + '/' + id, { observe: 'response' })
      .pipe(tap(response => this.setProjetObservable(response.body)));
  }

  postProjet(projet: Projet): Observable<HttpResponse<Projet>> {
    return this.httpClient.post<Projet>(this.projetUrl, JSON.stringify(projet), { observe: 'response' });
  }

  updateProjetStatut(id: string, statut: EnumProjetStatut): Observable<HttpResponse<Projet>> {
    return this.httpClient.put<Projet>(this.projetUrl + '/' + id + '/statut', JSON.stringify(statut), { observe: 'response' });
  }

  upsertInfoGeneProjet(id: string, projetInfo: ProjetInfo): Observable<HttpResponse<void>> {
    return this.httpClient.post<void>(this.projetUrl + '/' + id + '/infos', JSON.stringify(projetInfo), { observe: 'response' });
  }

  upsertEnqueteProjet(id: string, enquete: Enquete): Observable<HttpResponse<Projet>> {
    return this.httpClient.put<Projet>(this.projetUrl + '/' + id + '/enquete-projet', JSON.stringify(enquete), { observe: 'response' });
  }

  upsertDocumentProjet(document: DocumentProjet): Observable<HttpResponse<DocumentProjet>> {
    return this.httpClient.post<DocumentProjet>(this.documentUrl, JSON.stringify(document), {
      observe: 'response',
    });
  }

  getDocumentsStructure(
    idProjet: string,
    idStructure: string,
    typesDocuments?: EnumTypeDocument[]
  ): Observable<HttpResponse<DocumentProjet[]>> {
    return this.httpClient.get<DocumentProjet[]>(this.documentUrl, {
      params: this.buildHttpParamsForGetDocuments(idProjet, null, idStructure, typesDocuments),
      observe: 'response',
    });
  }

  getDocumentsProjet(idProjet: string, typesDocuments?: EnumTypeDocument[]): Observable<HttpResponse<DocumentProjet[]>> {
    return this.httpClient.get<DocumentProjet[]>(this.documentUrl, {
      params: this.buildHttpParamsForGetDocuments(idProjet, EnumScope.PROJET, null, typesDocuments),
      observe: 'response',
    });
  }

  private buildHttpParamsForGetDocuments(
    idProjet: string,
    scope?: EnumScope,
    idStructure?: string,
    typesDocuments?: EnumTypeDocument[]
  ): HttpParams {
    let params = new HttpParams();
    params = params.append('projetIds', idProjet);
    if (scope) {
      params = params.append('scope', scope);
    }
    if (idStructure) {
      params = params.append('structureId', idStructure);
    }
    if (typesDocuments && typesDocuments.length > 0) {
      params = params.appendAll({ typesDocuments });
    }
    return params;
  }

  deleteDocument(idProjet: string, idDocument: string): Observable<object> {
    return this.httpClient.delete(this.documentUrl + '/' + idDocument + '?projetId=' + idProjet, { observe: 'response' });
  }

  predeposeProjet(ids: string[]): Observable<HttpResponse<Projet>> {
    return this.httpClient.post<Projet>(this.projetUrl + '/pre-depo-envoyer?ids=' + ids, null, { observe: 'response' });
  }
  validateProjet(ids: string[]): Observable<HttpResponse<Projet>> {
    return this.httpClient.post<Projet>(this.projetUrl + '/pre-depo-valider?ids=' + ids, null, { observe: 'response' });
  }
  rejectProjet(ids: string[]): Observable<HttpResponse<Projet>> {
    return this.httpClient.post<Projet>(this.projetUrl + '/pre-depo-rejeter?ids=' + ids, null, { observe: 'response' });
  }

  rejoindreProjet(invitId: string): Observable<HttpResponse<Invitation>> {
    return this.httpClient.post<Invitation>(this.projetUrl + '/rejoindre?invitation_id=' + invitId, null, { observe: 'response' });
  }

  resendInvitation(id: string, idStructure: string, invitation: Invitation, idContact: string): Observable<HttpResponse<object>> {
    return this.httpClient.post(
      this.projetUrl + '/' + id + '/structures/' + idStructure + '/contacts/' + idContact + '/renvoyerinvitation',
      JSON.stringify(invitation),
      { observe: 'response' }
    );
  }

  deposeProjets(ids: string[], projet: Projet): Observable<HttpResponse<Projet>> {
    return this.httpClient.post<Projet>(this.projetUrl + '/depot-envoyer?ids=' + ids, JSON.stringify(projet), { observe: 'response' });
  }
  modifierProjets(ids: string[], projet: Projet): Observable<HttpResponse<Projet>> {
    return this.httpClient.post<Projet>(this.projetUrl + '/depot-modifier?ids=' + ids, JSON.stringify(projet), { observe: 'response' });
  }

  // DEMANDE DE CORRECTION
  getCorrections(projetId: string, enSuivi: boolean = false): Observable<HttpResponse<DemandeCorrection[]>> {
    return this.httpClient.get<DemandeCorrection[]>(
      environment.apiUrl + 'demandes-correction?projetId=' + projetId + '&statuts=OUVERT&enSuivi=' + enSuivi,
      {
        observe: 'response',
      }
    );
  }
  // DEMANDE DE DOCUMENTS COMPLEMENTAIRES
  getDemandeDocComplements(projetId: string): Observable<HttpResponse<DemandeComplement[]>> {
    return this.httpClient.get<DemandeComplement[]>(environment.apiUrl + 'demande-complements?projetId=' + projetId + '&statuts=OUVERT', {
      observe: 'response',
    });
  }

  changeThematiqueProjet(projet: Projet): Observable<HttpResponse<Projet>> {
    const projetThematique = new ProjetUpdateThematiqueModel(projet.thematique, projet.dateModification);
    return this.httpClient.patch<Projet>(this.projetUrl + '/' + projet.id + '/thematique', JSON.stringify(projetThematique), {
      observe: 'response',
    });
  }

  putSecteurs(projetId: string, secteurs: Secteur[]): Observable<HttpResponse<Secteur[]>> {
    return this.httpClient.put<Secteur[]>(this.projetUrl + '/' + projetId + '/secteurs', JSON.stringify(secteurs), {
      observe: 'response',
    });
  }

  putDomaines(projetId: string, domaines: Domaine[]): Observable<HttpResponse<Domaine[]>> {
    return this.httpClient.put<Domaine[]>(this.projetUrl + '/' + projetId + '/domaines', JSON.stringify(domaines), {
      observe: 'response',
    });
  }

  getSynthese(projetId: string): Observable<HttpResponse<SyntheseContractualisationProjet>> {
    return this.httpClient.get<SyntheseContractualisationProjet>(this.projetUrl + '/' + projetId + '/contractualisation/synthese', {
      observe: 'response',
    });
  }
}
