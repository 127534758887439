import { enumKeys } from "../../utils/enum-utils";

export enum EnumDocumentInvalide {
  ILLISIBLE = 'ILLISIBLE',
  NON_SIGNE = 'NON_SIGNE',
  MAUVAIS_TYPE = 'MAUVAIS_TYPE',
  EXPIRE = 'EXPIRE',
  AUTRE = 'AUTRE',
}

export namespace EnumDocumentInvalide {
  export function toString(option: EnumDocumentInvalide): string {
    switch (option) {
      case EnumDocumentInvalide.ILLISIBLE: return 'Illisible';
      case EnumDocumentInvalide.NON_SIGNE: return 'Non signé';
      case EnumDocumentInvalide.MAUVAIS_TYPE: return 'Mauvais type';
      case EnumDocumentInvalide.EXPIRE: return 'Expiré';
      case EnumDocumentInvalide.AUTRE: return 'Autre';
    }
  }

  export function all(): EnumDocumentInvalide[] {
    return enumKeys(EnumDocumentInvalide) as unknown as EnumDocumentInvalide[];
  }
}
