import { Inject, Injectable } from '@angular/core';
import { Environment, PreferencesNotification, PreferenceUtilisateur } from '@shared-ui';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PreferencesUtilisateurService {
  constructor(@Inject('environment') private environment: Environment, private httpClient: HttpClient) {}

  getPreferencesNotification(): Observable<PreferencesNotification | undefined> {
    return this.httpClient
      .get<{
        preferenceNotification?: PreferencesNotification;
      }>(this.environment.apiUrl + 'preferences-utilisateur', { observe: 'body' })
      .pipe(map(preferencesUtilisateur => preferencesUtilisateur?.preferenceNotification));
  }

  updatePreferencesNotification(preferencesNotification: PreferencesNotification): Observable<PreferencesNotification> {
    return this.httpClient
      .post<{
        preferenceNotification: PreferencesNotification;
      }>(this.environment.apiUrl + 'preferences-utilisateur/notification', preferencesNotification, { observe: 'body' })
      .pipe(map(preferencesUtilisateur => preferencesUtilisateur.preferenceNotification));
  }

  getPreferenceForCurrentUser(): Observable<PreferenceUtilisateur> {
    return this.httpClient.get<PreferenceUtilisateur>(this.environment.apiUrl + 'preferences-utilisateur');
  }

  updatePreference(key: string, value: string | string[]): Observable<PreferenceUtilisateur> {
    return this.httpClient.put<PreferenceUtilisateur>(`${this.environment.apiUrl}preferences-utilisateur/preferences/${key}`, value);
  }
}
