import { Component, Input } from '@angular/core';
import { InformationBancaire } from '../../../shared/models/information-bancaire.model';
import { EnumStatutCertificationIban } from '../../../shared/enums/enum.statut-certification-iban';

@Component({
  selector: 'lib-rib-iban-card',
  templateUrl: './rib-iban-card.component.html',
  styleUrls: ['./rib-iban-card.component.scss'],
})
export class RibIbanCardComponent {
  @Input() selectedInformationBancaire?: InformationBancaire;
  @Input() canUserWrite: boolean;
  @Input() isSiteAdmin = false;
  @Input() isMandatory: boolean;
  protected readonly EnumStatutCertificationIban = EnumStatutCertificationIban;
}
