import { enumKeys } from '../../utils/enum-utils';

export enum EnumRoleStructure {
  CHEF_DE_FILE = 'CHEF_DE_FILE',
  MANDATAIRE = 'MANDATAIRE',
  CANDIDAT = 'CANDIDAT',
}

export namespace EnumRoleStructure {
  export function toString(role: EnumRoleStructure) {
    switch (role) {
      case EnumRoleStructure.CHEF_DE_FILE:
        return 'Chef de file';
      case EnumRoleStructure.MANDATAIRE:
        return 'Mandataire';
      case EnumRoleStructure.CANDIDAT:
        return 'Candidat';
    }
  }
  export function all(): EnumRoleStructure[] {
    return enumKeys(EnumRoleStructure) as unknown as EnumRoleStructure[];
  }
}
