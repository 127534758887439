import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { cloneDeep } from 'lodash';

export interface ModificationColonnesModalData {
  title: string;
  columnList: any;
}

@Component({
  selector: 'lib-modification-colonnes-modal',
  templateUrl: './modification-colonnes-modal.component.html',
  styleUrls: ['./modification-colonnes-modal.component.scss'],
})
export class ModificationColonnesModalComponent {
  disabledColumns: any = [];
  activeColumns: any = [];
  result: any[] = [];
  touched = false;

  constructor(
    public dialogRef: MatDialogRef<ModificationColonnesModalComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: ModificationColonnesModalData
  ) {
    const columnListClone = cloneDeep(this.modalData.columnList);
    this.disabledColumns = columnListClone.filter((column: any) => column.disabled);
    this.activeColumns = columnListClone.filter((column: any) => !column.disabled);
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.activeColumns, event.previousIndex, event.currentIndex);
    this.result = [...this.disabledColumns, ...this.activeColumns];
    this.touched = true;
  }

  changeColumnVisibility() {
    this.result = [...this.disabledColumns, ...this.activeColumns];
    this.touched = true;
  }

  cancel(): void {
    if (this.touched) {
      const cn = confirm('Les modifications apportées ne seront peut-être pas sauvegardées. Etes-vous sûr de vouloir quitter cette page ?');
      if (cn) {
        this.dialogRef.close();
      }
    } else {
      this.dialogRef.close();
    }
  }

  submit() {
    this.dialogRef.close({ data: this.result });
  }
}
