import { Component, DestroyRef, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { DocumentProjet, EnumScope, EnumTypeDocument, ShowToastrService, UploadDocumentHttpService } from '@shared-ui';
import { EMPTY, Observable } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { catchError, map } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'lib-documents-card-uploader',
  templateUrl: './documents-card-uploader.component.html',
  styleUrls: ['./documents-card-uploader.component.scss'],
})
export class DocumentsCardUploaderComponent implements OnInit {
  @Input() title: string;
  @Input() buttonTitle: string;
  @Input() canUserUpload: boolean;
  @Input() documents: DocumentProjet[];
  @Input() projetId: string;
  @Input() structureId: string;
  @Input() fileSizeLimit: number;
  @Input() acceptedTypes: any[];
  @Input() typeDoc: EnumTypeDocument;
  @Input() isSiteAdmin: boolean;
  @Input() scope: string;
  @Input() isReadOnly = false;
  @Output() documentUploadedOrDeleted = new EventEmitter();

  private destroyRef = inject(DestroyRef);
  extensions!: string;
  types!: string[];

  constructor(private showToastrService: ShowToastrService, private uploadDocumentService: UploadDocumentHttpService) {}

  ngOnInit() {
    this.extensions = this.acceptedTypes?.map(t => t.extension).join(', ');
    this.types = this.acceptedTypes?.map(t => t.type);
  }

  onDocumentFileSelected(event: any) {
    if (!this.canUserUpload) {
      return;
    }

    const fileToUpload = event.target.files.item(0);
    if (fileToUpload.size > this.fileSizeLimit * 1048576) {
      this.showToastrService.error(`Le fichier importé est trop volumineux, la taille maximale autorisée est de ${this.fileSizeLimit} Mo`);
    } else if (!this.types.includes(fileToUpload.type)) {
      this.showToastrService.error("Le type du document sélectionné n'est pas supporté, merci de sélectionner un autre fichier.");
    } else {
      const documentProjet: DocumentProjet = new DocumentProjet();
      documentProjet.typeDoc = this.typeDoc;
      documentProjet.nom = fileToUpload.name;
      documentProjet.projetId = this.projetId;
      documentProjet.structureId = this.structureId;
      documentProjet.file = fileToUpload;
      documentProjet.scope = EnumScope.STRUCTURE;
      this.saveDocument(documentProjet);
    }
  }

  saveDocument(document: DocumentProjet) {
    this.uploadDocumentService
      .saveDocument(this.isSiteAdmin, document)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: reponse => {
          if (reponse.body) {
            const doc = reponse.body;
            document.id = doc.id;
            document.dateCreation = doc.dateCreation;
            this.documents.push(doc);
            this.uploadDocumentWithSignedUrl(document, doc.id);
          }
        },
        error: err => {
          this.handleError(err);
        },
      });
  }

  uploadDocumentWithSignedUrl(document: DocumentProjet, idDoc: string) {
    const path = `${this.projetId}/${this.structureId}/${idDoc}`;
    const fileType = 'B401';
    this.uploadDocumentService
      .getValueForDocUpload(document.nom, path, fileType, idDoc, this.projetId)
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        map(response => response.body!),
        catchError(err => this.handleError(err))
      )
      .subscribe(response => {
        const url = response.url;
        this.uploadDocument(url, document, idDoc, fileType);
      });
  }

  private uploadDocument(url: string, document: DocumentProjet, idDoc: string, fileType: string) {
    this.uploadDocumentService
      .uploadDoc(url, document.file, this.projetId, idDoc, fileType)
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        catchError(err => this.handleError(err))
      )
      .subscribe(() => {
        this.documentUploadedOrDeleted.emit(this.structureId);
      });
  }

  deleteDocument(document: DocumentProjet, docIndex: number) {
    this.uploadDocumentService
      .deleteDocument(this.isSiteAdmin, this.projetId, document.id)
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        catchError(err => this.handleError(err))
      )
      .subscribe({
        next: () => {
          this.documents.splice(docIndex, 1);
          this.documentUploadedOrDeleted.emit(this.structureId);
        },
      });
  }

  handleError(err: HttpErrorResponse): Observable<never> {
    this.showToastrService.checkCodeError(err?.error);
    return EMPTY;
  }
}
