<mat-accordion>
  <lib-expansion-panel
    [title]="'Historique des procédures de signature'"
    [badge]="{ text: proceduresHistory?.length + '', size: 'medium', color: 'warn' }"
    [disableHover]="true"
  >
    <div panel-content>
      <ng-container *ngFor="let procedure of proceduresHistory; let last = last">
        <div>
          <ng-container *ngIf="procedure.statut === EnumProcedureSignatureStatut.REFUSEE">
            <lib-card-info
              color="yellow"
              icon="icon-info-warning"
              message="{{
                (didUserDeniedSignature(procedure)
                  ? 'Vous avez refusé de signer ce contrat électroniquement le '
                  : 'Un des signataires a refusé de signer ce contrat électroniquement le ') +
                  (procedure.dateModification | date : 'dd/MM/yyyy')
              }}"
            ></lib-card-info>
          </ng-container>

          <ng-container *ngIf="procedure.statut === EnumProcedureSignatureStatut.EXPIREE">
            <lib-card-info
              color="yellow"
              icon="icon-info-warning"
              message="{{
                'La procédure de signature a expiré le ' +
                  (projet?.contractualisation?.procedureSignature?.echeance?.dateEcheance | date : 'dd/MM/yyyy')
              }}"
            ></lib-card-info>
          </ng-container>
        </div>

        <div class="mt-4">
          <div>
            <h6 class="ds-input-group__label">Échéance <lib-asterisk></lib-asterisk></h6>
          </div>
          <div class="ds-input-group ds-input-group--date">
            <p class="ds-input-group__label info-text disabled">
              La date de fin de validité a été fixée automatiquement à 4 mois après la date DPM.
            </p>
            <span class="ds-input-group__input">
              <input
                id="dateEcheance"
                matInput
                [value]="projet?.contractualisation?.procedureSignature?.echeance?.dateEcheance"
                [matDatepicker]="dateEcheancePicker"
                class="ds-input-group__input"
                placeholder="JJ/MM/AAAA"
                [disabled]="true"
              />
              <mat-datepicker-toggle matSuffix [for]="dateEcheancePicker"></mat-datepicker-toggle>
              <mat-datepicker #dateEcheancePicker></mat-datepicker>
            </span>
          </div>
        </div>

        <div class="mt-4">
          <h6>Signer électroniquement vos documents</h6>
          <mat-checkbox id="confirmation" [disabled]="true" [checked]="true"
            >Je confirme être l'unique utilisateur de l'adresse e-mail et du numéro de téléphone associé à ce compte</mat-checkbox
          >
        </div>

        <div class="mt-5 mb-5">
          <a href="{{ getProcedureLink(procedure) }}" target="_blank" id="download-documents" class="ds-btn ds-btn--tertiary"
            >Procédure de signature</a
          >
        </div>
        <div class="mb-5">
          <hr *ngIf="!last" />
        </div>
      </ng-container>
    </div>
  </lib-expansion-panel>
</mat-accordion>
