import { IntervenantRoles } from '../enums/enum.intervenantRoles';

export enum TableCellType {
  TEXT = 'text',
  DATE = 'date',
  NUMBER = 'number',
  CURRENCY = 'currency',
  SIRET = 'siret',
  SIREN = 'siren',
}

export interface TableColumnConfiguration {
  id: string;
  nom: string;
  label: string;
  disabled: boolean;
  visible: boolean;
  ordre: number;
  isDate?: boolean;
  type: TableCellType | null | undefined;
  restrictedRoles?: IntervenantRoles[];
}
