<lib-page-header [title]="title" [goBackLabel]="backLinkText" [goBackUrl]="backLinkText ? '../' + (backLinkPath || '') : backLinkText">
  <lib-menu-dropdown
    [actions]="actions"
    (actionClick)="actionClick.emit($event)"
    class="page-header__action"
    right-elements
  ></lib-menu-dropdown>
</lib-page-header>

<div class="page-content">
  <ng-content></ng-content>
</div>
