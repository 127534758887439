import { StatutGrilleImpacts } from '../enums/enum.statut-grille-impact';
import { GrilleImpactsKPIInterface } from '../interfaces/grille-impacts-KPI.interface';
import { GrilleImpactsInterface } from '../interfaces/grille-impacts.interface';
import { ScopeType } from '../types/scope.type';
import { GrilleImpactsVersion } from './grille-impact-version.model';

export class GrilleImpacts implements GrilleImpactsInterface {
  id: string;
  scope: ScopeType;
  statut: StatutGrilleImpacts;
  idAap: string;
  idProjet: string;
  idStructure: string;
  dateCreation: Date;
  dateModification: Date;
  version: GrilleImpactsVersion;
  kpis: GrilleImpactsKPIInterface[] = [];
}
