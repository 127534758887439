<div class="single-filter-container">
  <label class="filter-label"> Dispositif </label>
  <lib-custom-multiselect
    *ngIf="dispositifDataList"
    #dispositifFilterComponent
    [(dataList)]="dispositifDataList"
    [selectedList]="dispositifSelectedList"
    (selectedListChange)="selectedListChange($event, DISPOSITIF)"
    placeholder="Dispositif"
    name="Dispositif"
    [showSelectIcon]="true"
    [isFrontAutocomplete]="true"
  ></lib-custom-multiselect>
</div>
<div class="single-filter-container">
  <label class="filter-label"> Appel à projet </label>
  <lib-custom-multiselect
    #aapFilterComponent
    (autocompleteEvent)="autocompleteEvent($event, AAP)"
    (selectedListChange)="selectedListChange($event, AAP)"
    [(dataList)]="aapDataList"
    [selectedList]="aapSelectedList"
    placeholder="Appel à projet"
    name="Aap"
    class="filter"
    [isLoading]="loadingMap.get(AAP)"
    [autocompleteStart]="1"
  >
  </lib-custom-multiselect>
</div>
<div class="single-filter-container">
  <label class="filter-label"> Acronyme projet </label>
  <lib-custom-multiselect
    #acronymeFilterComponent
    (autocompleteEvent)="autocompleteEvent($event, ACRONYME)"
    (selectedListChange)="selectedListChange($event, ACRONYME)"
    [(dataList)]="acronymeDataList"
    [selectedList]="acronymeSelectedList"
    placeholder="Acronyme"
    name="Acronyme"
    class="filter"
    [isLoading]="loadingMap.get(ACRONYME)"
    [autocompleteStart]="1"
  >
  </lib-custom-multiselect>
</div>
<div class="single-filter-container">
  <label class="filter-label"> Nom de l'entreprise </label>
  <lib-custom-multiselect
    #denominationSocialeFilterComponent
    (autocompleteEvent)="autocompleteEvent($event, DENOMINATION_SOCIALE)"
    (selectedListChange)="selectedListChange($event, DENOMINATION_SOCIALE)"
    [(dataList)]="denominationSocialeDataList"
    [selectedList]="denominationSocialeSelectedList"
    placeholder="Nom de l'entreprise"
    name="denominationSociale"
    class="filter"
    [isLoading]="loadingMap.get(DENOMINATION_SOCIALE)"
    [autocompleteStart]="1"
  >
  </lib-custom-multiselect>
</div>
<div class="single-filter-container">
  <label class="filter-label"> Statut process FR30 </label>
  <lib-custom-multiselect
    #processFR30FilterComponent
    [(dataList)]="processFR30DataList"
    [selectedList]="processFR30SelectedList"
    (selectedListChange)="selectedListChange($event, PROCESS_FR30)"
    placeholder="statut process FR30"
    name="StatutProcessFR30"
    [showSelectIcon]="true"
    [isFrontAutocomplete]="true"
  ></lib-custom-multiselect>
</div>
