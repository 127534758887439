<lib-expansion-panel
  [title]="'Informations sur l\'entreprise Chef de file'"
  [showValidIcon]="showValidIcon()"
  [showMandatoryAsterisk]="true"
>
  <div panel-content>
    <div class="w-100">
      <lib-card-info
        message="Merci de vérifier que les images chargées dans cette section s’affichent correctement (bonne qualité, non tronquée,...), ces
          dernières seront reprises dans la fiche de communication"
      ></lib-card-info>
    </div>
    <form class="form" [formGroup]="infoChefDeFileForm" (ngSubmit)="onInfoChefDeFileSubmitted()">
      <div class="d-flex flex-wrap">
        <!-- Logo Chef de file -->
        <div class="ds-input-group__label fw-bold">Logo de l'entreprise Chef de file<lib-asterisk></lib-asterisk></div>
        <div
          id="logotype"
          class="form-step__logotype file-upload ds-input-group"
          [class.ds-input-group--error]="
            !infoChefDeFileForm.controls['logoChefDeFile'].valid && infoChefDeFileForm.controls['logoChefDeFile'].touched
          "
        >
          <div id="info-upload-fichier" class="file-upload__hint" *ngIf="!imgInfoChefDeFile.id">
            Poids maximum par fichier 17Mo. Taille requise : 264x37px. Format requis : PNG, JPEG.<br />
          </div>
          <label class="file-upload__custom-input">
            <input type="file" id="file" *ngIf="!imgInfoChefDeFile.id" (change)="onUpload($event.target)" accept=".jpg, .png" />
            <input type="hidden" name="fileHidden" formControlName="logoChefDeFile" />
            <div id="add-fichier" class="ds-btn ds-btn--primary" *ngIf="!imgInfoChefDeFile.id && canUserWrite && !isValideFicheCom">
              Ajouter un fichier
            </div>
            <div *ngIf="imgInfoChefDeFile.id" class="sidenav-projet__logo img-div-render" [class.ratio-error]="ratio">
              <img class="img-render" [alt]="imgInfoChefDeFile.nom" [src]="imagePath" />
            </div>
          </label>
          <div id="logo-value" class="file-upload__hint file-value" *ngIf="imgInfoChefDeFile.id">
            <div class="doc-infos">
              <span>{{ imgInfoChefDeFile?.nom }}</span>
              <br />
              <span *ngIf="imgInfoChefDeFile.dateCreation !== null"
                >Chargé le {{ imgInfoChefDeFile?.dateCreation | date : 'dd/MM/yyyy' }} {{ getNomCreateurDocument(imgInfoChefDeFile) }}</span
              >
            </div>
            <div class="doc-actions">
              <div class="card__action-button-wrapper">
                <button
                  type="button"
                  id="download-document"
                  class="card__action-button"
                  [ngClass]="{
                    'card__action-button--error': isScanedDocument(imgInfoChefDeFile) && imgInfoChefDeFile.scan !== SAFE
                  }"
                  (click)="downloadDocument(imgInfoChefDeFile)"
                  [disabled]="!isScanedDocument(imgInfoChefDeFile) || imgInfoChefDeFile.scan !== SAFE"
                >
                  <lib-pxl-icon class="icon" icon="icon-action-save-alt"></lib-pxl-icon>
                </button>
                <div class="card__action-button-tooltip" *ngIf="!isScanedDocument(imgInfoChefDeFile)">
                  <p>Le test antimalware est en cours.</p>
                </div>
                <div class="card__action-button-tooltip" *ngIf="isScanedDocument(imgInfoChefDeFile) && imgInfoChefDeFile.scan !== SAFE">
                  <p>Ce fichier a été identifié comme étant potentiellement dangereux et ne peut donc pas être téléchargé.</p>
                </div>
              </div>
              <div class="card__action-button-wrapper" *ngIf="canUserWrite && !isValideFicheCom">
                <button type="button" id="delete-document" class="card__action-button" (click)="onDeleteDocument(imgInfoChefDeFile)">
                  <lib-pxl-icon class="icon" icon="icon-action-delete"></lib-pxl-icon>
                </button>
              </div>
            </div>
          </div>
          <div id="error-logo-type" class="file-upload__error" *ngIf="fileNotSupported">
            Le type du document sélectionné n'est pas supporté, merci de sélectionner un autre fichier.
          </div>
          <div id="error-logo-size" class="file-upload__error" *ngIf="fileTooBig">
            Le fichier sélectionné est trop lourd, merci de sélectionner un autre fichier.
          </div>
        </div>
        <!-- Icône et message d'erreur -->
        <ng-container
          id="error-logo"
          *ngIf="!infoChefDeFileForm.controls['logoChefDeFile'].valid && infoChefDeFileForm.controls['logoChefDeFile'].touched"
        >
          <div class="ds-input-group__feedback error-logo">Merci de bien vouloir renseigner un fichier</div>
        </ng-container>
        <!-- FIN de Logo Chef de file -->

        <!-- Activité du Chef de File -->
        <div
          id="input-activite"
          class="form-step__activite ds-input-group"
          [class.ds-input-group--error]="!infoChefDeFileForm.controls['activite'].valid && infoChefDeFileForm.controls['activite'].touched"
        >
          <label for="activite" class="ds-input-group__label fw-bold"
            >Activité de l'entreprise Chef de file<lib-asterisk></lib-asterisk
          ></label>
          <textarea
            id="creation_projet_activite"
            name="activite"
            rows="5"
            col="0"
            [maxlength]="maxLengthActivite"
            pattern="(\n|.)*\S+(\n|.)*"
            placeholder="Décrire l'activité de l'entreprise Chef de file"
            class="ds-input-group__textarea"
            formControlName="activite"
            #activiteInput
          ></textarea>
          <div class="form-input__length-hint" [class.form-input__length-hint--full]="activiteInput.value?.length === maxLengthActivite">
            <span>{{ activiteInput.value?.length || 0 }}/{{ maxLengthActivite }}</span>
          </div>
          <!-- Icône et message d'erreur -->
          <ng-container
            id="error-activite"
            *ngIf="!infoChefDeFileForm.controls['activite'].valid && infoChefDeFileForm.controls['activite'].touched"
          >
            <div class="ds-input-group__feedback">Merci de bien vouloir renseigner une valeur</div>
          </ng-container>
        </div>
        <!-- FIN de Activité du Chef de File -->

        <div class="w-100 d-flex">
          <ng-container *ngIf="formSubmitted && infoChefDeFileForm.touched && !infoChefDeFileForm.valid">
            <div class="ds-input-group ds-input-group--error w-100">
              <div class="ds-input-group__feedback">Merci de renseigner les champs entourés en rouge.</div>
            </div>
          </ng-container>

          <div class="w-100 d-flex justify-content-end button-container" *ngIf="canUserWrite && !isValideFicheCom">
            <button type="button" class="ds-btn ds-btn--secondary me-2" (click)="cancel()">
              <span>Annuler</span>
            </button>
            <button type="submit" class="ds-btn ds-btn--primary">
              <span>Enregistrer</span>
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</lib-expansion-panel>
