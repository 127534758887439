<div
  id="card-rib-iban"
  class="card card--message cursor-pointer"
  [ngClass]="{ 'card--warning': !selectedInformationBancaire && isMandatory && canUserWrite }"
>
  <ng-container *ngIf="!selectedInformationBancaire; else ribIbanComplete">
    <p class="card__title">
      {{
        canUserWrite
          ? isSiteAdmin
            ? 'Vous devez renseigner les éléments de validation du RIB/IBAN pour le partenaire'
            : 'Ajouter votre IBAN'
          : 'RIB/IBAN pour le partenaire'
      }}
    </p>
  </ng-container>
  <ng-template #ribIbanComplete>
    <p
      *ngIf="
        selectedInformationBancaire?.libelle ||
        selectedInformationBancaire?.statutCertification !== EnumStatutCertificationIban.NON_CERTIFIE
      "
      class="card__title"
    >
      {{ selectedInformationBancaire?.libelle ?? '' }}
      <span
        *ngIf="
          selectedInformationBancaire?.validationManuelle ||
          selectedInformationBancaire?.statutCertification === EnumStatutCertificationIban.CERTIFIE_BPI
        "
        class="card__tag card__tag--green"
      >
        Certifié Bpifrance
      </span>
      <span
        *ngIf="
          !selectedInformationBancaire?.validationManuelle &&
          selectedInformationBancaire?.statutCertification === EnumStatutCertificationIban.EN_COURS_DE_CERTIFICATION_BPI
        "
        class="card__tag card__tag--orange"
      >
        En cours de certification Bpifrance
      </span>
      <span
        *ngIf="
          !selectedInformationBancaire?.validationManuelle &&
          selectedInformationBancaire?.statutCertification === EnumStatutCertificationIban.CERTIFICATION_REJETEE_BPI
        "
        class="card__tag card__tag--red"
      >
        Non certifié Bpifrance
      </span>
      <span *ngIf="selectedInformationBancaire?.validationManuelle" class="card__tag"> Validation manuelle </span>
    </p>
    <p *ngIf="selectedInformationBancaire?.iban" class="card__subtitle mb-0">{{ selectedInformationBancaire?.iban ?? '' | iban }}</p>
  </ng-template>

  <ng-content></ng-content>

  <lib-pxl-icon
    class="card__arrow"
    [icon]="canUserWrite ? 'icon-arrow-arrow-forward-ios' : 'icon-visibility'"
    aria-hidden="true"
  ></lib-pxl-icon>
</div>
