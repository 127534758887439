<div class="pxl-accordion-container">
  <button (click)="toggleAccordion()" class="pxl-accordion-header" [ngClass]="{ 'header-open': accordionOpen }">
    @if (titre) {
      <span>{{ titre }}</span>
    } @else {
      <ng-content select="[title]"></ng-content>
    }

    @if (tag) {
      <span class="card__tag" [ngClass]="tagClass">{{ tag }}</span>
    }
    
    <div class="buttons-container">
      @if (link) {
        <a (click)="$event.stopPropagation(); linkClick.emit()">{{ link }}</a>
      }
      <lib-pxl-icon class="card__arrow" icon="icon-arrow-arrow-forward-ios" aria-hidden="true"></lib-pxl-icon>
    </div>
  </button>
  @if (accordionOpen) {
    <div class="pxl-accordion-body">
      <ng-content></ng-content>
    </div>
  }
</div>
