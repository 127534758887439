<form class="form" [formGroup]="identiteForm">
  <section class="mt-4 mb-4 fw-bold">
    <label>
      {{ title }}
    </label>
  </section>

  <section class="contact-form">
    <section class="identite">
      <div class="form-step__content">
        <!-- Genre  -->
        <div
          class="ds-input-group genre_selection_option_overwrite"
          [class.ds-input-group--error]="identiteForm.controls['civilite'].invalid && identiteForm.controls['civilite'].touched"
        >
          <label for="genre" class="ds-input-group__label">Civilité<lib-asterisk></lib-asterisk></label>
          <div class="div_genre_checkboxs">
            <label *ngFor="let genre of genres">
              <input
                class="btnlike"
                type="checkbox"
                (change)="selectGenre(genre)"
                [checked]="genre.checked"
                [disabled]="identiteForm.controls['civilite']?.disabled"
              />
              <span
                [ngClass]="{
                  genre_invalid: identiteForm.controls['civilite'].invalid && identiteForm.controls['civilite'].touched
                }"
                [ngClass]="{ genre_disabled: identiteForm.controls['civilite']?.disabled }"
                class="unselectable"
                >{{ EnumGenre.toString(genre.valeur) }}</span
              >
            </label>
          </div>

          <!-- Icône et message d'erreur -->
          <ng-container id="error-raison" *ngIf="identiteForm.controls['civilite'].invalid && identiteForm.controls['civilite'].touched">
            <div class="ds-input-group__feedback invalid_msg_overwrite">Merci de bien vouloir renseigner une civilité valide.</div>
          </ng-container>
        </div>

        <!-- Nom -->
        <div
          id="input-nom"
          class="ds-input-group"
          [class.ds-input-group--error]="!identiteForm.controls['nom'].valid && identiteForm.controls['nom'].touched"
        >
          <label for="nom" class="ds-input-group__label">Nom<lib-asterisk></lib-asterisk></label>
          <span class="ds-input-group__input">
            <input
              id="nom"
              name="nom"
              type="text"
              placeholder="Nom"
              class="ds-input-group__input"
              maxlength="30"
              pattern="[a-zA-ZÀ-ÖØ-Ýà-öø-ÿ\s-]+"
              formControlName="nom"
            />
          </span>
          <!-- Icône et message d'erreur -->
          <ng-container id="error-nom" *ngIf="!identiteForm.controls['nom'].valid && identiteForm.controls['nom'].touched">
            <div class="ds-input-group__feedback">Merci de bien vouloir renseigner un nom valide</div>
          </ng-container>
        </div>

        <!-- Prénom  -->
        <div
          id="input-prenom"
          class="ds-input-group"
          [class.ds-input-group--error]="!identiteForm.controls['prenom'].valid && identiteForm.controls['prenom'].touched"
        >
          <label for="prenom" class="ds-input-group__label">Prénom<lib-asterisk></lib-asterisk></label>
          <span class="ds-input-group__input">
            <input
              id="prenom"
              name="prenom"
              type="text"
              placeholder="Prénom"
              class="input_prenom ds-input-group__input"
              maxlength="30"
              pattern="[a-zA-ZÀ-ÖØ-Ýà-öø-ÿ\s-]+"
              formControlName="prenom"
            />
          </span>
          <!-- Icône et message d'erreur -->
          <ng-container id="error-prenom" *ngIf="!identiteForm.controls['prenom'].valid && identiteForm.controls['prenom'].touched">
            <div class="ds-input-group__feedback">Merci de bien vouloir renseigner un prénom valide</div>
          </ng-container>
        </div>
      </div>
    </section>

    <section class="contact">
      <div class="form-step__content">
        <!-- Fonction  -->
        <div
          id="input-fonction"
          class="ds-input-group"
          [class.ds-input-group--error]="!identiteForm.controls['fonction'].valid && identiteForm.controls['fonction'].touched"
        >
          <label for="fonction" class="ds-input-group__label">Fonction<lib-asterisk></lib-asterisk></label>
          <span class="ds-input-group__input">
            <input
              id="fonction"
              name="fonction"
              type="text"
              placeholder="Votre fonction"
              class="input_fonction ds-input-group__input"
              maxlength="50"
              formControlName="fonction"
            />
          </span>
          <!-- Icône et message d'erreur -->
          <ng-container id="error-fonction" *ngIf="!identiteForm.controls['fonction'].valid && identiteForm.controls['fonction'].touched">
            <div class="ds-input-group__feedback">Merci de bien vouloir renseigner une fonction valide</div>
          </ng-container>
        </div>
        <!-- telephone -->
        <div
          id="input-telephone"
          #inputTelephone
          class="ds-input-group ds-input-group--phone"
          [class.ds-input-group--error]="!identiteForm.controls['telephone'].valid && identiteForm.controls['telephone'].touched"
        >
          <label id="id_input-telephone-label" for="telephone" class="ds-input-group__label">
            Téléphone mobile<lib-asterisk></lib-asterisk>
          </label>
          <span class="ds-input-group__input">
            <input
              (blur)="onBlur()"
              [id]="componentId + '-telephone'"
              [name]="componentId + '-telephone'"
              type="tel"
              maxlength="20"
              formControlName="telephone"
              class="ds-input-group__input"
              placeholder="06 12 34 56 78"
            />
          </span>
          <!-- Icône et message d'erreur -->
          <ng-container
            id="error-telephone"
            *ngIf="
              inputTelephone.classList.contains('ds-input-group--error') ||
              (identiteForm.controls['telephone'].touched && identiteForm.controls['telephone'].value === '')
            "
          >
            <div id="id_error-telephone-msg" class="error-telephone-msg ds-input-group__feedback">
              Merci de bien vouloir renseigner un numéro de téléphone valide
            </div>
          </ng-container>
        </div>
        <!-- mail -->
        <div
          id="input-email"
          class="ds-input-group"
          [class.ds-input-group--error]="!identiteForm.controls['email'].valid && identiteForm.controls['email'].touched"
        >
          <label for="email" class="ds-input-group__label">Mail<lib-asterisk></lib-asterisk></label>
          <span class="ds-input-group__input">
            <input
              id="email"
              name="email"
              type="text"
              maxlength="80"
              pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
              placeholder="contact@candidat.com"
              class="input_email ds-input-group__input"
              formControlName="email"
            />
          </span>
          <!-- Icône et message d'erreur -->
          <ng-container id="error-email" *ngIf="!identiteForm.controls['email'].valid && identiteForm.controls['email'].touched">
            <div class="ds-input-group__feedback">Merci de bien vouloir renseigner un mail valide</div>
          </ng-container>
        </div>
      </div>
    </section>
  </section>

  <section>
    <div
      [id]="componentId + 'input-identite-form-representantLegal'"
      class="ds-input-group"
      [class.ds-input-group--error]="
        !identiteForm.controls['representantLegal'].valid && identiteForm.controls['representantLegal'].touched
      "
    >
      <div class="questions-container">
        <div>
          <label class="fw-bold">Est-ce que le signataire déclaré est un.e représentant.e légal.e de la structure ?</label>
          <lib-asterisk></lib-asterisk>
        </div>
        <div class="radio-btn">
          <label class="ds-radio-classic" [for]="componentId + 'representantLegal-true'">
            <input
              type="radio"
              class="ds-radio__input"
              [id]="componentId + 'representantLegal-true'"
              name="representantLegal"
              (click)="checkProjetEtape(true)"
              formControlName="representantLegal"
              [value]="true"
            />
            <span class="ds-radio__label"><span>Oui</span></span>
          </label>
          <label class="ds-radio-classic" [for]="componentId + 'representantLegal-false'">
            <input
              type="radio"
              class="ds-radio__input"
              [id]="componentId + 'representantLegal-false'"
              name="representantLegal"
              (click)="checkProjetEtape(false)"
              formControlName="representantLegal"
              [value]="false"
            />
            <span class="ds-radio__label"><span>Non</span></span>
          </label>
        </div>
      </div>
      <ng-container
        id="error-identite-form-representantLegal"
        *ngIf="identiteForm.controls['representantLegal'].touched && identiteForm.controls['representantLegal'].invalid"
      >
        <div id="id_error-identite-form-representantLegal-msg" class="ds-input-group__feedback">
          Merci de bien vouloir renseigner une valeur
        </div>
      </ng-container>
    </div>
  </section>
  <lib-card-info *ngIf="isDisplayWarning" color="yellow" icon="icon-info-warning" [message]="warningMessage"></lib-card-info>
</form>
