import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { StatutGrilleImpacts } from '../../shared/enums/enum.statut-grille-impact';
import { GrilleImpactsKPIsReponseInterface } from '../../shared/interfaces/grille-impacts-kpis-reponse.interface';
import { GrilleImpactsKPI } from '../../shared/models/grille-impacts-KPI.model';
import { GrilleImpactsKPIsReponse } from '../../shared/models/grille-impacts-kpis-reponse.model';
import { GrilleImpacts } from '../../shared/models/grille-impacts.model';
import { KpisByCategorie } from '../../shared/models/kpis-by-categorie.model';
import { RefKPIDTO } from '../../shared/models/refKPIDTO.model';
import { GrilleImpactHttpService } from '../../shared/services/grille-impact.http.service';
import { ScopeType } from '../../shared/types/scope.type';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmModalComponent } from '../confirm-modal/confirm-modal.component';
import { MatAccordion } from '@angular/material/expansion';
import { Observable } from 'rxjs';
import { ShowToastrService } from '../../shared/services/show-toastr.service';
import { KpiCategorie } from '../../shared/models/kpiCategorie.model';
import { GrilleImpactHelperService } from '../../shared/services/_public_services';

@Component({
  selector: 'lib-grille-impact',
  templateUrl: './grille-impact.component.html',
  styleUrls: ['./grille-impact.component.scss'],
})
export class GrilleImpactComponent implements OnInit {
  @Input() kpisByCategorieList: KpisByCategorie[];
  @Input() id: string;
  @Input() scope: ScopeType;
  @Input() statut: StatutGrilleImpacts;
  @Input() idProjet: string;
  @Input() idStructure: string;
  @Input() dateCreation: Date;
  @Input() dateModification: Date;
  @Input() version: string;
  @Input() IdAap: string;
  @Input() grilleImpacts: GrilleImpacts;
  @Input() titreCategoriesKPI: KpiCategorie[];
  @Input() grille: GrilleImpacts[] = [];
  @Input() isUpdatableByUser = false;
  saveGrilleImpact = false;

  @ViewChild('accordion') accordion: MatAccordion;

  // formArray passé aux composants KPI, il est vide ici mais sera alimenté par chaque composant
  grilleImpactsKpisForm: UntypedFormArray;

  // formGroup d'une catégorie
  grilleImpactsCategorieForm: UntypedFormGroup;

  savedGrilleImpactsKpisFormValue: any;

  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    return !this.grilleImpactsCategorieForm.dirty;
  }

  constructor(
    private formBuilder: UntypedFormBuilder,
    public grilleImpactHttpService: GrilleImpactHttpService,
    public dialog: MatDialog,
    private showToastrService: ShowToastrService,
    private grilleImpactHelperService: GrilleImpactHelperService,
    private readonly changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    // initialisation à vide du formArray passé aux composants kpi
    this.grilleImpactsKpisForm = this.formBuilder.array([]);
    // initialisation à vide du formGroup des catégories
    this.grilleImpactsCategorieForm = this.formBuilder.group({
      kpis: this.grilleImpactsKpisForm,
    });
    this.grilleImpactHelperService.getGrillesImpactsData().subscribe(() => {
      this.grilleImpactsKpisForm = this.formBuilder.array([]);
      this.grilleImpactsCategorieForm = this.formBuilder.group({
        kpis: this.grilleImpactsKpisForm,
      });
      this.changeDetectorRef.detectChanges();
    });
  }

  getControlFormArray(): FormArray {
    return this.grilleImpactsCategorieForm.get('kpis') as FormArray;
  }

  /**
   * récupère la liste des réponses, d'un kpi donné, de la grille d'impacts
   * @param kpi
   * @returns
   */
  getKpiResponses(kpi: RefKPIDTO): GrilleImpactsKPIsReponseInterface[] | undefined {
    return this.grilleImpacts ? this.grilleImpacts.kpis.find(responseKpi => responseKpi.clef === kpi.clef)?.reponses : [];
  }

  isKpiCompleted(kpisAndCategorie: KpisByCategorie) {
    const kpisForms = this.getControlFormArray()['controls'];
    const kpisHasInvalidForm = kpisAndCategorie.refKPIDTOList
      .map(kpi => kpi.clef)
      .some((clef: string) => {
        const kpiForm = kpisForms.find(form => form.value[clef] != null);
        return kpiForm == null || kpiForm.invalid;
      });

    return !kpisHasInvalidForm;
  }

  /**
   * construit la grille d'impacts finale qui sera sauvegarder
   */
  saveGrilleImpacts(grilleImpacts: GrilleImpacts, kpisAndCategorie: KpisByCategorie): void {
    if (!this.grilleImpacts?.id) {
      grilleImpacts.idProjet = this.idProjet;
      grilleImpacts.idStructure = this.idStructure;
      grilleImpacts.scope = this.scope;
    }
    grilleImpacts.idAap = this.IdAap;
    this.save(grilleImpacts, kpisAndCategorie);
  }

  /**
   * reconstruire la grille d'impacts avec la liste des réponses classées par clef KPI
   * les réponses sont tirées du formulaire de la catégorie correspondante
   */
  grilleImpactsCategorieSubmit(kpisAndCategorie: KpisByCategorie): void {
    this.saveGrilleImpact = true;
    const updatedGrilleImpacts = this.grilleImpacts ? this.grilleImpacts : new GrilleImpacts();
    const grilleImpactReponse = this.getFilteredKpiFromCategory(kpisAndCategorie);
    if (!grilleImpactReponse) {
      return;
    }
    this.savedGrilleImpactsKpisFormValue = this.grilleImpactsCategorieForm.get('kpis')?.value;

    grilleImpactReponse.forEach((kpis: []) => {
      Object.entries(kpis).forEach(val => {
        const grilleKpi = new GrilleImpactsKPI();
        grilleKpi.clef = val[0];
        const kpiResponses = this.saveSingleKpi(val[1]);
        grilleKpi.reponses = kpiResponses;
        this.buildGrilleImpact(updatedGrilleImpacts, grilleKpi);
      });
    });
    this.saveGrilleImpacts(updatedGrilleImpacts, kpisAndCategorie);

    // Sauvegarde les données pour le reset avec le bouton Annuler
    grilleImpactReponse.forEach((kpis: any) => {
      const index = this.savedGrilleImpactsKpisFormValue.findIndex((object: any) => {
        const key = Object.getOwnPropertyNames(object)[0];
        return kpis[key] != null;
      });
      this.savedGrilleImpactsKpisFormValue[index] = kpis;
    });
  }

  /**
   * Récupère les seules kpi qui ont été modifiées pour l'enregistrement
   * @param kpisAndCategorie
   * @param grilleImpactReponse
   * @returns
   */
  getFilteredKpiFromCategory(kpisAndCategorie: KpisByCategorie): any {
    const grilleImpactReponseFiltered: {
      [key: string]: string;
    } = {};

    let isGrilleValid: boolean | undefined = true;

    kpisAndCategorie.refKPIDTOList
      .map(kpi => kpi.clef)
      .forEach((clef: string) => {
        grilleImpactReponseFiltered[clef] = this.grilleImpactsCategorieForm
          .get('kpis')
          ?.value.find((kpi: { [key: string]: string }) => kpi[clef] != null)[clef];
        const formIsValid = (this.grilleImpactsCategorieForm.get('kpis') as FormArray)['controls'].find(
          (kpiForm: AbstractControl) => kpiForm.value[clef] != null
        )?.valid;
        isGrilleValid = isGrilleValid && formIsValid;
        (this.grilleImpactsCategorieForm.get('kpis') as FormArray)['controls']
          .find((kpiForm: AbstractControl) => kpiForm.value[clef] != null)
          ?.markAllAsTouched();
      });

    if (!isGrilleValid) {
      return;
    }

    return [grilleImpactReponseFiltered];
  }

  /**
   * Vérifie si le form de la categorie est pristine avant d'ouvrir la modale de confirmation
   * @param kpisAndCategorie
   */
  cancelSave(kpisAndCategorie: KpisByCategorie): void {
    const isPristine = kpisAndCategorie.refKPIDTOList
      .map(kpi => kpi.clef)
      .every(
        clef => (this.grilleImpactsCategorieForm.get('kpis') as FormArray)?.controls.find(control => control.get(clef) != null)?.pristine
      );
    if (isPristine) {
      this.accordion.closeAll();
      return;
    }
    this.openCancelSaveModal(kpisAndCategorie);
  }

  /**
   * Ouvre la modale de confirmation pour annuler les modifications de la catégorie
   * @param kpisAndCategorie
   */
  openCancelSaveModal(kpisAndCategorie: KpisByCategorie): void {
    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      data: {
        title: 'Confirmer',
        description: `<p>Souhaitez-vous annuler les modifications ?</p>`,
        textGoButton: 'Oui',
        textReturnButton: 'Retour',
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const newValue = this.grilleImpactsKpisForm.value;

        // Reset la valeur des Kpis de la catégorie au niveau du bouton Annuler
        kpisAndCategorie.refKPIDTOList
          .map(kpi => kpi.clef)
          .forEach((clef: string) => {
            newValue.find((kpi: any) => kpi[clef] != null)[clef] = this.savedGrilleImpactsKpisFormValue.find(
              (kpi: any) => kpi[clef] != null
            )[clef];
          });

        this.grilleImpactsKpisForm.setValue(newValue);
        kpisAndCategorie.refKPIDTOList
          .map(kpi => kpi.clef)
          .map(clef =>
            (this.grilleImpactsCategorieForm.get('kpis') as FormArray)?.controls
              .find(control => control.get(clef) != null)
              ?.markAsPristine()
          );
        this.accordion.closeAll();
      }
    });
  }

  /**
   * ajouter les réponses du formulaire dans grille d'impacts à mettre à jour
   * @param updatedGrilleImpacts
   * @param grilleImpactsKPI
   */
  buildGrilleImpact(updatedGrilleImpacts: GrilleImpacts, grilleImpactsKPI: GrilleImpactsKPI): void {
    if (updatedGrilleImpacts.kpis.find(kpi => kpi.clef === grilleImpactsKPI.clef)) {
      updatedGrilleImpacts.kpis.forEach(kpi => {
        if (kpi.clef === grilleImpactsKPI.clef) {
          kpi.reponses = grilleImpactsKPI.reponses;
        }
      });
    } else {
      updatedGrilleImpacts.kpis.push(grilleImpactsKPI);
    }
  }

  saveSingleKpi(grilleImpactReponse: { [key: string]: string }): GrilleImpactsKPIsReponse[] {
    const reponses = new Array<GrilleImpactsKPIsReponse>();
    Object.entries(grilleImpactReponse).forEach(result => {
      const reponse = new GrilleImpactsKPIsReponse();
      reponse.reponse = result[1];
      reponse.index = parseInt(result[0]);
      reponses.push(reponse);
    });
    return reponses;
  }

  save(grilleImpacts: GrilleImpacts, kpisAndCategorie: KpisByCategorie) {
    this.grilleImpactHttpService.saveGrilleImpacts(grilleImpacts).subscribe({
      next: response => {
        this.grilleImpacts = response.body as GrilleImpacts;
        this.showToastrService.success('Catégorie enregistrée avec succès');

        kpisAndCategorie.refKPIDTOList
          .map(kpi => kpi.clef)
          .map(clef =>
            (this.grilleImpactsCategorieForm.get('kpis') as FormArray)?.controls
              .find(control => control.get(clef) != null)
              ?.markAsPristine()
          );
        this.saveGrilleImpact = false;
        this.accordion.closeAll();
      },
      error: (err: HttpErrorResponse) => {
        this.showToastrService.checkCodeError(err?.error);
      },
    });
  }
}
