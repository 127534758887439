import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Aap, ConfirmModalComponent, Projet } from '@shared-ui';
import { Observable } from 'rxjs';
import { ContactPresseFicheCom } from '../../../shared/models/contact-presse-fiche-com.model';
import { FicheCom } from '../../../shared/models/fiche-com.model';

declare const dsLib: any;

@Component({
  selector: 'lib-fiche-com-contact-presse',
  templateUrl: './fiche-com-contact-presse.component.html',
  styleUrls: ['./fiche-com-contact-presse.component.scss'],
})
export class FicheComContactPresseComponent implements OnInit {
  maxLengthNomPrenom = 35;
  maxLengthEmail = 45;
  savedContactPresse: ContactPresseFicheCom;
  @Input() aap: Aap;
  @Input() projet: Projet;
  @Input() canUserWrite: boolean;
  @Output() saveFicheCom = new EventEmitter();
  @Input() ficheCom: FicheCom;
  @Input() contactPresseForm: FormGroup;
  formSubmitted: boolean;

  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    return !this.contactPresseForm.dirty;
  }

  telephone = {};
  @Input() isValideFicheCom!: boolean;

  constructor(public matDialog: MatDialog) {}

  ngOnInit(): void {
    this.telephone = new dsLib.InputPhone('telephone');
    this.setForm();
    this.feedContactPresseForm();
  }

  onBlur(): void {
    const inputPhone = document.getElementById('input-telephone');
    if (inputPhone?.classList.contains('ds-input-group--error')) {
      this.contactPresseForm.controls['telephone'].setErrors({ incorrect: true });
    }
  }

  private setForm(): void {
    this.contactPresseForm = new FormGroup({
      nomPrenom: new FormControl<string>({ value: '', disabled: !this.canUserWrite || this.isValideFicheCom }, Validators.required),
      telephone: new FormControl<string>({ value: '', disabled: !this.canUserWrite || this.isValideFicheCom }, Validators.required),
      email: new FormControl<string>({ value: '', disabled: !this.canUserWrite || this.isValideFicheCom }, Validators.required),
    });
  }

  feedContactPresseForm(): void {
    this.contactPresseForm.patchValue(this.ficheCom?.contactPresse);
    this.savedContactPresse = this.contactPresseForm.getRawValue();
  }

  showValidIcon(): boolean {
    return this.ficheCom?.contactPresse !== undefined;
  }

  onContactPresseSubmitted() {
    this.formSubmitted = true;
    this.contactPresseForm.markAllAsTouched();

    if (this.contactPresseForm.valid) {
      this.savedContactPresse = this.contactPresseForm.getRawValue();
      this.ficheCom.contactPresse = this.savedContactPresse;
      this.saveFicheCom.emit(this.ficheCom);
      this.contactPresseForm.markAsPristine();
    }
  }

  cancel(): void {
    const dialogRef = this.matDialog.open(ConfirmModalComponent, {
      data: {
        title: 'Annuler les modifications en cours',
        description: `<p>En confirmant l'action, Vous allez perdre vos modification en cours.<br>
        Confirmez-vous l’action ?</p>`,
        textGoButton: 'Confirmer',
        textReturnButton: 'Annuler',
      },
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        if (this.savedContactPresse != null) {
          this.contactPresseForm.setValue(this.savedContactPresse);
          this.contactPresseForm.markAsPristine();
          return;
        }
        this.contactPresseForm.reset({
          nomPrenom: '',
          telephone: '',
          email: '',
        });
      }
    });
  }

  isFormDirty(): boolean {
    return this.contactPresseForm.dirty;
  }
}
