import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  ISOLATED_EDITABLE_ROW_PLAN_TRESORERIE,
  SaisieLabel_PlanTresorerie,
  SectionFinanciere_PlanTresorerie,
} from '../../../components/projet-consortium-donnees-financieres/projet-consortium-df-plan-tresorerie/plan-tresorerie.model';
import { DEFAULT_BILANS } from '../../../components/projet-consortium-donnees-financieres/projet-consortium-df-situation-financiere/situation-financiere.model';
import {
  SaisieLabel_ComptesResultats,
  SectionFinanciere_ComptesResultats,
} from '../../../components/projet-consortium-donnees-financieres/projet-consortium-df-comptes-resultats/comptes-resultats.model';
import {
  SaisieLabel_PlanFinancement as SLPF,
  SectionFinanciere_PlanFinancement as SFPF,
} from '../../../components/projet-consortium-donnees-financieres/projet-consortium-df-plan-financement/plan-financement.model';
import {
  DonneesFinancieres,
  Status,
  TableDataRecord,
  ValueByPeriodBySaisie,
  WarningAmountsByPeriod,
} from '../../models/donnees-financieres.model';
import { PeriodeFinanciere } from '../../models/periode-financiere.model';
import { IsolatedEditableRow_ComptesResultats } from '../../../components/dynamic-table/dynamic-table.model';
import { SituationFinanciere } from '../../models/situation-financiere.model';
import { SignalService } from '../signal.service';
import { AnneeCumulComponent } from '../../../components/annee-cumul/annee-cumul.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NumberUtils } from '../../../utils/number-utils';

@Injectable({
  providedIn: 'root',
})
export class DonneesFinancieresService {
  private readonly DEFAULT_NB_OF_YEARS = 5;
  private readonly MIN_NB_OF_YEARS_BEFORE_CURRENT = 4;
  private readonly NB_OF_YEARS = 6;
  private readonly NB_OF_PERIODS_PLAN_TRESORERIE = 4;
  public readonly CURRENT_YEAR_LABEL = 'Exercice en cours';
  private readonly PLAN_TRESORERIE_PERIODS: string[] = ["Aujourd'hui à 6 mois", '6 à 12 mois', '12 à 18 mois', '18 à 24 mois'];
  private readonly DEFAULT_SITUATION_FINANCIERE: SituationFinanciere = {
    commentaire: '',
    bilans: Object.values(DEFAULT_BILANS).map((bilan: { label: string; reference: string }) => ({
      label: bilan.label,
      ligneBilan: bilan.reference,
      montant: undefined,
    })),
  };

  private warningAmountsByPeriod: WarningAmountsByPeriod = {};
  private sharedValueByPeriodBySaisie: BehaviorSubject<ValueByPeriodBySaisie> = new BehaviorSubject<ValueByPeriodBySaisie>({});

  private yearActionFromComptesResultat$: BehaviorSubject<YearButtonAction> = new BehaviorSubject<YearButtonAction>(YearButtonAction.NONE);
  private yearActionFromPlanFinancement$: BehaviorSubject<YearButtonAction> = new BehaviorSubject<YearButtonAction>(YearButtonAction.NONE);

  constructor(private signalService: SignalService, private dialog: MatDialog) {}

  public buildDonneesFinancieres(df: DonneesFinancieres | undefined): DonneesFinancieres {
    const donneesFinancieres: DonneesFinancieres = df ?? this.getDefaultDonneesFinancieres();
    donneesFinancieres.comptesResultats = this.buildComptesResultats(donneesFinancieres);
    donneesFinancieres.planFinancements = this.buildPlanFinancement(donneesFinancieres);
    donneesFinancieres.planTresoreries = this.buildPlanTresorerie(donneesFinancieres);
    donneesFinancieres.situationFinanciere = this.buildSituationFinanciere(donneesFinancieres);
    return donneesFinancieres;
  }

  private getDefaultDonneesFinancieres(): DonneesFinancieres {
    return {
      statut: Status.NON_VALIDE,
      isPME: false,
      isOlderThan8Years: false,
      comptesResultats: this.buildDefaultComptesResultats(),
      planFinancements: this.buildDefaultPlansFinancement(),
      planTresoreries: this.buildDefaultPlansTresorerie(),
      situationFinanciere: this.DEFAULT_SITUATION_FINANCIERE,
    };
  }

  private buildDefaultComptesResultats(): PeriodeFinanciere[] {
    const periodesFinancieres: PeriodeFinanciere[] = [];
    for (let i = 0; i < this.DEFAULT_NB_OF_YEARS; i++) {
      periodesFinancieres.push(this.buildSingleComptesResultatsPeriodeFinanciere(''));
    }
    return periodesFinancieres;
  }

  private buildDefaultPlansFinancement(): PeriodeFinanciere[] {
    let periodesFinancieres: PeriodeFinanciere[] = [];
    for (let i = 0; i < this.DEFAULT_NB_OF_YEARS; i++) {
      periodesFinancieres = [...periodesFinancieres, this.buildSinglePlanFinancementPeriodeFinanciere('')];
    }
    return periodesFinancieres;
  }

  private buildDefaultPlansTresorerie(): PeriodeFinanciere[] {
    let periodesFinancieres: PeriodeFinanciere[] = [];
    for (let i = 0; i < this.NB_OF_PERIODS_PLAN_TRESORERIE; i++) {
      periodesFinancieres = [
        ...periodesFinancieres,
        {
          periode: '',
          sections: [
            {
              label: ISOLATED_EDITABLE_ROW_PLAN_TRESORERIE,
              saisies: [
                {
                  label: ISOLATED_EDITABLE_ROW_PLAN_TRESORERIE,
                },
              ],
            },
            {
              label: SectionFinanciere_PlanTresorerie.EDEX,
              saisies: [
                {
                  label: SaisieLabel_PlanTresorerie.EV,
                },
                {
                  label: SaisieLabel_PlanTresorerie.APE,
                },
              ],
            },
            {
              label: SectionFinanciere_PlanTresorerie.EHEX,
              saisies: [
                {
                  label: SaisieLabel_PlanTresorerie.LF,
                },
                {
                  label: SaisieLabel_PlanTresorerie.ACC,
                },
                {
                  label: SaisieLabel_PlanTresorerie.PB,
                },
                {
                  label: SaisieLabel_PlanTresorerie.FBPIPCB,
                },
                {
                  label: SaisieLabel_PlanTresorerie.FBPIAR,
                },
                {
                  label: SaisieLabel_PlanTresorerie.FBPISUB,
                },
                {
                  label: SaisieLabel_PlanTresorerie.AEHE,
                },
              ],
            },
            {
              label: SectionFinanciere_PlanTresorerie.TE,
              saisies: [],
            },
            {
              label: SectionFinanciere_PlanTresorerie.DDEX,
              saisies: [
                {
                  label: SaisieLabel_PlanTresorerie.DRD,
                },
                {
                  label: SaisieLabel_PlanTresorerie.IFBPIAN,
                },
                {
                  label: SaisieLabel_PlanTresorerie.IFHBPIAN,
                },
                {
                  label: SaisieLabel_PlanTresorerie.LCBAN,
                },
                {
                  label: SaisieLabel_PlanTresorerie.ADE,
                },
              ],
            },
            {
              label: SectionFinanciere_PlanTresorerie.DHEX,
              saisies: [
                {
                  label: SaisieLabel_PlanTresorerie.ICI,
                },
                {
                  label: SaisieLabel_PlanTresorerie.RFBPIAN,
                },
                {
                  label: SaisieLabel_PlanTresorerie.RFHBPIAN,
                },
                {
                  label: SaisieLabel_PlanTresorerie.RCC,
                },
                {
                  label: SaisieLabel_PlanTresorerie.ADHE,
                },
              ],
            },
            {
              label: SectionFinanciere_PlanTresorerie.TD,
              saisies: [],
            },
            {
              label: SectionFinanciere_PlanTresorerie.STFP,
              saisies: [],
            },
            {
              label: SectionFinanciere_PlanTresorerie.SCTFP,
              saisies: [],
            },
          ],
        },
      ];
    }
    return periodesFinancieres;
  }

  private buildComptesResultats(donneesFinancieres: DonneesFinancieres): PeriodeFinanciere[] {
    const comptesResultats: PeriodeFinanciere[] = donneesFinancieres.comptesResultats?.length
      ? donneesFinancieres.comptesResultats
      : this.buildDefaultComptesResultats();
    return this.fillPeriodsAsYears(comptesResultats);
  }

  private buildPlanFinancement(donneesFinancieres: DonneesFinancieres): PeriodeFinanciere[] {
    const plansFinancements: PeriodeFinanciere[] = donneesFinancieres.planFinancements?.length
      ? donneesFinancieres.planFinancements
      : this.buildDefaultPlansFinancement();
    return this.fillPeriodsAsYears(plansFinancements);
  }

  private buildPlanTresorerie(donneesFinancieres: DonneesFinancieres): PeriodeFinanciere[] {
    const plansTresorerie: PeriodeFinanciere[] = donneesFinancieres.planTresoreries?.length
      ? donneesFinancieres.planTresoreries
      : this.buildDefaultPlansTresorerie();
    return this.fillPlanTresoreriePeriods(plansTresorerie);
  }

  private buildSituationFinanciere(donneesFinancieres: DonneesFinancieres): SituationFinanciere {
    return donneesFinancieres.situationFinanciere || this.DEFAULT_SITUATION_FINANCIERE;
  }

  private fillPeriodsAsYears(periodesFinancieres: PeriodeFinanciere[]): PeriodeFinanciere[] {
    let currentYear = 0;
    if (this.signalService.getExerciceEnCours() !== 0) {
      currentYear = this.signalService.getExerciceEnCours();
    } else {
      currentYear = Number(periodesFinancieres[1].periode) - 1;
      this.signalService.updateExerciceEnCours(currentYear);
    }
    return periodesFinancieres.map((pf: PeriodeFinanciere, index: number) => {
      return {
        ...pf,
        periode: index === 0 ? this.CURRENT_YEAR_LABEL : '' + (currentYear + index),
      };
    });
  }

  public findCurrentYearLabelAndValue(periodesFinancieres: PeriodeFinanciere[]): CurrentYearLabelValue | undefined {
    const second: PeriodeFinanciere | undefined = periodesFinancieres[1];
    if (second) {
      const secondPeriod: number | undefined = NumberUtils.isNumeric(second.periode) ? Number(second.periode) : undefined;
      if (secondPeriod) {
        const value: number = secondPeriod - 1;
        return {
          value,
          label: '' + value,
        };
      }
    }
    return undefined;
  }

  private fillPlanTresoreriePeriods(periodesFinancieres: PeriodeFinanciere[]): PeriodeFinanciere[] {
    return periodesFinancieres.map((pf: PeriodeFinanciere, index: number) => {
      return {
        ...pf,
        periode: this.PLAN_TRESORERIE_PERIODS[index],
      };
    });
  }

  public generateAddedYear(nbOfPeriodes: number): string {
    const currentYear: number = this.signalService.getExerciceEnCours();
    return '' + (currentYear + nbOfPeriodes);
  }

  public addComptesResultatsPeriodeFinanciere(periodesFinancieres: PeriodeFinanciere[], addedPeriod: string): PeriodeFinanciere[] {
    const added = this.buildSingleComptesResultatsPeriodeFinanciere(addedPeriod);
    return [...periodesFinancieres, added];
  }

  public buildSingleComptesResultatsPeriodeFinanciere(periode: string): PeriodeFinanciere {
    return {
      periode,
      sections: [
        {
          label: SectionFinanciere_ComptesResultats.TOTAL_PEX,
          saisies: [
            {
              label: SaisieLabel_ComptesResultats.CA,
            },
            {
              label: SaisieLabel_ComptesResultats.CARP,
            },
            {
              label: SaisieLabel_ComptesResultats.DVE,
            },
            {
              label: SaisieLabel_ComptesResultats.PI,
            },
            {
              label: SaisieLabel_ComptesResultats.PST,
            },
          ],
        },
        {
          label: SectionFinanciere_ComptesResultats.TOTAL_CPT,
          saisies: [
            {
              label: SaisieLabel_ComptesResultats.AMPM,
            },
            {
              label: SaisieLabel_ComptesResultats.VSMM,
            },
            {
              label: SaisieLabel_ComptesResultats.AACE,
            },
            {
              label: SaisieLabel_ComptesResultats.DST,
            },
            {
              label: SaisieLabel_ComptesResultats.DCBR,
            },
          ],
        },
        {
          label: SectionFinanciere_ComptesResultats.VA,
          saisies: [],
        },
        {
          label: SectionFinanciere_ComptesResultats.EBE,
          saisies: [
            {
              label: SaisieLabel_ComptesResultats.SE,
            },
            {
              label: SaisieLabel_ComptesResultats.IT,
            },
            {
              label: SaisieLabel_ComptesResultats.CP,
            },
          ],
        },
        {
          label: SectionFinanciere_ComptesResultats.RESULTAT_EXPLOITATION,
          saisies: [
            {
              label: SaisieLabel_ComptesResultats.DA,
            },
            {
              label: SaisieLabel_ComptesResultats.ACE,
            },
            {
              label: SaisieLabel_ComptesResultats.APE,
            },
          ],
        },
        {
          label: SectionFinanciere_ComptesResultats.RESULTAT_CAI,
          saisies: [
            {
              label: SaisieLabel_ComptesResultats.PF,
            },
            {
              label: SaisieLabel_ComptesResultats.CF,
            },
          ],
        },
        {
          label: SectionFinanciere_ComptesResultats.RESULTAT_EXERCICE,
          saisies: [
            {
              label: SaisieLabel_ComptesResultats.PE,
            },
            {
              label: SaisieLabel_ComptesResultats.CE,
            },
            {
              label: SaisieLabel_ComptesResultats.PSA,
            },
            {
              label: SaisieLabel_ComptesResultats.IB,
            },
          ],
        },
        {
          label: SectionFinanciere_ComptesResultats.CAPACITE_AUTOFINANCEMENT,
          saisies: [
            {
              label: SaisieLabel_ComptesResultats.EF,
            },
          ],
        },
        {
          label: IsolatedEditableRow_ComptesResultats.MPCE,
          saisies: [
            {
              label: IsolatedEditableRow_ComptesResultats.MPCE,
            },
          ],
        },
        {
          label: IsolatedEditableRow_ComptesResultats.MP,
          saisies: [
            {
              label: IsolatedEditableRow_ComptesResultats.MP,
            },
          ],
        },
      ],
    };
  }

  public addPlansFinancementPeriodeFinanciere(periodesFinancieres: PeriodeFinanciere[], addedPeriod: string): PeriodeFinanciere[] {
    const added = this.buildSinglePlanFinancementPeriodeFinanciere(addedPeriod);
    return [...periodesFinancieres, added];
  }

  public buildSinglePlanFinancementPeriodeFinanciere(period: string): PeriodeFinanciere {
    return {
      periode: period,
      sections: [
        {
          label: SFPF.TOTAL_BESOINS,
          saisies: [
            {
              label: SLPF.IDP,
            },
            {
              label: SLPF.ILICRP,
            },
            {
              label: SLPF.IC,
            },
            {
              label: SLPF.BFRAUG,
            },
            {
              label: SLPF.BFRDIM,
            },
            {
              label: SLPF.RC,
            },
            {
              label: SLPF.DIVERS,
            },
          ],
        },
        {
          label: SFPF.TOTAL_RESSOURCES,
          saisies: [
            {
              label: SLPF.AC,
            },
            {
              label: SLPF.ACC,
            },
            {
              label: SLPF.CA,
            },
            {
              label: SLPF.EDN,
            },
            {
              label: SLPF.ERN,
            },
            {
              label: SLPF.AE,
            },
            {
              label: SLPF.AAPP,
            },
          ],
        },
        {
          label: SFPF.SOLDE_TRESORERIE,
          saisies: [],
        },
        {
          label: SFPF.CUMUL_TRESORERIE,
          saisies: [],
        },
      ],
    };
  }

  public completeWarningAmountsByPeriod_ComptesResultats(valueBySaisieBySectionByPeriod: TableDataRecord): void {
    const warningAmountsByPeriod: WarningAmountsByPeriod = this.getWarningAmountsByPeriod();
    Object.entries(valueBySaisieBySectionByPeriod).forEach(([periode, valueBySaisieBySection]) => {
      const mp: number = valueBySaisieBySection[IsolatedEditableRow_ComptesResultats.MP][IsolatedEditableRow_ComptesResultats.MP] || 0;
      const mpce: number =
        valueBySaisieBySection[IsolatedEditableRow_ComptesResultats.MPCE][IsolatedEditableRow_ComptesResultats.MPCE] || 0;
      if (!warningAmountsByPeriod[periode]) {
        warningAmountsByPeriod[periode] = {
          idpSaisie$: new BehaviorSubject<number>(0),
          mpSaisie$: new BehaviorSubject<number>(0),
          mpceSaisie$: new BehaviorSubject<number>(0),
        };
      }
      warningAmountsByPeriod[periode].mpSaisie$.next(mp);
      warningAmountsByPeriod[periode].mpceSaisie$.next(mpce);
    });
    this.setWarningAmountsByPeriod(warningAmountsByPeriod);
  }

  public completeWarningAmountsByPeriod_PlanFinancement(valueBySaisieBySectionByPeriod: TableDataRecord): void {
    const warningAmountsByPeriod: WarningAmountsByPeriod = this.getWarningAmountsByPeriod();
    Object.entries(valueBySaisieBySectionByPeriod).forEach(([periode, valueBySaisieBySection]) => {
      const idp: number = valueBySaisieBySection[SFPF.TOTAL_BESOINS][SLPF.IDP] || 0;
      if (!warningAmountsByPeriod[periode]) {
        warningAmountsByPeriod[periode] = {
          idpSaisie$: new BehaviorSubject<number>(0),
          mpSaisie$: new BehaviorSubject<number>(0),
          mpceSaisie$: new BehaviorSubject<number>(0),
        };
      }
      warningAmountsByPeriod[periode].idpSaisie$.next(idp);
    });
    this.setWarningAmountsByPeriod(warningAmountsByPeriod);
  }

  public setWarningAmountsByPeriod(warningAmountsByPeriod: WarningAmountsByPeriod): void {
    this.warningAmountsByPeriod = warningAmountsByPeriod;
  }

  public addWarningAmountsByPeriod(period: string): void {
    const existing = this.warningAmountsByPeriod[period];
    this.warningAmountsByPeriod = {
      ...this.warningAmountsByPeriod,
      [period]: {
        idpSaisie$: existing?.idpSaisie$ || new BehaviorSubject<number>(0),
        mpSaisie$: existing?.mpSaisie$ || new BehaviorSubject<number>(0),
        mpceSaisie$: existing?.mpceSaisie$ || new BehaviorSubject<number>(0),
      },
    };
  }

  public clearMpMpceSaisiesWarningAmountsByPeriod(period: string): void {
    const existing = this.warningAmountsByPeriod[period];
    this.warningAmountsByPeriod = {
      ...this.warningAmountsByPeriod,
      [period]: {
        idpSaisie$: existing?.idpSaisie$ || new BehaviorSubject<number>(0),
        mpSaisie$: new BehaviorSubject<number>(0),
        mpceSaisie$: new BehaviorSubject<number>(0),
      },
    };
  }

  public clearIdpSaisieWarningAmountsByPeriod(period: string): void {
    const existing = this.warningAmountsByPeriod[period];
    this.warningAmountsByPeriod = {
      ...this.warningAmountsByPeriod,
      [period]: {
        idpSaisie$: new BehaviorSubject<number>(0),
        mpSaisie$: existing?.mpSaisie$ || new BehaviorSubject<number>(0),
        mpceSaisie$: existing?.mpceSaisie$ || new BehaviorSubject<number>(0),
      },
    };
  }

  public getWarningAmountsByPeriod(): WarningAmountsByPeriod {
    return this.warningAmountsByPeriod;
  }

  public resetSharedValueByPeriodBySaisie(): void {
    this.sharedValueByPeriodBySaisie.next({});
  }

  public updateSharedValueByPeriodBySaisie(saisieLabel: string, periodeLabel: string, value: number): void {
    const sharedValueByPeriodBySaisieRecord = this.sharedValueByPeriodBySaisie.getValue();
    const valueByPeriod = sharedValueByPeriodBySaisieRecord[saisieLabel] || {};
    this.sharedValueByPeriodBySaisie.next({
      ...sharedValueByPeriodBySaisieRecord,
      [saisieLabel]: { ...valueByPeriod, [periodeLabel]: value },
    });
  }

  public getAutocalculatedValueBySaisieByPeriodObs(): Observable<ValueByPeriodBySaisie> {
    return this.sharedValueByPeriodBySaisie.asObservable();
  }

  public setYearActionFromComptesResultat(yearAction: YearButtonAction): void {
    this.yearActionFromComptesResultat$.next(yearAction);
  }

  public getYearActionFromComptesResultat(): BehaviorSubject<YearButtonAction> {
    return this.yearActionFromComptesResultat$;
  }

  public setYearActionFromPlanFinancement(yearAction: YearButtonAction): void {
    this.yearActionFromPlanFinancement$.next(yearAction);
  }

  public getYearActionFromPlanFinancement(): BehaviorSubject<YearButtonAction> {
    return this.yearActionFromPlanFinancement$;
  }

  public triggerCurrentYearModal(): Observable<ModalResult | undefined> {
    const currentYear: number = new Date().getFullYear();
    const startYear: number = currentYear - this.MIN_NB_OF_YEARS_BEFORE_CURRENT;
    const years: Array<number> = Array.from({ length: this.NB_OF_YEARS }, (_, i) => startYear + i);
    const dialogRef: MatDialogRef<AnneeCumulComponent, ModalResult | undefined> = this.dialog.open(AnneeCumulComponent, {
      data: {
        title: "Modification de l'exercice en cours",
        textGoButton: 'OK',
        textReturnButton: 'Annuler',
        years,
        onlyYears: true,
      },
    });
    return dialogRef.afterClosed();
  }
}

export enum YearButtonAction {
  ADD,
  REMOVE,
  NONE,
}

export type ModalResult = { currentYear: string; soldeTresorerieDernierBilan?: number };

export type CurrentYearLabelValue = {
  label: string;
  value: number;
};
