<div class="buttons-container">
  <ng-container *ngIf="status !== Status.VALIDE; else update">
    <button
      *ngIf="displayCancelButton"
      (click)="onCancel()"
      [hidden]="hideAllButtons"
      id="cancel-btn"
      type="button"
      class="ds-btn ds-btn--tertiary"
    >
      Annuler
    </button>
    <button (click)="onSave()" [hidden]="hideAllButtons" id="save-btn" class="ds-btn ds-btn--secondary">Enregistrer</button>
    <button (click)="onValidate()" [hidden]="hideAllButtons" id="valid-btn" class="ds-btn ds-btn--primary">Valider</button>
  </ng-container>
  <ng-template #update>
    <button (click)="onUpdate()" [hidden]="hideModifyButtons" id="modify-btn" class="ds-btn ds-btn--secondary">Modifier</button>
  </ng-template>
</div>
