export enum EnumEtapeCleType {
  AVANCE_INITIALE_SANS_CP = 'AVANCE_INITIALE_SANS_CP',
  AVANCE_INITIALE = 'AVANCE_INITIALE',
  ETAPE_CLE = 'ETAPE_CLE',
  SOLDE = 'SOLDE',
}

export namespace EnumEtapeCleType {
  export function toString(etape?: EnumEtapeCleType): string {
    switch (etape) {
      case EnumEtapeCleType.AVANCE_INITIALE:
      case EnumEtapeCleType.AVANCE_INITIALE_SANS_CP:
        return 'Avance initiale';
      case EnumEtapeCleType.ETAPE_CLE:
        return 'Étape clé';
      case EnumEtapeCleType.SOLDE:
        return 'Solde';
      default:
        return '';
    }
  }
}
