import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ActionDictionaryType } from '@shared-ui';

@Component({
  selector: 'lib-page-wrapper',
  templateUrl: './page-wrapper.component.html',
  styleUrls: ['./page-wrapper.component.scss'],
})
export class PageWrapperComponent {
  @Input() title: string;
  @Input() backLinkPath: string;
  @Input() backLinkText: string;
  @Input() actions: ActionDictionaryType;
  @Output() actionClick = new EventEmitter<string>();
  constructor(private router: Router, private route: ActivatedRoute) {}
}
