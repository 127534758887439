import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DocumentProjet } from '../../shared/models/documentProjet.model';
import { DocumentService } from '../../shared/services/document.service';
import { ShowToastrService } from '../../shared/services/show-toastr.service';
import { SubscriptionDestroyerComponent } from '../../shared/subscription-destroyer/subscription-destroyer.abstract';
import { DocumentHelper } from '../../utils/document-helper';
import { StructureValidationFunction } from '../../utils/structure-validation';
import { ChangeDocumentModalComponent, ConfirmModalComponent, DocumentInvalideModalComponent } from '../_public_api';
import { DocumentFileManagerService } from '../../shared/services/document-file-manager.service';
import { EnumScope } from '../../shared/enums/enum.scope';
import { EnumTypeDocument } from '../../shared/enums/enum.typeDocument';
import { EnumDocumentComplementaireType } from '../../shared/enums/enum.document-complementaire-type';
import { SharedFunction } from '../../utils/sharedFunction';
import { EnumApprobationStatut } from '../../shared/enums/enum.approbation-statut';
import { Utilisateur } from '../../shared/models/utilisateur.model';
import { MoveDocumentConfig } from '../../shared/models/move-document-config.model';
import { EnumValidation } from '../../shared/enums/enum.validation';
import { EnumDocumentInvalide } from '../../shared/enums/enum.documentInvalide';
import { DocumentValidation } from './document-validation';

@Component({
  selector: 'lib-document-card',
  templateUrl: './document-card.component.html',
  styleUrls: ['./document-card.component.scss'],
})
export class DocumentCardComponent extends SubscriptionDestroyerComponent implements OnInit {
  @Input() hasCheckbox = false;
  @Input() isChecked = false;
  @Input() isDocAdmin = false;
  @Input() document: DocumentProjet;
  @Input() moveDocumentConfig: MoveDocumentConfig;
  @Input() utilisateur: Utilisateur;
  @Input() canUserWrite: boolean;
  @Input() showDownload?: boolean;
  @Input() deleteDisabled?: boolean;
  @Input() showNomAsType?: boolean;
  @Input() isCandidat?: boolean;
  @Input() isSiteAdmin?: boolean;
  @Input() isSiteEval?: boolean;
  @Input() validationEnabled?: boolean;
  @Input() statut: EnumValidation | undefined;
  @Input() motifDocumentInvalide: EnumDocumentInvalide | undefined;
  @Input() commentaireDocumentInvalide: string;

  @Input() approbation?: EnumApprobationStatut;
  @Input() showDateAndUploaderName: boolean = true;

  @Output() deleteDocEmitter = new EventEmitter<DocumentProjet>();
  @Output() deleteDocAdminEmitter = new EventEmitter<DocumentProjet>();
  @Output() isCheckedChange = new EventEmitter();
  @Output() documentStatutChange = new EventEmitter<DocumentValidation>();

  invalideChecked: boolean | null;
  valideChecked: boolean | null;
  isScanedDocument: boolean;
  isValidDocument: boolean;
  nomCreateur: string;
  documentName: string;

  readonly VALIDE = (EnumValidation as any)[EnumValidation.VALIDE.toString()];
  readonly INVALIDE = (EnumValidation as any)[EnumValidation.INVALIDE.toString()];
  protected readonly EnumDocumentInvalide = EnumDocumentInvalide;
  protected readonly EnumValidation = EnumValidation;

  constructor(
    public structureValidationFunction: StructureValidationFunction,
    public dialog: MatDialog,
    private readonly cd: ChangeDetectorRef,
    public showToastrService: ShowToastrService,
    public documentService: DocumentService,
    public documentFileManagerService: DocumentFileManagerService,
    public sharedFunction: SharedFunction
  ) {
    super();
  }

  ngOnInit(): void {
    this.invalideChecked = this.document?.controlesValidite?.statut === this.INVALIDE;
    this.valideChecked = this.document?.controlesValidite?.statut === this.VALIDE;
    this.isScanedDocument = this.structureValidationFunction.isScanedDocument(this.document);
    this.isValidDocument = this.structureValidationFunction.isValidDocument(this.document);
    this.nomCreateur = DocumentHelper.getNomCreateurDocument(this.document);
    this.getNomDocument();
  }

  /*
   * Affiche la modale pour supprimer un document
   * */
  onDeleteDocument(document: DocumentProjet): void {
    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      data: {
        description: `<p>Êtes-vous sûr de vouloir supprimer ce document.</p>
                          <p>Cette action est irréversible. </p>`,
        textGoButton: 'Oui',
        textReturnButton: 'Non',
        icon: true,
      },
    });
    dialogRef
      .afterClosed()
      .pipe(this.takeUntilDestroyed())
      .subscribe(result => {
        if (result) {
          if (this.isDocAdmin) {
            this.deleteDocAdminEmitter.emit(document);
          } else {
            this.deleteDocument(document);
          }
        }
      });
  }

  deleteDocument(document: DocumentProjet): void {
    this.documentService
      .delete(document, this.isSiteAdmin)
      .pipe(this.takeUntilDestroyed())
      .subscribe({
        next: () => {
          this.showToastrService.success('Le document a bien été supprimé.');
          this.deleteDocEmitter.emit(document);
        },
        error: (err: HttpErrorResponse) => {
          this.showToastrService.checkCodeError(err?.error);
        },
      });
  }

  downloadDocument(document: DocumentProjet): void {
    this.documentFileManagerService.downloadDocument(document, this.isSiteAdmin).pipe(this.takeUntilDestroyed()).subscribe();
  }

  onChangeDocument(document: DocumentProjet): void {
    const dialogRef = this.dialog.open(ChangeDocumentModalComponent, {
      data: {
        title: 'Demande de changement',
        moveDocumentConfig: this.moveDocumentConfig,
        textGoButton: 'Valider',
        textReturnButton: 'Annuler',
        icon: true,
        document: document,
      },
    });
    dialogRef
      .afterClosed()
      .pipe(this.takeUntilDestroyed())
      .subscribe(result => {
        if (!result) {
          return;
        }
        if (result.projet) {
          document.scope = EnumScope.PROJET;
          document.type = result.projet.nomDocument;
          document.typeDoc = this.PROJET_DOCUMENT_TYPES.includes(document.typeDoc) ? EnumTypeDocument.PROJET : document.typeDoc;
          delete document.structureId;
        } else {
          document.scope = result.documentCible.scope;
          document.type = result.documentCible.nomDocument;
          document.typeDoc = this.PROJET_DOCUMENT_TYPES.includes(document.typeDoc) ? result.documentCible.scope : document.typeDoc;
          document.structureId = result.structureCible.id;
        }
        this.documentService.updateDocument(document, this.isSiteAdmin).subscribe({
          next: () => {
            this.showToastrService.success('Le fichier a bien été déplacé');
          },
          error: (err: HttpErrorResponse) => {
            this.showToastrService.checkCodeError(err?.error);
          },
        });
      });
  }

  checkChange(): void {
    this.isCheckedChange.emit(this.isChecked);
  }

  getNomDocument() {
    return (this.documentName = this.sharedFunction.getDocumentName(this.document));
  }

  isUserOwner() {
    return this.document.createur === this.utilisateur.matricule;
  }

  changeDocumentStatut(statut: EnumValidation | undefined) {
    this.statut = statut;
    this.documentStatutChange.emit({
      statut: statut,
      commentaire: statut === EnumValidation.toKey(EnumValidation.INVALIDE) ? this.commentaireDocumentInvalide : undefined,
      motif: statut === EnumValidation.toKey(EnumValidation.INVALIDE) ? this.motifDocumentInvalide : undefined,
    });
    this.updateRadioChecked();
  }

  private updateRadioChecked() {
    this.invalideChecked = null;
    this.valideChecked = null;
    this.cd.detectChanges();

    const isInvalid = this.statut === this.INVALIDE;
    const isValid = this.statut === this.VALIDE;

    this.invalideChecked = isInvalid;
    this.valideChecked = isValid;
    this.cd.detectChanges();
  }

  openInvalidateModal() {
    const dialogRef = this.dialog.open(DocumentInvalideModalComponent, {
      data: {
        fieldInput: this.commentaireDocumentInvalide,
        motif: this.motifDocumentInvalide,
        title: 'Document Invalide',
        description: 'Vous pouvez ajouter un commentaire',
        description2: "Choisir la raison d'invalidité du document",
        textGoButton: 'Enregistrer',
        textReturnButton: 'Annuler',
        deleteMessage: 'Souhaitez vous supprimer le commentaire ?',
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != null) {
        this.commentaireDocumentInvalide = result.finalValue;
        this.motifDocumentInvalide = result.motif;
        this.changeDocumentStatut(this.INVALIDE);
      } else {
        this.updateRadioChecked();
      }
    });
  }

  protected readonly EnumTypeDocument = EnumTypeDocument;
  protected readonly EnumDocumentComplementaireType = EnumDocumentComplementaireType;
  protected readonly PROJET_DOCUMENT_TYPES = [
    EnumTypeDocument.PROJET,
    EnumTypeDocument.STRUCTURE,
    EnumTypeDocument.STRUCTURE_ACTIONARIAL,
  ] as string[];
}
