<form [formGroup]="form">
  <!-- Role de la structure -->
  <div
    id="input-role"
    class="form-step__role ds-input-group"
    [class.ds-input-group--error]="!form.controls['role'].valid && form.controls['role'].touched"
  >
    <div class="ds-input-group__label">Rôle <span class="ds-input-group__label-asterisk"> *</span></div>
    <div class="select-container">
      <div class="role-select">
        <input formControlName="role" type="radio" name="role" id="chef" value="CHEF_DE_FILE" hidden /><label for="chef"
          >Chef de file</label
        >
        <input formControlName="role" type="radio" name="role" id="mandataire" value="MANDATAIRE" hidden /><label for="mandataire"
          >Mandataire</label
        >
      </div>
    </div>
    <!-- Icône et message d'erreur -->
    <ng-container id="error-role" *ngIf="!form.controls['role'].valid && form.controls['role'].touched">
      <div class="ds-input-group__feedback">Merci de bien vouloir sélectionner votre rôle</div>
    </ng-container>
    <!-- Message d'info sur le role selectionné -->
    <ng-container *ngIf="form.controls['role']?.value === roleStructure[roleStructure.CHEF_DE_FILE]">
      <div class="card--info card--blue mt-3">
        <lib-pxl-icon class="icon" icon="icon-action-info"></lib-pxl-icon>
        <div class="card--info--text">
          La structure avec le rôle Chef de file est partie prenante du projet. Elle est en charge de renseigner les éléments du projet,
          assure la coordination du dépôt et aura la charge de transmettre le dossier complet à Bpifrance. La structure Chef de file possède
          un droit d'écriture sur l'ensemble des sections projet et structure hors grille d'impacts. Il n'y a qu'une seule structure avec le
          rôle Chef de file sur le projet.
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="form.controls['role']?.value === roleStructure[roleStructure.MANDATAIRE]">
      <div class="card--info card--blue mt-3">
        <lib-pxl-icon class="icon" icon="icon-action-info"></lib-pxl-icon>
        <div class="card--info--text">
          La structure avec le rôle Mandataire <b>ne prend pas part au projet</b>. Elle possède un mandat pour venir déposer tout ou partie
          du dossier au nom de son ou ses clients, et aura la charge de transmettre le dossier complet à Bpifrance. La structure Mandataire
          possède un droit d'écriture sur l'ensemble des sections projet et structure. Il n'y a qu'une seule structure avec le rôle
          Mandataire sur le projet.
        </div>
      </div>
    </ng-container>
  </div>
</form>
