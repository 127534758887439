<form [formGroup]="adresseForm">
  <!-- Code Postal -->
  <div
    id="input-codePostal"
    class="ds-input-group"
    [class.ds-input-group--error]="adresseForm.get('cp')?.invalid && adresseForm.get('cp')?.enabled && adresseForm.get('cp')?.touched"
  >
    <label for="cp" class="ds-input-group__label">Code postal <span class="ds-input-group__label-asterisk"> *</span></label>
    <span class="ds-input-group__input">
      <input
        id="cp"
        name="cp"
        type="text"
        maxlength="9"
        placeholder="{{ isPersonnePhysique ? 'Code Postal' : 'Code Postal de la structure' }}"
        class="input_codePostal ds-input-group__input"
        formControlName="cp"
      />
    </span>
    <!-- Icône et message d'erreur -->
    <ng-container
      id="error-codePostal"
      *ngIf="adresseForm.get('cp')?.invalid && adresseForm.get('cp')?.enabled && adresseForm.get('cp')?.touched"
    >
      <div class="ds-input-group__feedback">Merci de bien vouloir renseigner un code postal valide</div>
    </ng-container>
  </div>

  <!-- Ville -->
  <div
    id="input-ville"
    class="ds-input-group"
    [class.ds-input-group--error]="
      adresseForm.get('ville')?.invalid && adresseForm.get('ville')?.enabled && adresseForm.get('ville')?.touched
    "
  >
    <label for="ville" class="ds-input-group__label">Ville <span class="ds-input-group__label-asterisk"> *</span></label>
    <span class="ds-input-group__input">
      <input
        id="ville"
        name="ville"
        type="text"
        maxlength="32"
        placeholder="{{ isPersonnePhysique ? 'Ville' : 'Ville de la structure' }}"
        class="input_ville ds-input-group__input"
        formControlName="ville"
      />
    </span>
    <!-- Icône et message d'erreur -->
    <ng-container
      id="error-ville"
      *ngIf="adresseForm.get('ville')?.invalid && adresseForm.get('ville')?.enabled && adresseForm.get('ville')?.touched"
    >
      <div class="ds-input-group__feedback">Merci de bien vouloir renseigner une ville valide</div>
    </ng-container>
  </div>

  <ng-container *ngIf="isPaysFrance">
    <!-- Mentions de distribution -->
    <div id="input-mention-distrib" class="ds-input-group">
      <label for="mentionDistribution" class="ds-input-group__label">Mentions de distribution</label>
      <span class="ds-input-group__input">
        <input
          id="mentionDistribution"
          name="mentionDistribution"
          type="text"
          maxlength="20"
          placeholder="Ex : BP, CEDEX, CS, TSA"
          class="input_mentionDistribution ds-input-group__input"
          formControlName="mentionDistribution"
        />
      </span>
    </div>

    <!-- Hameau -->
    <div id="input-hameau" class="ds-input-group">
      <label for="hameau" class="ds-input-group__label">Hameau</label>
      <span class="ds-input-group__input">
        <input
          id="hameau"
          name="hameau"
          type="text"
          maxlength="32"
          placeholder="{{ isPersonnePhysique ? 'Hameau' : 'Hameau de la structure' }}"
          class="input_hameau ds-input-group__input"
          formControlName="hameau"
        />
      </span>
    </div>

    <!-- Numéro de la voie -->
    <div
      id="input-numero"
      class="ds-input-group"
      [class.ds-input-group--error]="
        adresseForm.get('numero')?.invalid && adresseForm.get('numero')?.enabled && adresseForm.get('numero')?.touched
      "
    >
      <label for="numero" class="ds-input-group__label">Numéro de la voie <span class="ds-input-group__label-asterisk"> *</span></label>
      <span class="ds-input-group__input">
        <input
          id="numero"
          name="numero"
          type="text"
          maxlength="4"
          (keypress)="onkeypress($event)"
          placeholder="{{ isPersonnePhysique ? 'Numéro de voie' : 'Numéro de voie de la structure' }}"
          class="input_numero ds-input-group__input"
          formControlName="numero"
        />
      </span>
      <!-- Icône et message d'erreur -->
      <ng-container
        id="error-numero"
        *ngIf="adresseForm.get('numero')?.invalid && adresseForm.get('numero')?.enabled && adresseForm.get('numero')?.touched"
      >
        <div class="ds-input-group__feedback">Merci de bien vouloir renseigner un numéro de voie valide</div>
      </ng-container>
    </div>

    <!-- Complément -->
    <div id="input-complement" class="ds-input-group">
      <label for="complement" class="ds-input-group__label">Complément (num voie)</label>
      <div class="ds-input-group__select ds-input-group__select--with-icon">
        <select id="complement" name="complement" formControlName="complement">
          <option [ngValue]="null" selected disabled>
            {{ isPersonnePhysique ? 'Complément du numéro de voie' : 'Complément du numéro de voie de la structure' }}
          </option>
          <option *ngFor="let item of qualifRue | keyvalue : valueOrder" value="{{ item.key }}">
            {{ item.value }}
          </option>
        </select>
      </div>
    </div>

    <!-- Type de Voie -->
    <div
      id="input-typeVoie"
      class="ds-input-group"
      [class.ds-input-group--error]="
        adresseForm.get('typeVoie')?.invalid && adresseForm.get('typeVoie')?.enabled && adresseForm.get('typeVoie')?.touched
      "
    >
      <label for="typeVoie" class="ds-input-group__label">Voie <span class="ds-input-group__label-asterisk"> *</span></label>
      <!-- si restitution automatique -->
      <ng-container *ngIf="readOnly">
        <span class="ds-input-group__input">
          <input
            id="typeVoie"
            name="typeVoie"
            type="text"
            placeholder="{{ isPersonnePhysique ? 'Type de voie' : 'Type de voie de la structure' }}"
            class="input_typeVoie ds-input-group__input"
            formControlName="typeVoie"
          />
        </span>
      </ng-container>
      <ng-container *ngIf="!readOnly">
        <div class="ds-input-group__select ds-input-group__select--with-icon">
          <select id="typeVoie" name="typeVoie" formControlName="typeVoie">
            <option [ngValue]="null" selected disabled>{{ isPersonnePhysique ? 'Type de voie' : 'Type de voie de la structure' }}</option>
            <option *ngFor="let item of typesVoie | keyvalue : valueOrder" value="{{ item.key }}">{{ item.value }}</option>
          </select>
        </div>
      </ng-container>
      <!-- Icône et message d'erreur -->
      <ng-container
        id="error-typeVoie"
        *ngIf="adresseForm.get('typeVoie')?.invalid && adresseForm.get('typeVoie')?.enabled && adresseForm.get('typeVoie')?.touched"
      >
        <div class="ds-input-group__feedback">Merci de bien vouloir renseigner un type de voie valide</div>
      </ng-container>
    </div>
  </ng-container>

  <!-- Nom de la voie -->
  <div
    id="input-voie"
    class="ds-input-group"
    [class.ds-input-group--error]="adresseForm.get('voie')?.invalid && adresseForm.get('voie')?.enabled && adresseForm.get('voie')?.touched"
  >
    <label for="voie" class="ds-input-group__label">Nom de la voie <span class="ds-input-group__label-asterisk"> *</span></label>
    <span class="ds-input-group__input">
      <input
        id="voie"
        name="voie"
        type="text"
        maxlength="64"
        placeholder="{{ isPersonnePhysique ? 'Nom de la voie' : 'Nom de la voie de la structure' }}"
        class="input_voie ds-input-group__input"
        formControlName="voie"
      />
    </span>
    <!-- Icône et message d'erreur -->
    <ng-container
      id="error-voie"
      *ngIf="adresseForm.get('voie')?.invalid && adresseForm.get('voie')?.enabled && adresseForm.get('voie')?.touched"
    >
      <div class="ds-input-group__feedback">Merci de bien vouloir renseigner un nom de voie valide</div>
    </ng-container>
  </div>

  <!-- complément Info -->
  <div id="input-complementInfo" class="ds-input-group" *ngIf="isPaysFrance">
    <label for="complementInfo" class="ds-input-group__label">Complément (adresse)</label>
    <span class="ds-input-group__input">
      <input
        id="complementInfo"
        name="complementInfo"
        type="text"
        maxlength="64"
        placeholder="Ex : escalier, étage, bâtiment..."
        class="input_complementInfo ds-input-group__input"
        formControlName="complementInfo"
      />
    </span>
  </div>

  <!-- Division administrative -->
  <div id="input-divisionAdministrative" class="ds-input-group" *ngIf="!isPaysFrance">
    <label for="divisionAdministrative" class="ds-input-group__label">Division administrative</label>
    <span class="ds-input-group__input">
      <input
        id="divisionAdministrative"
        name="divisionAdministrative"
        type="text"
        maxlength="64"
        placeholder="Ex : escalier, étage, bâtiment..."
        class="input_divisionAdministrative ds-input-group__input"
        formControlName="divisionAdministrative"
      />
    </span>
  </div>

  <!-- Pays -->
  <div
    id="input-pays"
    class="ds-input-group"
    [class.ds-input-group--error]="paysControl.invalid && paysControl.enabled && paysControl.touched"
  >
    <label class="ds-input-group__label">Pays <span class="ds-input-group__label-asterisk"> *</span></label>
    <mat-select-country
      appearance="outline"
      libNoCountrySuggestionWithoutHint
      [formControl]="paysControl"
      [value]="defaultValue"
      [itemsLoadSize]="5"
      placeHolder="Renseigner le pays"
    >
    </mat-select-country>
    <!-- Icône et message d'erreur -->
    <ng-container id="error-pays" *ngIf="paysControl.invalid && paysControl.enabled && paysControl.touched">
      <div class="ds-input-group__feedback">Merci de bien vouloir renseigner un pays valide</div>
    </ng-container>
  </div>
</form>
