<lib-demande-document-complementaire-container [demande]="demande" [projet]="projet" [isArchived]="isArchived">
  <ng-container auteur>
    Créée par <span *ngIf="demande.auteur">{{ demande.auteur?.prenom }} {{ demande.auteur?.nom }}</span
    >, le {{ demande.dateCreation | date : "dd/MM/yyyy à HH'h'mm" }}
  </ng-container>
  <ng-container action-section>
    <div class="card__correction card card-correction" *ngIf="demande.commentaire">
      <p class="card__title">Réponse du porteur de projet</p>
      <p class="card__description">{{ demande.commentaire }}</p>
    </div>

    <div class="card__actions" *ngIf="canWrite">
      <ng-container *ngIf="demande.statut !== RENVOYEE">
        <button id="projet_btn_reject" type="button" class="ds-btn ds-btn--secondary" (click)="updateStatutDemandeComplementEmitter.emit()">
          Abandonner
        </button>
      </ng-container>
      <ng-container *ngIf="demande.statut === RENVOYEE">
        <button
          id="projet_btn_reject"
          type="button"
          class="ds-btn ds-btn--secondary"
          (click)="updateStatutDemandeComplementEmitter.emit(REJETEE)"
        >
          Rejeter et archiver
        </button>
        <button
          id="projet_btn_validate"
          type="button"
          class="ds-btn ds-btn--primary"
          (click)="updateStatutDemandeComplementEmitter.emit(VALIDEE)"
        >
          Valider et archiver
        </button>
      </ng-container>
    </div>
  </ng-container>
</lib-demande-document-complementaire-container>
