export const DEFAULT_BILANS = {
  DA: { label: 'Capital social', reference: 'DA' },
  DB: { label: "Prime d'émission", reference: 'DB' },
  DD: { label: 'Réserve légale', reference: 'DD' },
  DE: { label: 'Réserves statutaires', reference: 'DE' },
  DF: { label: 'Réserves réglementées', reference: 'DF' },
  DG: { label: 'Autres réserves', reference: 'DG' },
  DH: { label: 'Report à nouveau', reference: 'DH' },
  DI: { label: "Résultat de l'exercice", reference: 'DI' },
};

export type BilanReference = keyof typeof DEFAULT_BILANS;

export type PmeNotPme = 'PME' | 'Non PME';
