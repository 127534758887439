import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  DocumentConfig,
  MoveDocumentConfig,
  DocumentHelper,
  DocumentProjet,
  EnumScope,
  enumValues,
  FileUtils,
  SharedFunction,
} from '@shared-ui';

export interface ModalData {
  title: string;
  document: DocumentProjet;
  moveDocumentConfig: MoveDocumentConfig;
  textGoButton: string;
  textReturnButton: string;
}

@Component({
  selector: 'lib-change-document-modal',
  templateUrl: './change-document-modal.component.html',
  styleUrls: ['./change-document-modal.component.scss'],
})
export class ChangeDocumentModalComponent implements OnInit {
  projectToggleState = false;
  toggleLabels: string[];
  changeDocForm: UntypedFormGroup;
  documentsForPrj: DocumentConfig[] = [];
  documentsForStr: DocumentConfig[] = [];
  documentsForStrActio: DocumentConfig[] = [];
  isUploadableToProject = true;
  isWarningRed = false;
  constructor(
    public dialogRef: MatDialogRef<ChangeDocumentModalComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: ModalData,
    private formBuilder: UntypedFormBuilder,
    public sharedFunction: SharedFunction
  ) {
    this.toggleLabels = enumValues(ToggleValues);
  }

  ngOnInit(): void {
    this.createForms();
    this.setListDocProjet();
    this.disableSelectionIfDocNotUploadable();
  }

  get projetControl() {
    return this.changeDocForm.controls['projet'];
  }

  get structureCibleControl() {
    return this.changeDocForm.controls['structureCible'];
  }

  get documentCibleControl() {
    return this.changeDocForm.controls['documentCible'];
  }

  disableSelectionIfDocNotUploadable() {
    if (this.changeDocForm.controls['projet'] && !this.isUploadableToProject) {
      this.changeDocForm.controls['projet'].disable();
    }
  }

  createForms(): void {
    if (this.changeDocForm) {
      this.changeDocForm.reset();
      this.changeDocForm.updateValueAndValidity();
    }
    if (!this.projectToggleState) {
      this.changeDocForm = this.formBuilder.group({
        projet: [null, Validators.required],
      });
    } else {
      this.changeDocForm = this.formBuilder.group({
        structureCible: [null, Validators.required],
        documentCible: [null, Validators.required],
      });
    }
  }

  submitForm(changeDocForm: UntypedFormGroup): void {
    this.isWarningRed = true;
    if (changeDocForm.valid) {
      this.dialogRef.close(changeDocForm.value);
    } else {
      this.changeDocForm.markAllAsTouched();
    }
  }

  onChangeToggle($event: string): void {
    this.projectToggleState = $event === ToggleValues.STRUCTURES;
    this.createForms();
    this.disableSelectionIfDocNotUploadable();
  }

  setListDocProjet() {
    this.documentsForPrj = this.filterDocumentsByExtension(
      this.modalData.moveDocumentConfig.documents?.filter(document => document.scope === EnumScope.PROJET)
    );
  }

  refreshdocumentsForSelectedStructure() {
    if (this.projectToggleState) {
      const strSelected = this.structureCibleControl.value;
      this.documentsForStr = this.filterDocumentsByExtension(
        this.modalData.moveDocumentConfig.documents?.filter(
          document => document.structureId === strSelected.id && document.scope === EnumScope.STRUCTURE
        )
      );
      this.documentsForStrActio = this.filterDocumentsByExtension(
        this.modalData.moveDocumentConfig.documents?.filter(
          document => document.structureId === strSelected.id && document.scope === EnumScope.STRUCTURE_ACTIONARIAL
        )
      );
      this.documentCibleControl.reset();
      this.documentCibleControl.updateValueAndValidity();
    }
  }

  /**
   * Filters the given list of documents based on the file extension and the `connaissanceClient` property.
   *
   * The method first retrieves the file extension of the current document. It then checks if the extension
   * is available in either the `KYC_EXTENSIONS` or `FINNO_EXTENSIONS` list. Based on this check, it filters
   * the documents:
   * - If the extension is in `KYC_EXTENSIONS` and the document is a `connaissanceClient` document.
   * - If the extension is in `FINNO_EXTENSIONS` and the document is not a `connaissanceClient` document.
   */
  filterDocumentsByExtension(documentsList: DocumentConfig[]): DocumentConfig[] {
    const documentExtension = FileUtils.getFileExtension(this.modalData.document.nom);
    const isKycExtension = FileUtils.KYC_EXTENSIONS.includes(documentExtension);
    const isFinnoExtension = FileUtils.FINNO_EXTENSIONS.includes(documentExtension);
    const isVideoExtension = FileUtils.VIDEO_EXTENSIONS.includes(documentExtension);

    // Les .pdf peuvent être à la fois des Kyc et des Finno
    this.isUploadableToProject = !(
      isKycExtension &&
      !isFinnoExtension &&
      !documentsList.some((doc: DocumentConfig) => doc.connaissanceClient)
    );
    return documentsList.filter(doc => {
      const docIsVideo = DocumentHelper.isVideoMediaTypes(doc.mediaTypes);
      const kycAvailable = isKycExtension && doc.connaissanceClient && !docIsVideo;
      const finnoAvailable = isFinnoExtension && !doc.connaissanceClient && !docIsVideo;
      const videoAvailable = isVideoExtension && docIsVideo;
      return kycAvailable || finnoAvailable || videoAvailable;
    });
  }

  protected readonly ToggleValues = ToggleValues;
}

enum ToggleValues {
  PROJET = 'Projet',
  STRUCTURES = 'Structure(s)',
}
