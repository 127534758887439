export enum EnumPermissionUtilisateur {
  AUTOAFFECTATION_PROJET = 'AUTOAFFECTATION_PROJET',
  PROJET_READ = 'PROJET_READ',
  PROJET_WRITE = 'PROJET_WRITE',
  PROGRAMME_READ = 'PROGRAMME_READ',
  AAP_READ = 'AAP_READ',
  PARAMETRAGE_WRITE = 'PARAMETRAGE_WRITE',
  PARAMETRAGE_READ = 'PARAMETRAGE_READ',
  CONSORTIUM_WRITE = 'CONSORTIUM_WRITE',
  STRUCTURE_READ = 'STRUCTURE_READ',
  STRUCTURE_WRITE = 'STRUCTURE_WRITE',
  STRUCTURE_WRITE_ALL = 'STRUCTURE_WRITE_ALL',
  PROJET_EVALUATION_WRITE = 'PROJET_EVALUATION_WRITE',
  PROJET_EVALUATION_WRITE_ALL = 'PROJET_EVALUATION_WRITE_ALL',
  PROJET_AUDITION_WRITE = 'PROJET_AUDITION_WRITE',
  PROJET_LETTRE_LABELISATION_WRITE = 'PROJET_LETTRE_LABELISATION_WRITE',
  PROJET_NOTATION_EVALUATION_WRITE = 'PROJET_NOTATION_EVALUATION_WRITE',
  PROJET_NOTATION_EVALUATION_WRITE_ALL = 'PROJET_NOTATION_EVALUATION_WRITE_ALL',
  PROJET_NOTATION_AUDITION_WRITE = 'PROJET_NOTATION_AUDITION_WRITE',
  PROJET_AUDITION_WRITE_ALL = 'PROJET_AUDITION_WRITE_ALL',
  PROJET_EVALUATION_READ = 'PROJET_EVALUATION_READ',
  PROJET_AUDITION_READ = 'PROJET_AUDITION_READ',
  PROJET_EVALUATION_READ_ALL = 'PROJET_EVALUATION_READ_ALL',
  PROJET_AUDITION_READ_ALL = 'PROJET_AUDITION_READ_ALL',
  PROJET_DEMANDECORRECTION_WRITE = 'PROJET_DEMANDECORRECTION_WRITE',
  PROJET_DEMANDECORRECTION_READ = 'PROJET_DEMANDECORRECTION_READ',
  PROJET_DEMANDE_COMPLEMENT_READ = 'PROJET_DEMANDE_COMPLEMENT_READ',
  PROJET_DEMANDE_COMPLEMENT_WRITE = 'PROJET_DEMANDE_COMPLEMENT_WRITE',
  WKF_DEPOT = 'WKF_DEPOT',
  WKF_PREDEPOT = 'WKF_PREDEPOT',
  EXTRACTION_PROJET_PARTENAIRE = 'EXTRACTION_PROJET_PARTENAIRE',
  ORGANISME_COMPTE_VALIDATE = 'ORGANISME_COMPTE_VALIDATE',
  PROJET_VERROUILLAGE_READ = 'PROJET_VERROUILLAGE_READ',
  PROJET_VERROUILLAGE_WRITE = 'PROJET_VERROUILLAGE_WRITE',
  PROJET_ELIGIBILITE_READ = 'PROJET_ELIGIBILITE_READ',
  PROJET_ELIGIBILITE_WRITE = 'PROJET_ELIGIBILITE_WRITE',
  PROJET_CONTRACTUALISATION_READ = 'PROJET_CONTRACTUALISATION_READ',
  PROJET_CONTRACTUALISATION_WRITE = 'PROJET_CONTRACTUALISATION_WRITE',
  PROJET_INSTRUCTION_READ = 'PROJET_INSTRUCTION_READ',
  PROJET_INSTRUCTION_READ_ALL = 'PROJET_INSTRUCTION_READ_ALL',
  PROJET_INSTRUCTION_WRITE = 'PROJET_INSTRUCTION_WRITE',
  PROJET_NOTATION_INSTRUCTION_READ = 'PROJET_NOTATION_INSTRUCTION_READ',
  PROJET_NOTATION_INSTRUCTION_EVALUATEUR_WRITE = 'PROJET_NOTATION_INSTRUCTION_EVALUATEUR_WRITE',
  WKF_INSTRUCTION = 'WKF_INSTRUCTION',
  WKF_CONTRACTUALISATION = 'WKF_CONTRACTUALISATION',
  COMITE_WRITE = 'COMITE_WRITE',
  COMITE_READ = 'COMITE_READ',
  FEATURE_FLIPPING = 'FEATURE_FLIPPING',
  PXL_QLIK_READ = 'PXL_QLIK_READ',
  STRUCTURE_GDC_WRITE = 'STRUCTURE_GDC_WRITE',
  PROJET_GLOBAL_SEARCH = 'PROJET_GLOBAL_SEARCH',
  RECHERCHE_TRANSVERSE_READ = 'RECHERCHE_TRANSVERSE_READ',
  NOTIFICATION_READ = 'NOTIFICATION_READ',
  NOTIFICATION_WRITE = 'NOTIFICATION_WRITE',
  DOCUMENT_H1 = 'DOCUMENT_H1',
  DOCUMENT_H2 = 'DOCUMENT_H2',
  DOCUMENT_H3 = 'DOCUMENT_H3',
  DOCUMENT_H4 = 'DOCUMENT_H4',
  PREFERENCE_UTILISATEUR_READ = 'PREFERENCE_UTILISATEUR_READ',
  PREFERENCE_UTILISATEUR_WRITE = 'PREFERENCE_UTILISATEUR_WRITE',
  PREFERENCE_UTILISATEUR_NOTIFICATION_WRITE = 'PREFERENCE_UTILISATEUR_NOTIFICATION_WRITE',
  SIGNATURE_READ = 'SIGNATURE_READ',
  SIGNATURE_WRITE = 'SIGNATURE_WRITE',
  DOCUMENT_TRANSFER_GED = 'DOCUMENT_TRANSFER_GED',
  ORGANISME_READ = 'ORGANISME_READ',
  ORGANISME_WRITE = 'ORGANISME_WRITE',
  PURGE_SYSTEM = 'PURGE_SYSTEM',
  PURGE_READ = 'PURGE_READ',
  PURGE_WRITE = 'PURGE_WRITE',
  DISPOSITIF_READ = 'DISPOSITIF_READ',
  SUIVI_PROJET = 'SUIVI_PROJET',
  PROJET_FILTERS = 'PROJET_FILTERS',
  WKF_PREDEPOT_EVALUATION = 'WKF_PREDEPOT_EVALUATION',
  WKF_PREDEPOT_AUDITION = 'WKF_PREDEPOT_AUDITION',
  WKF_DEPOT_ENCOURS = 'WKF_DEPOT_ENCOURS',
  WKF_DEPOT_ANALYSE = 'WKF_DEPOT_ANALYSE',
  PROJET_INSTRUCTION_COMPLETUDE_WRITE = 'PROJET_INSTRUCTION_COMPLETUDE_WRITE',
  PROJET_INSTRUCTION_COMPLETUDE_READ = 'PROJET_INSTRUCTION_COMPLETUDE_READ',
  PROJET_DOCUMENT_READ = 'PROJET_DOCUMENT_READ',
  PROJET_DOCUMENT_WRITE = 'PROJET_DOCUMENT_WRITE',
  STRUCTURE_DOCUMENT_READ = 'STRUCTURE_DOCUMENT_READ',
  STRUCTURE_DOCUMENT_WRITE = 'STRUCTURE_DOCUMENT_WRITE',
  PROJET_DATE_ECHEANCE_WRITE = 'PROJET_DATE_ECHEANCE_WRITE',
  AFFECTATION_EVALUATEUR_PROJET = 'AFFECTATION_EVALUATEUR_PROJET',
  PROJET_ARCHIVE_DOWNLOAD = 'PROJET_ARCHIVE_DOWNLOAD',
  STRUCTURE_ANNEXES_FINANCIERES_READ = 'STRUCTURE_ANNEXES_FINANCIERES_READ',
  STRUCTURE_ANNEXES_FINANCIERES_WRITE = 'STRUCTURE_ANNEXES_FINANCIERES_WRITE',
  STRUCTURE_PREVISIONS_ECONOMIQUES_READ = 'STRUCTURE_PREVISIONS_ECONOMIQUES_READ',
  STRUCTURE_PREVISIONS_ECONOMIQUES_WRITE = 'STRUCTURE_PREVISIONS_ECONOMIQUES_WRITE',
  PROJET_GRILLE_IMPACTS_READ = 'PROJET_GRILLE_IMPACTS_READ',
  PROJET_GRILLE_IMPACTS_WRITE = 'PROJET_GRILLE_IMPACTS_WRITE',
  STRUCTURE_GRILLE_IMPACTS_READ = 'STRUCTURE_GRILLE_IMPACTS_READ',
  STRUCTURE_GRILLE_IMPACTS_WRITE = 'STRUCTURE_GRILLE_IMPACTS_WRITE',
  PROJET_BASE_DONNEES_BUDGETAIRE_READ = 'PROJET_BASE_DONNEES_BUDGETAIRE_READ',
  PROJET_BASE_DONNEES_BUDGETAIRE_WRITE = 'PROJET_BASE_DONNEES_BUDGETAIRE_WRITE',
  PROJET_RAPPORT_EXPERTISE_READ = 'PROJET_RAPPORT_EXPERTISE_READ',
  PROJET_RAPPORT_EXPERTISE_WRITE = 'PROJET_RAPPORT_EXPERTISE_WRITE',
  PROJET_INSTRUCTION_CONTROLE_READ = 'PROJET_INSTRUCTION_CONTROLE_READ',
  PROJET_SYNTHESE_EVALUATION_WRITE = 'PROJET_SYNTHESE_EVALUATION_WRITE',
  PROJET_SYNTHESE_EVALUATION_READ = 'PROJET_SYNTHESE_EVALUATION_READ',
  PROJET_DECISION_EVALUATION_READ = 'PROJET_DECISION_EVALUATION_READ',
  PROJET_DECISION_EVALUATION_WRITE = 'PROJET_DECISION_EVALUATION_WRITE',
  PROJET_NOTATION_EVALUATION_EVALUATEUR_READ = 'PROJET_NOTATION_EVALUATION_EVALUATEUR_READ',
  WKF_DEPOT_EVALUATION = 'WKF_DEPOT_EVALUATION',
  WKF_DEPOT_AUDITION = 'WKF_DEPOT_AUDITION',
  PROJET_NOTATION_EVALUATION_EVALUATEUR_WRITE = 'PROJET_NOTATION_EVALUATION_EVALUATEUR_WRITE',
  PROJET_NOTATION_EVALUATION_INSTRUCTEUR_READ = 'PROJET_NOTATION_EVALUATION_INSTRUCTEUR_READ',
  PROJET_NOTATION_EVALUATION_INSTRUCTEUR_WRITE = 'PROJET_NOTATION_EVALUATION_INSTRUCTEUR_WRITE',
  PROJET_NOTATION_EVALUATION_READ = 'PROJET_NOTATION_EVALUATION_READ',
  PROJET_SYNTHESE_AUDITION_READ = 'PROJET_SYNTHESE_AUDITION_READ',
  PROJET_SYNTHESE_AUDITION_WRITE = 'PROJET_SYNTHESE_AUDITION_WRITE',
  PROJET_DECISION_AUDITION_READ = 'PROJET_DECISION_AUDITION_READ',
  PROJET_DECISION_AUDITION_WRITE = 'PROJET_DECISION_AUDITION_WRITE',
  PROJET_NOTATION_AUDITION_EVALUATEUR_READ = 'PROJET_NOTATION_AUDITION_EVALUATEUR_READ',
  PROJET_NOTATION_AUDITION_EVALUATEUR_WRITE = 'PROJET_NOTATION_AUDITION_EVALUATEUR_WRITE',
  PROJET_NOTATION_AUDITION_INSTRUCTEUR_READ = 'PROJET_NOTATION_AUDITION_INSTRUCTEUR_READ',
  PROJET_NOTATION_AUDITION_INSTRUCTEUR_WRITE = 'PROJET_NOTATION_AUDITION_INSTRUCTEUR_WRITE',
  PROJET_NOTATION_AUDITION_READ = 'PROJET_NOTATION_AUDITION_READ',
  PROJET_INSTRUCTION_CONTROLES_READ = 'PROJET_INSTRUCTION_CONTROLES_READ',
  PROJET_INSTRUCTION_INTERVENTION_PI_READ = 'PROJET_INSTRUCTION_INTERVENTION_PI_READ',
  PROJET_INSTRUCTION_INTERVENTION_PI_WRITE = 'PROJET_INSTRUCTION_INTERVENTION_PI_WRITE',
  PROJET_PROPOSITION_FINANCEMENT_READ = 'PROJET_PROPOSITION_FINANCEMENT_READ',
  PROJET_PROPOSITION_FINANCEMENT_WRITE = 'PROJET_PROPOSITION_FINANCEMENT_WRITE',
  PROJET_SYNTHESE_INSTRUCTION_READ = 'PROJET_SYNTHESE_INSTRUCTION_READ',
  PROJET_SYNTHESE_INSTRUCTION_WRITE = 'PROJET_SYNTHESE_INSTRUCTION_WRITE',
  PROJET_DECISION_INSTRUCTION_READ = 'PROJET_DECISION_INSTRUCTION_READ',
  PROJET_DECISION_INSTRUCTION_WRITE = 'PROJET_DECISION_INSTRUCTION_WRITE',
  PROJET_CONTRACTUALISATION_EDITION_RELECTURE_DONNEES_STRUCTURE_WRITE = 'PROJET_CONTRACTUALISATION_EDITION_RELECTURE_DONNEES_STRUCTURE_WRITE',
  PROJET_CONTRACTUALISATION_EDITION_RELECTURE_BDD_WRITE = 'PROJET_CONTRACTUALISATION_EDITION_RELECTURE_BDD_WRITE',
  PROJET_INSTRUCTION_WRITE_ALL = 'PROJET_INSTRUCTION_WRITE_ALL',
  PROJET_INSTRUCTION_BDD_READ = 'PROJET_INSTRUCTION_BDD_READ',

  // TODO: Legacy perms, keeping them just in case, delete if not used
  PROGRAMME_ADD = 'PROGRAMME_ADD',
  PROGRAMME_UPDATE = 'PROGRAMME_UPDATE',
  PROGRAMME_DELETE = 'PROGRAMME_DELETE',
  AAP_ADD = 'AAP_ADD',
  AAP_UPDATE = 'AAP_UPDATE',
  AAP_PROJET_APERCU_VALIDER = 'AAP_PROJET_APERCU_VALIDER',
  AAP_PROJET_APERCU_REJETER = 'AAP_PROJET_APERCU_REJETER',
  PROJET_INFO_UPSERT = 'PROJET_INFO_UPSERT',
  PROJET_INFO_UPLOAD_DOCUMENT = 'PROJET_INFO_UPLOAD_DOCUMENT',
  PROJET_CONSORT_ADD_STRUCT = 'PROJET_CONSORT_ADD_STRUCT',
  PROJET_CONSORT_UPDATE_STRUCT = 'PROJET_CONSORT_UPDATE_STRUCT',
  PROJET_CONSORT_DELETE_STRUCT = 'PROJET_CONSORT_DELETE_STRUCT',
  PROJET_CONSORT_STRUCT_ADD_CONTACT = 'PROJET_CONSORT_STRUCT_ADD_CONTACT',
  PROJET_CONSORT_STRUCT_UPDATE_CONTACT = 'PROJET_CONSORT_STRUCT_UPDATE_CONTACT',
  PROJET_CONSORT_STRUCT_DELETE_CONTACT = 'PROJET_CONSORT_STRUCT_DELETE_CONTACT',
  PROJET_DELETE_DOCUMENT = 'PROJET_DELETE_DOCUMENT',
  AAP_ADD_FILE = 'AAP_ADD_FILE',
  AAP_RECORD = 'AAP_RECORD',
  AAP_ADD_AUTRESDATES = 'AAP_ADD_AUTRESDATES',
  ACTIVITE_READ = 'ACTIVITE_READ',
  PROJET_NOTATION_SELECTION_WRITE = 'PROJET_NOTATION_SELECTION_WRITE',
  PROJET_NOTATION_SELECTION_WRITE_ALL = 'PROJET_NOTATION_SELECTION_WRITE_ALL',
  AFFECTATION_EXPERT_EXTERNE_PROJET = 'AFFECTATION_EXPERT_EXTERNE_PROJET',
  PROJET_CONTRACTUALISATION_PROCEDURE_SIGNATURE_READ = 'PROJET_CONTRACTUALISATION_PROCEDURE_SIGNATURE_READ',
}
