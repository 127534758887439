<section class="page-section">
  <form class="form" [formGroup]="signatureElectroniqueForm">
    <h2 class="page-section__title mt-3">Mode de signature des documents contractuels</h2>
    <div class="card--info card--blue card--form">
      <lib-pxl-icon class="icon" icon="icon-action-info"></lib-pxl-icon>
      Afin d'améliorer la qualité de service et d'accélérer le processus de signature, Bpifrance propose la signature électronique des
      documents contractuels. Ceci ne concerne pas la signature des pièces du présent dossier de demande d'aide dont la signature est
      manuelle. Pour mettre en œuvre ce processus, il est indispensable d’obtenir votre accord ainsi que les informations ci-dessous.
      L'accord donné dans ce cadre ne préjuge pas de la décision finale d'octroi de l'aide
    </div>
    <div class="card">
      <section>
        <div
          class="ds-input-group"
          [class.ds-input-group--error]="
            signatureElectroniqueForm.controls['signatureElectronique'].invalid &&
            signatureElectroniqueForm.controls['signatureElectronique'].touched
          "
        >
          <div class="questions-container">
            <div>
              <label class="fw-bold">
                Je donne mon accord pour signer électroniquement les documents contractuels ?
                <lib-asterisk></lib-asterisk>
              </label>
            </div>
            <div class="radio-btn">
              <label class="ds-radio-classic" for="signatureElectronique-true">
                <input
                  type="radio"
                  class="ds-radio__input"
                  id="signatureElectronique-true"
                  name="signatureElectronique"
                  formControlName="signatureElectronique"
                  [value]="true"
                />
                <span class="ds-radio__label"><span>Oui</span></span>
              </label>
              <label class="ds-radio-classic" for="signatureElectronique-false">
                <input
                  type="radio"
                  class="ds-radio__input"
                  id="signatureElectronique-false"
                  name="signatureElectronique"
                  formControlName="signatureElectronique"
                  [value]="false"
                />
                <span class="ds-radio__label"><span>Non</span></span>
              </label>
            </div>
          </div>

          <ng-container
            id="error-signatureElectronique"
            *ngIf="
              !signatureElectroniqueForm.controls['signatureElectronique'].valid &&
              signatureElectroniqueForm.controls['signatureElectronique'].touched
            "
          >
            <div class="ds-input-group__feedback">Merci de bien vouloir renseigner une valeur.</div>
          </ng-container>
        </div>
      </section>

      <section *ngIf="isDisplaySignatureElectroniqueIdentique" class="mt-3">
        <div
          class="ds-input-group"
          [class.ds-input-group--error]="
            isDisplaySignatureElectroniqueIdentique &&
            signatureElectroniqueForm.controls['signataireElectroniqueIdentique'].invalid &&
            signatureElectroniqueForm.controls['signataireElectroniqueIdentique'].touched
          "
        >
          <div class="questions-container">
            <div class="question-signature-electronique">
              <label class="fw-bold"
                >Est ce que le signataire de l’entité en charge de la signature électronique des documents contractuels est le même que
                celui déclaré dans la section ci-dessus “Correspondant signature manuelle de la fiche de demande d’aide ?
                <lib-asterisk></lib-asterisk>
              </label>
            </div>
            <div class="radio-btn">
              <label class="ds-radio-classic" for="signataireElectroniqueIdentique-true">
                <input
                  type="radio"
                  class="ds-radio__input"
                  id="signataireElectroniqueIdentique-true"
                  name="signataireElectroniqueIdentique"
                  formControlName="signataireElectroniqueIdentique"
                  [value]="true"
                />
                <span class="ds-radio__label"><span>Oui</span></span>
              </label>
              <label class="ds-radio-classic" for="signataireElectroniqueIdentique-false">
                <input
                  type="radio"
                  class="ds-radio__input"
                  id="signataireElectroniqueIdentique-false"
                  name="signataireElectroniqueIdentique"
                  formControlName="signataireElectroniqueIdentique"
                  [value]="false"
                />
                <span class="ds-radio__label"><span>Non</span></span>
              </label>
            </div>
          </div>
          <ng-container
            id="error-signataireElectroniqueIdentique"
            *ngIf="
              isDisplaySignatureElectroniqueIdentique &&
              signatureElectroniqueForm.controls['signataireElectroniqueIdentique'].invalid &&
              signatureElectroniqueForm.controls['signataireElectroniqueIdentique'].touched
            "
          >
            <div class="ds-input-group__feedback">Merci de bien vouloir renseigner une valeur.</div>
          </ng-container>
        </div>
      </section>

      <section *ngIf="isDisplayIdentiteForm">
        <lib-fiche-demande-aide-signature-identite-form
          [identiteForm]="identiteForm"
          [etapeProjet]="etapeProjet"
          [title]="TITLE_FORM"
          componentId="signature-electronique"
        >
        </lib-fiche-demande-aide-signature-identite-form>
      </section>
    </div>
  </form>
</section>
