import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Directive({
  selector: '[libSiret]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: SiretDirective,
      multi: true,
    },
  ],
})
export class SiretDirective implements ControlValueAccessor {
  private onChange: (value: any) => void = () => {};
  private onTouched: () => void = () => {};

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  @HostListener('blur', ['$event.target.value'])
  onBlur(value: string): void {
    const siret = this.getSiret(value);

    if (isNaN(Number(siret)) || siret.length !== 14) {
      this.onChange(siret);
    } else {
      const formattedValue = this.formatSiret(siret);
      this.renderer.setProperty(this.el.nativeElement, 'value', formattedValue);
      this.onChange(this.getSiret(formattedValue));
    }
    this.onTouched();
  }

  writeValue(siret: any): void {
    if (siret) {
      const formattedValue = this.formatSiret(siret);
      this.renderer.setProperty(this.el.nativeElement, 'value', formattedValue);
      this.onChange(this.getSiret(formattedValue));
    } else {
      this.renderer.setProperty(this.el.nativeElement, 'value', '');
    }
  }

  formatSiret(value: string): string {
    return value.replace(/.{13}(?=.)/, (m: string) => m.replace(/.{10}(?=.)/, n => n.replace(/.{3}(?=.)/g, '$& ')));
  }

  getSiret(formattedValue: string): string {
    return formattedValue.replace(/\s/g, '');
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.renderer.setProperty(this.el.nativeElement, 'disabled', isDisabled);
  }
}
