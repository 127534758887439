export class TableUtils {
  static handlePreferenceResponse(displayedColumns: string[] | null, tableConf: any[]): any[] {
    if (displayedColumns) {
      // Filtrer et trier les éléments de tableConf qui existent dans displayedColumns
      const orderedColumns = displayedColumns
        .map(nom => tableConf.find(column => column.nom === nom))
        .filter(column => column !== undefined);

      // Filtrer les éléments de tableConf qui n'existent pas dans displayedColumns
      const remainingColumns = tableConf.filter(column => !displayedColumns.includes(column.nom));

      // Concaténer les deux listes
      const updatedTableConf = [...orderedColumns, ...remainingColumns];

      // Mettre à jour la propriété visible des colonnes
      updatedTableConf.forEach(column => (column.visible = displayedColumns.includes(column.nom)));

      return updatedTableConf;
    } else {
      return tableConf;
    }
  }
}
