<lib-document-list
  [scope]="scope"
  [structureId]="structureId"
  [documents]="documents"
  [documentConfig]="documentConfigs"
  [moveDocumentConfig]="moveDocumentConfig"
  [canUserWrite]="canUserWrite"
  [utilisateur]="utilisateur"
  [fileTooBig]="fileTooBig"
  [fileNotSupported]="fileNotSupported"
  [nameFileNotSupported]="nameFileNotSupported"
  [isCandidat]="!isSiteAdmin"
  [isSiteAdmin]="isSiteAdmin"
  [suiviProjetEnable]="suiviProjetEnable"
  (deleteDocument)="onDeleteDocument($event)"
  (uploadDocument)="onUploadDocument($event)"
  (documentStatutChange)="onDocumentStatutChange($event)"
></lib-document-list>
