<div class="buttons-container">
  <a role="button" class="text-decoration-underline d-inline-flex align-items-center gap-1" (click)="onUpdateCurrentYear()">
    Modifier l'exercice en cours
  </a>
  <a
    role="button"
    class="text-decoration-underline d-inline-flex align-items-center gap-1"
    [ngClass]="{ disabled: isRemoveYearButtonDisabled }"
    (click)="onRemoveYear()"
  >
    <lib-pxl-icon class="icon" icon="icon-action-delete"></lib-pxl-icon>
    Supprimer une année
  </a>
  <a role="button" class="text-decoration-underline d-inline-flex align-items-center gap-1" (click)="onAddYear()">
    <lib-pxl-icon class="icon" icon="icon-action-add"></lib-pxl-icon>
    Ajouter une année
  </a>
</div>
