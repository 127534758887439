import { Component, Input } from '@angular/core';

@Component({
  selector: 'lib-card-info',
  templateUrl: './card-info.component.html',
  styleUrls: ['./card-info.component.scss'],
})
export class CardInfoComponent {
  @Input() message: string;
  @Input() content: string;
  @Input() icon = 'icon-action-info';
  @Input() color: 'blue' | 'green' | 'red' | 'yellow' = 'blue';
  @Input() disabled?: boolean;
}
