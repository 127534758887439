import { Component, Input } from '@angular/core';
import { FILE_SIZE } from '../../utils/constants/file-size.constant';

@Component({
  selector: 'lib-card-title',
  templateUrl: './card-title.component.html',
  styleUrls: ['./card-title.component.scss'],
})
export class CardTitleComponent {
  readonly FILE_SIZE_LIMIT = FILE_SIZE;

  @Input() title: string;
  @Input() titleComplement: string;
  @Input() disabled = false;
  @Input() dossier: string;
  @Input() showAddDocSubtitle = false;
  @Input() showAddProjectDocSubtitle = false;
  @Input() showMaxSizeInfo = false;
}
