import { Injectable } from '@angular/core';
import { EnumQualifRue } from '../enums/enum.qualifRue';
import { EnumTypeVoie } from '../enums/enum.typeVoie';
import { Structure } from '../models/structure.model';
import { StructureRoleType } from '../types/structureRole.type';
import { Adresse } from '../models/adresse.model';

@Injectable({
  providedIn: 'root',
})
export class StructureHelperService {
  static structureHasRole(structure: Structure, roles: StructureRoleType[]): boolean {
    return !!structure && roles && roles.includes(structure?.role?.toString().toUpperCase() as StructureRoleType);
  }

  private getInfoItem(infoItem: string | null, defaultValue: string = ''): string {
    return infoItem || defaultValue;
  }

  private getAdresseVoie(value: string): string {
    return value ? value + ',' : '';
  }

  getAdresse(adresse: Adresse, delimiter: string = ' '): string {
    const voie = delimiter === ' ' ? this.getAdresseVoie(adresse?.voie) : adresse?.voie;
    const cp = this.getInfoItem(adresse?.cp);
    const ville = this.getInfoItem(adresse?.ville)?.toUpperCase();
    const pays = this.getInfoItem(adresse?.pays)?.toUpperCase();
    if (adresse?.codePays === 'FR') {
      const numero = this.getInfoItem(adresse?.numero);
      const complement = this.getInfoItem(this.getComplementFromEnum(adresse?.complement));
      const typeVoie = this.getInfoItem(this.getTypeVoieFromEnum(adresse?.typeVoie));
      const num = `${numero}${complement ? ' ' + complement : ''}`;
      const voieLabel = `${typeVoie} ${voie}`;
      return [num, voieLabel, cp, ville, pays].filter(val => val).join(delimiter);
    } else {
      return [voie, cp, ville, pays].join(delimiter);
    }
  }

  getTypeVoieFromEnum(typeVoie: string): string {
    return (EnumTypeVoie as any)[typeVoie] ?? typeVoie;
  }

  getComplementFromEnum(complement: string): string {
    return (EnumQualifRue as any)[complement] ?? complement;
  }
}
