import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'iban',
})
export class IbanPipe implements PipeTransform {
  transform(value: string): string {
    return value
      .replace(/\s/g, '')
      .replace(/(.{4})/g, '$1 ')
      .trim();
  }
}
