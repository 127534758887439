import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Signataire } from '@shared-ui';

@Component({
  selector: 'lib-signataire-list',
  templateUrl: './signataire-list.component.html',
  styleUrls: ['./signataire-list.component.scss'],
})
export class SignataireListComponent {
  @Input() signataires: Signataire[] = [];
  @Input() isStructureUpdatableByUser = false;

  constructor(private router: Router, private route: ActivatedRoute) {}

  onGoToSignataire(signataire: Signataire): void {
    if (this.isStructureUpdatableByUser) {
      const routeUrl = signataire?.id ? 'projet-consortium-signataire/' + signataire.id : 'projet-consortium-signataire';
      this.router.navigate([routeUrl], { relativeTo: this.route });
    }
  }
  
  parsePhoneNumber(phone: string): string {
    phone = phone?.replace(/\d{2}(?=.)/g, '$& ');
    return phone;
  }
}
