import { HttpErrorResponse } from '@angular/common/http';
import { Component, DestroyRef, EventEmitter, OnInit, Output, ViewChild, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  BasicFilters,
  DispositifInterface,
  FilterDataInterface,
  FlechageBudgetaireService,
  MultiselectComponent,
  RechercheTransverseService,
  ShowToastrService,
  StatutProcessFR30,
} from '@shared-ui';

@Component({
  selector: 'lib-recherche-transverse-filters',
  templateUrl: './recherche-transverse-filters.component.html',
  styleUrls: ['./recherche-transverse-filters.component.scss'],
})
export class RechercheTransverseFiltersComponent implements OnInit {
  destroyRef = inject(DestroyRef);

  readonly AAP = 'aap';
  readonly DISPOSITIF = 'dispositif';
  readonly ACRONYME = 'acronyme';
  readonly DENOMINATION_SOCIALE = 'denomination_sociale';
  readonly PROCESS_FR30 = 'process_fr30';

  @ViewChild('aapFilterComponent') aapFilterComponent!: MultiselectComponent;
  @ViewChild('dispositifFilterComponent') dispositifFilterComponent!: MultiselectComponent;
  @ViewChild('acronymeFilterComponent') acronymeFilterComponent!: MultiselectComponent;
  @ViewChild('denominationSocialeFilterComponent') denominationSocialeFilterComponent!: MultiselectComponent;
  @ViewChild('processFR30FilterComponent') processFR30FilterComponent!: MultiselectComponent;

  @Output() loadData: EventEmitter<BasicFilters> = new EventEmitter<BasicFilters>();

  dispositifSelectedList: FilterDataInterface[] = [];
  dispositifDataList: FilterDataInterface[];
  dispositifInitialList: DispositifInterface[] = [];
  aapSelectedList: FilterDataInterface[] = [];
  aapDataList: FilterDataInterface[] = [];
  acronymeSelectedList: FilterDataInterface[] = [];
  acronymeDataList: FilterDataInterface[] = [];
  denominationSocialeSelectedList: FilterDataInterface[] = [];
  denominationSocialeDataList: FilterDataInterface[] = [];
  processFR30SelectedList: FilterDataInterface[] = [];
  processFR30DataList: FilterDataInterface[] = [];
  processFR30InitialList: string[] = StatutProcessFR30;

  loadingMap = new Map();

  constructor(
    public rechercheTransverseService: RechercheTransverseService,
    public toastrService: ShowToastrService,
    private flechageBudgetaireService: FlechageBudgetaireService
  ) {
    this.loadingMap.set(this.AAP, false);
    this.loadingMap.set(this.ACRONYME, false);
    this.loadingMap.set(this.DENOMINATION_SOCIALE, false);
  }

  ngOnInit(): void {
    this.loadDispositifs();
    this.loadStatutProcessFR30();
  }

  submitSearch(): void {
    this.loadData.emit(this.getFiltersValues());
  }

  public getFiltersValues(): BasicFilters {
    return {
      dispositifs: this.dispositifSelectedList.map(dispositif => dispositif.value),
      aaps: this.aapSelectedList.map(aap => aap.value),
      acronymes: this.acronymeSelectedList.map(acronyme => acronyme.value),
      denominationsSociales: this.denominationSocialeSelectedList.map(denominationSociale => denominationSociale.value),
      processFr30List: this.processFR30SelectedList.map(processFR30 => processFR30.value),
    };
  }

  resetFilters(): void {
    this.resetDispositifFilter();
    this.aapFilterComponent.reset();
    this.acronymeFilterComponent.reset();
    this.denominationSocialeFilterComponent.reset();
    this.resetProcessFR30Filter();
  }

  private resetDispositifFilter(): void {
    this.dispositifFilterComponent.reset();
    this.dispositifDataList = this.dispositifInitialList.map(dispositif => ({ id: dispositif.id, value: dispositif.code }));
  }

  private resetProcessFR30Filter(): void {
    this.processFR30FilterComponent.reset();
    this.processFR30DataList = this.processFR30InitialList.map(statut => ({ id: statut, value: statut }));
  }

  selectedListChange(event: FilterDataInterface[], source: string): void {
    switch (source) {
      case this.DISPOSITIF:
        this.dispositifSelectedList = event;
        break;
      case this.AAP:
        this.aapSelectedList = event;
        break;
      case this.ACRONYME:
        this.acronymeSelectedList = event;
        break;
      case this.DENOMINATION_SOCIALE:
        this.denominationSocialeSelectedList = event;
        break;
      case this.PROCESS_FR30:
        this.processFR30SelectedList = event;
        break;
    }
  }

  /**
   * Récupère l'input du composant fils, lance la recherche d'autocomplete, ensuite actualise la liste des options
   * @param event Event envoyé par le composant fils de filtre, soit le texte écrit dans l'input
   * @param source Le nom du composant fils qui a emit cet event
   */
  autocompleteEvent(event: string, source: string): void {
    this.loadingMap.set(source, true);
    const searchObject = { query: this.removeElasticsearchSpecialCharacters(event), fieldName: source, isPrefix: false };
    this.rechercheTransverseService.getAutocomplete(searchObject).subscribe({
      next: resultAutocomplete => {
        switch (source) {
          case this.AAP:
            this.aapDataList = (resultAutocomplete.body as string[]).map(aap => ({ id: aap, value: aap }));
            break;
          case this.ACRONYME:
            this.acronymeDataList = (resultAutocomplete.body as string[]).map(aap => ({ id: aap, value: aap }));
            break;
          case this.DENOMINATION_SOCIALE:
            this.denominationSocialeDataList = (resultAutocomplete.body as string[]).map(aap => ({ id: aap, value: aap }));
            break;
        }
        this.loadingMap.set(source, false);
      },
      error: (err: HttpErrorResponse) => {
        this.toastrService.checkCodeError(err?.error);
        this.loadingMap.set(source, false);
      },
    });
  }

  loadDispositifs(): void {
    this.flechageBudgetaireService
      .getDispositifs()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: dispositifs => {
          this.dispositifInitialList = dispositifs;
          this.dispositifDataList = dispositifs.map(dispositif => ({ id: dispositif.id, value: dispositif.code }));
        },
        error: (err: HttpErrorResponse) => {
          this.toastrService.checkCodeError(err?.error);
        },
      });
  }

  loadStatutProcessFR30(): void {
    this.processFR30DataList = this.processFR30InitialList.map(statut => ({ id: statut, value: statut }));
  }

  // Helper function to remove special characters from a string
  removeSpecialCharacters(str: string) {
    return str
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .replace(/[^a-z0-9\s:]/gi, '');
  }

  private removeElasticsearchSpecialCharacters(str: string) {
    const specialCharacters = [
      '+',
      '-',
      '=',
      '&&',
      '.',
      '||',
      '>',
      '<',
      '!',
      '(',
      ')',
      '{',
      '}',
      '[',
      ']',
      '^',
      '"',
      '~',
      '*',
      '?',
      ':',
      '\\',
      '/',
    ];
    return str.replace(new RegExp(`[${specialCharacters.join('\\')}]`, 'g'), ' ');
  }
}
