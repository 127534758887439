import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DemandeCorrection, EnumStatutDemandeCorrectionTech, Projet } from '@shared-ui';

@Component({
  selector: 'lib-demande-modification-admin',
  templateUrl: './demande-modification-admin.component.html',
  styleUrls: ['./demande-modification-admin.component.scss'],
})
export class DemandeModificationAdminComponent {
  @Input() demande: DemandeCorrection;
  @Input() etapeCleTitle: string;
  @Input() canWrite: boolean;
  @Input() isArchived = false;
  @Input() projet: Projet;
  @Output() updateStatutCorrectionEmitter = new EventEmitter();

  readonly VALIDE = EnumStatutDemandeCorrectionTech.VALIDE;
  readonly REJETE = EnumStatutDemandeCorrectionTech.REJETE;
  readonly CORRIGE = EnumStatutDemandeCorrectionTech.CORRIGE;
}
