<h2 id="modal-title" class="modal__title">Correction des informations du siège</h2>
<div class="modal__content">
  <form [formGroup]="demandeRectificationForm">
    <div
      id="input-description"
      class="form-step__description ds-input-group"
      [class.ds-input-group--error]="
        !demandeRectificationForm.get('commentaire')?.valid && demandeRectificationForm.get('commentaire')?.touched
      "
    >
      <label for="description" class="ds-input-group__label"
        >Vos informations sont incorrectes ? Vous pouvez nous communiquer vos corrections juste ici *</label
      >
      <textarea
        id="demande_rectification_commentaire"
        name="commentaire"
        rows="5"
        col="0"
        maxlength="250"
        placeholder="Saisir votre texte ici."
        class="ds-input-group__textarea"
        formControlName="commentaire"
      ></textarea>
      <div
        class="form-input__length-hint"
        [class.form-input__length-hint--full]="demandeRectificationForm.get('commentaire')?.value?.length === 250"
      >
        <span>{{ demandeRectificationForm.get('commentaire')?.value?.length || 0 }}/250</span>
      </div>
      <!-- Icône et message d'erreur -->
      <ng-container
        id="error-description"
        *ngIf="!demandeRectificationForm.get('commentaire')?.valid && demandeRectificationForm.get('commentaire')?.touched"
      >
        <div class="ds-input-group__feedback">Merci de bien vouloir renseigner une description du projet</div>
      </ng-container>
    </div>
  </form>
</div>
<div id="modal-btn" class="modal__actions">
  <button class="ds-btn ds-btn--secondary" (click)="cancelModal()">Annuler</button>
  <button
    [disabled]="demandeRectificationForm.get('commentaire')?.invalid"
    class="ds-btn ds-btn--primary"
    (click)="submitDemandeRectification()"
    mat-dialog-close
  >
    Envoyer
  </button>
</div>
