<lib-demande-modification-container [demande]="demande" [etapeCleStatut]="etapeCleTitle" [isArchived]="isArchived">
  <ng-container auteur>
    Créée par <span *ngIf="demande.auteurs">{{ demande.auteurs[0].prenom }} {{ demande.auteurs[0].nom }}</span
    >, le {{ demande.dateCreation | date : "dd/MM/yyyy à HH'h'mm" }}
  </ng-container>
  <ng-container action-section>
    <div class="card__correction card card-correction" *ngIf="demande.correction">
      <p class="card__title">Réponse du porteur de projet</p>
      <p class="card__auteur">
        Créée
        <span *ngIf="demande?.correction?.auteur">par {{ demande.correction.auteur.prenom }} {{ demande.correction.auteur.nom }}, </span>le
        {{ demande.correction.dateCreation | date : "dd/MM/yyyy à HH'h'mm" }}
      </p>
      <p class="card__description">{{ demande.correction.description }}</p>
    </div>

    <div class="card__actions" *ngIf="canWrite">
      <button id="projet_btn_reject" type="button" class="ds-btn ds-btn--secondary" (click)="updateStatutCorrectionEmitter.emit(REJETE)">
        Rejeter et archiver
      </button>
      <ng-container *ngIf="demande.statut === CORRIGE">
        <button id="projet_btn_validate" type="button" class="ds-btn ds-btn--primary" (click)="updateStatutCorrectionEmitter.emit(VALIDE)">
          Valider et archiver
        </button>
      </ng-container>
    </div>
  </ng-container>
</lib-demande-modification-container>
