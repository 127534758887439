import { AbstractControl, FormArray, ValidatorFn } from '@angular/forms';

export function minSelectedCheckboxes(min: number = 1): ValidatorFn {
  return (formArray: AbstractControl) => {
    if (formArray instanceof FormArray) {
      const totalSelected = formArray.controls
        .map(control => control.value)
        .reduce((prev: any, next: any) => (next ? prev + next : prev), 0);

      return totalSelected >= min ? null : { required: true };
    }
    throw new Error('formArray is not an instance of FormArray');
  };
}
