import { Component } from '@angular/core';

@Component({
  selector: 'lib-firm-in-difficulty',
  templateUrl: './firm-in-difficulty.component.html',
  styleUrls: ['./firm-in-difficulty.component.scss'],
})
export class FirmInDifficultyComponent {
  protected readonly titleMessage = `L'entreprise est-elle considérée "en difficulté" ?`;
  protected readonly contentHeaderMessage = `Une entreprise est considérée en difficulté selon la règlementation européenne (règlement UE 651/2014 de la Commission du 17 Juin 2014) « lorsqu’il est pratiquement certain, qu’en l’absence d’intervention de l’État, elle sera contrainte de renoncer à son activité à court ou à moyen terme. ». Dans tous les cas, il convient donc de s’assurer qu’en l’absence de financement par Bpifrance ou de la Région, la pérennité de l’entreprise à court ou moyen terme est assurée par ses propres moyens ou via des interventions extérieures privées. Sont considérées comme étant « en difficulté » :`;
  protected readonly descriptionA = `Les entreprises ayant bénéficié d'une aide au sauvetage et n'ayant pas encore remboursé le prêt ou mis fin à la garantie, ou ayant bénéficié d'une aide à la restructuration et étant toujours soumises à un plan de restructuration ;`;
  protected readonly descriptionB = `Les entreprises concernées par un jugement d’ouverture de procédure collective (sauvegarde, redressement judiciaire, liquidation judiciaire), quels que soient leur âge et leur forme juridique ;`;
  protected readonly descriptionC = `Les entreprises non-PME, dont les associés ont une responsabilité limitée ou illimitée, et dont les pertes cumulées (augmentées des réserves) sont supérieures à la moitié du capital social souscrit (primes d'émissions incluses) ;`;
  protected readonly descriptionD = `Les PME âgées de plus de 3 ans (par référence à la date d’immatriculation), dont les associés ont une responsabilité limitée ou illimitée, et dont les pertes cumulées (augmentées des réserves) sont supérieures à la moitié du capital social souscrit (primes d'émissions incluses).`;
}
