import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { EnumRoleStructure } from '../../shared/enums/enum.roleStructure';

@Component({
  selector: 'lib-structure-role-select',
  templateUrl: './structure-role-select.component.html',
  styleUrls: ['./structure-role-select.component.scss'],
})
export class StructureRoleSelectComponent {
  @Input() form: FormGroup;
  roleStructure = EnumRoleStructure;
}
