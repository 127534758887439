import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { GrilleImpactHelperService } from '@shared-ui';
import { GrilleImpactsKPIsReponseInterface } from '../../shared/interfaces/grille-impacts-kpis-reponse.interface';
import { KpiQuestionInterface } from '../../shared/interfaces/kpi-question.interface';
import { KpiQuestionsSousAxe } from '../../shared/models/kpi-questions-sous-axe.model';
import { RefKPIDTO } from '../../shared/models/refKPIDTO.model';

type MapKpiQuestionsBySousAxe = Map<string, { sousAxe: KpiQuestionsSousAxe; questions: KpiQuestionInterface[] }>;

/**
 * composant contenant l'ensemble des formfield d'un kpi
 */
@Component({
  selector: 'lib-kpi-questions-form-field',
  templateUrl: './kpi-questions-form-field.component.html',
  styleUrls: ['./kpi-questions-form-field.component.scss'],
})
export class KpiQuestionsFormFieldComponent implements OnInit {
  readonly maxLengthMultiligne = 7500;

  @Input() kpi: RefKPIDTO; // = GrilleImpactRefKPIMock;
  // réponse provenant du back
  @Input() responses: GrilleImpactsKPIsReponseInterface[] | undefined;
  // formArray de l'ensemble des kpis pour une catégorie donné
  @Input() kpisFormArray: FormArray;
  @Input() savedKpisFormArrayValue: any;
  @Input() isUpdatableByUser: boolean;
  @Input() saveGrilleImpact: boolean;
  @Output() savedKpisFormArrayValueChange = new EventEmitter<any>(true);
  // formGroup du kpi lié à ce composant
  kpiQuestionsForm: UntypedFormGroup;

  questionsBySousAxe: MapKpiQuestionsBySousAxe;

  constructor(private formBuilder: UntypedFormBuilder, private grilleImpactHelperService: GrilleImpactHelperService) {}

  ngOnInit() {
    this.kpi.aide = this.replaceLineBreak(this.kpi.aide);
    this.grilleImpactHelperService.getGrillesImpactsData().subscribe(() => {
      this.createForm(this.kpi);
      if (!this.isUpdatableByUser) this.kpiQuestionsForm.disable();
    });
    this.createForm(this.kpi);
    if (!this.isUpdatableByUser) this.kpiQuestionsForm.disable();
  }

  get questions() {
    return this.kpiQuestionsForm.get('questions') as FormArray;
  }

  createForm(kpi: RefKPIDTO): void {
    this.questionsBySousAxe = this.mapKpiQuestionsBySousAxe(kpi);

    const kpiquestionsForm = this.createKpiForm(this.questionsBySousAxe);
    // regroupement des formGroup des questions du kpi dans un formgroup avec pour key la clefKPI
    const formGroupOfKpis = this.formBuilder.group({});
    formGroupOfKpis.addControl(kpi.clef, kpiquestionsForm);
    this.kpiQuestionsForm = formGroupOfKpis;
    this.kpisFormArray.push(this.kpiQuestionsForm);
    this.savedKpisFormArrayValue = this.kpisFormArray.value;
    this.savedKpisFormArrayValueChange.emit(this.savedKpisFormArrayValue);
  }

  mapKpiQuestionsBySousAxe(kpi: RefKPIDTO): MapKpiQuestionsBySousAxe {
    const questionsBySousAxe = new Map<string, { sousAxe: KpiQuestionsSousAxe; questions: KpiQuestionInterface[] }>();

    kpi.questions.sort((a, b) => a.ordre - b.ordre);

    kpi.questions.map(q => {
      const values: KpiQuestionInterface[] = new Array<KpiQuestionInterface>();
      const sousAxe = new KpiQuestionsSousAxe();
      q.aide = this.replaceLineBreak(q.aide);
      sousAxe.sousAxeAide = this.replaceLineBreak(q.sousAxeAide);
      sousAxe.sousAxeAideVisible = q.sousAxeAideVisible;
      sousAxe.sousAxeTitre = q.sousAxeTitre;
      sousAxe.sousAxeExistant = false;
      if (q.sousAxeTitre) {
        if (questionsBySousAxe.has(q.sousAxeTitre)) {
          questionsBySousAxe.get(q.sousAxeTitre)?.questions.push(q);
        } else {
          values.push(q);
          sousAxe.sousAxeExistant = true;
          questionsBySousAxe.set(q.sousAxeTitre, { sousAxe, questions: values });
        }
      } else {
        values.push(q);
        questionsBySousAxe.set(q.index + '', { sousAxe, questions: values });
      }
    });
    return questionsBySousAxe;
  }

  createKpiForm(questionsBySousAxe: MapKpiQuestionsBySousAxe): UntypedFormGroup {
    // form group des questions du kpi
    const sousAxeFormGroup = this.formBuilder.group({});
    questionsBySousAxe.forEach(value => {
      value.questions.map(q => {
        // form controle de chaque question
        const res = this.responses?.find(resp => resp.index === q.index);
        sousAxeFormGroup.addControl(q.index + '', new FormControl(res?.reponse, q.obligatoire ? Validators.required : null));
      });
    });
    return sousAxeFormGroup;
  }

  getQuestionControlName(key: string, j: number): string {
    return this.questionsBySousAxe.get(key)?.questions[j].index + '';
  }

  valueOrder(): number {
    return 0;
  }

  getsousAxeQuestionForm(sousAxeTitre: string) {
    return this.kpiQuestionsForm.get('questions')?.get(sousAxeTitre);
  }

  replaceLineBreak(aide: string): string {
    return aide?.replace(/§/g, '\n');
  }

  onkeypress($event: any): boolean {
    if ($event.key) {
      if (/[^\d]/.test($event.key)) {
        return false;
      }
    }
    return true;
  }
}
