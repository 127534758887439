export const StatutProcessFR30: string[] = [
  'Analyse opportunité terminée',
  'Attente dépôt complet',
  'Audition: Abandonné',
  'Audition: Ajourné',
  'Audition: Défavorable',
  'COEX: Abandonné',
  'COEX: Ajourné',
  'COEX: Défavorable',
  'COEX: Favorable',
  'Contrat Abandonné',
  'Contrat signé BPI et envoyé',
  'Contrat signé client',
  'COPIL: Abandonné',
  'COPIL: Ajourné',
  'COPIL: Défavorable',
  'COPIL: Favorable',
  'Décidé OAD',
  'Décidé PM',
  'Eligibilité: KO',
  'Eligibilité: OK',
  'En instruction',
  'Instruction autre opérateur',
  'Instruction finalisée',
  'Non financé',
  'Opportunité: Ajourné',
  'Opportunité: Défavorable',
  'Opportunité: Favorable',
  'Opportunité: Réorientation',
  'Réorientation en cours',
  'Réorientation refusée',
  'Réorienté autre opérateur',
  'Réorienté vers un autre dispositif',
  'Verrouillé',
];
