export enum EnumEnqueteConnaissance {
  RADIO = 'Radio',
  PRESSE = 'Presse',
  AFFICHAGE = 'Affichage',
  BOUCHE_A_OREILLES = 'Bouche à oreilles',
  BPIFRANCE = 'Bpifrance',
  INCUBATEUR = 'Incubateur',
  SATT = 'SATT',
  SITE_INTERNET = 'Site internet',
}

export enum EnumEnqueteConnaissanceTech {
  RADIO,
  PRESSE,
  AFFICHAGE,
  BOUCHE_A_OREILLES,
  BPIFRANCE,
  INCUBATEUR,
  SATT,
  SITE_INTERNET,
}
