import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments-candidat/environment';
import { EnumUserGroup, Evaluateur, NpsCreation, ShowToastrService, Utilisateur } from '@shared-ui';
import { BehaviorSubject, catchError, map, Observable, tap, throwError } from 'rxjs';
import { PxlOidcService } from './pxl-oidc.service';

@Injectable()
export class UserService {
  constructor(private httpClient: HttpClient, private showToastrService: ShowToastrService, private oidcSecurityService: PxlOidcService) {}

  private userSubject = new BehaviorSubject(null);
  private userSubjectForCGU = new BehaviorSubject(null);

  public setUserObservable(user: any): void {
    this.userSubject.next(user);
  }

  public getUserObservable(): Observable<Utilisateur> {
    return this.userSubject.asObservable();
  }

  public setUserObservableForCGU(user: any): void {
    this.userSubjectForCGU.next(user);
  }

  public getUserObservableForCGU(): Observable<any> {
    return this.userSubjectForCGU.asObservable();
  }

  getUserInfos(): Observable<HttpResponse<Utilisateur>> {
    return this.httpClient.get<Utilisateur>(environment.apiUrl + 'utilisateurs', { observe: 'response' });
  }

  public putCguUser(): Observable<Utilisateur> {
    return this.httpClient.post<Utilisateur>(environment.apiUrl + 'utilisateurs/cgu', {});
  }

  getUtilisateur(): Observable<Utilisateur> {
    return this.getUserInfos().pipe(
      map(response => response.body!),
      tap(user => {
        this.oidcSecurityService.getUserData().subscribe(userData => {
          user.firstName = userData?.firstName || user.prenom;
          user.lastName = userData?.lastName || user.nom;
          user.emailAddress = userData?.email || user.email;
        });
        this.setUserObservable(user);
        this.setUserObservableForCGU(user);
      }),
      catchError((err: HttpErrorResponse) => {
        this.showToastrService.checkCodeError(err?.error);
        return throwError(() => err);
      })
    );
  }

  updateEvaluateur(invitId: string, evaluateur: Evaluateur): Observable<HttpResponse<any>> {
    return this.httpClient.put<HttpResponse<any>>(environment.apiUrl + 'evaluateurs?invitation_id=' + invitId, JSON.stringify(evaluateur), {
      observe: 'response',
    });
  }

  createNps(npsCreation: NpsCreation): Observable<HttpResponse<Utilisateur>> {
    return this.httpClient.post<Utilisateur>(environment.apiUrl + 'nps', JSON.stringify(npsCreation), {
      observe: 'response',
    });
  }

  isUserInterne(): boolean {
    const user = this.userSubject.getValue() as Utilisateur;
    const groups = [
      EnumUserGroup.PXL_USERINNO_GROUPE,
      EnumUserGroup.PXL_USER_GROUPE,
      EnumUserGroup.PXL_ADMIN_GROUPE,
      EnumUserGroup.PXL_USERDAR_GROUPE,
    ];
    return groups.includes(user.groupe);
  }
}
