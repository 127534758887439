import { Component, Input } from '@angular/core';
import { RangeDate } from '../../shared/models/rangeDate.model';

@Component({
  selector: 'lib-date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.scss'],
})
export class DateRangePickerComponent {
  @Input() isDisabled = false;

  dateDebut: Date | null;
  dateFin: Date | null;
  isValid = true;

  checkValidity(): void {
    this.isValid = this.dateFin !== null && this.dateDebut !== null && this.dateFin > this.dateDebut;
  }

  getSelectedDate(): RangeDate {
    return {
      dateDebut: this.dateDebut ? this.formateDate(this.dateDebut) : null,
      dateFin: this.dateFin ? this.formateDate(this.dateFin) : null,
    } as RangeDate;
  }

  formateDate(date: Date): Date {
    return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
  }

  reset(): void {
    this.dateDebut = null;
    this.dateFin = null;
  }
}
