export enum EnumEtapeCleStatut {
  VALIDEE = 'VALIDEE',
  EN_COURS = 'EN_COURS',
  EN_ANALYSE = 'EN_ANALYSE',
  ENVOYEE = 'ENVOYEE',
}

export namespace EnumEtapeCleStatut {
  export function toString(statutEtape?: EnumEtapeCleStatut): string {
    switch (statutEtape) {
      case EnumEtapeCleStatut.VALIDEE:
        return 'Validée';
      case EnumEtapeCleStatut.EN_COURS:
        return 'En cours';
      case EnumEtapeCleStatut.ENVOYEE:
        return 'Envoyée';
      case EnumEtapeCleStatut.EN_ANALYSE:
        return 'En analyse';
      default:
        return '';
    }
  }
}
