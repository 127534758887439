<lib-expansion-panel [title]="'Demande de dérogation sur la date de publication'" [showValidIcon]="showValidIcon()">
  <div panel-content>
    <form class="form" [formGroup]="derogationForm" (ngSubmit)="onDemandeDerogationSubmitted()">
      <p>Pour information la communication se fait par défaut post contractualisation</p>
      <div class="w-100 d-flex">
        <div class="ds-checkbox-group modal__checkbox">
          <mat-checkbox formControlName="derogation" (change)="onCheckboxChange($event)" [disabled]="!canUserWrite">
            Je demande une dérogation sur la date de publication de ma fiche de communication publique.
          </mat-checkbox>
        </div>
      </div>
      <div class="w-100 d-flex mt-4" *ngIf="showDatePublication">
        <div
          id="date-picker-date_publication"
          class="ds-input-group ds-input-group--date w-100"
          [class.ds-input-group--error]="
            !derogationForm.controls['datePublication'].valid && derogationForm.controls['datePublication'].touched
          "
        >
          <label class="ds-input-group__label fw-bold" for="datePublication">
            Date à partir de laquelle la communication publique peut être faite<lib-asterisk></lib-asterisk>
          </label>
          <span class="ds-input-group__input">
            <input
              matInput
              [matDatepicker]="openDatePicker1"
              formControlName="datePublication"
              class="w-100 ds-input-group__input"
              placeholder="JJ/MM/AAAA"
            />
            <mat-datepicker-toggle matSuffix [for]="openDatePicker1"></mat-datepicker-toggle>
            <mat-datepicker #openDatePicker1></mat-datepicker>
          </span>
        </div>
      </div>
      <div class="w-100 d-flex" *ngIf="canUserWrite && !isValideFicheCom">
        <ng-container *ngIf="formSubmitted && derogationForm.touched && !derogationForm.valid">
          <div class="ds-input-group ds-input-group--error w-100">
            <div class="ds-input-group__feedback">Merci de renseigner les champs entourés en rouge.</div>
          </div>
        </ng-container>
        <div class="w-100 d-flex justify-content-end button-container" *ngIf="canUserWrite && !isValideFicheCom">
          <button type="button" class="ds-btn ds-btn--secondary me-2" (click)="cancel()">
            <span>Annuler</span>
          </button>
          <button type="submit" class="ds-btn ds-btn--primary">
            <span>Enregistrer</span>
          </button>
        </div>
      </div>
    </form>
  </div>
</lib-expansion-panel>
