import { formatDate } from '@angular/common';

import {
  MoveDocumentConfig,
  EnumEtapeCleStatut,
  EnumEtapeCleType,
  EnumRoleStructure,
  ProjetEtapeCle,
  SuiviProjet,
  projetDocumentConfig,
  avanceInitialeProjetDocumentConfig,
  structureDocumentConfig,
  avanceInitialeStructureDocumentConfig,
  EnumProjetTag,
  Utilisateur,
  PermissionUtils,
  EnumPermissionUtilisateur,
  Structure,
} from '@shared-ui';

function inferEtapeCleIndex(etapeCle: ProjetEtapeCle, etapesCle: readonly ProjetEtapeCle[]): number {
  const filteredSortedEtapesCle = etapesCle
    .filter(ec => ec.type === etapeCle.type)
    .sort((ec1, ec2) => new Date(ec1.dateVersementPrev).getTime() - new Date(ec2.dateVersementPrev).getTime());

  return filteredSortedEtapesCle.findIndex(ec => ec.id === etapeCle.id) + 1;
}

function isAvanceInitiale(etapeCle: ProjetEtapeCle): boolean {
  return [EnumEtapeCleType.AVANCE_INITIALE, EnumEtapeCleType.AVANCE_INITIALE_SANS_CP].includes(etapeCle?.type);
}

function buildEtapeCleTitle(
  etapeCle: ProjetEtapeCle | undefined,
  etapesCle: readonly ProjetEtapeCle[],
  dateFormat?: string
): string | undefined {
  if (!etapeCle) return undefined;

  const date = dateFormat ? formatDate(new Date(etapeCle.dateVersementReel || etapeCle.dateVersementPrev), dateFormat, 'fr-FR') : '';

  switch (etapeCle.type) {
    case EnumEtapeCleType.AVANCE_INITIALE:
    case EnumEtapeCleType.AVANCE_INITIALE_SANS_CP:
      return `${EnumEtapeCleType.toString(EnumEtapeCleType.AVANCE_INITIALE)} ${date}`;
    case EnumEtapeCleType.SOLDE:
      return `${EnumEtapeCleType.toString(EnumEtapeCleType.SOLDE)} ${date}`;
    default:
      return `${EnumEtapeCleType.toString(EnumEtapeCleType.ETAPE_CLE)} ${inferEtapeCleIndex(etapeCle, etapesCle)} ${date}`;
  }
}

function isEtapeCleEncours(etapeCle: ProjetEtapeCle) {
  return etapeCle?.statut === EnumEtapeCleStatut.EN_COURS;
}

function isEtapeCleNonValidee(etapeCle: ProjetEtapeCle) {
  return etapeCle?.statut !== EnumEtapeCleStatut.VALIDEE;
}

function sortStructuresByRole<T extends { role: string }>(structures: T[]) {
  return structures.sort((s1, s2) => {
    if (s1.role === EnumRoleStructure.CHEF_DE_FILE && s2.role !== EnumRoleStructure.CHEF_DE_FILE) {
      return -1;
    } else if (s1.role !== EnumRoleStructure.CHEF_DE_FILE && s2.role === EnumRoleStructure.CHEF_DE_FILE) {
      return 1;
    }
    return 0;
  });
}

function getMoveDocumentConfig(suiviProjet: SuiviProjet, etapeCle: ProjetEtapeCle): MoveDocumentConfig {
  const isAI = isAvanceInitiale(etapeCle);
  const projetDocuments = (isAI ? avanceInitialeProjetDocumentConfig : projetDocumentConfig).map(doc => ({
    nomDocument: doc.nomDocument,
    scope: doc.scope,
    mediaTypes: doc.mediaTypes,
  }));

  const activeStructures = suiviProjet.structures.filter(str => !str.closed);
  return {
    structures: activeStructures.map(str => ({ id: str.id, raisonSociale: str.raisonSociale })),
    documents: projetDocuments
      .concat(
        activeStructures.flatMap(str =>
          (isAI ? avanceInitialeStructureDocumentConfig : structureDocumentConfig).map(doc => ({
            nomDocument: doc.nomDocument,
            scope: doc.scope,
            mediaTypes: doc.mediaTypes,
            structureId: str.id,
          }))
        )
      )
      .sort((a, b) => (a.nomDocument > b.nomDocument ? 1 : -1)),
  };
}

function isProjetWithDemandeModificationInEtapeCleId(projet: SuiviProjet, etapeCleId: string): boolean {
  return projet.contextDemandeCorrections?.findIndex(demande => demande.etapeCleId === etapeCleId && !demande.structureId) !== -1;
}

function isStructureWithDemandeModificationInEtapeCleId(projet: SuiviProjet, etapeCleId: string, structureId: string): boolean {
  return (
    projet.contextDemandeCorrections?.findIndex(demande => demande.etapeCleId === etapeCleId && demande.structureId === structureId) !== -1
  );
}

function checkSuiviProjetTagForUpdate(projet: SuiviProjet): boolean {
  return projet.tag.toString() === EnumProjetTag[EnumProjetTag.A_CORRIGER];
}

function canUserUpdateSuiviProjet(utilisateur: Utilisateur, projet: SuiviProjet, etapeCle: ProjetEtapeCle): boolean {
  if (isEtapeCleEncours(etapeCle)) {
    return PermissionUtils.hasGlobalOrProjetPermission(utilisateur, EnumPermissionUtilisateur.PROJET_WRITE, projet);
  }
  return (
    checkSuiviProjetTagForUpdate(projet) &&
    PermissionUtils.hasGlobalOrProjetPermission(utilisateur, EnumPermissionUtilisateur.PROJET_WRITE, projet) &&
    isProjetWithDemandeModificationInEtapeCleId(projet, etapeCle.id)
  );
}

function canUserUpdateSuiviStructure(
  utilisateur: Utilisateur,
  projet: SuiviProjet,
  structure: Structure,
  etapeCle: ProjetEtapeCle
): boolean {
  if (isEtapeCleEncours(etapeCle)) {
    return PermissionUtils.hasGlobalOrProjetPermission(utilisateur, EnumPermissionUtilisateur.PROJET_WRITE, projet);
  }
  return (
    checkSuiviProjetTagForUpdate(projet) &&
    structure?.contacts?.some(contact => !contact.inactif && contact.matricule === utilisateur.matricule) &&
    isStructureWithDemandeModificationInEtapeCleId(projet, etapeCle.id, structure.id)
  );
}

export {
  inferEtapeCleIndex,
  buildEtapeCleTitle,
  isAvanceInitiale,
  isEtapeCleEncours,
  isEtapeCleNonValidee,
  sortStructuresByRole,
  getMoveDocumentConfig,
  canUserUpdateSuiviProjet,
  canUserUpdateSuiviStructure,
};
