<ng-container *ngIf="syntheseStructure && currentProcedure">
  <p class="fw-bold mb-2">Contrat</p>
  <ng-container *ngIf="syntheseStructure?.contratDocument">
    <div class="border-grid">
      <div class="py-1">
        <lib-document-light
          *ngIf="syntheseStructure.contratDocument"
          [document]="syntheseStructure.contratDocument"
          [signatureDate]="currentProcedure.dateFin"
        ></lib-document-light>
      </div>
    </div>
  </ng-container>

  <p class="fw-bold mb-2">Mandats</p>
  <ng-container *ngIf="syntheseStructure.mandatDocuments">
    <div class="border-grid">
      <div *ngFor="let mandat of syntheseStructure.mandatDocuments" class="py-1">
        <lib-document-light [document]="mandat" [signatureDate]="currentProcedure.dateFin"></lib-document-light>
      </div>
    </div>
  </ng-container>
</ng-container>
