import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NpsModalComponent } from './nps-modal.component';
import { MaterialModule } from '@shared-candidat/modules/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [NpsModalComponent],
  imports: [CommonModule, MaterialModule, FormsModule, ReactiveFormsModule],
})
export class NpsModalModule {}
