export enum EnumRoleContact {
  REPRESENTANT_LEGAL = 'Représentant.e Légal.e' as any,
  RESPONSABLE_PROJET = 'Responsable projet' as any,
  RESPONSABLE_ADMINISTRATIF = 'Responsable administratif et financier projet' as any,
  AUTRE = 'autre' as any,
}

export namespace EnumRoleContact {
  export function toTagText(role: EnumRoleContact): string {
    switch (role) {
      case (EnumRoleContact as any)[EnumRoleContact.RESPONSABLE_PROJET.toString()]:
        return 'Responsable projet';
      case (EnumRoleContact as any)[EnumRoleContact.RESPONSABLE_ADMINISTRATIF.toString()]:
        return 'Responsable administratif et financier';
      default:
        return '';
    }
  }
}
