<form [formGroup]="form">
  <div class="ds-input-group" [class.ds-input-group--error]="form.get('siret')?.invalid && form.get('siret')?.touched">
    <label for="siret" class="ds-input-group__label">
      Siret du siège
      <lib-asterisk></lib-asterisk>
    </label>
    <span class="ds-input-group__input d-flex align-items-center">
      <input
        libSiret
        id="siret"
        name="siret"
        formControlName="siret"
        type="text"
        placeholder="000 000 000 0000"
        class="input_siret ds-input-group__input"
      />
      <button class="ds-btn ds-btn--primary search-btn" (click)="search()" [disabled]="form.get('siret')!.disabled">Rechercher</button>
    </span>
    <!-- Icône et message d'erreur -->
    <ng-container id="error-siret" *ngIf="form.get('siret')!.invalid && form.get('siret')!.touched">
      <div class="ds-input-group__feedback">
        Merci de bien vouloir renseigner un SIRET valide ou de sélectionner la raison de son absence.
      </div>
    </ng-container>
  </div>

  <!-- No Siret -->
  <div class="no_siret">
    <input
      id="en-cours-imm"
      type="radio"
      name="motifNonRattachement"
      formControlName="motifNonRattachement"
      value="IMMATRICULATION_EN_COURS"
      (click)="onMotifChange($event)"
    />
    <label for="en-cours-imm" class="ds-input-group__label immatriculation-label"> Structure en cours d'immatriculation au RCS </label>
  </div>
  <div class="no_siret">
    <input
      id="non-imm"
      type="radio"
      name="motifNonRattachement"
      formControlName="motifNonRattachement"
      value="NON_IMMATRICULE"
      (click)="onMotifChange($event)"
    />
    <label for="non-imm" class="ds-input-group__label immatriculation-label"> Structure non immatriculée au RCS </label>
  </div>
  <ng-container *ngIf="displayCreationEnCoursOption">
    <div class="no_siret">
      <input
        id="en-cours-crea"
        type="radio"
        name="motifNonRattachement"
        formControlName="motifNonRattachement"
        value="EN_COURS_DE_CREATION"
        (click)="onMotifChange($event)"
      />
      <label for="en-cours-crea" class="ds-input-group__label immatriculation-label"> Structure en cours de création </label>
    </div>
  </ng-container>
</form>

<ng-container *ngIf="structures">
  <div class="my-3">
    <span class="fw-bold" *ngIf="structures?.length">
      Nous avons trouvé un ou plusieurs établissements qui correspondent à votre numéro de SIRET, merci d'en sélectionner un.
    </span>
  </div>
  <div *ngFor="let siege of structures; let i = index" class="tuile-info-siege">
    <label [for]="siege.siret + '__' + i" class="siege-label" [ngClass]="{ 'checked-label': selectedStructure === siege }">
      <div class="label-text-container">
        <span class="fw-bold">{{ siege.raisonSociale }}</span>
        <span>SIRET : {{ siege.siret }}</span>
      </div>
      <input
        type="radio"
        [id]="siege.siret + '__' + i"
        name="siege-select"
        [value]="siege"
        [checked]="selectedStructure === siege"
        (change)="selectStructure(siege)"
      />
    </label>
  </div>
</ng-container>

<div *ngIf="structures && structures.length === 0" class="d-flex flex-wrap justify-content-center">
  <div class="d-flex justify-content-center w-100">
    <img src="assets/images/no-result-siege.svg" alt="Aucun siège trouvé" />
  </div>
  <p class="w-100">
    Aucun établissement ne semble correspondre à votre recherche.
    <span class="d-block fw-bold"> Veuillez vérifier votre numéro de SIRET ou entrer vos informations manuellement. </span>
  </p>
</div>

<div *ngIf="maStructureNotListedButtonVisible" class="d-flex justify-content-end mt-3">
  <button type="button" class="ds-btn ds-btn--tertiary no-entreprise-btn" (click)="onMaStructureNotListedClick()">
    Mon entreprise n’est pas dans la liste
  </button>
</div>
