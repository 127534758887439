import { EnumInstructionStatus } from '../enums/enum.instruction-status';
import { InstructionProjetInterface } from '../interfaces/instruction-projet.interface';
import { SyntheseCompletudeSecondNiveau } from './synthese-completude-second-niveau.model';
import { CompletudeSecondNiveau } from './completude-second-niveau.model';
import { SyntheseControleSecondNiveau } from './synthese-controle-second-niveau.model';
import { SyntheseInstruction } from './synthese-instruction.model';

export class InstructionProjet implements InstructionProjetInterface {
  statut: EnumInstructionStatus;
  syntheseCompletudeSecondNiveau: SyntheseCompletudeSecondNiveau;
  syntheseControleSecondNiveau: SyntheseControleSecondNiveau = new SyntheseControleSecondNiveau();
  completudeSecondNiveau: CompletudeSecondNiveau;
  synthese: SyntheseInstruction;
}
