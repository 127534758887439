import { EnumProjetEtape } from './enumProjetEtape';
import { enumKeys } from "../../utils/enum-utils";

export enum EnumTypeWorkflow {
  WKF1 = 'WKF1',
  WKF2 = 'WKF2'
}

export namespace EnumTypeWorkflow {

 export function toString(type: EnumTypeWorkflow) {
   switch (type) {
     case EnumTypeWorkflow.WKF1: return 'WKF1 : Pré-dépôt + Dépôt';
     case EnumTypeWorkflow.WKF2: return 'WKF2 : Dépôt unique';
   }
 }

  export function getFirstEtape(typeWkf: EnumTypeWorkflow): EnumProjetEtape {
    return typeWkf === EnumTypeWorkflow.WKF2 ? EnumProjetEtape.DEPOT : EnumProjetEtape.PRE_DEPOT;
  }

  export function all(): EnumTypeWorkflow[] {
    return enumKeys(EnumTypeWorkflow) as unknown as EnumTypeWorkflow[];
  }
}
