import { Injectable } from '@angular/core';
import { AbstractControl, ValidatorFn } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class PxlValidators {
  public static isNumberValidator(): ValidatorFn {
    return (control: AbstractControl) => {
      const isValid = /^\d+$/.test(control.value);
      return isValid ? null : { notANumber: { value: control.value } };
    };
  }

  public static mustBeChecked(): ValidatorFn {
    return (control: AbstractControl) => {
      return control.value ? null : { mustBeCheckedError: { value: control.value } };
    };
  }
}
