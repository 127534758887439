import { enumKeys } from '../../utils/enum-utils';

export enum EnumTypePartenaire {
  INDIV = 'INDIV',
  MULTI = 'MULTI',
}

export namespace EnumTypePartenaire {
  export function toString(type: EnumTypePartenaire): string {
    switch (type) {
      case EnumTypePartenaire.INDIV:
        return 'Indiv';
      case EnumTypePartenaire.MULTI:
        return 'Multi';
    }
  }

  export function all(): EnumTypePartenaire[] {
    return enumKeys(EnumTypePartenaire) as unknown as EnumTypePartenaire[];
  }
}
