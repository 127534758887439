export const ROW_HEADER_COLUMN_DEF = 'ROW_HEADER_COLUMN_DEF';

export enum RowType {
  EDITABLE = 'EDITABLE',
  TITLE = 'TITLE',
  RESULT = 'RESULT',
  ISOLATED_EDITABLE = 'ISOLATED_EDITABLE',
  MIXED = 'MIXED',
}

export class CellNumberValue {
  value: number;
  constructor(input: string | number | undefined) {
    if (!Number.isNaN(Number(input))) {
      this.value = Number(input);
    } else {
      this.value = 0;
    }
  }
}

export interface Cell {
  value: string | number | undefined;
  isEditable: boolean;
  rowDef: string;
  colDef: string;
  sectionLabel: string;
  rowType: RowType;
  tooltip?: string;
  isRowHeader?: boolean;
}

export class RowBase {
  sectionLabel: string;
  rowType: RowType;
  order: number;
  headerRowLabel: string;
  style?: string;
  rowDef?: string;
}

export class Row extends RowBase {
  [key: string]: Cell | string | number | undefined;
}

export enum IsolatedEditableRow_ComptesResultats {
  MP = 'Montant du projet',
  MPCE = "Montant du projet passé en charges d'exploitation",
}
