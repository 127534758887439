import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { EnumGenre, EnumProjetEtape, GenreItem } from '@shared-ui';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const dsLib: any;

@Component({
  selector: 'lib-fiche-demande-aide-signature-identite-form',
  templateUrl: './fiche-demande-aide-signature-identite-form.component.html',
  styleUrls: ['./fiche-demande-aide-signature-identite-form.component.scss'],
})
export class FicheDemandeAideSignatureIdentiteFormComponent implements OnInit {
  @Input() identiteForm: FormGroup;
  @Input() etapeProjet: EnumProjetEtape;
  @Input() title: string;
  @Input() componentId: string;

  isDisplayWarning = false;
  warningMessage = '';
  telephone = {};

  readonly WARNING_MESSAGE_PIECE_IDENTITE =
    'Merci de fournir la pièce d\'identité du signataire déclaré au niveau de la section "Document(s) de la structure"';
  readonly WARNING_MESSAGE_DELEGATION =
    'Merci de fournir la délégation de signature du signataire déclaré au niveau de la section "Document(s) de la structure"';
  readonly WARNING_MESSAGE_DELEGATION_ET_PIECE_IDENTITE =
    'Merci de fournir la pièce d\'identité et la délégation de signature du signataire déclaré au niveau de la section "Document(s) de la structure"';

  genres: Array<GenreItem> = EnumGenre.all().map(item => ({ valeur: item, checked: false }));

  protected readonly EnumGenre = EnumGenre;

  ngOnInit(): void {
    if (this.etapeProjet && this.identiteForm?.valid) {
      const valueRepresentantLegal = this.identiteForm?.get('representantLegal')?.value;
      this.checkProjetEtape(valueRepresentantLegal);
    }
    this.setGenreFormFieldValue();
    this.telephone = new dsLib.InputPhone(this.componentId + '-telephone');
  }

  selectGenre(genre: GenreItem): void {
    const itemIndex = this.genres.findIndex(item => item.valeur === genre.valeur);
    if (!genre.checked) {
      this.genres.forEach(gen => {
        gen.checked = false;
      });
      this.genres[itemIndex].checked = true;
      this.identiteForm.controls['civilite'].setValue(genre.valeur);
    } else {
      this.genres[itemIndex].checked = false;
      this.identiteForm.controls['civilite'].setValue(null);
    }
  }

  onBlur(): void {
    const inputPhone = document.getElementById('input-telephone');
    if (inputPhone?.classList.contains('ds-input-group--error')) {
      this.identiteForm.controls['telephone'].setErrors({ incorrect: true });
    }
  }

  checkProjetEtape(value: boolean): void {
    this.isDisplayWarning = false;
    if (value && this.etapeProjet !== EnumProjetEtape.PRE_DEPOT) {
      this.isDisplayWarning = true;
      this.warningMessage = this.WARNING_MESSAGE_PIECE_IDENTITE;
    } else if (!value && this.etapeProjet === EnumProjetEtape.PRE_DEPOT) {
      this.isDisplayWarning = true;
      this.warningMessage = this.WARNING_MESSAGE_DELEGATION;
    } else if (!value && this.etapeProjet !== EnumProjetEtape.PRE_DEPOT) {
      this.isDisplayWarning = true;
      this.warningMessage = this.WARNING_MESSAGE_DELEGATION_ET_PIECE_IDENTITE;
    }
  }

  setGenreFormFieldValue(): void {
    const savedGenre = this.identiteForm.get('civilite')?.value;
    if (savedGenre) {
      const itemIndex = this.genres.findIndex(item => item.valeur === savedGenre);
      this.genres.forEach(genre => {
        genre.checked = false;
      });
      this.genres[itemIndex].checked = true;
    }
  }
}
