import { enumKeys } from '../../utils/enum-utils';

export enum EnumMotifNonRattachement {
  IMMATRICULATION_EN_COURS = 'IMMATRICULATION_EN_COURS',
  NON_IMMATRICULE = 'NON_IMMATRICULE',
  EN_COURS_DE_CREATION = 'EN_COURS_DE_CREATION',
}

export namespace EnumMotifNonRattachement {
  export function toString(motif?: EnumMotifNonRattachement): string {
    switch (motif) {
      case EnumMotifNonRattachement.IMMATRICULATION_EN_COURS:
        return "Structure en cours d'immatriculation au RCS";
      case EnumMotifNonRattachement.NON_IMMATRICULE:
        return 'Structure non immatriculée au RCS';
      case EnumMotifNonRattachement.EN_COURS_DE_CREATION:
        return 'Structure en cours de création';
      default:
        return '';
    }
  }

  export function all(): EnumMotifNonRattachement[] {
    return enumKeys(EnumMotifNonRattachement) as unknown as EnumMotifNonRattachement[];
  }
}
