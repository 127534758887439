export enum EnumTypeVoie {
  'ALL' = 'ALLEE',
  'AV' = 'AVENUE',
  'BD' = 'BOULEVARD',
  'C.C' = 'CTRE COMME',
  'C.I' = 'CTRE INDUS',
  'CAR' = 'CARREFOUR',
  'CHE' = 'CHEMIN',
  'CHS' = 'CHAUSSEE',
  'CIT' = 'CITE',
  'CLO' = 'CLOS',
  'CRS' = 'COURS',
  'ESP' = 'ESPLANADE',
  'FG' = 'FAUBOURG',
  'G.M' = 'GAL.MARCHA',
  'GR' = 'GRANDE RUE',
  'ILE' = 'ILE',
  'IMP' = 'IMPASSE',
  'L-D' = 'LIEU-DIT',
  'LOT' = 'LOTISSEMEN',
  'MAI' = 'MAIL',
  'PAR' = 'PARC',
  'PAS' = 'PASSAGE',
  'PL' = 'PLACE',
  'POR' = 'PORT',
  'PRO' = 'PROMENADE',
  'PTE' = 'PORTE',
  'QU' = 'QUAI',
  'QUA' = 'QUARTIER',
  'RES' = 'RESIDENCE',
  'RLE' = 'RUELLE',
  'RN' = 'ROUTE NALE',
  'ROC' = 'ROCADE',
  'RPT' = 'ROND-POINT',
  'RTE' = 'ROUTE',
  'RUE' = 'RUE',
  'SEN' = 'SENTIER',
  'SQ' = 'SQUARE',
  'TRA' = 'TRAVERSE',
  'VGE' = 'VILLAGE',
  'VLA' = 'VILLA',
  'VOI' = 'VOIE',
  'ZA' = 'Z.AMENAGEM',
  'ZAC' = 'Z.AME.CONC',
  'ZI' = 'ZONE INDUS',
  'ZUP' = 'Z.URB.PRIO',
}
