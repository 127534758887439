import { Inject, Injectable } from '@angular/core';
import { EnumStatutSignature, Environment, ProcedureSignatureStructure } from '@shared-ui';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class ContractualisationSharedService {
  constructor(@Inject('environment') private environment: Environment, private httpClient: HttpClient) {}

  upsertStatutSignatureSignataire(isAdmin: boolean, structureId: string, statutSignature: EnumStatutSignature) {
    const baseUrl = isAdmin ? this.environment.apiAdminUrl : this.environment.apiUrl;
    return this.httpClient.patch<ProcedureSignatureStructure>( `${baseUrl}structures/${structureId}/contractualisation/signature`,
      { statutSignature: statutSignature },
      {
        observe: 'response',
      }
    );
  }
}
