<lib-expansion-panel [title]="'Contact Presse'" [showValidIcon]="showValidIcon()" [showMandatoryAsterisk]="true">
  <div panel-content>
    <form class="form" [formGroup]="contactPresseForm" (ngSubmit)="onContactPresseSubmitted()">
      <div class="d-flex flex-wrap">
        <div
          class="ds-input-group w-100"
          [class.ds-input-group--error]="!contactPresseForm.controls['nomPrenom'].valid && contactPresseForm.controls['nomPrenom'].touched"
        >
          <label for="nom-prenom" class="ds-input-group__label fw-bold">
            Nom & prénom
            <lib-asterisk></lib-asterisk>
          </label>
          <span class="ds-input-group__input">
            <input
              id="nom-prenom"
              name="nomPrenom"
              type="text"
              [maxlength]="maxLengthNomPrenom"
              class="ds-input-group__input"
              placeholder="Nom et prénom"
              formControlName="nomPrenom"
              #nomPrenomInput
            />
          </span>
          <!-- Icône et message d'erreur -->
          <ng-container
            id="error-nomPrenom"
            *ngIf="!contactPresseForm.controls['nomPrenom'].valid && contactPresseForm.controls['nomPrenom'].touched"
          >
            <div class="ds-input-group__feedback">Merci de bien vouloir renseigner une valeur</div>
          </ng-container>
        </div>

        <!-- telephone -->
        <div
          id="input-telephone"
          #inputTelephone
          class="ds-input-group ds-input-group--phone w-100"
          [class.ds-input-group--error]="!contactPresseForm.controls['telephone'].valid && contactPresseForm.controls['telephone'].touched"
        >
          <label id="id_input-telephone-label" for="telephone" class="ds-input-group__label fw-bold">
            Téléphone<lib-asterisk></lib-asterisk>
          </label>
          <span class="ds-input-group__input">
            <input
              (blur)="onBlur()"
              id="telephone"
              name="telephone"
              type="tel"
              maxlength="20"
              formControlName="telephone"
              class="ds-input-group__input"
            />
          </span>
          <!-- Icône et message d'erreur -->
          <ng-container
            id="error-telephone"
            *ngIf="
              inputTelephone.classList.contains('ds-input-group--error') ||
              (contactPresseForm.controls['telephone'].touched && contactPresseForm.controls['telephone'].value === '')
            "
          >
            <div id="id_error-telephone-msg" class="error-telephone-msg ds-input-group__feedback">
              Merci de bien vouloir renseigner une valeur
            </div>
          </ng-container>
        </div>

        <!-- mail -->
        <div
          id="input-email"
          class="w-100 ds-input-group"
          [class.ds-input-group--error]="!contactPresseForm.controls['email'].valid && contactPresseForm.controls['email'].touched"
        >
          <label for="email" class="ds-input-group__label fw-bold">Mail<lib-asterisk></lib-asterisk></label>
          <span class="ds-input-group__input">
            <input
              id="email"
              name="email"
              type="text"
              [maxlength]="maxLengthEmail"
              pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$"
              placeholder="contact@candidat.com"
              class="input_email ds-input-group__input"
              formControlName="email"
            />
          </span>
          <!-- Icône et message d'erreur -->
          <ng-container id="error-email" *ngIf="!contactPresseForm.controls['email'].valid && contactPresseForm.controls['email'].touched">
            <div class="ds-input-group__feedback">Merci de bien vouloir renseigner une valeur</div>
          </ng-container>
        </div>

        <div class="w-100 d-flex" *ngIf="canUserWrite && !isValideFicheCom">
          <ng-container *ngIf="formSubmitted && contactPresseForm.touched && !contactPresseForm.valid">
            <div class="ds-input-group ds-input-group--error w-100">
              <div class="ds-input-group__feedback">Merci de renseigner les champs entourés en rouge.</div>
            </div>
          </ng-container>

          <div class="w-100 d-flex justify-content-end button-container" *ngIf="canUserWrite">
            <button type="button" class="ds-btn ds-btn--secondary me-2" (click)="cancel()">
              <span>Annuler</span>
            </button>
            <button type="submit" class="ds-btn ds-btn--primary">
              <span>Enregistrer</span>
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</lib-expansion-panel>
