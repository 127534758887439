<section class="accordion-section">
  <mat-accordion>
    <mat-expansion-panel class="mat-elevation-z0">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div class="title-container">
            <lib-pxl-icon class="icon" [icon]="'icon-action-info'"></lib-pxl-icon>
            <div>{{ titleMessage }}</div>
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="content-message">{{ contentHeaderMessage }}</div>
      <ul>
        <li class="content-message">{{ descriptionA }}</li>
        <li class="content-message">{{ descriptionB }}</li>
        <li class="content-message">{{ descriptionC }}</li>
        <li class="content-message">{{ descriptionD }}</li>
      </ul>
    </mat-expansion-panel>
  </mat-accordion>
</section>
