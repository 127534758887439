import { AfterViewInit, Component, EventEmitter, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { MAT_MENU_DEFAULT_OPTIONS, MatMenuTrigger } from '@angular/material/menu';
import { DateRangePickerComponent, DateTimeUtils, RangeDatesCriteria } from '@shared-ui';

@Component({
  selector: 'lib-date-filters',
  templateUrl: './date-filters.component.html',
  styleUrls: ['./date-filters.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: MAT_MENU_DEFAULT_OPTIONS,
      useValue: {
        overlayPanelClass: 'date-filters-menu',
      },
    },
  ],
})
export class DateFiltersComponent implements AfterViewInit {
  readonly DATE_RELEVE = 'date_de_releve';
  readonly COPIE_DATE_DE_PASSAGE = 'copil_date_de_passage_en_comite';

  @ViewChild('dateReleveComponent') dateReleveComponent: DateRangePickerComponent;
  @ViewChild('copilDateDePassageComponent') copilDateDePassageComponent: DateRangePickerComponent;
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
  isOpened = false;

  @Output() filtersChanged: EventEmitter<RangeDatesCriteria> = new EventEmitter<RangeDatesCriteria>();

  ngAfterViewInit(): void {
    this.trigger.menuOpened.subscribe(() => {
      this.isOpened = true;
    });
  }

  applyFilters(): void {
    const rangeDatesCriteriaMap: RangeDatesCriteria = {};
    rangeDatesCriteriaMap[this.DATE_RELEVE] =
      this.dateReleveComponent.dateDebut && this.dateReleveComponent.dateFin
        ? {
            start: DateTimeUtils.formatToDate(this.dateReleveComponent.dateDebut)!,
            end: DateTimeUtils.formatToDate(this.dateReleveComponent.dateFin)!,
          }
        : null;
    rangeDatesCriteriaMap[this.COPIE_DATE_DE_PASSAGE] =
      this.copilDateDePassageComponent.dateDebut && this.copilDateDePassageComponent.dateFin
        ? {
            start: DateTimeUtils.formatToDate(this.copilDateDePassageComponent.dateDebut)!,
            end: DateTimeUtils.formatToDate(this.copilDateDePassageComponent.dateFin)!,
          }
        : null;
    this.filtersChanged.emit(rangeDatesCriteriaMap);
    if (this.isOpened) {
      this.trigger.closeMenu();
    }
  }

  resetFilters(): void {
    this.dateReleveComponent?.reset();
    this.copilDateDePassageComponent?.reset();
  }

  onMenuClosed(): void {
    if (this.isOpened) {
      this.isOpened = false;
      this.applyFilters();
    }
  }
}
