import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Kpi } from '../models/kpi.model';
import { Environment } from '../models/environment.model';
import { KpisByCategorie } from '../models/kpis-by-categorie.model';
import { GrilleImpacts } from '../models/grille-impacts.model';
import { EnumScope } from '../enums/enum.scope';
import { GrilleImpactStatutModel } from '../models/grille-impact-statut.model';
import { GrilleImpactStatutInterface } from '../interfaces/grilleImpactStatut.interface';

@Injectable({
  providedIn: 'root',
})
export class GrilleImpactHttpService {
  constructor(@Inject('environment') private environment: Environment, public httpClient: HttpClient) {}

  getKpis(): Observable<HttpResponse<Kpi[]>> {
    return this.httpClient.get<Kpi[]>(this.environment.apiUrl + 'kpis', {
      observe: 'response',
    });
  }

  getKpisByCategorie(id: string, type: string): Observable<HttpResponse<KpisByCategorie[]>> {
    return this.httpClient.get<KpisByCategorie[]>(this.environment.apiUrl + `kpis/aaps/${id}?type=${type}`, { observe: 'response' });
  }

  getGrilleImpactByFilters(projetId: string, structureId: string, scope: EnumScope): Observable<HttpResponse<GrilleImpacts[]>> {
    const params: { projetId?: string; structureId?: string; scope?: string } = {};
    if (projetId !== null) {
      params.projetId = projetId;
    }
    if (structureId !== null) {
      params.structureId = structureId;
    }
    if (scope !== null) {
      params.scope = scope.toString().toUpperCase();
    }
    return this.httpClient.get<GrilleImpacts[]>(this.environment.apiUrl + `grille-impacts`, { params, observe: 'response' });
  }

  saveGrilleImpacts(grilleImpact: GrilleImpacts): Observable<HttpResponse<GrilleImpacts>> {
    return this.httpClient.post<GrilleImpacts>(this.environment.apiUrl + `grille-impacts`, JSON.stringify(grilleImpact), {
      observe: 'response',
    });
  }

  getProjectAndStructuresKpisStatus(projetId: string): Observable<GrilleImpactStatutModel> {
    return this.httpClient.get<GrilleImpactStatutModel>(this.environment.apiUrl + 'grille-impacts/projets/' + projetId + '/statuts');
  }

  getStatusGrilleImpactByProjetId(id: string): Observable<HttpResponse<GrilleImpactStatutInterface>> {
    return this.httpClient.get<GrilleImpactStatutInterface>(this.environment.apiUrl + `grille-impacts/projet/${id}/statut`, {
      observe: 'response',
    });
  }
}
