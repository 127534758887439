import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CsvValidationError } from '../../shared/models/depenses-projet-import-result.model';

export interface UploadErrorDisplayKey {
  columnName: string;
  columnLabel: string;
}

export type UploadErrorParent = {
  key: UploadErrorDisplayKey;
  errors: CsvValidationError[];
};

@Component({
  selector: 'lib-depense-projet-upload-error-modal',
  templateUrl: './depense-projet-upload-error-modal.component.html',
  styleUrl: './depense-projet-upload-error-modal.component.scss',
})
export class DepenseProjetUploadErrorModalComponent implements OnInit {
  @Output() responseModal: EventEmitter<any> = new EventEmitter();

  uploadErrors: UploadErrorParent[] = [];

  constructor(public dialogRef: MatDialogRef<DepenseProjetUploadErrorModalComponent>, @Inject(MAT_DIALOG_DATA) public modalData: any) {}

  ngOnInit(): void {
    this.uploadErrors = this.convertToUploadErrorParents(this.modalData.csvValidationErrors);
  }

  convertToUploadErrorParents(csvValidationErrors: CsvValidationError[]): UploadErrorParent[] {
    const groupedErrors = csvValidationErrors.reduce((acc, error) => {
      if (!acc[error.columnName]) {
        acc[error.columnName] = [];
      }
      acc[error.columnName].push(error);
      return acc;
    }, {} as Record<string, CsvValidationError[]>);

    const uploadErrorParents: UploadErrorParent[] = Object.keys(groupedErrors).map(columnName => ({
      key: { columnName, columnLabel: groupedErrors[columnName][0].columnLabel },
      errors: groupedErrors[columnName].sort((a, b) => a.rowNumber - b.rowNumber),
    }));

    return uploadErrorParents.sort((a, b) => a.key.columnName.localeCompare(b.key.columnName));
  }
}
