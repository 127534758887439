import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, shareReplay } from 'rxjs';
import { Environment, Utilisateur } from '@shared-ui';
import { HttpClient, HttpResponse } from '@angular/common/http';

@Injectable()
export abstract class UtilisateurAbstractService {
  protected userSubject: BehaviorSubject<any> = new BehaviorSubject(null);

  // CGUs
  protected userSubjectForCGU: BehaviorSubject<any> = new BehaviorSubject(null);

  protected constructor(protected environment: Environment, protected httpClient: HttpClient) {}

  getUtilisateurObservable(): Observable<Utilisateur> {
    return this.userSubject.asObservable();
  }

  public setUserObservable(user: any): void {
    this.userSubject.next(user);
  }

  public setUserObservableForCGU(user: Utilisateur): void {
    this.userSubjectForCGU.next(user);
  }

  public getUserObservableForCGU(): Observable<Utilisateur> {
    return this.userSubjectForCGU.asObservable();
  }

  getUserInfos(): Observable<HttpResponse<Utilisateur>> {
    return this.httpClient
      .get<Utilisateur>(this.environment.apiUrl + 'utilisateurs', { observe: 'response' })
      .pipe(shareReplay({ refCount: true }));
  }
}
