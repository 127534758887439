<div class="filter-container" [ngClass]="{ 'filter-container-disabled': isDisabled, 'filter-container-error': !isValid }">
  <mat-date-range-input [rangePicker]="rangePicker" [separator]="'-'" class="filter-input" [disabled]="isDisabled">
    <input id="dateDebut" [(ngModel)]="dateDebut" placeholder="Date début" matStartDate (keyup)="checkValidity()" />
    <input id="dateFin" [(ngModel)]="dateFin" placeholder="Date fin" matEndDate (keyup)="checkValidity()" (dateChange)="checkValidity()" />
  </mat-date-range-input>
  <mat-date-range-picker #rangePicker></mat-date-range-picker>
  <div class="icon-container">
    <mat-datepicker-toggle matSuffix [for]="rangePicker"></mat-datepicker-toggle>
  </div>
</div>
