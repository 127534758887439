import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { EnumProjetEtape } from '@shared-ui';

@Component({
  selector: 'lib-fiche-demande-aide-signature-electronique',
  templateUrl: './fiche-demande-aide-signature-electronique.component.html',
  styleUrls: ['./fiche-demande-aide-signature-electronique.component.scss'],
})
export class FicheDemandeAideSignatureElectroniqueComponent implements OnInit {
  @Input() signatureElectroniqueForm: FormGroup;
  @Input() identiteFormName: string;
  @Input() etapeProjet: EnumProjetEtape;

  identiteForm: FormGroup;
  isDisplaySignatureElectroniqueIdentique = false;
  isDisplayIdentiteForm = false;

  readonly TITLE_FORM =
    'Compléter les informations ci-dessous concernant le signataire de l’entité en charge de la signature électronique des documents contractuels';

  ngOnInit(): void {
    this.checkSignedByRepresentLegal();
    this.identiteForm = this.signatureElectroniqueForm.get(this.identiteFormName) as FormGroup;
  }

  checkSignedByRepresentLegal(): void {
    this.signatureElectroniqueForm?.get('signatureElectronique')?.valueChanges.subscribe(value => {
      this.isDisplaySignatureElectroniqueIdentique = value;
      if (this.isDisplaySignatureElectroniqueIdentique) {
        this.signatureElectroniqueForm.get('signataireElectroniqueIdentique')?.enable();
        this.checkSignataireElectroniqueIdentique();
      } else {
        this.signatureElectroniqueForm.get('signataireElectroniqueIdentique')?.disable();
        this.isDisplayIdentiteForm = false;
        this.resetIdentiteForm();
      }
    });
  }

  checkSignataireElectroniqueIdentique(): void {
    this.signatureElectroniqueForm?.get('signataireElectroniqueIdentique')?.valueChanges.subscribe(value => {
      if (value !== null) {
        this.isDisplayIdentiteForm = !value;
        if (this.isDisplayIdentiteForm) {
          this.identiteForm.enable();
        } else {
          this.resetIdentiteForm();
        }
      }
    });
  }

  resetIdentiteForm(): void {
    this.identiteForm.reset();
    this.identiteForm.disable();
  }
}
