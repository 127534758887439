import { Component, OnDestroy } from '@angular/core';
import { MonoTypeOperatorFunction, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'lib-subscription-destroyer',
  template: '',
})
export abstract class SubscriptionDestroyerComponent implements OnDestroy {
  protected destroyed = new Subject();

  ngOnDestroy(): void {
    this.destroyed.next(undefined);
    this.destroyed.complete();
  }

  takeUntilDestroyed(): MonoTypeOperatorFunction<any> {
    return takeUntil(this.destroyed);
  }
}
