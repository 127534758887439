<section class="label-slide-toggle">
  <label>Type d'entreprise</label>
  <lib-pxl-toggle [labels]="toggleLabels" (changeToggleEvent)="ontoggleChange($event)" class="list-toggle"> </lib-pxl-toggle>
</section>

<lib-firm-in-difficulty></lib-firm-in-difficulty>

<table mat-table multiTemplateDataRows [dataSource]="dataSource" class="table-responsive mat-elevation-z0">
  <ng-container *ngFor="let col of columns">
    <ng-container [matColumnDef]="col.def" [sticky]="col.sticky || false">
      <th
        mat-header-cell
        *matHeaderCellDef
        class="fw-bold"
        [ngStyle]="{ 'max-width': col.width, 'min-width': col.width, 'text-align': 'center' }"
      >
        {{ col.label }}
      </th>
      <td
        mat-cell
        *matCellDef="let element; let i = dataIndex"
        [attr.colspan]="getColSpan(element[col.def])"
        [class.hidden]="isHidden(element[col.def])"
        [ngStyle]="{ 'max-width': col.width, 'min-width': col.width, 'text-align': 'center' }"
      >
        <input
          *ngIf="!isEvaluateur && element[col.def].isEditable; else notEditable"
          [disabled]="isDisabled"
          [id]="col.def + element.headerRowLabel"
          [name]="col.def"
          [placeholder]="col.placeholder || ''"
          [value]="element[col.def]?.value ?? ''"
          (change)="onDataChange($event, element[col.def])"
          (keydown)="onKeyPress($event)"
          type="text"
          class="input-cell ds-input-group__input"
        />
        <ng-template #notEditable>
          <div [ngClass]="element[col.def].isRowHeader ? 'row-header' : 'ligne-bilan'">
            <span [style]="element.style">
              {{ element[col.def]?.value ?? '' }}
            </span>
          </div>
        </ng-template>
      </td>
    </ng-container>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns; let i = dataIndex"></tr>
</table>

<lib-situation-conclusion
  [isEvaluateur]="isEvaluateur"
  [isAPme]="isPme"
  [isInDifficulty]="isInDifficulty"
  (commentEmitter)="onComment($event)"
  [comment]="comment"
></lib-situation-conclusion>
