import { EnumRoleContact } from '../enums/enum.roleContact';
import { ContactInterface } from '../interfaces/contact.interface';
import { Adresse } from './adresse.model';

export class Contact implements ContactInterface {
  nom: string | null;
  prenom: string;
  genre: string;
  fonction: string;
  roles: EnumRoleContact[];
  telephone: string;
  paysNaissance: string;
  codePaysNaissance: string;
  email: string;
  dateModification: Date;
  dateNaissance: string;
  villeNaissance: string;
  adresse: Adresse;
  matricule: string;
  inactif: boolean;
  selectionne: boolean; // selection du contact pour la signature de contrat
}
