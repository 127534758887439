<h2 id="modal-title" class="modal__title" *ngIf="modalData.title">{{ modalData.title }}</h2>

<div id="modal-body" class="modal__content" [style.font-weight]="modalData.boldContent ? 'bold' : 'normal'">
  <p>{{ modalData.description }}</p>

  <form [formGroup]="duplicateForm">
    <div class="ds-checkbox-group">
      <input type="checkbox" class="cb-duplicate" name="displayCoefficient" id="displayCoefficient" (click)="isDisplayCoefficient()" />
      <label for="displayCoefficient">J'affecte un coefficient multiplicateur</label>
    </div>

    <div
      *ngIf="this.displayCoefficient"
      class="ds-input-group ip-duplicate"
      [class.ds-input-group--error]="duplicateForm.controls['coefficient']?.invalid && duplicateForm.controls['coefficient']?.touched"
    >
      <span class="ds-input-group__input">
        <input
          id="coefficient"
          name="coefficient"
          placeholder="Coefficient"
          type="text"
          (keypress)="onkeypress($event)"
          formControlName="coefficient"
          class="ds-input-group__input"
        />
      </span>

      <ng-container *ngIf="duplicateForm.controls['coefficient'].touched && duplicateForm.controls['coefficient'].invalid">
        <div id="error-duplicate" class="ds-input-group__feedback">Merci de bien vouloir renseigner une valeur</div>
      </ng-container>
    </div>
  </form>
</div>

<div id="modal-btn" class="modal__actions">
  <button *ngIf="modalData.textReturnButton" class="ds-btn ds-btn--secondary" mat-dialog-close>
    {{ modalData.textReturnButton }}
  </button>
  <button class="ds-btn ds-btn--primary" [mat-dialog-close]="true" (click)="onSubmit()" [disabled]="duplicateForm.invalid" cdkFocusInitial>
    {{ modalData.textGoButton }}
  </button>
</div>
