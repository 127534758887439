import { enumKeys } from '../../utils/enum-utils';

export enum EnumDocumentComplementaireType {
  FICHE_DEMANDE_AIDE = 'FICHE_DEMANDE_AIDE',
  PLAN_FINANCEMENT_PREVISIONNEL = 'PLAN_FINANCEMENT_PREVISIONNEL',
  ANNEXE_FINANCIERE = 'ANNEXE_FINANCIERE',
  PJ_RAPPORT_ETUDE = 'PJ_RAPPORT_ETUDE',
  ACCORD_CONSORTIUM = 'ACCORD_CONSORTIUM',
  DELEGATION_POUVOIR_CLIENT = 'DELEGATION_POUVOIR_CLIENT',
  FICHE_LABELLISATION_POLE = 'FICHE_LABELLISATION_POLE',
  ANNEXES_TECHNIQUES = 'ANNEXES_TECHNIQUES',
  ETATS_PRIVILEGES = 'ETATS_PRIVILEGES',
  EVALUATION_EXPERTISE = 'EVALUATION_EXPERTISE',
  COMMANDE_EXPERTISE = 'COMMANDE_EXPERTISE',
  AUTORISATION_PRELEVEMENT = 'AUTORISATION_PRELEVEMENT',
  CORRESPONDANCE_CLIENT = 'CORRESPONDANCE_CLIENT',
  FICHIER_CALCUL_AIDE = 'FICHIER_CALCUL_AIDE',
  CONTROLE_PREMIER_NIVEAU = 'CONTROLE_PREMIER_NIVEAU',
  GRILLE_SELECTION = 'GRILLE_SELECTION',
  ACCORD_CONFIDENTIALITE = 'ACCORD_CONFIDENTIALITE',
  DOCUMENT_IDENTITE = 'DOCUMENT_IDENTITE',
  TABLE_CAPITALISATION = 'TABLE_CAPITALISATION',
  KBIS_EQUIVALENT = 'KBIS_EQUIVALENT',
  EXTRAIT_JOURNAL_OFFICIEL = 'EXTRAIT_JOURNAL_OFFICIEL',
  AUTRES_DOCUMENTS = 'AUTRES_DOCUMENTS',
  STATUTS = 'STATUTS',
  DECLARATION_BENEFICIAIRES_EFFECTIFS = 'DECLARATION_BENEFICIAIRES_EFFECTIFS',
  QUESTIONNAIRE_PAYS_SANCTIONS = 'QUESTIONNAIRE_PAYS_SANCTIONS',
  LISTE_MEMBRES_BUREAU = 'LISTE_MEMBRES_BUREAU',
  AUTORISATION_PRELEVEMENT_SEPA = 'AUTORISATION_PRELEVEMENT_SEPA',
  CERTIFICATION_IBAN_CLIENT = 'CERTIFICATION_IBAN_CLIENT',
}

export namespace EnumDocumentComplementaireType {
  export function toString(option: any): string {
    switch (option) {
      case EnumDocumentComplementaireType.FICHE_DEMANDE_AIDE:
        return "Fiche de demande d'aide";
      case EnumDocumentComplementaireType.PLAN_FINANCEMENT_PREVISIONNEL:
        return 'Plan de financement prévisionnel / Comptes de résultats prévisionnels';
      case EnumDocumentComplementaireType.ANNEXE_FINANCIERE:
        return 'Annexe financière / Base de données de calcul financier (BDD)';
      case EnumDocumentComplementaireType.PJ_RAPPORT_ETUDE:
        return "PJ au rapport d'étude";
      case EnumDocumentComplementaireType.ACCORD_CONSORTIUM:
        return 'Accord de consortium / bilatéral';
      case EnumDocumentComplementaireType.DELEGATION_POUVOIR_CLIENT:
        return 'Délégation de pouvoir du client';
      case EnumDocumentComplementaireType.FICHE_LABELLISATION_POLE:
        return 'Fiche de labellisation pôle';
      case EnumDocumentComplementaireType.ANNEXES_TECHNIQUES:
        return 'Annexes techniques';
      case EnumDocumentComplementaireType.ETATS_PRIVILEGES:
        return 'Etats et privilèges (URSSAF, Trésor, DRFIP)';
      case EnumDocumentComplementaireType.EVALUATION_EXPERTISE:
        return "Evaluation d'expertise";
      case EnumDocumentComplementaireType.COMMANDE_EXPERTISE:
        return "Commande d'expertise";
      case EnumDocumentComplementaireType.AUTORISATION_PRELEVEMENT_SEPA:
        return 'KYC - Autorisation de prélèvement / Mandat SEPA + RIB';
      case EnumDocumentComplementaireType.AUTORISATION_PRELEVEMENT:
        return 'FINNO - Autorisation de prélèvement / Mandat SEPA + RIB';
      case EnumDocumentComplementaireType.CORRESPONDANCE_CLIENT:
        return 'Correspondance client';
      case EnumDocumentComplementaireType.FICHIER_CALCUL_AIDE:
        return "Fichier calcul de l'aide à verser";
      case EnumDocumentComplementaireType.CONTROLE_PREMIER_NIVEAU:
        return 'Contrôle premier niveau';
      case EnumDocumentComplementaireType.GRILLE_SELECTION:
        return 'Grille de sélection';
      case EnumDocumentComplementaireType.ACCORD_CONFIDENTIALITE:
        return "Accord de confidentialité / absence conflit d'intérêt";
      case EnumDocumentComplementaireType.DOCUMENT_IDENTITE:
        return 'Document d’identité';
      case EnumDocumentComplementaireType.TABLE_CAPITALISATION:
        return 'Table de capitalisation';
      case EnumDocumentComplementaireType.KBIS_EQUIVALENT:
        return 'Kbis ou équivalent';
      case EnumDocumentComplementaireType.EXTRAIT_JOURNAL_OFFICIEL:
        return 'Extrait du journal officiel';
      case EnumDocumentComplementaireType.AUTRES_DOCUMENTS:
        return 'Autres documents';
      case EnumDocumentComplementaireType.STATUTS:
        return 'Statuts';
      case EnumDocumentComplementaireType.DECLARATION_BENEFICIAIRES_EFFECTIFS:
        return 'Déclaration des bénéficiaires effectifs';
      case EnumDocumentComplementaireType.QUESTIONNAIRE_PAYS_SANCTIONS:
        return 'Questionnaire pays sous sanctions';
      case EnumDocumentComplementaireType.LISTE_MEMBRES_BUREAU:
        return 'Liste des membres du bureau';
      case EnumDocumentComplementaireType.CERTIFICATION_IBAN_CLIENT:
        return 'Certification IBAN Client + justificatifs associés';
      default:
        return '';
    }
  }

  export function toKey(option: string): EnumDocumentComplementaireType | undefined {
    switch (option) {
      case "Fiche de demande d'aide":
        return EnumDocumentComplementaireType.FICHE_DEMANDE_AIDE;
      case 'Plan de financement prévisionnel / Comptes de résultats prévisionnels':
        return EnumDocumentComplementaireType.PLAN_FINANCEMENT_PREVISIONNEL;
      case 'Annexe financière / Base de données de calcul financier (BDD)':
        return EnumDocumentComplementaireType.ANNEXE_FINANCIERE;
      case "PJ au rapport d'étude":
        return EnumDocumentComplementaireType.PJ_RAPPORT_ETUDE;
      case 'Accord de consortium / bilatéral':
        return EnumDocumentComplementaireType.ACCORD_CONSORTIUM;
      case 'Délégation de pouvoir du client':
        return EnumDocumentComplementaireType.DELEGATION_POUVOIR_CLIENT;
      case 'Fiche de labellisation pôle':
        return EnumDocumentComplementaireType.FICHE_LABELLISATION_POLE;
      case 'Annexes techniques':
        return EnumDocumentComplementaireType.ANNEXES_TECHNIQUES;
      case 'Etats et privilèges (URSSAF, Trésor, DRFIP)':
        return EnumDocumentComplementaireType.ETATS_PRIVILEGES;
      case "Evaluation d'expertise":
        return EnumDocumentComplementaireType.EVALUATION_EXPERTISE;
      case "Commande d'expertise":
        return EnumDocumentComplementaireType.COMMANDE_EXPERTISE;
      case 'Correspondance client':
        return EnumDocumentComplementaireType.CORRESPONDANCE_CLIENT;
      case "Fichier calcul de l'aide à verser":
        return EnumDocumentComplementaireType.FICHIER_CALCUL_AIDE;
      case 'Contrôle premier niveau':
        return EnumDocumentComplementaireType.CONTROLE_PREMIER_NIVEAU;
      case 'Grille de sélection':
        return EnumDocumentComplementaireType.GRILLE_SELECTION;
      case "Accord de confidentialité / absence conflit d'intérêt":
        return EnumDocumentComplementaireType.ACCORD_CONFIDENTIALITE;
      case 'Document d’identité':
        return EnumDocumentComplementaireType.DOCUMENT_IDENTITE;
      case 'Table de capitalisation':
        return EnumDocumentComplementaireType.TABLE_CAPITALISATION;
      case 'Kbis ou équivalent':
        return EnumDocumentComplementaireType.KBIS_EQUIVALENT;
      case 'Extrait du journal officiel':
        return EnumDocumentComplementaireType.EXTRAIT_JOURNAL_OFFICIEL;
      case 'Autres documents':
        return EnumDocumentComplementaireType.AUTRES_DOCUMENTS;
      case 'Statuts':
        return EnumDocumentComplementaireType.STATUTS;
      case 'Déclaration des bénéficiaires effectifs':
        return EnumDocumentComplementaireType.DECLARATION_BENEFICIAIRES_EFFECTIFS;
      case 'Questionnaire pays sous sanctions':
        return EnumDocumentComplementaireType.QUESTIONNAIRE_PAYS_SANCTIONS;
      case 'Liste des membres du bureau':
        return EnumDocumentComplementaireType.LISTE_MEMBRES_BUREAU;
      case 'KYC - Autorisation de prélèvement / Mandat SEPA + RIB':
        return EnumDocumentComplementaireType.AUTORISATION_PRELEVEMENT_SEPA;
      case 'FINNO - Autorisation de prélèvement / Mandat SEPA + RIB':
        return EnumDocumentComplementaireType.AUTORISATION_PRELEVEMENT;
      case 'Certification IBAN Client + justificatifs associés':
        return EnumDocumentComplementaireType.CERTIFICATION_IBAN_CLIENT;
      default:
        return undefined;
    }
  }

  export function all(): EnumDocumentComplementaireType[] {
    return enumKeys(EnumDocumentComplementaireType) as unknown as EnumDocumentComplementaireType[];
  }
}
