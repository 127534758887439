import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'lib-expansion-panel',
  templateUrl: './expansion-panel.component.html',
  styleUrls: ['./expansion-panel.component.scss'],
})
export class ExpansionPanelComponent {
  @Input() title: string;
  @Input() titleComplement = '';
  @Input() subTitle: string;
  @Input() showValidIcon: boolean;
  @Input() showMandatoryAsterisk: boolean;
  @Input() openExpertAccordion: boolean;
  @Input() actionButtonLabel: string;
  @Input() showOverflow: boolean;
  @Input() badge?: { text: string; size: 'small' | 'medium' | 'large'; color: 'warn' | 'primary' | 'accent' };
  @Input() disableHover = false;

  @Output() actionButtonEmitter = new EventEmitter<void>();

  isPanelOpen: boolean;

  actionButtonClicked() {
    this.actionButtonEmitter.emit();
  }
}
