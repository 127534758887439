import { ChangeDetectorRef, Directive, ElementRef, Host, OnDestroy, OnInit } from '@angular/core';
import { MatSelectCountryComponent } from '@angular-material-extensions/select-country';

@Directive({
  selector: '[libNoCountrySuggestionWithoutHint]',
})
export class NoCountrySuggestionWithoutHintDirective implements OnInit, OnDestroy {
  inputElement: HTMLInputElement;
  pollingId: ReturnType<typeof setInterval>;

  constructor(
    @Host() private selector: MatSelectCountryComponent,
    private elementRef: ElementRef,
    private changeDetector: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.selector.debounceTime = 100;
    this.inputElement = this.elementRef.nativeElement.querySelector('input[matInput]');

    this.pollingId = pollProperty(this.selector, 'filteredOptions', (newValue: any) => {
      if (!this.inputElement.value && newValue.length > 0) {
        this.selector.filteredOptions = [];
        this.changeDetector.detectChanges();
      }
    });
  }

  ngOnDestroy() {
    clearInterval(this.pollingId);
  }
}

function pollProperty(
  obj: any,
  property: string,
  callback: (newValue: any, oldValue: any) => void,
  interval = 100
): ReturnType<typeof setInterval> {
  let currentValue = obj[property];

  return setInterval(() => {
    if (obj[property] !== currentValue) {
      callback(obj[property], currentValue);
      currentValue = obj[property];
    }
  }, interval);
}
