import { Component, DestroyRef, EventEmitter, inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import {
  DateFiltersComponent,
  ExportCsvFunction,
  InterneBpifranceFiltersComponent,
  ModificationColonnesModalComponent,
  MultiSelectCriteria,
  PartenaireFilters,
  PartenaireFiltersComponent,
  ProjetFilters,
  ProjetFiltersComponent,
  RangeCriteria,
  RangeDatesCriteria,
  RechercheTransverse,
  RechercheTransverseFiltersComponent,
  RechercheTransverseService,
  SearchCriteria,
  ShowToastrService,
  TableColumnConfiguration,
} from '@shared-ui';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'lib-recherche-transverse-tab-content',
  templateUrl: './recherche-transverse-tab-content.component.html',
  styleUrls: ['./recherche-transverse-tab-content.component.scss'],
})
export class RechercheTransverseTabContentComponent implements OnInit {
  @Input() tab!: 'RECHERCHE_GUIDEE' | 'RECHERCHE_LIBRE';
  @Input() displayedColumns!: string[];
  @Input() tableConf!: TableColumnConfiguration[];
  @Output() displayedColumnsChange = new EventEmitter<string[]>();

  destroyRef = inject(DestroyRef);

  readonly RECHERCHE_GUIDEE = 'RECHERCHE_GUIDEE';
  readonly RECHERCHE_LIBRE = 'RECHERCHE_LIBRE';
  readonly CELL_MAX_LENGTH = 35;

  @ViewChild('basicFilters') basicFiltersComponent!: RechercheTransverseFiltersComponent;
  @ViewChild('dateFilters') dateFiltersComponent!: DateFiltersComponent;
  @ViewChild('projetFilters') projetFilters!: ProjetFiltersComponent;
  @ViewChild('interneBpifranceFilters') interneBpifranceFilters!: InterneBpifranceFiltersComponent;
  @ViewChild('partenaireFilters') partenaireFiltersComponent!: PartenaireFiltersComponent;
  @ViewChild(MatSort) sort!: MatSort;

  selectedData: any[] = [];
  dataSource: MatTableDataSource<RechercheTransverse> = new MatTableDataSource<RechercheTransverse>([]);
  selection = new SelectionModel<any>(true, []);

  dataLoaded = false;
  allFiltersValues: SearchCriteria = new SearchCriteria();

  constructor(
    public rechercheTransverseService: RechercheTransverseService,
    public toastrService: ShowToastrService,
    private exportCsvFunction: ExportCsvFunction,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource<RechercheTransverse>([]);
  }

  searchData(): void {
    const searchCriterias = this.getAllFiltersValues();
    if (!this.canLaunchSearch(searchCriterias)) {
      return;
    }
    this.dataLoaded = true;
    this.rechercheTransverseService
      .search(searchCriterias)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: result => {
          this.dataSource.data = result.body ? result.body : [];
          this.dataSource.sortingDataAccessor = this.getSortingDataAccessor();
          this.dataSource.sort = this.sort;
        },
        error: (err: HttpErrorResponse) => {
          this.dataLoaded = false;
          this.toastrService.checkCodeError(err?.error);
        },
      });
  }

  loadDateFilters(rangeDatesCriteria: RangeDatesCriteria) {
    const mergedRangeDates = { ...this.allFiltersValues.rangeDatesCriterias, ...rangeDatesCriteria };
    this.allFiltersValues.rangeDatesCriterias = this.removeNullValues(mergedRangeDates);
  }

  loadProjetFilters(filters: ProjetFilters) {
    this.allFiltersValues.multiSelectCriterias = { ...this.allFiltersValues.multiSelectCriterias, ...filters.selectFilters };
    const mergedRangeCriterias = { ...this.allFiltersValues.rangeCriterias, ...filters.rangeFilters };
    this.allFiltersValues.rangeCriterias = this.removeNullValues(mergedRangeCriterias);
    this.allFiltersValues.objetProjet = filters.objetProjet;
  }

  loadInterneBpifranceFilters(filters: MultiSelectCriteria) {
    this.allFiltersValues.multiSelectCriterias = { ...this.allFiltersValues.multiSelectCriterias, ...filters };
  }

  private removeNullValues(criterias: RangeCriteria | RangeDatesCriteria): any {
    return Object.entries(criterias)
      .filter(([_, value]) => value !== null)
      .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});
  }

  loadPartenaireFilters(filters: PartenaireFilters) {
    this.allFiltersValues.multiSelectCriterias = { ...this.allFiltersValues.multiSelectCriterias, ...filters.selectFilters };
    const mergedRangeCriterias = { ...this.allFiltersValues.rangeCriterias, ...filters.rangeFilters };
    this.allFiltersValues.rangeCriterias = this.removeNullValues(mergedRangeCriterias);
  }

  private noFilterSelected(searchObjectMerged: SearchCriteria): boolean {
    return !Object.values(searchObjectMerged).some(this.hasValue.bind(this));
  }

  private hasValue(value: any): boolean {
    if (value === null || value === undefined) {
      return false;
    }
    if (Array.isArray(value)) {
      return value.length > 0;
    } else if (typeof value === 'string') {
      return value !== '';
    } else if (typeof value === 'object') {
      return Object.values(value)?.some(this.hasValue.bind(this));
    }
    return true;
  }

  private getAllFiltersValues(): SearchCriteria {
    if (this.RECHERCHE_GUIDEE === this.tab) {
      const basicFilters = this.basicFiltersComponent.getFiltersValues();
      this.allFiltersValues.multiSelectCriterias = {};
      this.allFiltersValues.multiSelectCriterias['aap'] = basicFilters.aaps;
      this.allFiltersValues.multiSelectCriterias['acronyme'] = basicFilters.acronymes;
      this.allFiltersValues.multiSelectCriterias['denomination_sociale'] = basicFilters.denominationsSociales;
      this.allFiltersValues.multiSelectCriterias['dispositif'] = basicFilters.dispositifs;
      this.allFiltersValues.multiSelectCriterias['process_fr30'] = basicFilters.processFr30List;
    }
    return this.allFiltersValues;
  }

  private getSortingDataAccessor(): (item: RechercheTransverse, property: string) => string {
    return (item, property) => {
      return typeof (item as any)[property] === 'string' ? (item as any)[property]?.toLocaleLowerCase().trim() : (item as any)[property];
    };
  }

  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle(): void {
    this.isAllSelected() ? this.selection.clear() : this.selectAll();
  }

  private selectAll() {
    this.dataSource.data.forEach(row => this.selection.select(row));
  }

  exportData() {
    this.selectedData = this.selection.selected;
    if (this.selectedData.length === 0) {
      this.toastrService.error('Veuillez sélectionner au moins une ligne');
      return;
    }
    this.rechercheTransverseService
      .export({ ids: this.selectedData.map(s => s.id) })
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: response => {
          if (response && response.body) {
            this.exportCsvFunction.downloadFile(response.body, this.exportCsvFunction.extractFileNameFromHeaders(response.headers));
          }
        },
        error: (err: HttpErrorResponse) => {
          this.toastrService.checkCodeError(err?.error);
        },
      });
  }

  modifierColonnes() {
    const dialogRef = this.dialog.open(ModificationColonnesModalComponent, {
      data: {
        title: 'Modifier les colonnes',
        columnList: this.tableConf,
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result?.data && result.data.length > 0) {
        this.tableConf = result.data;
        this.displayedColumnsChange.emit(this.tableConf.filter(column => column.visible).map(column => column.id));
      }
    });
  }

  resetAllFilters(): void {
    this.allFiltersValues = new SearchCriteria();
    if (this.RECHERCHE_GUIDEE === this.tab) {
      this.basicFiltersComponent.resetFilters();
    }
    this.dateFiltersComponent?.resetFilters();
    this.projetFilters?.resetFilters();
    this.interneBpifranceFilters?.resetFilters();
    this.partenaireFiltersComponent?.resetFilters();
  }

  private canLaunchSearch(searchCriterias: SearchCriteria) {
    if (this.noFilterSelected(searchCriterias) && this.RECHERCHE_GUIDEE === this.tab) {
      this.toastrService.error('Veuillez sélectionner au moins un filtre pour lancer la recherche');
      return false;
    }
    if (this.RECHERCHE_LIBRE === this.tab && (!searchCriterias.freeSearchQuery || searchCriterias.freeSearchQuery.length < 2)) {
      this.toastrService.error('Veuillez saisir au moins deux caractères pour lancer votre recherche');
      return false;
    }
    return true;
  }
}
