import { Component, Input } from '@angular/core';
import { SyntheseContractualisationStructure } from '../../../shared/models/synthese-contractualisation-structure.model';
import { ProcedureSignatureStructure } from '../../../shared/models/procedure-signature.model';

@Component({
  selector: 'lib-contrat-document-list',
  templateUrl: './contrat-document-list.component.html',
  styleUrls: ['./contrat-document-list.component.scss'],
})
export class ContratDocumentListComponent {
  @Input() syntheseStructure!: SyntheseContractualisationStructure | undefined;
  @Input() currentProcedure!: ProcedureSignatureStructure | null;
}
