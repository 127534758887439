import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[libNumericNegatifAndDotOnly]',
})
export class NumericNegatifAndDotOnlyDirective {
  @HostListener('keypress', ['$event'])
  onKeyPress(event: KeyboardEvent): void {
    const onlyDigitsRegex = new RegExp(/^-?\d*\.?\d*$/);
    const inputValue = (event.target as HTMLInputElement)?.value;
    if (inputValue && ['-', '.'].includes(event.key) && inputValue.includes(event.key)) {
      event.preventDefault();
      return;
    }
    if (event.key && !String(event.key).match(onlyDigitsRegex)) {
      event.preventDefault();
    }
  }
}
