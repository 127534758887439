import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'lib-table-dialog-delete',
  templateUrl: './table-dialog-delete.component.html',
  styleUrls: ['./table-dialog-delete.component.scss']
})
export class TableDialogDeleteComponent {

  constructor(public dialogRef: MatDialogRef<TableDialogDeleteComponent>, @Inject(MAT_DIALOG_DATA) public modalData: any) {
    if (modalData.boldContent === undefined) {
      modalData.boldContent = true;
    }
  }

}
