import { EnumMotifNonRattachement } from '../enums/enum.motifNonRattachement';
import { Adresse } from './adresse.model';
import { DirectionRegionale } from './directionRegionale.model';
import { EnumTypeStructure } from "../enums/enum.typeStructure";

export class LieuRD {
  siret: string;
  raisonSiret?: EnumMotifNonRattachement;
  raisonSocial: string | null;
  dateModification: Date;
  typeStructure?: EnumTypeStructure;
  lieuRDisSiege = false;
  adresse: Adresse = new Adresse();
  directionRegionale: DirectionRegionale = new DirectionRegionale();
}
