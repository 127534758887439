import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ContractualisationSharedService, EnumStatutSignature, ShowToastrService } from '@shared-ui';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'lib-procedure-signature-signataire-statut-updater',
  templateUrl: './procedure-signature-signataire-statut-updater.component.html',
})
export class ProcedureSignatureSignataireStatutUpdaterComponent implements OnInit {
  private readonly destroyRef: DestroyRef = inject(DestroyRef);
  private readonly racineRoute: string;
  private statutSignature: EnumStatutSignature;
  private isAdmin: boolean;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private showToastrService: ShowToastrService,
    private contractualisationService: ContractualisationSharedService
  ) {
    this.statutSignature = this.route.snapshot.data?.['statutSignature'];
    this.racineRoute = this.route.snapshot.data?.['racineRoute'];
    this.isAdmin = this.route.snapshot.data?.['isAdmin'];
  }

  ngOnInit(): void {
    const context = localStorage.getItem('procedure-signature-contexte');
    if (!context) {
      this.goToRacine();
      return;
    }
    const jsonContext = JSON.parse(context);
    if (!this.statutSignature) {
      this.showToastrService.error(
        "Une erreur est survenue pendant la signature du contrat. Vous allez être redirigé sur l'écran de signature."
      );
      this.goBackToSignatureProcedure(jsonContext.projetId, jsonContext.structureId);
      return;
    }
    this.updateStatutSignatureSignataire(jsonContext.projetId, jsonContext.structureId);
  }

  private updateStatutSignatureSignataire(projetId: string, structureId: string) {
    this.contractualisationService
      .upsertStatutSignatureSignataire(this.isAdmin, structureId, this.statutSignature)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: () => {
          localStorage.removeItem('procedure-signature-contexte');
          this.goBackToSignatureProcedure(projetId, structureId);
        },
        error: err => {
          this.showToastrService.checkCodeError(err?.error);
          this.goToRacine();
        },
      });
  }

  private goBackToSignatureProcedure(projetId: string, structureId: string) {
    this.router.navigate([
      `/${this.isAdmin ? 'projets' : 'projet-creation'}/${projetId}/projet-contractualisation/signature-contrat/consortium/${structureId}`,
    ]);
  }

  private goToRacine() {
    this.router.navigate([this.racineRoute]);
  }
}
