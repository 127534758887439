export class PiaCsv {
  nomProgramme: string;
  nomAap: string;
  identifiantPXL: string;
  acronyme: string;
  genreRp: string;
  nomRp: string;
  prenomRp: string;
  emailRp: string;
  telRp: string;
  nomRl: string | null | undefined;
  prenomRl: string;
  genreRl: string;
  emailRl: string;
  telRl: string;
  partenaireType: string;
  raisonSocial: string;
  role: string;
  poles: string;
  nbPartenaires: string;
  thematique: string;
  secteurs: string;
  domaines: string;
  description: string;
  DRBpi: string;
  typeStruture: string;
  siret: string;
  siren: string;
  codePostal: string;
  departement: string;
  regionAdministrative: string;
  CodePostalRD: string;
  departementRD: string;
  regionAdministrativeRD: string;
  assietteInit: string | null | undefined;
  assietteDetail: string | null | undefined;
  dateVerrouillage: string;
  etape: string;
  statut: string;
  dateValidationEtape: string;
  dateReleve: string;
  date: string;
  commentaire: string;
}
