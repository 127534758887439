import { Directive, ElementRef, HostListener, Inject, Input, LOCALE_ID, Renderer2 } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Directive({
  selector: '[libDecimalNumber]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: DecimalNumberDirective,
      multi: true,
    },
  ],
})
export class DecimalNumberDirective implements ControlValueAccessor {
  @Input() fractionDigits?: number = undefined; // undefined number of digits mean there will be no rounding or truncating
  @Input() isRounded = true;

  private onChange: (value: any) => void = () => {};
  private onTouched: () => void = () => {};

  constructor(@Inject(LOCALE_ID) public locale: string, private el: ElementRef, private renderer: Renderer2) {}

  @HostListener('blur', ['$event.target.value'])
  onBlur(value: string): void {
    const number = this.getNumber(value);

    if (isNaN(number)) {
      this.renderer.setProperty(this.el.nativeElement, 'value', '');
      this.onChange(null);
    } else {
      const formattedValue = this.formatNumber(number);
      this.renderer.setProperty(this.el.nativeElement, 'value', formattedValue);
      this.onChange(this.getNumber(formattedValue));
    }
    this.onTouched();
  }

  writeValue(number: any): void {
    if (number != null) {
      const formattedValue = this.formatNumber(number);
      this.renderer.setProperty(this.el.nativeElement, 'value', formattedValue);
      this.onChange(this.getNumber(formattedValue));
    }
  }

  formatNumber(value: number): string {
    const options: Intl.NumberFormatOptions = {
      useGrouping: true,
    };
    if (this.fractionDigits) {
      options['minimumFractionDigits'] = +this.fractionDigits;
      options['maximumFractionDigits'] = +this.fractionDigits;
      if (!this.isRounded) {
        value = this.truncate(value);
      }
    } else {
      const countOfFractionDigits = this.countFractionDigits(value);
      options['minimumFractionDigits'] = countOfFractionDigits;
      options['maximumFractionDigits'] = countOfFractionDigits;
    }
    return Intl.NumberFormat(this.locale, options).format(value);
  }

  getNumber(formattedValue: string): number {
    return Number(formattedValue.replace(/\s/g, '').replace(',', '.'));
  }

  truncate(value: number): number {
    const multiplier = this.fractionDigits ? 10 * +this.fractionDigits : 1;
    return Math.floor(value * multiplier) / multiplier;
  }

  countFractionDigits(value: number): number {
    const decimalSeparator = '.';
    const strNumber = value.toString();

    const decimalIndex = strNumber.indexOf(decimalSeparator);

    if (decimalIndex !== -1) {
      return strNumber.length - decimalIndex - 1;
    } else {
      return 0; // No fraction digits
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.renderer.setProperty(this.el.nativeElement, 'disabled', isDisabled);
  }
}
