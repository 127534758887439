import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { DepensesData, DisplayColumn, LotLightModel, TableCell } from '@shared-ui';
import { Subscription, take } from 'rxjs';
import { TableDialogDeleteComponent } from './table-dialog-delete/table-dialog-delete.component';
import { TableDialogDuplicateComponent } from './table-dialog-duplicate/table-dialog-duplicate.component';
import { TableDialogEditComponent } from './table-dialog-edit/table-dialog-edit.component';
import _ from 'lodash';

@Component({
  selector: 'lib-table-synthese',
  templateUrl: './table-synthese.component.html',
  styleUrls: ['./table-synthese.component.scss'],
})
export class TableSyntheseComponent implements AfterViewInit, OnDestroy {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('table', { read: ElementRef }) matTableRef: ElementRef;

  @Input() set tachesByLots(data: {
    lotsLights: LotLightModel[];
    columns: DisplayColumn[];
    updated: boolean;
    updatedSidenav: boolean;
    compareMode: boolean;
  }) {
    this.columns = data.columns;
    this.lotUpdated = data.updated;
    this.updatedSidenav = data.updatedSidenav;
    this.handleCompareState(data);
    const result = this.sortTachesInLots(data);
    this.tachesByLotsChange(result);
    this.clearRowSelected();
  }

  @Input() set showOngletTab(value: boolean) {
    this.showOngletOrListTab(value);
  }

  @Input() set filterChange(filter: string) {
    this.clearRowSelected();
    if (this.afficherOnglets) {
      this.applyFilter(filter);
    } else {
      this.sourceFiltered = this.dataSource.filteredData;
    }
  }

  @Input() set updateData(data: { index: number; columnName: string; depensesData: DepensesData<number | undefined> }) {
    if (this.dataSource?.data && data.index && data.columnName) {
      (this.dataSource.data[data.index][data.columnName as keyof LotLightModel] as TableCell) = new TableCell(
        data.depensesData.value,
        false,
        false,
        false,
        false,
        data.depensesData.note
      );
    }
  }

  @Input() disColumns!: string[];
  @Input() isFullScreen = false;
  @Input() readOnly: boolean;
  @Input() regimes: string[];

  @Output() lotsTachesChanges: EventEmitter<{
    isSideNav: boolean | undefined;
    lots: LotLightModel[];
  }> = new EventEmitter<{
    isSideNav: boolean | undefined;
    lots: LotLightModel[];
  }>();

  @Output() cellSelectedEvent: EventEmitter<{
    columnName: string;
    defNote: string;
    idTache: string;
    editable: boolean;
  }> = new EventEmitter<{ columnName: string; defNote: string; idTache: string; editable: boolean }>();

  @Output() actionEvent: EventEmitter<{ type: string; selected: LotLightModel[]; param?: any }> = new EventEmitter<{
    type: string;
    selected: LotLightModel[];
    param?: number;
  }>();

  private lotUpdated = false;
  private updatedSidenav = false;
  private filterColumnsMap: Map<string, any[]> = new Map();
  private filterEnable = false;
  private subscriptionAction: Subscription;
  public columns: DisplayColumn[];
  public docElement: HTMLElement;
  public filter: string[] = [];
  public selected: string[] = [];
  public afficherOnglets: boolean;
  public filterActive: string[] = [];
  public dataSource: MatTableDataSource<LotLightModel> = new MatTableDataSource();
  public tacheByLots: LotLightModel[] = [];
  public spans: { [x: string]: number }[] = [];
  public spansIndex: string[] = [];
  public filtersMap: Map<string, Set<string>> = new Map();
  public lotSite = 300;
  public tachesForm: FormGroup;
  public sourceFiltered: LotLightModel[];
  public compareMode: boolean;
  private cleanAfterCompare: boolean;

  readonly DUPLIQUER = 'Dupliquer';
  readonly SUPPRIMER = 'Supprimer';
  readonly MODIFIER = 'Modifier';
  externalChange = false;

  constructor(private fb: FormBuilder, public matDialog: MatDialog, private cdRef: ChangeDetectorRef, private dialog: MatDialog) {}

  ngAfterViewInit() {
    this.paginator._changePageSize(this.lotSite);
    this.dataSource.paginator = this.paginator;

    if (this.dataSource?.data?.length === 0 || this.lotUpdated) {
      this.dataSource.data = this.tachesForm.value.taches;
      this.getHeaderSFiltersValue(this.dataSource.data);
    }
    this.cdRef.detectChanges();

    setTimeout(() => {
      this.dataSource.data = this.tachesForm.value.taches;
      this.getHeaderSFiltersValue(this.dataSource.data);
      this.paginator._changePageSize(this.tachesForm.value.taches.length);
      this.cdRef.detectChanges();
    });
    this.clearRowSelected();
  }

  private handleCompareState(data: {
    lotsLights: LotLightModel[];
    columns: DisplayColumn[];
    updated: boolean;
    updatedSidenav: boolean;
    compareMode: boolean;
  }) {
    if (this.compareMode && !data.compareMode) {
      this.cleanAfterCompare = true;
    }
    this.compareMode = data.compareMode;
  }

  private sortTachesInLots(data: {
    lotsLights: LotLightModel[];
    columns: DisplayColumn[];
    updated: boolean;
    updatedSidenav: boolean;
    compareMode: boolean;
  }) {
    const grouped = _.mapValues(_.groupBy(data.lotsLights, 'lot.value'));
    const result: LotLightModel[] = [];
    for (const key in grouped) {
      const lot = grouped[key].sort((a, b) => a.idTache.localeCompare(b.idTache));
      lot.forEach(tache => result.push(tache as LotLightModel));
    }
    return result;
  }

  private tachesByLotsChange(lotsLights: LotLightModel[]): void {
    this.filterEnable = false;
    this.tacheByLots = lotsLights;
    this.cacheSpan('lot', (d: { lot: any }) => d.lot.value);
    this.initDataSource();
    this.initHeaderFilters();
    if (this.lotUpdated || this.updatedSidenav || this.compareMode || this.cleanAfterCompare) {
      this.dataSource.data = this.tachesForm.value.taches;
      if ((this.compareMode || this.cleanAfterCompare) && this.paginator) {
        this.paginator._changePageSize(this.tachesForm.value.taches.length);
        this.cdRef.detectChanges();
        this.readOnly = true;
      }
      if (this.cleanAfterCompare) {
        this.readOnly = false;
        this.cleanAfterCompare = false;
      }
    }

    this.lotUpdated = false;
    this.updatedSidenav = false;
  }

  private showOngletOrListTab(value: boolean): void {
    this.afficherOnglets = value;
    this.initHeaderFilters();
    this.hideLotByDisplayType(value);
    this.displayColumns();
    if (value) {
      // true onglet
      this.filterEnable = true;
      const lotFilters = this.filtersMap.get('lot');
      this.selected = [lotFilters ? Array.from(lotFilters)[0]?.toString() : ''];
      this.onFilterChange({ columnKey: 'lot', filterValue: [lotFilters ? Array.from(lotFilters)[0]?.toString() : ''] });
      this.getHeaderSFiltersValue(this.dataSource.filteredData);
    } else {
      // liste
      this.filterEnable = false;
      this.filterActive = [];
      this.filterColumnsMap.clear();
      this.onFilterChange({ columnKey: 'lot', filterValue: [] });
      this.getHeaderSFiltersValue(this.dataSource.data);
    }
  }

  private initHeaderFilters(): void {
    this.dataSource.filterPredicate = (data: LotLightModel) => {
      let result = true;
      this.filterColumnsMap.forEach((value, key) => {
        if (value.length > 0) {
          result = result && value.includes((data[key as keyof LotLightModel] as TableCell)?.value + '');
        }
      });
      return result;
    };
  }

  private initDataSource(): void {
    this.tachesForm = this.fb.group({ taches: this.fb.array([]) });
    this.tacheByLots.sort((a, b) => (a.lot.value as number) - (b.lot.value as number)).forEach(tache => this.feedFormArrays(tache));
    this.docElement = document.documentElement;
    this.hideLotByDisplayType(this.afficherOnglets);
    this.setFilters();
    this.getHeaderSFiltersValue(this.dataSource.data);
  }

  private feedFormArrays(tache: LotLightModel): void {
    const ecAmortissements = tache.ecAmortissements?.reduce<{ [key: string]: TableCell }>((acc, curr, index) => {
      acc['ecAmortissements' + index] = curr;
      return acc;
    }, {});
    const ecCouts = tache.ecCouts?.reduce<{ [key: string]: TableCell }>((acc, curr, index) => {
      acc['ecCouts' + index] = curr;
      return acc;
    }, {});
    const ecFrais = tache.ecFrais?.reduce<{ [key: string]: TableCell }>((acc, curr, index) => {
      acc['ecFrais' + index] = curr;
      return acc;
    }, {});
    const ecInvestissements = tache.ecInvestissements?.reduce<{ [key: string]: TableCell }>((acc, curr, index) => {
      acc['ecInvestissements' + index] = curr;
      return acc;
    }, {});
    const ecSalaires = tache.ecSalaires?.reduce<{ [key: string]: TableCell }>((acc, curr, index) => {
      acc['ecSalaires' + index] = curr;
      return acc;
    }, {});
    const ecSousTraitance = tache.ecSousTraitance?.reduce<{ [key: string]: TableCell }>((acc, curr, index) => {
      acc['ecSousTraitance' + index] = curr;
      return acc;
    }, {});
    const ecCumul = tache.cumul?.reduce<{ [key: string]: TableCell }>((acc, curr, index) => {
      acc['cumul' + index] = curr;
      return acc;
    }, {});

    const row = {
      ...tache,
      ...tache.ec,
      ...ecAmortissements,
      ...ecCouts,
      ...ecFrais,
      ...ecInvestissements,
      ...ecSalaires,
      ...ecSousTraitance,
      ...ecCumul,
      structureName: tache.structureName,
      action: new TableCell(undefined, true, false, false),
    };
    delete row['ec'];
    delete row['ecAmortissements'];
    delete row['ecCouts'];
    delete row['ecFrais'];
    delete row['ecInvestissements'];
    delete row['ecSalaires'];
    delete row['ecSousTraitance'];
    delete row['cumul'];
    const form = this.tachesForm
      ?.get('taches')
      ?.value.filter(
        (f: { lot: number; indexTache: string; structureName: string }) =>
          f.lot === tache.lot.value && f.indexTache === tache.indexTache.value && f.structureName === tache.structureName.value
      );

    if (form.length === 0) {
      (<FormArray>this.tachesForm.get('taches')).push(this.fb.group(row));
    }
  }

  private getHeaderSFiltersValue(lots: LotLightModel[]): void {
    if (this.columns) {
      for (const column of this.columns) {
        if (column.filterEnable) {
          this.filtersMap.set(
            column.def,
            new Set(lots.map((tache: LotLightModel) => (tache[column.def as keyof LotLightModel] as TableCell)?.value + ''))
          );
        }
      }
    }
  }

  private cacheSpan(
    key: string,
    accessor: {
      (d: { lot: LotLightModel }): string;
      (arg0: LotLightModel): string;
    }
  ): void {
    let currentValue: string,
      count = 0,
      i = 0;
    const data = this.filterEnable ? this.dataSource.filteredData : this.tacheByLots;

    this.spans = data.reduce((spans: { [x: string]: number }[], item, index) => {
      if (index === 0 || accessor(item) !== currentValue) {
        currentValue = accessor(item);
        count = 1;
        i = index;
      } else {
        count++;
      }

      spans[i] = { [key]: count };
      return spans;
    }, []);

    this.spansIndex = Object.keys(this.spans);
  }

  onChange(isSideNav?: boolean): void {
    this.lotsTachesChanges.emit({ isSideNav: isSideNav, lots: this.tachesForm?.get('taches')?.value });
  }

  getTotalCost(column: DisplayColumn): number {
    if (column.total) {
      return this.getColumnTotal(lot => +lot[column.def]?.value);
    } else {
      return 0;
    }
  }

  private getColumnTotal(p: (lot: any) => number): number {
    return this.dataSource.filteredData.map(p).reduce((acc: number, value: number) => acc + value, 0);
  }

  displayColumns(): void {
    this.disColumns = this.columns?.filter(cd => !cd.hide).map(cd => cd.def);
  }

  setFilters(): void {
    this.tacheByLots?.forEach((element: LotLightModel) => {
      this.filter.push(String(element.lot));
    });
    this.filter = Array.from(new Set(this.filter));
    this.dataSource.filterPredicate = (data: LotLightModel, filter: string) => {
      return filter.indexOf(String(data.lot)) !== -1;
    };
  }

  hideLotByDisplayType(afficherOnglets: boolean): void {
    this.columns?.forEach(displayCol => {
      if (displayCol.def === 'lot') {
        displayCol.hide = afficherOnglets;
      }
    });
  }

  applyFilter(lot: string): void {
    this.filterEnable = true;
    this.selected = [lot];
    this.onFilterChange({ columnKey: 'lot', filterValue: [lot] });
    this.getHeaderSFiltersValue(this.dataSource.filteredData);
    this.sourceFiltered = this.dataSource.filteredData;
  }

  isActive(lot: string): boolean {
    return this.selected.includes(lot);
  }

  onFilterChange($event: { columnKey: string; filterValue: string[] }) {
    this.filterColumnsMap.set($event.columnKey, $event.filterValue);
    this.dataSource.filter = 'filter';
    this.filterEnable = $event.filterValue.length !== 0;
    this.filterColumnsMap.forEach((value, key) => {
      this.filterActive.push(key);
      this.filterActive = Array.from(new Set(this.filterActive));
      if (value.length === 0) {
        this.filterActive = this.filterActive.filter(filter => filter !== key);
      }
    });
    this.filterEnable = this.filterActive.length !== 0;
    this.cacheSpan('lot', (d: { lot: any }) => d.lot.value);
  }

  cellSelected(col: DisplayColumn, element: any): void {
    const seletedCell = {
      columnName: col?.def ? col.def : '',
      defNote: col?.defNote ? col.defNote : '',
      idTache: element.idTache,
      editable: col?.editable ? col.editable : false,
    };

    if (col.editable) {
      this.cellSelectedEvent.emit(seletedCell);
    }
  }

  onActionClicked($event: string) {
    const tachesSelected = this.dataSource.data.filter(
      (tache: LotLightModel) => (tache['action' as keyof LotLightModel] as TableCell).selected
    );
    this.deleteAction(tachesSelected, $event);
    this.duplicateAction(tachesSelected, $event);
    this.modifyAction(tachesSelected, $event);
  }

  private deleteAction(tachesSelected: LotLightModel[], $event: string) {
    if (tachesSelected.length > 0 && $event === this.SUPPRIMER) {
      const modal = this.dialog.open(TableDialogDeleteComponent, {
        data: {
          title: 'Suppression Dépense(s)',
          description: `<p>La ou les ligne(s) sélectionnée(s) seront supprimée(s) de manière permanente et irréversible sur le scénario courant.</p>`,
          textGoButton: 'Confirmer',
          textReturnButton: 'Annuler',
        },
      });

      this.subscriptionAction = modal.afterClosed().subscribe(result => {
        if (result) {
          this.actionEvent.emit({ type: $event, selected: tachesSelected });
        }
      });
    }
    this.externalChange = !this.externalChange;
  }

  private duplicateAction(tachesSelected: LotLightModel[], $event: string) {
    if (tachesSelected.length > 0 && $event === this.DUPLIQUER) {
      const modal = this.dialog.open(TableDialogDuplicateComponent, {
        data: {
          title: 'Duplication',
          description: `Vous allez dupliquer la ou les tâche(s) sélectionnée(s)`,
          textGoButton: 'Confirmer',
          textReturnButton: 'Annuler',
        },
      });

      this.subscriptionAction = modal.afterClosed().subscribe(result => {
        if (result?.confirm) {
          this.actionEvent.emit({ type: $event, selected: tachesSelected, param: result.coefficient });
        }
      });
    }
    this.externalChange = !this.externalChange;
  }

  private modifyAction(tachesSelected: LotLightModel[], $event: string) {
    if (tachesSelected.length > 0 && $event === this.MODIFIER) {
      const modal = this.dialog.open(TableDialogEditComponent, {
        data: {
          title: 'Modification',
          description: `Vous allez pouvoir modifier une ou plusieurs catégories sur plusieurs tâches différentes.`,
          textGoButton: 'Confirmer',
          textReturnButton: 'Annuler',
          value: this.regimes.filter(regime => regime !== null),
        },
      });

      modal
        .afterClosed()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.dataSource.data.forEach((tache: LotLightModel) => {
              const selected = tachesSelected.find(tacheSelected => tacheSelected.idTache === tache.idTache);
              if (selected) {
                tache.regime = new TableCell(result);
              }
              (tache['action' as keyof LotLightModel] as TableCell).selected = false;
            });
            this.actionEvent.emit({ type: $event, selected: tachesSelected, param: result });
          }
        });
    }
    this.externalChange = !this.externalChange;
  }

  ngOnDestroy() {
    if (this.subscriptionAction) {
      this.subscriptionAction.unsubscribe();
    }
  }

  getBackgroundColor(col: any, element: any, index: number): string {
    if (element.compareEnabled) {
      return '#FFF0B2';
    }

    if (element[col.def]?.highlight) {
      return '#ffcd00';
    }

    const isEvenRow = (col.span ? +element[col.def]?.value : index) % 2 === 0;
    if (isEvenRow) {
      return col.calculated ? '#f3f3f3' : '#eef4ff';
    } else {
      return col.calculated ? '#e3e3e3' : '#ffffff';
    }
  }

  setCellEditable(element: any, def: string) {
    if (element[def]) {
      element[def].editable = true;
    }
  }

  onSelectAll($event: boolean) {
    this.dataSource.filteredData.forEach((tache: LotLightModel) => {
      (tache['action' as keyof LotLightModel] as TableCell).selected = $event;
    });
  }

  private clearRowSelected(): void {
    const data = this.dataSource?.data;
    if (data?.some(tache => (tache['action' as keyof LotLightModel] as TableCell).selected)) {
      data.forEach(tache => ((tache['action' as keyof LotLightModel] as TableCell).selected = false));
      this.externalChange = !this.externalChange;
    }
  }
}
