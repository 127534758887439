export enum EnumStatutDemandeDocumentComplementaire {
  ENVOYEE = 'Envoyée' as any,
  COMPLETEE = 'Complétée' as any,
  RENVOYEE = 'Renvoyée' as any,
  VALIDEE = 'Validée' as any,
  REJETEE = 'Rejetée' as any,
}

export enum EnumStatutDemandeDocumentComplementaireTech {
  ENVOYEE = 'ENVOYEE',
  COMPLETEE = 'COMPLETEE',
  RENVOYEE = 'RENVOYEE',
  VALIDEE = 'VALIDEE',
  REJETEE = 'REJETEE',
}
