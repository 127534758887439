import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { EnumProjetEtape } from '@shared-ui';

@Component({
  selector: 'lib-fiche-demande-aide-signature-manuelle',
  templateUrl: './fiche-demande-aide-signature-manuelle.component.html',
  styleUrls: ['./fiche-demande-aide-signature-manuelle.component.scss'],
})
export class FicheDemandeAideSignatureManuelleComponent implements OnInit {
  @Input() signatureManuelleForm: FormGroup;
  @Input() identiteFormName: string;
  @Input() etapeProjet: EnumProjetEtape;

  isSignedByRepresentLegal = true;
  identityForm: FormGroup;

  readonly TITLE_FORM =
    'Compléter les informations ci-dessous concernant le signataire de l’entité en charge de la signature de la fiche de demande d’aide';

  ngOnInit(): void {
    this.checkSignedByRepresentLegal();
    this.identityForm = this.signatureManuelleForm.get(this.identiteFormName) as FormGroup;
  }

  checkSignedByRepresentLegal(): void {
    this.signatureManuelleForm?.get('signeeParRepresentantLegal')?.valueChanges.subscribe(value => {
      this.isSignedByRepresentLegal = value;
      if (this.isSignedByRepresentLegal) {
        this.identityForm.reset();
        this.identityForm.disable();
      } else {
        this.identityForm.enable();
      }
    });
  }
}
