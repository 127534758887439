import { EnumValidation } from '../enums/enum.validation';
import { ValidationInterface } from '../interfaces/validation.interface';
import { EnumDocumentInvalide } from "../enums/enum.documentInvalide";

export class Verrouillage implements ValidationInterface {
  statut: EnumValidation;
  commentaire: string;
  motif: EnumDocumentInvalide;
  manualChange: boolean;
  date: string;
}
