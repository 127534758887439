import { Component, Input } from '@angular/core';
import { EnumProcedureSignatureStatut, ProcedureSignatureStructure, Projet, StructureSyntheseHelperService, Utilisateur } from '@shared-ui';

@Component({
  selector: 'lib-procedure-signature-contrat-history',
  templateUrl: './procedure-signature-contrat-history.component.html',
  styleUrls: ['./procedure-signature-contrat-history.component.scss'],
})
export class ProcedureSignatureContratHistoryComponent {
  @Input() proceduresHistory!: ProcedureSignatureStructure[];
  @Input() projet!: Projet;
  @Input() utilisateur!: Utilisateur;

  constructor(private structureSyntheseHelperService: StructureSyntheseHelperService) {}

  getProcedureLink(procedure: ProcedureSignatureStructure) {
    return this.structureSyntheseHelperService.getProcedureLink(procedure, this.utilisateur);
  }

  didUserDeniedSignature(procedure: ProcedureSignatureStructure) {
    return this.structureSyntheseHelperService.didUserDeniedSignature(procedure, this.utilisateur);
  }

  protected readonly EnumProcedureSignatureStatut = EnumProcedureSignatureStatut;
}
